.map-image {
	display: flex;
	flex-direction: row;

	.map {
		height: 300px;
		overflow: hidden;
		width: 50%;
	}

	.image {
		height: 300px;
		width: 50%;

		img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	@media (max-width: '768px') {
		flex-direction: column;
		.image,
		.map {
			width: 100%;
		}
	}
}
