.about-this-location-container {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
	margin-right: 10px;
	padding-top: 20px;

	.features-icon {
		display: flex;
		flex-direction: row;
		height: 25px;
	}
	.features-icon-description {
		padding-left: 14px;
		@include text(15, 22);
		font-family: $font-family-secondary;
		margin-bottom: 14px;
		margin-top: -2px;
	}
	.about-this-location-description {
		flex-direction: column-reverse;
		display: flex;
	}

	@include breakpoint(md) {
		width: 100%;
		display: flex;

		.about-this-location-container {
			display: flex;
			flex-direction: column;
		}
		.about-this-location-description {
			display: flex;
			flex-direction: row;
			max-width: 950px;
		}
		.about-this-location-features {
			margin-left: 50px;
		}
		.features-icon {
			display: flex;
			flex-direction: row;
			height: 34px;
		}
		.features-icon-description {
			white-space: nowrap;
		}
	}
}
