.styleguide-header {
	padding-top: 2rem;
}

.styleguide-divider {
	@include text(kappa);
	align-items: center;
	display: flex;
	margin: 3rem -1rem 1rem;
	text-transform: uppercase;

	&:before,
	&:after {
		content: '';
		flex: 1;
		height: 1px;
		margin: 0 1rem;
	}

	&:before {
		background: linear-gradient(to right, #f6f3f1, black);
	}

	&:after {
		background: linear-gradient(to left, #f6f3f1, black);
	}
}

.styleguide-notes {
	@include text(lambda);
	text-align: center;
	font-family: $font-family-italic;
}

.styleguide-list {
	margin-top: 1rem;
	li {
		padding: 0.375rem 0;
	}
}
