.checkbox-wrapper {
	align-items: flex-start;
	display: flex;
	justify-items: flex-start;
	margin-bottom: 0.75rem;
	position: relative;

	&.disabled {
		pointer-events: none;

		input[type='checkbox'] {
			&:checked {
				+ label {
					&:after {
						background-color: $color-neutral-lightest;
					}
				}
			}

			+ label {
				background-color: $color-white;
				border-radius: 25px;
				color: $color-neutral-lightest;
				display: block;
				padding: 0.625rem 2.5rem 0.625rem 1.25rem;
			}
		}
	}

	input[type='checkbox'] {
		height: 0;
		margin: 0;
		opacity: 0;
		width: 0;

		&:checked {
			+ label {
				background-color: $color-neutral-dark;
				border-color: $color-neutral-dark;
				color: $color-white;

				&:after {
					border-bottom: 1px solid $color-white;
					border-right: 1px solid $color-white;
					content: '';
					display: block;
					height: 0.9rem;
					right: 20px;
					margin: 0.25rem 0.5rem;
					position: absolute;
					top: 50%;
					transform: rotate(45deg)
						translate(calc(-75% - 1px), calc(-75% - 1px));
					width: 0.45rem;
					border-bottom-width: 1px;
					border-right-width: 1px;
				}

				.label-number {
					visibility: hidden;
				}
			}
		}

		&:hover {
			cursor: pointer;
		}
	}

	label {
		@include text(lambda, 20);
		display: flex;
		justify-content: space-between;
		margin: 0;
		padding: 0 0 0 0.5rem;
		width: 100%;
		background-color: $color-white;
		border-radius: 25px;
		padding: 0.625rem 1.25rem;
		transition: all 0.1s ease-in-out;
		border: 1px solid $color-neutral-lighter;

		&:hover {
			cursor: pointer;
			background-color: $color-neutral-light;
			border-color: $color-neutral-light;
		}

		.label-display-name {
			+ .label-name {
				text-align: right;
			}
		}

		.label-name {
			flex-grow: 1;
		}

		.label-number {
			padding-left: 1rem;
		}
	}
}
