.blog-post-hero {
	margin: 0 auto;
	overflow: hidden;
	padding: 0;
	width: 100%;
	position: relative;
	padding-bottom: 55.55%;

	.blog-post-hero-img {
		display: block;
		width: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		img,
		source,
		canvas {
			object-fit: cover;
			object-position: top;
			width: 100%;
			height: 100%;
			aspect-ratio: 720 / 400;
		}
	}
	@include breakpoint(md) {
		padding-bottom: 31.25%;

		.blog-post-hero-img {
			img,
			source,
			canvas {
				aspect-ratio: 2000 / 625;
			}
		}
	}

	@include breakpoint(xl) {
		margin: 0 auto;
		overflow: hidden;
		padding: 0;
		width: 100%;

		.blog-post-hero-img {
			display: block;
			position: relative;
			max-height: 492px;
			min-height: 350px;
			width: 100%;

			img,
			source,
			canvas {
				object-fit: cover;
				object-position: top;
				width: 100%;
				height: 100%;
				min-height: 350px;
			}
		}
	}
}
