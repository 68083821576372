.topic-index {
	padding: 2rem 0 3rem;

	.index-link-list {
		margin-bottom: 2rem;

		li {
			font-family: $font-family-secondary;

			&:first-child {
				.text-link {
					padding-top: 0;
				}
			}
		}
	}

	.text-link {
		display: inline-block;
		padding: 12px 0;
	}

	.section-title {
		margin-bottom: 45px;
	}

	@include breakpoint(md) {
		padding: 41px 0 78px;

		.container {
			flex-direction: row;
		}

		.index-row {
			@include make-row();
			flex-grow: 1;
		}

		.index-col {
			@include make-col-ready();
			@include make-col(6);
		}

		.index-link-list {
			margin-bottom: 3rem;
		}
	}

	@include breakpoint(lg) {
		.index-col {
			@include make-col(4);
		}

		.index-link-list {
			&.three-column {
				columns: 2;
			}
		}
	}

	@include breakpoint(xl) {
		.index-col {
			@include make-col-ready();
			@include make-col(3);
		}

		.index-link-list {
			&.three-column {
				columns: 3;
			}
		}
	}
}
