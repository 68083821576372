div[id^='form-Subscribe'] {
	display: none;
}

.subscription-form {
	background: $color-qualderm-blue;

	.subscription-container {
		@include make-container();
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-bottom: 30px;
		padding-top: 30px;
		gap: 30px;
	}

	.subscription-row {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 30px;
	}

	.subscription-info {
		display: flex;
		flex-direction: column;
		gap: 5px;

		.headline-large {
			color: $color-white;
			font-weight: $font-weight-regular;
			margin: 0;
		}

		p {
			@include text(15, 22);
			color: $color-white;
			font-family: $font-family-secondary;
			font-style: normal;
			font-weight: 400;
			margin: 0;
		}
	}

	div[id^='form-Subscribe'] {
		display: block;

		input[type='submit'] {
			@include text(16, 16);
			border-radius: 6px;
			border: 1px solid $color-qualderm-gray;
			background: $color-qualderm-blue;
			display: inline-flex;
			padding: 12px 20px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			color: $color-white;
			display: flex;
			height: auto;
			width: 100%;
			cursor: pointer;
			transition: all 0.3s;
			min-width: 162px;

			&:hover {
				filter: brightness(0.9);
			}

			&:active {
				filter: brightness(1.1);
			}
		}

		.ktc-default-section {
			margin-bottom: 16px;
		}

		.form-field {
			margin-bottom: 0;

			&:has([type='email']) {
				.control-label {
					@include text(14);
					color: $color-white;
					font-family: $font-family-regular;
					text-transform: uppercase;
				}
			}

			&:has([id$='Title_Value']),
			&:has([id$='Description_Value']),
			&:has([id$='SiteOrigin_Value']),
			&:has([id$='WebEmailConsent_Value']) {
				display: none;
			}
		}

		.explanation-text {
			@include text(14, 18);
			color: $color-white;
			font-family: $font-family-secondary;
			margin: 0;
		}
	}

	.subscription-confirmation {
		display: none;
		padding: 0px 12px;

		&.show {
			display: block;
		}

		p {
			@include text(15, 22);
			color: $color-white;
			font-family: $font-family-secondary;
			font-style: normal;
			font-weight: 400;
			margin: 0;
		}
	}

	.formwidget-submit-text {
		@include text(15, 22);
		color: $color-white;
		font-family: $font-family-secondary;
		font-style: normal;
		font-weight: $font-weight-medium;
		margin: 0;
		text-align: center;
	}

	@include breakpoint(md) {
		.subscription-row {
			@include make-row();
			align-items: center;
			flex-direction: row;
			gap: 0;
		}

		.subscription-info {
			@include make-col(6);
		}

		div[id^='form-Subscribe'] {
			@include make-col(6);

			form {
				display: flex;
				justify-content: flex-end;
				gap: 20px;
			}

			.ktc-default-section {
				flex: 1;
				margin-bottom: 0;
				max-width: 330px;
			}

			input[type='submit'] {
				align-self: flex-start;
				flex: 0 0 30%;
				margin-top: 21px;
				max-width: 164px;
				min-width: 0;
			}
		}

		.formwidget-submit-text {
			@include make-col(6);
			text-align: right;
		}
	}

	@include breakpoint(lg) {
		div[id^='form-Subscribe'] {
			@include make-col(5);
		}

		.formwidget-submit-text {
			@include make-col(5);
		}
	}
}
