.provider-profile-background {
	.background-block {
		margin-bottom: 30px;
	}

	.headline-small {
		margin-bottom: 0.375rem;
	}

	.background-list {
		list-style: none;
	}

	.background-item {
		@include text(kappa);
		font-family: $font-family-secondary;
		margin-bottom: 0.25rem;
	}

	@include breakpoint(md) {
		.background-columns {
			@include make-row();
		}

		.background-column {
			@include make-col(4);
		}

		.background-item {
			@include text(lambda);
		}
	}

	@include breakpoint(lg) {
		.background-item {
			@include text(kappa);
		}
	}
}
