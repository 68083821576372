.blockquote {
	background-color: $color-letter-charcoal;
	border-radius: 10px;
	display: flex;
	flex-direction: column;

	img {
		margin: 24px;
		max-width: 200px;
	}

	.blockquote-inner {
		@include text(32, 35);
		background-color: $color-letter-charcoal;
		color: $color-qualderm-green;
		font-family: $font-family-italic;
		padding: 24px 27px 29px 24px;
		display: flex;
		flex-direction: column;
		border-radius: 10px;

		.icon-container {
			margin-left: 9px;
			margin-bottom: 19px;
		}

		.icon {
			height: 26.51515px;
			width: 35px;
		}
	}

	@include breakpoint(md) {
		flex-direction: row;

		.blockquote-inner {
			padding: 30px 94px 30px 30px;
			flex-direction: row;

			.icon-container {
				margin-bottom: 0;
				margin-left: 0;
				margin-right: 29px;
			}
		}
	}
}
