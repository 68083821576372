.location-profile-providers {
	width: 100%;

	.cards-container {
		display: flex;
		flex-wrap: wrap;
		margin-top: 30px;
		justify-content: space-between;
	}

	.provider-profile-card {
		display: flex;
		width: 22rem;
		height: 6.75rem;
		background-color: $color-white;
		border-radius: 6px;
		margin-bottom: 1.5rem;
		padding: 12px;
	}

	.provider-profile-info {
		margin-left: 12px;
	}

	.provider-profile-name {
		@include text(lambda, 18);
		color: $color-qualderm-blue;
		font-family: $font-family-secondary;
		font-weight: $font-weight-bold;

		&:hover {
			text-decoration: underline;
		}
	}

	.accepting-new-patients-label {
		@include text(lambda, 18);
		color: $color-black;
		font-weight: $font-weight-regular;
	}

	.accepting-new-patients-wrap {
		margin-top: -5px;
	}

	.ratings {
		margin-top: 5px;
	}

	.view-all {
		display: block;
		width: 100%;
		text-align: center;
	}

	@include breakpoint(xs) {
		.provider-profile-card {
			width: 100%;
		}
	}

	@include breakpoint(md) {
		.cards-container {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 24px;
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@include breakpoint(lg) {
		.cards-container {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}
