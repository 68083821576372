@mixin heading-0 {
	@include text(beta, 54);
	color: $color-light-black;
	font-weight: $font-weight-light;
	margin-bottom: 1.25rem;
}

@mixin heading-1 {
	@include text(34, 38);
	color: $color-light-black;
	font-weight: $font-weight-light;
	margin-bottom: 1.25rem;
}

@mixin heading-2 {
	@include text(eta, 26);
	font-weight: $font-weight-light;
	margin-bottom: 0.3rem;
}

@mixin heading-2-regular {
	@include text(eta, 26);
	font-weight: $font-weight-regular;
	margin-bottom: 0.3rem;
}

@mixin heading-3 {
	@include text(gamma);
	font-weight: $font-weight-medium;
	letter-spacing: 0.15rem;
	margin-bottom: 1rem;
	text-transform: uppercase;
}

@mixin heading-4 {
	@include text(gamma, 37);
	color: $color-neutral-darker;
	font-weight: $font-weight-semi-bold;
	margin-bottom: 1rem;
}

@mixin heading-5 {
	@include text(eta);
	color: $color-neutral-darker;
	font-weight: $font-weight-semi-bold;
	margin-bottom: 0.875rem;

	&:not(:first-of-type) {
		border-top: 1px solid $color-neutral-light;
		margin-top: 50px;
		padding-top: 50px;
	}
}

@mixin heading-6 {
	@include text(iota);
	color: $color-neutral-darker;
	font-weight: $font-weight-bold;
	margin: 0;
}

@mixin intro-headline {
	@include text(alpha);
	color: $color-light-black;
	font-weight: $font-weight-light;
}

@mixin headline {
	@include text(eta, 26);
}

@mixin module-title {
	@include text(mu);
	font-weight: $font-weight-medium;
	letter-spacing: 0.15rem;
	text-transform: uppercase;
}

@mixin page-title {
	@include text(iota);
	font-weight: $font-weight-bold;
}

@mixin section-title {
	@include text(kappa);
	font-weight: $font-weight-semi-bold;
	text-transform: uppercase;
	letter-spacing: 0.84px;
	margin-bottom: 1.25rem;
}

@mixin content-title {
	@include text(eta);
	font-weight: $font-weight-bold;
	color: $color-light-black;
	margin-bottom: 1rem;
}

@mixin content-subtitle {
	@include text(epsilon);
	font-weight: $font-weight-light;
	color: $color-light-black;
	margin-bottom: 1rem;
}

@mixin subtitle {
	@include text(iota);
	font-weight: $font-weight-bold;
}

@mixin hero-content-title {
	@include text(34, 38);
	color: $color-light-black;
	font-family: $font-family-regular;
	font-weight: $font-weight-regular;
	margin-bottom: 1.25rem;
}

@mixin hero-content-paragraph {
	@include text(34, 38);
	color: $color-neutral-darker;
	font-family: $font-family-regular;
	font-weight: $font-weight-light;
	margin-bottom: 1.25rem;
}
