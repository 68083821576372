.show-on-mobile {
	display: block;
	& .flex {
		display: flex;
	}
	@include breakpoint(md) {
		display: none;
	}
}

.hide-on-mobile {
	display: none;
	@include breakpoint(md) {
		display: block;
	}
}

main {
	// Header Height: 70px;
	// Subscription Height: 324px;
	// Footer Height: 751px;
	min-height: calc(100vh - 70px - 324px - 751px);

	@include breakpoint(md) {
		// Header Height: 70px;
		// Subscription Height: 159px;
		// Footer Height: 379px;
		min-height: calc(100vh - 70px - 159px - 379px);
	}

	@include breakpoint(944px) {
		// Header Height: 70px;
		// Subscription Height: 151px;
		// Footer Height: 337px;
		min-height: calc(100vh - 70px - 151px - 337px);
	}

	@include breakpoint(lg) {
		// Header Height: 150px;
		// Subscription Height: 151px;
		// Footer Height: 337px;
		min-height: calc(100vh - 150px - 151px - 337px);
	}
}
