/*
 *
 * COLOR VARIABLES
 *
 */

/* Monochromes */
$color-black: #000;
$color-light-black: #333;
$color-light-blue: #0c9ef0;
$color-neon-silver: #c9cbcb;
$color-neutral-darker: #181922;
$color-neutral-dark: #525259;
$color-neutral: #8b8b90;
$color-neutral-light: #b1b1b3;
$color-neutral-lighter: #e3dbd1;
$color-neutral-lightest: #f0ebe6;
$color-neutral-lightest-2: #f2f2f2;
$color-neutral-lightest-3: #ffffff;
$color-white: #fff;
$color-gray: #cdcdcd;
$color-gray-light: #cccccc;
$color-light-gray: #dddddd;
$color-gray-dark: #989898;
$color-gray-dark-2: #666666;
$color-gray-dark-3: #464646;
$color-gray-neutral-ligth: #707070;
$color-charcoal: #1d1d1d;
$color-letter-charcoal: #1d1d1d;
$color-background-hover-nav: #eeeeee;
$color-gray-lightest: #f9f9f9;
$color-warm-gray: #f6f3f1;
$color-qualderm-gray: #888b8d;

/* Greens */
$color-green-dark: #4e8209;
$color-green: #64a70b;
$color-green-light: #7cba25;
$color-green-lighter: #a1d45f;
$color-green-lightest: #c7e6a1;
$color-green-darker: #5a8027;
$color-green-darker-2: #508117;
$color-green-darker-3: #7db338;
$color-green-mid-light: #e3f2d0;
$color-qualderm-green: #9bc158;

/* Blues */
$color-blue-dark: #2178bc;
$color-blue: #53b9d1;
$color-blue-light: #2bb9d9;
$color-blue-lighter: #74d3e8;
$color-blue-lightest: #eefafe;
$color-blue-links: #046791;
$color-qualderm-blue: #0c5688;
$color-deep-blue: #002742;

/* Other */
$color-red: #ab405b;
$color-critical: #e52b24;
$color-warning: #ffd905;
$color-gold: #ff8c00;
