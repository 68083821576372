.meet-the-providers {
	width: 100%;

	.cards-container {
		display: flex;
		flex-wrap: wrap;
		gap: 1.5625rem;
		justify-content: space-between;
	}

	.medium-provider-card {
		flex-grow: 1;
	}

	.provider-profile-name {
		@include text(kappa);
		color: $color-qualderm-blue;
		font-family: $font-family-tertiary;
		font-weight: $font-weight-bold;

		&:hover {
			text-decoration: underline;
		}
	}

	.provider-profile-info {
		margin-left: 12px;
	}
}
