.two-up-rich-text {
	.section {
		border-bottom: 1px solid $color-neon-silver;

		.headline-small,
		.headline-medium {
			border-bottom: 1px solid $color-neon-silver;
			padding-bottom: 0.375rem;
		}
	}

	@include breakpoint(sm) {
		.rich-text {
			ul {
				columns: 2;
			}
		}
	}

	@include breakpoint(md) {
		display: flex;
		gap: 1.5rem;

		.section {
			flex-basis: 50%;
			min-width: 50%;
		}
	}
}
