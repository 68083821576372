.related-topics {
	.related-title {
		@include text(eta);
		color: $color-light-black;
		font-weight: $font-weight-semi-bold;
		margin-bottom: 1rem;
		margin-left: 0.75rem;
	}

	.related-link {
		@include text(iota);
		align-items: center;
		background-color: $color-neutral-darker;
		border-radius: 4px;
		color: $color-white;
		display: flex;
		font-weight: $font-weight-regular;
		height: 50px;
		justify-content: center;
		margin-bottom: 1rem;
		padding: 0.75rem;
		width: 100%;
	}
}
