.provider-ratings {
	.average-rating {
		font-family: $font-family-bold;
		margin: 0;
		padding-right: 0.625rem;
	}
	.section-title {
		padding-top: 1.5rem;
	}

	.rating-description {
		margin-bottom: 1rem;
	}
}

.rating-main {
	@include text(alpha);
	font-family: $font-family-bold;
	padding-right: 0.25em;
}
.review-number {
	@include text(kappa);
	position: absolute;
	top: 35px;
}
.rating-review {
	@include text(kappa, 20);
	border-top: 1px solid;
	display: flex;
	padding: 0.625em 0;

	.rating-date {
		margin-left: 0.5rem;
	}
}
.rating-stars {
	height: 30px;
}
.ratings-wrapper {
	display: flex;
	position: relative;
	align-items: center;
	margin-bottom: 1em;
}
