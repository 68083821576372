/* Package Specific Styling */
@import './node_modules/lite-youtube-embed/src/lite-yt-embed';

lite-youtube {
	max-width: none;
}

/* Global (reset, functions, variables, mixins, helpers) */
@import 'global';

/* Atoms (basic HTML elements e.g. inputs, labels, buttons) */
@import 'atoms';

/* Molecules (e.g. Hero, Primary Navigation, Search) */
@import 'molecules';

/* Organisms (e.g. Header, Footer, Article, SitecoreComponent) */
@import 'organisms';

/* Pages (e.g. Search Page, Article Page) */
@import 'pages';

/* Templates (e.g. Home, Full Width, Two Column) */
@import 'templates';

/* Print (e.g. Provider Print, Location Print) */
@import 'print';
