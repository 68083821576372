.medium-provider-card {
	display: flex;
	width: 28rem;
	height: 14rem;
	background-color: $color-white;
	border-radius: 6px;
	margin-bottom: 1.5rem;
	padding: 12px;

	.provider-profile-info {
		padding-top: 4rem;
		margin-left: 12px;
	}
}
