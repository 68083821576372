.control-toggles {
	justify-content: flex-start;
	display: flex;
	margin-bottom: 1rem;
	padding: 0 0.5rem;
	position: relative;

	.control-toggle-btns {
		display: flex;
		width: 100%;
	}

	.filter-count {
		display: none;
	}

	.control-toggle {
		@include text(lambda);
		align-items: center;
		background-color: $color-white;
		color: $color-qualderm-gray;
		display: flex;
		font-weight: $font-weight-bold;
		height: 50px;
		justify-content: center;
		text-transform: uppercase;
		transition: background-color 0.1s ease-in-out;
		width: 50px;

		.icon {
			height: 20px;
			transition: fill 0.1s ease-in-out, stroke 0.1s ease-in-out;
			width: 20px;
			stroke: $color-qualderm-gray;
			fill: $color-qualderm-gray;
		}

		.icon-columns {
			transform: rotate(90deg);
		}

		.mobile-controls-open & {
			&.active {
				background-color: $color-neutral-dark;

				.icon {
					display: none;
					stroke: $color-white;
					fill: $color-white;
				}
			}
		}

		&:hover {
			color: $color-qualderm-green;
			cursor: pointer;

			.icon {
				fill: $color-qualderm-green;
				stroke: $color-qualderm-green;
			}
		}
	}

	.view-toggles-container {
		display: flex;
		margin-left: auto;
	}

	.view-toggle {
		&[disabled] {
			background-color: $color-warm-gray;
			cursor: default;
			pointer-events: none;

			.icon {
				fill: $color-black;
				stroke: $color-black;
			}
		}
	}

	@include breakpoint(md) {
		margin-right: 15px;
	}

	@include breakpoint(lg) {
		margin-bottom: 0;
		margin-right: 0;

		.filter-count {
			@include text(kappa);
			align-items: center;
			color: $color-qualderm-blue;
			display: flex;
			font-family: $font-family-bold;
			height: 50px;
			left: 0;
			margin-right: 0.5rem;
			padding: 0 8px 0 0;
			position: absolute;
			transform: translateX(-100%);
		}

		.control-toggle-btns {
			padding-right: 0;
			flex-direction: column;
			width: 100%;
		}

		.control-toggle {
			margin-right: 0;
			margin-bottom: 2px;
			width: 100%;

			&:first-of-type {
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
			}

			&:last-of-type {
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
			}

			.icon {
				stroke: $color-qualderm-gray;
				fill: $color-qualderm-gray;
			}

			&.active {
				.icon {
					stroke: $color-black;
					fill: $color-black;
				}
			}
		}

		.view-toggle {
			display: none;
		}
	}
}
