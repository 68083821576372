.back-to-top {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 0.875rem;
	margin-right: 0.75rem;

	.back-to-top-btn {
		background-color: $color-white;
		border-radius: 100%;
		height: 40px;
		width: 40px;
		cursor: pointer;

		&:hover {
			background-color: $color-blue-lightest;
		}
	}

	.icon-download-file {
		display: block;
		margin: auto;
		transform: rotate(180deg) scale(1.2);
	}

	@include breakpoint(md) {
		margin-right: 0;
	}
}
