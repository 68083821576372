.search-page {
	min-height: 100vh;
	margin-top: -32px;

	.container & {
		margin-left: -20px;
		width: 100vw;
	}

	.title-and-description {
		margin-bottom: 20px;
		.headline-large {
			margin-bottom: 9px;
		}

		.intro-text {
			@include text(kappa, 20);
			margin-bottom: 0;
		}
	}

	.search-page-controls {
		display: block;
	}

	.search-page-wrapper {
		display: block;

		&.map-view {
			.map-view-collapse-container {
				display: none;
			}

			.search-page-results {
				position: relative;
			}

			.results-list-outer {
				position: relative;
			}

			.results-list-container {
				background-color: rgba($color-black, 0.5);
				position: absolute;
				top: 0;
				left: -20px;
				height: 100%;
				width: 100vw;
			}

			.no-results-message {
				margin: 0;
				background-color: $color-white;
				box-shadow: 0 -5px 23px 4px rgba(0, 0, 0, 0.15);
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: calc(100% - 40px);
				padding: 30px 20px;
				border-radius: 10px;
			}
		}
	}

	.search-landing {
		display: none;
	}

	.selected-filters-list {
		.tag-list {
			padding-bottom: 0.5rem;
		}

		+ .input-icon-container {
			border-top: 2px solid $color-gray-light;
		}
	}

	.icon-interaction-btn {
		@include text(lambda);
		align-items: center;
		color: $color-black;
		display: flex;
		font-family: $font-family-bold;
		text-transform: uppercase;

		&[disabled] {
			color: $color-neutral-light;

			.icon {
				fill: $color-neutral-light;
			}
		}

		.icon-close-alt {
			fill: $color-qualderm-green;
			height: 12px;
			margin-right: 5px;
			width: 12px;
		}

		.icon-arrow-left {
			fill: $color-qualderm-green;
			height: 12px;
			margin-right: 5px;
			width: 22px;
		}

		.icon-arrow-right {
			fill: $color-qualderm-green;
			height: 12px;
			margin-left: 5px;
			width: 22px;
		}
	}

	.open-filters-btn {
		display: none;
	}

	.heading-1 {
		@include text(gamma, 40);
		color: $color-neutral-darker;
		font-weight: $font-weight-medium;
	}

	.input-icon-container {
		margin: 0;
		position: relative;
		z-index: 1;

		.icon-search {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
		}
	}

	.active-filter-toggle {
		align-items: center;
		background-color: $color-neutral-dark;
		border-radius: 50%;
		display: flex;
		height: 40px;
		justify-content: center;
		min-width: 40px;
		transition: background-color 0.1s ease-in-out;
		width: 40px;

		span {
			color: $color-white;
		}
		.icon {
			height: 20px;
			stroke: $color-white;
			transition: stroke 0.1s ease-in-out;
			width: 20px;
			&:hover {
				stroke: $color-black;
			}
		}

		&:hover {
			background-color: $color-blue-lightest;
			cursor: pointer;
			span {
				color: $color-black;
			}
		}
	}

	.search-loaded & {
		min-height: 0;
	}

	.results-list {
		li {
			margin: 0;

			&:before {
				display: none;
			}
		}
	}

	.map-container {
		height: 70vh;
		min-height: 520px;
		position: relative;
		width: 100vw;
		margin-left: -20px;
		margin-bottom: -64px;

		.location-listing-card {
			box-shadow: 0 -5px 23px 4px rgba(0, 0, 0, 0.15);
			position: absolute;
			bottom: 15px;
			width: calc(100% - 30px);
			left: 15px;
			margin-bottom: 0;
			max-width: none;
			overflow: hidden;

			&.no-results-card {
				z-index: 9;
				position: absolute;
				top: 0;
			}

			&.multi-location-card {
				.map-result-close-btn {
					top: 15px;
				}

				.location-picture {
					width: 100%;
				}
			}

			.location-picture {
				width: calc(100% - 28px);
			}

			.map-result-close-btn {
				position: absolute;
				right: 15px;
				top: 17px;

				.icon {
					fill: $color-qualderm-green;
				}

				&:hover {
					cursor: pointer;
				}
			}

			.map-result-pagination {
				align-items: center;
				display: flex;
				justify-content: center;
				padding-top: 8px;
				padding-bottom: 8px;
				border-bottom: 2px solid $color-warm-gray;
				width: calc(100% + 24px);
				margin-left: -12px;
				margin-top: -11px;
				margin-bottom: 1rem;

				button {
					&[disabled] {
						.icon {
							fill: $color-neutral-light;
						}
					}
					padding: 0 20px;
				}

				.icon {
					fill: $color-qualderm-blue;

					&.left {
						transform: rotate(180deg) translateY(-4px);
					}
				}

				p {
					@include text(lambda);
					color: $color-light-black;
					font-family: $font-family-bold;
					margin: 0;
				}
			}
		}

		.marker-label {
			bottom: 2px;
			position: relative;

			&.active-marker {
				bottom: 3px;
			}
		}
	}

	.icon-filter {
		fill: $color-black;
	}

	.mobile-search-page-controls {
		background-color: $color-white;
		border-top: 1px solid $color-background-hover-nav;
		box-shadow: 0px 11px 8px -8px rgba(85, 85, 85, 0.35);
		width: 100vw;
		margin-left: -20px;
		position: sticky;
		top: 0;
		z-index: 7;
		transition: top 0.3s ease-in-out;

		.filter-count {
			@include text(mu);
			color: $color-white;
			background-color: $color-qualderm-green;
			align-items: center;
			display: flex;
			position: absolute;
			justify-content: center;
			top: 8px;
			left: 45px;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			pointer-events: none;
		}

		.control-toggle.reset {
			display: none;
		}

		.scroll-up:not(.navbar-fixed) + .layout-full-width & {
			top: 69px;
		}

		.navbar-fixed.scroll-up + .layout-full-width & {
			top: 69px;
		}

		.navbar-fixed.scroll-up.scroll-down + .layout-full-width & {
			top: 0;
		}

		.mobile-nav-open & {
			top: 100vh !important;
		}
	}

	.search-page-controls {
		.control-toggles {
			display: none;
		}
	}

	.controls-container {
		background-color: $color-white;
		height: 100%;
		left: 0;
		opacity: 0;
		overflow: auto;
		// padding: 20px 15px 30px;
		pointer-events: none;
		position: fixed;
		top: -20vh;
		transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out,
			top 0.2s ease-in-out;
		visibility: hidden;
		width: 100%;
		z-index: 9;

		.mobile-controls-open & {
			display: block;
			opacity: 1;
			pointer-events: auto;
			top: 0;
			transition-delay: 0s;
			visibility: visible;
		}

		.mobile-controls-results-btn {
			@include text(iota);
			background-color: $color-qualderm-blue;
			bottom: 0;
			box-shadow: 0px 5px 5px $color-neutral;
			color: $color-white;
			font-weight: $font-weight-medium;
			left: 1em;
			position: fixed;
			width: calc(100% - 2em);
			z-index: 2;

			.search-type-count {
				font-weight: $font-weight-regular;
				margin-left: auto;
				margin-right: 0.5rem;
			}
			.search-type-match {
				text-transform: capitalize;
			}
			.icon-arrow-up {
				fill: none;
				margin-left: 0;
				stroke: $color-white;
				transform: rotate(90deg);
			}

			&:hover,
			&:focus {
				background-color: $color-neutral;
				color: $color-white;

				.icon-arrow-up {
					stroke: $color-white;
				}
			}
		}
	}

	.clear-all-btn {
		color: $color-blue;
		cursor: pointer;
		text-decoration: underline;

		&:hover {
			color: $color-blue-dark;
			text-decoration: none;
		}
	}

	.controls-top {
		align-items: flex-start;
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.view-mode-controls-container {
		align-items: center;
		display: none;
		justify-content: space-between;
	}

	.view-btn {
		align-items: center;
		display: flex;
		justify-content: center;
		margin-right: 0.625rem;

		&:last-of-type {
			margin-right: 0;
		}

		&:hover,
		&:focus {
			cursor: pointer;

			.icon {
				fill: $color-neutral-darker;
			}
		}

		&[disabled] {
			cursor: default;

			.icon {
				fill: $color-neutral-darker;
			}
		}

		.icon {
			fill: $color-neutral;
			height: 17px;
			width: 17px;
		}
	}

	.results-controls {
		margin-bottom: 10px;

		.controls-bottom {
			display: flex;
			justify-content: space-between;

			.mobile-filters-toggle,
			.mobile-sort-toggle {
				@include text(lambda);
				background-color: transparent;
				border: none;
				color: $color-white;
				flex: 0 0 33.3333%;
				max-width: 33.3333%;
				padding: none;
			}
		}
	}

	.results-count {
		@include text(lambda);
		align-items: center;
		color: $color-black;
		display: flex;
		font-family: $font-family-bold;
		text-transform: uppercase;
	}

	.controls-container {
		.control-panel {
			display: none;

			&.active {
				display: block;
			}
		}
	}

	.clipboard-notification {
		margin-left: 1rem;
		opacity: 0;
		transition: opacity 0.4s;

		&.active {
			opacity: 1;
		}
	}

	.search-page-results {
		.no-results-message {
			margin: 4rem auto;
			max-width: 650px;

			.heading-1 {
				@include text(34, 38);
				margin-bottom: 1rem;
			}

			.heading-2 {
				@include text(delta);
			}

			li {
				@include text(iota);
			}
		}
	}

	.results-list-container {
		position: relative;
		min-height: 320px;

		&.loading {
			pointer-events: none;

			.loading-overlay {
				opacity: 1;
			}
		}
	}

	.contracted-link-container {
		display: none;
	}

	.contracted-link {
		@include text(lambda);
		color: $color-blue;
		display: none;
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	.filter-title-bar {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;

		.filter-btn {
			margin-bottom: 0;
		}
	}

	.filter-heading {
		@include text(iota, 25);
		display: none;
		font-weight: 500;
	}

	.filter-title {
		@include text(lambda);
		font-weight: 400;
		margin: 0 0 1rem;
		padding-left: 20px;
	}

	.tag-list {
		margin-top: 0;
		padding: 0 0 1rem;
		position: relative;
	}

	.sort-panel {
		.control-toggle-btns {
			padding: 1rem 30px 6rem;
		}
	}

	.react-autosuggest__container {
		position: relative;
		z-index: 1;
	}

	.react-autosuggest__input {
		border-radius: 0;
		&--open,
		&:focus {
			+ .react-autosuggest__suggestions-container {
				display: block;
			}
		}
	}

	.location-search-input {
		&:focus {
			+ .react-autosuggest__suggestions-container {
				display: block;
			}
		}
	}

	.distance-link {
		.location {
			color: $color-qualderm-blue;
		}
	}

	.react-autosuggest__suggestions-container {
		background-color: $color-white;
		border-bottom-left-radius: 25px;
		border-bottom-right-radius: 25px;
		box-shadow: 0px -5px 23px 4px rgba($color-black, 0.15);
		display: none;
		margin-top: -20px;
		padding: 30px 10px 0;
		position: absolute;
		top: 100%;
		width: 100%;
		z-index: -1;

		[aria-selected='true'] {
			.typeahead-suggestion {
				background-color: $color-neutral-light;
			}
		}

		.filter-title {
			color: $color-neutral-dark;
		}
	}

	.typeahead-suggestion {
		@include text(lambda, 20);
		background-color: rgba($color-white, 0.5);
		border-radius: 25px;
		display: flex;
		justify-content: space-between;
		margin: 0 0 0.75rem;
		padding: 0 0 0 0.5rem;
		padding: 0.625rem 1.25rem;
		width: 100%;

		&:hover,
		&:focus,
		&[aria-selected='true'],
		&.selected {
			background-color: $color-neutral-light;
			cursor: pointer;
		}

		mark {
			background-color: $color-green-lighter;
		}

		.suggestion-name {
			flex-grow: 1;
		}

		.suggestion-number {
			padding-left: 1rem;
		}
	}

	.filter-btn-container {
		display: flex;

		&:last-of-type {
			.filter-btn {
				margin-bottom: 0;
			}
		}

		.react-autosuggest__container {
			flex: 1;
		}

		.geolocate-btn {
			flex: 0;
			height: 60px;
			margin-left: 0.75rem;
			flex-basis: 90px;
			max-width: 90px;
		}
	}

	.geolocate-btn {
		align-items: center;
		color: $color-blue-dark;
		display: flex;
		height: 40px;
		justify-content: center;
		margin-bottom: 10px;
		width: 100%;

		&:hover,
		&:focus {
			color: $color-black;
		}

		&.geolocating {
			justify-content: flex-start;
			text-decoration: none;

			&:after {
				-webkit-animation: ellipsis steps(4, end) 900ms infinite;
				animation: ellipsis steps(4, end) 900ms infinite;
				content: '\2026'; /* ascii code for the ellipsis character */
				display: inline-block;
				overflow: hidden;
				position: absolute;
				bottom: 11px;
				left: calc(100% - 20px);
				vertical-align: bottom;
				width: 0px;
			}

			@keyframes ellipsis {
				to {
					width: 20px;
				}
			}
		}
	}

	.location-typeahead {
		.react-autosuggest__container {
			margin-bottom: 0.5rem;
		}
	}

	.distance-filter-wrapper {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
	}

	.distance-radio {
		display: flex;
		flex: 0 0 160px;

		.distance-radio-wrapper {
			flex: 1;
			height: 40px;
			width: 40px;
			position: relative;
			margin-right: 0.375rem;

			input[type='radio'] {
				height: 0;
				width: 0;

				&:checked {
					+ label {
						background-color: $color-neutral-dark;
						color: $color-white;
					}
				}

				&:focus,
				&:hover {
					+ label {
						background-color: $color-neutral-light;
					}

					&:checked {
						+ label {
							background-color: $color-neutral-dark;
							color: $color-white;
						}
					}
				}
			}

			label {
				@include text(lambda);
				align-items: center;
				background-color: rgba($color-white, 0.5);
				border-radius: 25px;
				color: $color-black;
				display: flex;
				height: 40px;
				justify-content: center;
				position: absolute;
				top: 0;
				width: 40px;

				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.distance-label {
		@include text(mu);
	}

	.filter-btn-wrapper {
		display: flex;

		.filter-btn {
			background-color: rgba($color-white, 0.5);

			span {
				color: $color-green-dark;
				margin-left: auto;
			}

			&:hover {
				background-color: $color-blue-lightest;
				cursor: pointer;
			}
			&:hover span {
				color: $color-black;
				cursor: pointer;
			}
		}
	}

	.preferred-provider-info {
		display: none;
		padding: 0 1.25rem 1.5rem;

		&.active {
			display: block;
		}
	}
	.search-print {
		display: none;
	}

	.provider-result-card {
		.provider-picture {
			aspect-ratio: 41 / 49;
			object-fit: cover;
			object-position: top;
		}
	}
	@include breakpoint(md) {
		margin-top: -50px;

		.mobile-search-page-controls {
			margin-left: -30px;
		}

		.map-container {
			position: relative;
			width: 100%;
			margin-left: 0;
			margin-bottom: -64px;

			.location-listing-card {
				position: absolute;
				top: 15px;
				bottom: auto;
				left: 15px;
				width: 70%;
				margin-bottom: 0;
				padding: 31px 42px 31px;
				padding-top: 40px;

				&.multi-location-card {
					padding-top: 50px;

					.location-picture {
						width: 164px;
					}
				}

				.location-picture {
					width: 164px;
				}

				.map-result-close-btn {
					top: 12px;
					right: 12px;
				}

				.map-result-pagination {
					position: absolute;
					top: 0;
					width: 100%;
					left: 0;
					padding: 10px 50px 10px 42px;
					margin: 0;
				}

				.text-link {
					@include text(lambda);
				}
			}
		}

		.search-page-controls {
			display: block;
		}

		.search-page-wrapper {
			&.map-view {
				.results-list-container {
					background-color: rgba($color-black, 0.5);
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}

		.results-controls {
			margin-left: 15px;
			margin-bottom: 1rem;
		}

		.results-list {
			padding: 0 $md-grid-outer-padding;
			@media screen {
				&.view-mode-grid {
					display: flex;
					flex-wrap: wrap;

					.results-list-item {
						flex: calc(50% - 15px);
						margin-bottom: 3.25rem;
						max-width: calc(50% - 15px);
						&:nth-of-type(2n + 1) {
							margin-right: 15px;
						}
						&:nth-of-type(2n + 2) {
							margin-left: 15px;
						}
					}

					.search-result {
						display: flex;
						flex-direction: column;
						height: 100%;
						margin-bottom: 0;

						.result-top {
							height: 100%;
						}

						.result-details {
							display: flex;
							flex-direction: column;
						}

						.detail-bottom {
							border: none;
							margin-top: auto;
							padding-top: 0;
						}

						.ratings {
							height: 21px;
						}
					}
				}
			}
		}

		.map-container {
			height: 0;
			padding-bottom: 71.62%;
			margin-bottom: 0;
		}
	}

	@include breakpoint(md) {
		.container & {
			margin-left: -30px;
		}
	}

	@include breakpoint(lg) {
		padding-top: 70px;

		.container & {
			margin-left: -70px;
		}

		.title-and-description {
			border-bottom: 1px solid $color-neon-silver;
			margin-bottom: 15px;
			padding-bottom: 22px;
		}

		.results-count {
			margin-left: 0;
			margin-top: 0;

			.count {
				display: inline-block;
				margin-left: 0.5rem;
			}
		}

		.input-icon-container {
			z-index: 3;
		}

		.results-list {
			padding: 0;
		}

		.selected-filters-list {
			.tag-list {
				padding-bottom: 1rem;
			}

			+ .input-icon-container {
				border-top: none;
			}
		}

		.mobile-search-page-controls {
			display: none;
		}

		.search-page-controls {
			@include make-col-ready();
			@include make-col(1);
			position: relative;

			.control-toggles {
				display: flex;
			}
		}

		.mobile-close-container,
		.mobile-filter-actions,
		.mobile-controls-results-btn {
			display: none;
		}

		.results-controls {
			align-items: center;
			background-color: transparent;
			color: $color-black;
			display: none;
			justify-content: space-between;
			margin-left: 0;
			padding: 0;
			position: relative;
			top: 0;
		}

		.view-mode-controls-container {
			display: flex;
			margin-right: 30px;
			margin-top: 1.5rem;
		}

		.filter-heading {
			display: block;
		}

		.filter-btn-container {
			.geolocate-btn {
				height: 52px;

				&.geolocating {
					&:after {
						bottom: 6px;
					}
				}
			}
		}

		.sort-panel {
			.control-toggle-btns {
				padding: 0;
			}
		}

		.search-page-wrapper {
			@include make-row();
			align-items: stretch;

			&.map-view {
				.search-page-controls {
					display: none;
				}

				.map-view-collapse-container {
					display: flex;
					justify-content: space-between;
					margin-bottom: 1rem;
				}

				.search-page-results {
					@include make-col-ready();
					@include make-col(8);
				}

				&.controls-closed {
					.search-page-results {
						@include make-col-ready();
						@include make-col(12);
					}

					.open-filters-btn {
						display: block;
					}

					.location-listing-card {
						width: 50%;
					}
				}
			}

			&.controls-closed {
				.controls-container {
					display: none;
				}

				.search-page-results {
					@include make-col-ready();
					@include make-col(10);
				}

				.results-list {
					&.view-mode-grid {
						.results-list-item {
							flex: calc(33.333% - (60px / 3));
							max-width: calc(33.333% - (60px / 3));
							&:nth-of-type(3n + 1) {
								margin-left: 0;
								margin-right: 15px;
							}
							&:nth-of-type(3n + 2) {
								margin-left: 15px;
								margin-right: 15px;
							}
							&:nth-of-type(3n + 3) {
								margin-left: 15px;
								margin-right: 0;
							}
						}
					}
				}

				.list-result {
					flex-direction: row;
					min-height: 230px;

					.result-top {
						flex: 0 1 35%;
					}

					.location-scheduling {
						flex: 0 1 65%;
						margin-top: 0;
					}

					.profile-image-outer {
						flex: 0 1 180px;
					}

					.profile-image-container {
						height: 100%;
						padding-bottom: 0;
					}

					.location {
						flex: 2;

						&:hover {
							// background-color: $color-blue-lightest;
						}

						&.not-available {
							background-color: $color-neutral-lightest;

							&:hover {
								background-color: $color-neutral-lightest;
							}
						}
					}

					.schedule-or-call {
						display: flex;
						flex: 3;
						flex-direction: row;

						.schedule-btn {
							flex-basis: 60%;
							max-width: 60%;

							&:only-child {
								flex-basis: 100%;
								max-width: 100%;
							}
						}

						.call-link {
							flex-basis: 40%;
							max-width: 40%;

							&:only-child {
								flex-basis: 100%;
								max-width: 100%;
							}
						}
					}
				}

				@media screen {
					.grid-result {
						.schedule-btn,
						.call-link {
							flex-basis: auto;
							flex-grow: 1;
						}
					}
				}

				.location-result {
					.location-scheduling {
						&.no-scheduling {
							.location {
								flex: 0 1 50%;
							}
						}
						.location {
							flex: 0 1 33.333%;
							&:only-child {
								flex-basis: 100%;
							}
						}

						.schedule-or-call {
							flex: 1;
							flex-direction: row;
						}
					}
				}

				.map-container {
					padding-bottom: 60%;

					.map-result {
						.schedule-or-call {
							flex-direction: column;
						}
					}
				}
			}
		}

		.controls-container {
			@include make-col-ready();
			@include make-col(4);
			background-color: transparent;
			height: auto;
			left: 0;
			opacity: 1;
			overflow: visible;
			pointer-events: auto;
			position: relative;
			top: 0;
			transition: none;
			visibility: visible;
			z-index: 0;
			-ms-overflow-style: none;
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}

			.control-toggles {
				display: none;
			}
		}

		.controls-top {
			align-items: center;
		}

		.view-mode-controls-container {
			margin: 0;
		}

		.control-panel {
			background-color: $color-neutral-lightest-2;
			margin-bottom: 20px;
			// max-height: calc(100vh - 3rem);
			// overflow: auto;
			// position: sticky;
			// top: 1.5rem;
		}

		.sort-panel {
			.radio-wrapper {
				label {
					border: none;
				}
			}
		}

		.mobile-filters-toggle,
		.mobile-sort-toggle {
			display: none;
		}

		.search-page-results {
			@include make-col-ready();
			@include make-col(6);
			padding-left: 13px;
			// padding-top: 0.75rem;

			.results-controls {
				display: flex;
				width: 100%;
				margin-bottom: 1.5rem;
			}
		}

		.map-container {
			padding-bottom: 80%;
		}
	}
}
