.intro-text {
	@include text(18, 24);
	color: $color-black;
	font-family: $font-family-secondary;
	font-weight: 400;

	.rich-text & {
		@include text(18, 24);
		color: $color-black;
		font-family: $font-family-secondary;
		font-weight: 400;
	}
}
