.error-content {
	padding: 0px 0px 0px;

	.wrapper {
		display: flex;
		justify-content: space-between;
		gap: 20px;
	}

	.left-content {
		display: flex;
		flex-direction: column;

		.error-404-label {
			@include text(lambda);
			font-family: $font-family-bold;
			text-transform: uppercase;
			margin-bottom: 5px;
		}

		.error-404-message {
			@include text(delta);
			font-family: $font-family-regular;
			font-weight: $font-weight-regular;
		}

		.links-list-desktop {
			margin-top: 20px;
		}
	}

	.right-content {
		.image-wrapper {
			position: relative;
			width: 100%;
			padding-top: 55%;
			overflow: hidden;

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.links-list-title {
		@include text(zeta);
		font-family: $font-family-regular;
		font-weight: $font-weight-regular;
	}

	.links-list {
		li {
			margin-top: 10px;
		}
	}

	.report-link {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 80px;
	}

	@include breakpoint(xs) {
		.wrapper {
			flex-direction: column;

			.left-content {
				.links-list-desktop {
					display: none;
				}
			}
		}

		.links-list-mobile {
			display: block;
			margin-top: 20px;
			margin-top: 20px;
		}
	}

	@include breakpoint(md) {
		padding: 60px 0px 20px;

		.wrapper {
			display: grid;
			grid-template-columns: repeat(2, 1fr);

			.left-content {
				width: 100%;

				.links-list-desktop {
					display: block;
				}
			}
		}

		.links-list-mobile {
			display: none;
		}

		.report-link {
			margin-top: 130px;
		}
	}
}
