.horizontal-videos-container {
	.videos-wrap {
		display: flex;
		justify-content: space-between;
	}

	@include breakpoint(xs) {
		.videos-wrap {
			width: 100%;
			flex-direction: column;
		}
	}
	@include breakpoint(md) {
		.videos-wrap {
			gap: 24px;
			flex-direction: row;
		}
	}
}
