.provider-profile-summary {
	background-color: $color-white;
	padding: 24px;

	&.bio-expanded {
		.view-more,
		.short-bio {
			display: none;
		}

		.view-less,
		.full-bio {
			display: block;
		}
	}

	.view-less,
	.full-bio {
		display: none;
	}

	.provider-headshot {
		margin-bottom: 1rem;
	}

	.provider-bio {
		margin-bottom: 2rem;

		.headline-large {
			margin-bottom: 0.85rem;
		}

		.ratings {
			margin-bottom: 0.875rem;
		}

		p {
			@include text(15, 22);
			font-weight: $font-weight-regular;
		}
	}

	.feature-list-item {
		margin-bottom: 10px;

		.icon {
			margin-bottom: -2px;
		}

		span {
			@include text(lambda);
			font-weight: $font-weight-regular;
			font-family: $font-family-secondary;
			margin-left: 5px;
		}
	}

	@include breakpoint(md) {
		.inner-content {
			@include make-row();
		}

		.provider-headshot,
		.provider-bio-and-features {
			@include make-col-ready();
		}

		.provider-headshot {
			@include make-col(4);
		}

		.provider-bio-and-features {
			@include make-col(8);
		}
	}

	@include breakpoint(lg) {
		.provider-bio-and-features-inner {
			@include make-row();
			justify-content: space-between;
		}

		.provider-bio,
		.provider-features {
			@include make-col-ready();
		}

		.provider-bio {
			@include make-col(7);
		}

		.feature-list {
			@include make-col(4);
			margin-top: 70px;
		}
	}
}
