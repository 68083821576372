.pagination-nav {
	display: flex;
	justify-content: center;
	margin: 0 0 3.25rem;

	.pagination {
		align-items: center;
		border-radius: 25px;
		display: flex;
		padding: 0 5px;
		width: auto;
	}

	.page-item {
		align-items: center;
		display: flex;
		margin-right: 2px;
		padding: 0;

		&.active {
			pointer-events: none;

			.page-link {
				background-color: $color-qualderm-green;
				color: $color-white;
			}
		}

		&.disabled {
			pointer-events: none;
			color: $color-light-gray;

			.page-link {
				background-color: transparent;
			}

			svg {
				fill: $color-neutral-light;
			}
		}

		&:last-of-type {
			margin-right: 0;
		}
	}

	.page-link {
		@include text(kappa);
		border: none;
		border-radius: 50%;
		font-weight: 600;
		height: 40px;
		padding: 0.625rem;
		line-height: 1;
		width: 40px;

		&:hover,
		&:focus {
			background-color: $color-neutral-light;
			color: $color-neutral-darker;
			cursor: pointer;

			svg {
				fill: $color-neutral-darker;
			}
		}

		.icon-chevron-right {
			margin-left: 2px;
		}

		.left {
			transform: rotate(180deg);
			margin: 4px 2px 0 0;
		}
	}
}
