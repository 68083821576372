.tag-list {
	display: flex;
	flex-wrap: wrap;
	// justify-content: center;
	gap: 10px;
	margin-top: 2rem;

	.button {
		@include text(kappa, 18);
		background-color: $color-qualderm-green;
		color: $color-black;
		font-family: $font-family-regular;
		font-weight: $font-weight-regular;

		&:hover {
			background-color: $color-neon-silver;
		}
	}
}
