body {
	.color-black {
		color: $color-black;
	}
	.color-green {
		color: $color-green;
		&.jss-link > a {
			color: $color-green;
		}
	}

	.color-blue-dark {
		color: $color-blue-dark;
	}

	.color-white {
		color: $color-white;
	}
}
