.layout-narrow {
	background-color: $color-warm-gray;

	main {
		@include make-container-narrow();
		padding-bottom: 2rem;
		padding-top: 2rem;
	}

	@include breakpoint(md) {
		main {
			padding-bottom: 50px;
			padding-top: 50px;
		}
	}
}
