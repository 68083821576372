.tag-container {
	display: flex;
}

.tag {
	@include text(lambda, 16);
	align-items: center;
	background-color: $color-qualderm-blue;
	border-radius: 14px;
	display: flex;
	justify-content: flex-start;
	padding: 0.25rem;
	margin-bottom: 0.25rem;
	max-width: 100%;

	.tag-description {
		align-items: center;
		color: $color-white;
		display: flex;
		flex-basis: calc(100% - 16px);
		max-width: calc(100% - 16px);
		justify-content: flex-start;
		margin-left: 0.25rem;
		margin-right: -1rem;
		transition: all 0.1s ease-in-out;
	}

	.close-btn {
		background-color: $color-white;
		height: 16px;
		flex: 0 0 2px;
		margin: 0;
		min-width: 16px;

		.icon {
			height: 8px;
			stroke: $color-qualderm-blue;
			stroke-width: 3px;
			width: 8px;
		}
	}

	.filter-category {
		@include text(lambda, 16);
		margin-right: 0.25rem;
		transition: all 0.1s ease-in-out;
	}

	.filter-value {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
