.news-story {
	//max-width: 916px;
	//padding-left: 240px;
	.headline {
		margin-bottom: 61px;
	}

	.main-news {
		margin-bottom: 5.5rem;
	}
}
