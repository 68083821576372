.home-finder {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
	padding: 0 15px;
	margin: auto;
	margin-top: -60px;
	margin-bottom: 3.125rem;
	gap: 20px;

	.finder-component {
		background-color: $color-white;
		border-radius: 6px;
		height: auto;
		box-shadow: 1px 3px 3px rgba(85, 85, 85, 0.35);
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		max-width: 500px;
		width: 100%;
	}

	.left-block {
		flex-direction: column;
		justify-content: center;
		margin-bottom: 1rem;
	}

	.find-component {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
		max-width: 350px;
		padding: 14px 20px 20px;

		span {
			text-align: center;
			font-size: 0.875rem;
			margin-bottom: 0.4375rem;
		}

		.schedules {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			gap: 4px;

			.schedule-row {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				gap: 4px;

				.schedule-separator {
					text-align: center;
					color: $color-qualderm-gray;
				}

				.schedule-item {
					@include text(16, 16);

					.day-schedule {
						text-transform: uppercase;
					}
				}
			}
		}
	}

	.icon-container {
		display: flex;
		margin-bottom: 0.5rem;
		min-height: 2.125rem;
		justify-content: center;
		align-items: flex-end;
	}

	.icon-find-provider {
		width: 2.125rem;
		height: 2.125rem;
	}

	.icon-find-location,
	.icon-clock {
		width: 1.431rem;
		height: 1.875rem;
	}

	.icon-quick-links {
		width: 2.391rem;
		height: 1.6875rem;
	}

	.horizontal-separaton {
		border-bottom: 1px solid $color-neon-silver;
		width: 90%;
		margin: auto;
	}

	.button {
		line-height: 1;
		padding: 9px 20px;
	}

	.find-component-full-width {
		width: 100%;
	}

	@include breakpoint(sm) {
		margin-left: -10px;
		margin-right: -10px;
		width: calc(100% + 20px);
	}

	@include breakpoint(md) {
		margin-left: -40px;
		margin-right: -40px;
		margin-top: -70px;
		display: flex;
		gap: 20px;
		flex-direction: row;
		width: calc(100% + 80px);

		.finder-component {
			max-width: none;
		}

		.left-block {
			flex-basis: calc(200% / 3);
			margin-bottom: 0;
		}

		.right-block {
			flex-basis: calc(100% / 3);
		}

		.find-component-full-width {
			width: 100%;
		}

		.left-block {
			flex-direction: row;
		}

		.find .find-component {
			width: auto;
			flex-grow: 1;
		}

		.horizontal-separaton {
			border-bottom: none;
			border-right: 1px solid $color-neon-silver;
			width: 1px;
			flex-basis: 1px;
			height: 95px;
			margin: auto;
		}
	}

	@include breakpoint(lg) {
		margin-left: -70px;
		margin-right: -70px;
		margin-top: -90px;
		width: 1100px;
	}

	@include breakpoint(xl) {
		margin-left: -70px;
	}
}
