.profile-mobile-actions {
	height: 70px;
	width: 100%;

	.clipboard-notification {
		opacity: 0;
		transition: opacity 0.4s;

		&.active {
			opacity: 1;
		}
	}

	.profile-mobile-actions-inner {
		box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.4);
		display: flex;
		flex-direction: column-reverse;
		position: fixed;
		bottom: 0;
		width: 100%;

		&.relative {
			position: relative;
		}
	}

	.toggles-container {
		display: flex;
	}

	.section-toggle {
		flex: 1;
		height: 70px;

		&.expanded {
			.section-toggle-btn {
				background-color: $color-green-lightest;
			}

			.section-content {
				background-color: $color-green-lighter;
				bottom: 100%;
				box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.4);
				display: block;
				left: 0;
				position: absolute;
				width: 100%;
			}
		}
	}

	.section-toggle-btn {
		background-color: $color-green-lighter;
		width: 100%;
		height: 70px;

		.icon {
			pointer-events: none;
		}

		&:hover {
			cursor: pointer;
		}
	}

	.section-content {
		display: none;
	}

	.action-btn {
		background-color: $color-green-lightest;
		justify-content: center;
		min-height: 90px;
		width: 100%;

		&:not(:last-child) {
			margin-bottom: 2px;
		}

		.icon {
			top: 50%;
			transform: translateY(-50%);
		}
		.icon-share {
			transform: rotate(-90deg);
		}
	}

	@include breakpoint(lg) {
		display: none;
	}
}
