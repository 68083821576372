.loading-overlay {
	@include loader($color-qualderm-gray, 85px, 5px);
	align-items: flex-start;
	background-color: $color-warm-gray;
	display: flex;
	height: 100%;
	justify-content: center;
	left: -15px;
	opacity: 0;
	padding-top: 130px;
	pointer-events: none;
	position: absolute;
	top: 0;
	transition: opacity 0.4s ease-in-out;
	width: calc(100% + 30px);
	z-index: 2;
}
