/*
 * text($font-size, $line-height(optional))
 * calculates font-size and line-height corresponding to $font-size-scale and $line-height-scale maps in _variables.scss
 * $line-height argument is optional, and can be a different value in $line-height-scale or a number value
 */
@mixin text($font-size, $line-height: null) {
	@if map-has-key($font-size-scale, $font-size) {
		font-size: calculateRem(map-get($font-size-scale, $font-size));

		@if ($line-height != null) {
			line-height: calulateLineHeight($font-size, $line-height);
		} @else {
			line-height: calulateLineHeight($font-size, $font-size);
		}
	} @else if (type-of($font-size) == 'number') {
		font-size: calculateRem($font-size);

		@if ($line-height != null) {
			line-height: calulateLineHeight($font-size, $line-height);
		} @else {
			line-height: calulateLineHeight($font-size, $font-size);
		}
	} @else {
		@warn 'Unfortunately, no value could be retrieved from `#{$font-size}`. ' + 'Please make sure it is defined in `$font-size-scale` map in _variables.scss.';
	}
}
