.video-content-wrapper {
	max-height: 360px;
	overflow: hidden;
	padding: 0;
	padding-top:70px;
	position: relative;

	&.video-active {
		iframe {
			z-index: 0;
		}
	}

	img {
		cursor: pointer;
		height: 100%;
		object-fit: cover;
		width: 100%;
	}

	iframe {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		padding-top:70px;
		width: 100%;
		z-index: -1;
		@include breakpoint(sm){
			padding-top:0px;
		}
	}

	@include breakpoint(sm){
		padding-top:0px;
	}
}
