body {
	.bg-green-dark {
		background-color: $color-green-dark;
	}

	.bg-green {
		background-color: $color-green;
	}

	.bg-green-light {
		background-color: $color-green-light;
	}

	.bg-green-lighter {
		background-color: $color-green-lighter;
	}

	.bg-green-lightest {
		background-color: $color-green-lightest;
	}

	.bg-blue-dark {
		background-color: $color-blue-dark;
	}

	.bg-blue {
		background-color: $color-blue;
	}

	.bg-blue-light {
		background-color: $color-blue-light;
	}

	.bg-blue-lighter {
		background-color: $color-blue-lighter;
	}

	.bg-blue-lightest {
		background-color: $color-blue-lightest;
	}

	.bg-neutral-darker {
		background-color: $color-neutral-darker;
	}

	.bg-neutral-dark {
		background-color: $color-neutral-dark;
	}

	.bg-neutral {
		background-color: $color-neutral;
	}

	.bg-neutral-light {
		background-color: $color-neutral-light;
	}

	.bg-neutral-lighter {
		background-color: $color-neutral-lighter;
	}

	.bg-neutral-lightest {
		background-color: $color-neutral-lightest;
	}

	.bg-neutral-lightest-2 {
		background-color: $color-neutral-lightest-2;
	}

	.bg-white {
		background-color: $color-white;
	}

	.bg-black {
		background-color: $color-black;
		color: $color-white;
	}
}
