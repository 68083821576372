.active-filter-panel {
	position: relative;

	&:not(:first-child) {
		padding-top: 1.75rem;

		&::before {
			background-color: $color-neutral;
			border-radius: 50%;
			display: block;
			content: '';
			margin: auto;
			position: absolute;
			top: 11px;
			left: 50%;
			transform: translateX(-50%);
			height: 0.5rem;
			width: 0.5rem;
		}
	}

	&:not(:last-child) {
		padding-bottom: 2.5rem;

		&::after {
			background-color: $color-neutral;
			border-radius: 50%;
			display: block;
			content: '';
			margin: auto;
			position: absolute;
			top: calc(100% - 31px);
			left: 50%;
			transform: translateX(-50%);
			height: 0.5rem;
			width: 0.5rem;
		}
	}

	.filter-panel-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 50px;
	}

	.filter-panel-title {
		@include text(iota);
		font-weight: 500;
		margin-bottom: 1rem;
		margin-left: 1.25rem;
		padding-top: 0.5rem;
	}

	.view-all-trigger,
	.view-all-back-btn {
		@include text(lambda);
		font-weight: 400;
		color: $color-blue-dark;
		margin-bottom: 0.75rem;
		margin-left: 20px;

		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	.view-all-panel {
		display: none;

		&.view-all-open {
			display: block;
		}
	}
}
