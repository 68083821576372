.schedule-appointment-form {
	display: flex;
	background-color: $color-white;
	border-radius: 6px;
	flex-direction: column;
	justify-content: center;

	.modal & {
		.iframe-container {
			max-height: calc(100vh - 2rem - 117px);
		}

		iframe {
			height: calc(100vh - 2rem - 117px);
		}
	}

	.iframe-container {
		margin: auto;
		max-height: calc(100vh - 2rem - 117px - 100px);
		max-width: 800px;
		width: 100%;
	}

	iframe {
		height: calc(100vh - 2rem - 117px - 100px);
		min-width: 100%;

		.appointment-information-heading {
			font-size: 88px;
		}
	}

	.help-cta {
		display: flex;
		flex-direction: column;
		margin: 1rem 0;
		align-items: center;

		p {
			margin-bottom: 0;
		}
	}

	.schedule-an-appointment-call {
		border-top: 1px solid $color-gray-light;
		display: flex;
		flex-direction: column;
		margin: auto;
		align-items: center;
		padding: 1rem 1rem 1.5rem;
		width: 100%;

		.location-phone-item {
			@include text(lambda);
			font-family: $font-family-secondary;
		}
	}

	.headline-small {
		margin-bottom: 0.2rem;
	}

	@include breakpoint(sm) {
		margin: 0;
	}

	@include breakpoint(md) {
		.modal & {
			min-width: 800px;
			max-width: 800px;
		}
	}

	@include breakpoint(lg) {
		.iframe-container {
			max-height: calc(100vh - 2rem - 117px - 130px);
		}

		iframe {
			height: calc(100vh - 2rem - 117px - 130px);
		}
	}
}
