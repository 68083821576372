.event-listing {
	.card-description {
		padding: 14px 20px 20px;
	}

	.event-image {
		border-radius: 6px;
		width: 100%;
		height: 100%;
		object-fit: cover;
		aspect-ratio: 1.46;
	}

	.event-date-and-cost {
		border-bottom: 1px solid $color-neon-silver;
		margin-bottom: 2rem;
		padding-bottom: 2rem;
	}

	.start-date {
		@include text(iota);
		color: $color-black;
		font-family: $font-family-secondary;
		font-weight: $font-weight-bold;
		margin-bottom: 0.375rem;
	}

	.start-time {
		@include text(15);
		font-family: $font-family-secondary;
	}

	.eyebrow {
		margin-bottom: 0.25rem;
	}

	.text-link {
		@include text(kappa);
	}

	.event-location,
	.event-contact {
		@include text(kappa);
		color: $color-black;
		font-family: $font-family-secondary;
		margin-bottom: 1.5rem;

		a,
		span[itemprop='streetAddress'] {
			display: block;
		}
	}

	.button {
		width: 100%;
	}

	.title-main {
		display: none;
	}

	.mobile-title {
		margin-bottom: 20px;
	}

	@include breakpoint(md) {
		@include make-row();

		.event-details,
		.event-description {
			@include make-col(6);
		}

		.mobile-title {
			display: none;
		}

		.event-date-and-cost {
			display: flex;
			justify-content: space-between;
		}

		.event-cost {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}

		.title-main {
			@include text(28, 32);
			display: block;
			font-family: $font-family-regular;
			font-weight: $font-weight-regular;
			margin-bottom: 0.8125rem;
		}
	}

	@include breakpoint(lg) {
		.event-details {
			@include make-col(5);
		}

		.event-description {
			@include make-col(7);
		}
	}
}
