.geolocate-user {
	display: flex;
	justify-content: flex-end;
	padding: 14px 30px;
	position: relative;

	&.geolocating {
		.loader {
			display: inline-block;
		}
		.zip-input {
			display: none;
		}
		.icon-location {
			fill: $color-qualderm-gray;
		}
	}

	&.entering {
		.zip-input {
			border-bottom: 2px solid $color-qualderm-blue;
		}

		.icon-location {
			fill: $color-qualderm-gray;
		}
	}

	&.valid {
		.zip-input {
			border-bottom: none;
		}
	}

	&.invalid {
		.zip-input {
			border-bottom: 2px solid $color-qualderm-blue;
		}
	}

	.input-with-icon {
		display: flex;

		.icon {
			margin-right: 5px;
		}
	}

	.zip-input {
		@include text(kappa, 16);
		background-color: transparent;
		border: none;
		border-radius: 0;
		line-height: 1;
		min-height: 0;
		padding: 0;
		width: 45px;

		&::placeholder {
			@include text(14, 16);
		}

		&:focus,
		&:focus-visible {
			outline: none;
		}
	}

	.loader {
		display: none;
		font-family: $font-family-regular;
		width: 45px;

		&:after {
			// overflow: hidden;
			display: inline-block;
			animation: dotty steps(1, end) 1s infinite;
			content: '';

			@keyframes ellipsis {
				to {
					width: 80px;
				}
			}
		}
	}

	.error-message,
	.fallback-message {
		@include text(14);
		color: $color-red;
		display: inline-block;
		font-family: $font-family-regular;
		opacity: 0;
		position: absolute;
		right: 0;
		text-align: right;
		top: calc(100% + 4px);
		transition: opacity 0.2s;
		width: 196px;

		&.active {
			opacity: 1;
		}
	}

	@keyframes dotty {
		0% {
			content: '';
		}
		25% {
			content: '.';
		}
		50% {
			content: '..';
		}
		75% {
			content: '...';
		}
		100% {
			content: '';
		}
	}

	@include breakpoint(lg) {
		padding: 0;
	}
}
