body {
	font-family: $font-family-regular;
	overflow-x: hidden;
}

p {
	@include text(15, 22);
	font-family: $font-family-secondary;
	margin-bottom: 1.25rem;
}

.button-headline {
	@include text(iota);
	font-family: $font-family-bold;
}

.button-cta {
	@include text(theta);
}

.button-detail {
	@include text(kappa, 20);
}

.filter {
	@include text(lambda);
}

.subject-matter-detail {
	@include text(kappa);
}

.text-plus {
	@include text(kappa, 20);
}

.paragraph-intro {
	@include text(kappa);
	span {
		margin-bottom: 1.5rem;
	}
}
.text-container {
	margin-top: 2rem;
	margin-bottom: 1.5rem;
}

.biography-text {
	font-weight: $font-weight-light;
	margin-bottom: 1.5rem;
}

.ordered-list {
	@include text(kappa, lambda);
	font-family: $font-family-regular;
}

.content-block {
	margin-bottom: 1.25rem;
}
