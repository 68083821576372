a {
	text-decoration: none;

	.list-block {
		@include text(theta);
		align-items: center;
		background-color: $color-neutral-lightest-2;
		color: $color-neutral-dark;
		display: flex;
		justify-content: space-between;
		list-style: none;
		margin-bottom: 0.5rem;
		padding: 0.5rem 0.75rem;

		&.active {
			background-color: $color-white;
			color: $color-neutral-darker;
		}
	}

	.list-block.active {
		background-color: $color-white;
	}

	.list-block-icon {
		margin-right: -10px;
		width: 40px;
	}
}
