.container {
	@include make-container();
}

.container-bg {
	@include make-container-bg();
}

.row {
	@include make-row();
}

.col {
	width: 100%;
}

.col,
[class*='col-'] {
	@include make-col-ready();

	&.position-center {
		margin: auto;
	}
}

@for $i from 1 through $grid-columns {
	.col-#{$i} {
		@include make-col($i);
	}
}

@include breakpoint(md) {
	@for $i from 1 through $grid-columns {
		.col-md-#{$i} {
			@include make-col($i);
		}
	}

	.justify-content-md-center {
		justify-content: center;
	}
}

@include breakpoint(lg) {
	@for $i from 1 through $grid-columns {
		.col-lg-#{$i} {
			@include make-col($i);
		}
	}

	.justify-content-lg-center {
		justify-content: center;
	}
}

@include breakpoint(xl) {
	@for $i from 1 through $grid-columns {
		.col-xl-#{$i} {
			@include make-col($i);
		}
	}

	.justify-content-xl-center {
		justify-content: center;
	}
}
