.provider-listing-group {
	.eyebrow {
		margin-bottom: 10px;
	}

	.result-list {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.directions-link {
		margin-right: 0.25rem;
	}

	@include breakpoint(md) {
		.result-list {
			justify-content: space-around;
			flex-wrap: wrap;
		}
	}

	@include breakpoint(1140px) {
		.result-list {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 26px;
		}
	}
}
