$legend: (
	success: $color-green-lighter,
	default: $color-blue,
	info: $color-blue-lighter,
	warning: $color-warning,
	critical: $color-critical,
);

.alert {
	background-color: $color-neutral-lightest-2;
	display: flex;
	justify-content: space-between;
	margin: 0px 20px;
	max-width: unset;
	padding: 10px;

	@each $label, $value in $legend {
		&.alert-#{$label} {
			.icon-wrapper {
				background-color: $value;
				background-image: url(/assets/images/icons/#{$label}.svg);
			}
		}
	}

	.content {
		align-items: flex-start;
		display: flex;
	}

	.icon-wrapper {
		background-position: center center;
		background-repeat: no-repeat;
		border-radius: 50%;
		height: 40px;
		margin-right: 15px;
		max-width: unset;
		width: 40px;
	}

	a {
		color: #6e9735;
	}

	button {
		cursor: pointer;
		margin-left: 15px;
		padding: 5px 10px;
	}

	@include breakpoint(md) {
		align-items: center;
		margin: 0 auto;
		max-width: calc(100vw - 500px);
		width: 90%;

		.content {
			align-items: center;
		}
	}
}
