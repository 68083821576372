.product-card {
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	margin-top: 5px;
	margin-bottom: 16px;
	max-width: 500px;
	background-color: $color-neutral-lightest-3;

	.card-image {
		height: 257px;
		display: block;
		margin: 0 auto;
		padding-top: 0.313rem;
	}

	.card-content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: 16px;

		.card-title {
			font-size: 1.2em;
			font-weight: $font-weight-regular;
			margin-bottom: 10px;
		}

		.card-description {
			margin-bottom: 8px;
			overflow: hidden;
			margin-bottom: 20px;
		}

		.card-cta-container {
			display: flex;
			flex-grow: 1;
		}

		.button,
		.card-cta {
			align-self: flex-end;
			display: block;
			width: 100%;
			text-align: center;
		}

		.button {
			font-size: 1em;
			font-family: $font-family-regular;
		}

		.card-cta {
			@include text(mu);
			color: $color-black;
			font-family: $font-family-bold;
			margin-bottom: 0;
			padding: 8px;
			text-transform: uppercase;
		}
	}
}
