.provider-profile {
	> section {
		margin-bottom: 2rem;
	}

	.line-bottom-title {
		border-bottom: solid 1px $color-neon-silver;
		padding-bottom: 15px;
	}

	.dr-info {
		display: flex;
		flex-direction: column;
		padding: 10px 26px;
	}

	.dr-name {
		margin-bottom: 0px;
	}

	.dr-subtitle {
		@include text(kappa);
		font-family: $font-family-secondary;
	}

	/*Patient Reviews*/
	.patient-reviews {
		margin-top: 30px;
	}

	.review-title {
		@include text(lambda);
		font-family: $font-family-bold;
		text-transform: uppercase;
	}

	.review-text {
		font-size: 1rem;
		font-family: $font-family-secondary;
		margin-bottom: 0;
	}

	.comment-review {
		padding: 20px 0;
		border-bottom: solid 1px $color-neon-silver;

		&:last-of-type {
			border-bottom: none;
		}
	}

	.last-comment-review {
		margin-top: 20px;
	}

	.custom-block-title {
		margin-bottom: 20px;
	}

	.dont-see-your-insurance-block {
		margin-top: 50px;

		p:last-of-type {
			margin-bottom: 0;
		}
	}

	.custom-p {
		@include text(lambda);
		font-family: $font-family-secondary;
	}

	.custom-schedule-appointment-button {
		width: 190px;
		margin: auto;
	}

	@include breakpoint(md) {
		> section {
			margin-bottom: 3.125rem;
		}

		.custom-block-title {
			margin-bottom: 30px;
		}
	}
}
