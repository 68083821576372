.provider-locations {
	.provider-location-inner {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@include breakpoint(lg) {
		.provider-location-inner {
			align-items: flex-start;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 24px;
			width: 100%;

			.provider-location-card {
				width: 100%;
			}
		}
	}
}
