.background-image {
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: -1;

	picture,
	img {
		height: 100%;
		position: absolute;
		width: 100%;
	}

	img {
		object-fit: cover;
	}
}
