.layout-full-width {
	background-color: $color-warm-gray;
	position: relative;

	main {
		@include make-container();
		padding-bottom: 2rem;
		padding-top: 2rem;
	}

	@include breakpoint(md) {
		main {
			padding-bottom: 14px;
			padding-top: 50px;
		}
	}
}
