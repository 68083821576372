.previous-section-button,
.next-section-button {
	@include text(lambda);
	font-family: $font-family-regular;
	background: $color-white;
	border: 0;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	padding: 0.5rem 0.5rem;
	display: inline-block;
	cursor: pointer;
}
.previous-section-button {
	.previous-section-icon {
		transform: scale(-1.5, 1.5) translate(0, 1px);
	}
}
.next-section-button {
	.next-section-icon {
		transform: scale(1.5) translate(0, 1px);
	}
}
.section-previous,
.section-next {
	position: relative;
	display: flex;
	align-items: center;
	gap: 10px;
}

.section-nav {
	display: flex;
	background-color: $color-neutral-lightest;
	justify-content: space-between;
	width: 100%;
	margin: 0;
	padding: 1.5rem 1.75rem 3.5rem;
}
