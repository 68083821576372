.story-listing {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	// max-width: 1280px;
	margin: 0 auto;

	.content-story-listing {
		display: flex;
		width: 100%;
		flex-direction: column;
	}

	.image-story-listing {
		width: 330px;
		height: 188px;
		border-radius: 6px;
		margin-bottom: 20px;
	}

	.eyebrow-story-listing {
		@include text(14);
		display: block;
		font-family: $font-family-bold;
		text-transform: uppercase;
		color: $color-charcoal;
		margin-bottom: 0.625rem;

		span {
			color: $color-qualderm-gray;
		}
	}

	.title-story-listing {
		@include text(24);
		display: block;
		font-family: $font-family-regular;
		color: $color-qualderm-blue;
		margin-bottom: 0.625rem;

		&:hover {
			text-decoration: underline;
		}
	}

	.description-story-listing {
		@include text(15, 22);
		font-family: $font-family-secondary;
		color: $color-black;
		margin-bottom: 0;
	}

	@include breakpoint(md) {
		display: flex;
		flex-direction: row;

		.content-story-listing {
			flex-direction: row;
		}

		.image-story-listing {
			width: 352px;
			height: 198px;
			margin-bottom: 0;
			margin-right: 20px;
			border-radius: 6px;
		}
	}
}
