div[id^='form-Unsubscribe'] {
	display: none;
}

.unsubscribe-form {
	background: $color-white;
	border-radius: 10px;
	padding: 3rem;
	margin-bottom: 4rem;
	margin-left: auto;
	margin-right: auto;
	margin-top: -255px;
	max-width: 726px;
	z-index: 6;

	&.submitted {
		.form-description,
		.form-note {
			display: none;
		}
	}

	.usubscribe-info {
		margin-bottom: 1.25rem;

		p {
			margin-bottom: 0;
		}
	}

	.form-description {
		&:has(+ .formwidget-submit-text) {
			display: none;
		}
	}

	div[id^='form-Unsubscribe'] {
		display: block;
		margin-top: -20px;

		input[type='submit'] {
			@include text(16, 16);
			border-radius: 6px;
			border: 1px solid $color-qualderm-gray;
			background: $color-qualderm-blue;
			display: inline-flex;
			padding: 12px 20px;
			justify-content: center;
			align-items: center;
			gap: 10px;
			color: $color-white;
			display: flex;
			height: auto;
			width: 100%;
			cursor: pointer;
			transition: all 0.3s;
			min-width: 162px;

			&:hover {
				filter: brightness(0.9);
			}

			&:active {
				filter: brightness(1.1);
			}

			&.disabled {
				background: $color-qualderm-gray;
				cursor: default;

				&:hover {
					filter: none;
				}
			}
		}

		.ktc-default-section {
			margin-bottom: 16px;
		}

		.form-field {
			&:has([type='email']) {
				.control-label {
					@include text(14);
					color: $color-white;
					font-family: $font-family-regular;
					text-transform: uppercase;
				}
			}

			&:has([id$='Title_Value']),
			&:has([id$='Description_Value']),
			&:has([id$='SiteOrigin_Value']),
			&:has([id$='WebEmailConsent_Value']) {
				display: none;
			}
		}

		.explanation-text {
			@include text(14, 18);
			color: $color-white;
			font-family: $font-family-secondary;
			margin: 0;
		}
	}

	.form-note {
		font-weight: $font-weight-bold;
		margin-top: 2rem;
	}

	.unsubscribe-container {
		max-width: 352px;
		margin-left: auto;
		margin-right: auto;
	}

	.form-field {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		.editing-form-control-nested-control {
			gap: 0;
			order: 0;
			width: auto;
		}

		.control-label {
			@include text(15);
			font-family: $font-family-secondary;
			order: 1;
			margin-bottom: 0;
			text-transform: none;
			cursor: pointer;
		}
	}

	.formwidget-submit-text {
		@include text(15, 22);
		font-family: $font-family-secondary;

		~ .form-note {
			display: none;
		}
	}
}
