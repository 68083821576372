.blockquote-carousel {
	width: 100%;
	position: relative;
	background-color: $color-letter-charcoal;
	border-radius: 10px;
	margin-bottom: 42px;

	.blockquote-container {
		display: flex;
		overflow: hidden;
		width: 100%;

		.blockquote-item {
			flex: 0 0 auto;
			width: 100%;
			scroll-snap-align: start;
			&.active {
				opacity: 1;
			}

			.blockquote {
				margin: 0;
			}
		}
	}

	.controls {
		position: absolute;
		bottom: 20px;
		left: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		opacity: 0.6;
		transition: opacity 0.3s;

		.arrow {
			background: transparent;
			border: none;
			fill: #343a40;
			&:hover {
				cursor: pointer;
			}
		}

		.blockquote-carousel-indicators {
			display: flex;
			list-style: none;
			justify-content: center;
			gap: 7px;
			width: 100px;

			.blockquote-carousel-dot {
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background: $color-neon-silver;
				&:hover {
					cursor: pointer;
				}
			}

			li {
				display: flex;
				justify-content: center;
			}

			.active {
				background: $color-qualderm-green;
			}
		}
	}

	&:hover {
		.controls {
			opacity: 1;
		}
	}
}
