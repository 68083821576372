.button-bar {
	align-items: center;
	display: flex;
	gap: 1em;
	justify-content: flex-start;
	justify-items: flex-start;
	overflow-x: auto;
	padding: 0.75em;
	position: relative;

	.nav-btn {
		margin-right: 5px;
		white-space: nowrap;

		&:last-child {
			margin-right: 0;
		}
	}

	@include breakpoint(md) {
		padding: 0.75em 0;
	}
}
