.textarea {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;

	&.success {
		textarea {
			border-color: $color-green-lighter;
		}
	}
	&.error {
		textarea {
			border-color: $color-critical;
		}
	}

	label {
		display: block;
		margin-bottom: 5px;
	}

	.textarea-container {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 24.5px;
	}

	textarea {
		@include text(15, 22);
		font-family: $font-family-secondary;
		padding: 8px 11.5px;
		border: 1px solid $color-warm-gray;
		border-radius: 6px;
		transition: border-color 0.3s;
		width: 100%;

		&:focus {
			border-color: $color-green-lighter;
		}
	}

	.label-textarea {
		@include text(14);
		color: $color-black;
		font-family: $font-family-regular;
		text-transform: uppercase;
	}

	.textarea-error-message {
		color: $color-warning;
		font-family: $font-family-tertiary;
	}

	.span-explanation {
		@include text(10);
		display: flex;
		color: $color-qualderm-gray;
		font-family: $font-family-tertiary;
		margin-top: 8px;
	}

	.error-message {
		color: $color-critical;
		margin-top: 5px;
	}

	@include breakpoint(md) {
		gap: 2px;
	}
}
