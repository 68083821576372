.location-listing-card {
	display: flex;
	flex-direction: column;
	background-color: $color-white;
	border-radius: 10px;
	padding: 17px 17px 15px;
	margin-bottom: 1.875rem;
	position: relative;

	.left-block {
		.location-picture {
			aspect-ratio: 16/9;
			width: 100%;
			margin-bottom: 0.75rem;
			position: relative;

			img {
				height: 100%;
				left: 0;
				object-fit: cover;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
	}

	.right-block {
		.main-info {
			.location-name {
				@include text(iota, 20);
				color: $color-qualderm-blue;
				display: inline-block;
				font-weight: $font-weight-bold;
				margin-bottom: 0.25rem;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.ratings-wrapper {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			margin: 0;

			.ratings {
				margin-bottom: 0.75rem;

				.rating-stars {
					height: fit-content;
				}
			}
		}

		.custom-schedule-appointment-button {
			@include text(theta, 22);
			padding: 14px 20px;
			width: 100%;
		}

		.schedule-an-appointment-call {
			padding-top: 0.375rem;

			.schedule-an-appointment-call-text {
				@include text(14, 18);
				margin: 0 0 6px;
			}
		}
	}

	.distance-link {
		display: none;
		margin-bottom: 13px;

		.directions-link {
			@include text(kappa, 20);
		}

		&.mobile {
			display: block;
		}
	}

	@include breakpoint(md) {
		flex-direction: row;
		gap: 24px;
		padding: 12px 21px 21px 12px;

		.left-block {
			.location-picture {
				width: 164px;
				margin-bottom: 10px;
			}
		}

		.right-block {
			.main-info {
				.location-name {
					@include text(kappa, 20);
					margin-bottom: 5px;
				}
			}

			.ratings-wrapper {
				.ratings {
					margin-bottom: 10px;
				}
			}

			.custom-schedule-appointment-button {
				@include text(kappa, 18);
				padding: 9px 20px;
				width: auto;
			}
		}

		.distance-link {
			display: block;

			&.mobile {
				display: none;
			}
		}
	}
}
