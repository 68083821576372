.alert-banner {
	width: 100%;
	background-color: $color-qualderm-blue;

	* {
		color: $color-white;
	}

	.alert-banner-inner {
		@include make-container();
		padding: 1rem;
		position: relative;

		.alert-close-btn {
			cursor: pointer;
			position: absolute;
			right: 1rem;
			top: 1rem;
		}

		.alert-content {
			display: flex;
			flex-direction: column;
			gap: 1.4375rem;

			p {
				flex: 1;

				.alert-actions {
					padding-left: 1rem;

					a {
						text-decoration: underline;
					}

					.action-separator {
						padding: 0 0.5rem;
					}
				}
			}
		}
	}

	.icon {
		margin-top: 2px;
	}

	@include breakpoint(md) {
		.alert-banner-inner {
			padding: 1rem 2.5rem 1rem 1rem;

			.alert-content {
				flex-direction: row;
			}
		}
	}
}
