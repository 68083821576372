.global-navigation {
	height: 70px;
	overflow: visible;
	position: relative;
	transition: margin-bottom 0.3s ease-in-out;
	z-index: 7;

	&.no-transition {
		transition: none;
	}

	&.scroll-up {
		.navbar {
			animation: mobile-nav-slide-down 0.3s ease-in-out;
			transform: translateY(0);
			top: 0;
			position: fixed;

			@keyframes mobile-nav-slide-down {
				from {
					transform: translateY(-70px);
				}
				to {
					transform: translateY(0);
				}
			}
		}

		&.scroll-down {
			.navbar {
				transform: translateY(-70px);
				pointer-events: none;
				position: fixed;
			}
		}
	}

	&.navbar-fixed {
		&.no-transition {
			.navbar {
				transition: none;
			}
		}

		.navbar {
			transform: translateY(-70px);
			position: fixed;
			top: 0;
			transition: none;
		}

		&.scroll-up {
			.navbar {
				transform: translateY(0);
				transition: transform 0.3s ease-in-out,
					margin-top 0.3s ease-in-out;
			}

			&.scroll-down {
				.navbar {
					transform: translateY(-70px);
					pointer-events: none;
				}
			}
		}
	}

	&.mobile-nav-open {
		margin-bottom: calc(100vh + 2rem);

		.navbar {
			margin-top: 100vh !important;
		}

		.mobile-nav {
			display: block;
		}
	}

	.nav-inner {
		width: 100%;
	}

	.navbar {
		background-color: $color-white;
		box-shadow: 0px 3px 8px 0px rgba(85, 85, 85, 0.35);
		display: flex;
		height: 70px;
		padding: 0;
		transition: margin-top 0.3s ease-in-out;
		z-index: 9;
		width: 100%;
	}

	.nav-top {
		display: flex;
		justify-content: space-between;
		padding: 15px;
		width: 100%;

		.text-link {
			font-family: $font-family-regular;
		}
	}

	.corporate-logo {
		width: 106px;
		height: auto;
	}

	.practice-logo {
		width: 140px;
		height: auto;
	}

	.family-member-link {
		display: none;
	}

	.nav-bottom {
		display: none;
	}

	.utility-nav {
		display: none;
	}

	.hovered {
		background-color: $color-background-hover-nav;
	}

	.hovered:hover {
		background-color: $color-background-hover-nav;
	}

	.mobile-nav {
		display: none;
		width: 100vw;
		height: 100svh;
		background-color: $color-background-hover-nav;
		margin-top: -100vh;
		overflow: hidden;
		position: absolute;
		top: 0;

		&:before {
			background-color: $color-background-hover-nav;
			content: ' ';
			height: 100svh;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: -1;
		}

		&.visible {
			display: block;
		}

		.nav-top {
			background-color: $color-white;
			box-shadow: 1px 1px 2px 0px rgba($color-black, 0.2);
			padding-right: 20px;
		}

		.input-icon-container {
			align-items: center;
			position: relative;
			background-color: $color-background-hover-nav;
			border-bottom: 1px solid $color-white;
			display: flex;
			height: 50px;
			justify-content: flex-end;
			padding: 15px;
			width: 100%;
		}

		.nav-link {
			@include text(theta, 22);
			border-bottom: 1px solid $color-white;
			color: $color-black;
			display: flex;
			font-family: $font-family-bold;
			padding: 14px 30px;
			width: 100%;

			.icon {
				margin-left: auto;
			}

			&.has-sub-nav {
				svg {
					fill: $color-qualderm-green;
				}
			}

			&.view-all-link {
				color: $color-qualderm-blue;
			}
		}
	}

	.mobile-nav-container {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow-y: auto;

		.nav-logo {
			@include text(alpha);
			margin-bottom: 4rem;

			.logo-subtitle {
				@include text(eta);
				margin-left: 10px;
				margin-top: -5px;
			}
		}
	}

	.mobile-nav-list-title {
		@include text(theta, 22);
		background-color: $color-white;
		border-bottom: 1px solid $color-white;
		color: $color-black;
		display: block;
		font-family: $font-family-bold;
		padding: 14px 20px;
	}

	.mobile-sub-nav {
		display: none;
		width: 100%;
		height: 100vh;
		background-color: $color-background-hover-nav;
		flex-direction: column;
		overflow-y: auto;
		position: absolute;
		top: 0;
		left: 0;

		.nav-link {
			@include text(kappa, 18);
			font-weight: $font-weight-regular;
			padding: 14px 20px;
		}
	}

	.sub-nav-header {
		align-items: center;
		background-color: $color-white;
		box-shadow: 1px 1px 2px 0px rgba($color-black, 0.2);
		display: flex;
		min-height: 70px;
		justify-content: space-between;
		padding: 20px;
		z-index: 1;

		.icon {
			align-items: center;
			display: flex;
			height: 25px;
			justify-content: center;
			width: 25px;

			&.icon-chevron-right {
				transform: rotate(180deg);
				fill: $color-black;
			}
		}
	}

	.mobile-utility-nav {
		margin-top: auto;

		.utility-link-list {
			display: flex;

			li {
				flex-grow: 1;
				margin-right: 2px;

				&:last-of-type {
					margin-right: 0;
				}

				.utility-link {
					@include text(kappa, 16);
					align-items: center;
					background-color: $color-white;
					color: $color-black;
					display: flex;
					height: 50px;
					justify-content: center;
					padding: 8px;
					width: 100%;
				}
			}
		}

		.mobile-schedule-button {
			@include text(iota, 22);
			align-items: center;
			background-color: $color-qualderm-blue;
			border-radius: 0;
			color: $color-white;
			display: flex;
			font-weight: $font-weight-regular;
			height: 50px;
			justify-content: center;
			width: 100%;
		}
	}

	.nav-close {
		.icon {
			align-items: center;
			display: flex;
			height: 25px;
			justify-content: center;
			width: 25px;
		}
	}

	.sub-nav-title {
		@include text(theta, 22);
		color: $color-black;
		font-family: $font-family-bold;
	}

	.nav-list-item {
		&.active {
			&.closing {
				.mobile-sub-nav {
					transform: translateX(100%);
					transition: transform 0.2s ease-in-out;
				}
			}

			.mobile-sub-nav {
				animation: slideIn 0.2s ease-in-out;
				display: flex;

				@keyframes slideIn {
					from {
						transform: translateX(100%);
					}
					to {
						transform: translateX(0);
					}
				}
			}
		}
	}

	.nav-open,
	.nav-close {
		cursor: pointer;
	}

	.nav-open {
		.icon {
			height: 30px;
			fill: $color-white;
			width: 30px;
		}
	}

	@include breakpoint(lg) {
		height: 150px;

		+ .hero,
		+ .blog-post-hero {
			margin-top: -150px;
		}

		&.navbar-fixed {
			height: 100px;
			position: fixed;
			top: 0;
			width: 100%;

			+ .hero,
			+ .blog-post-hero {
				margin-top: 0;
			}

			+ [class^='layout-'] {
				margin-top: 150px;
			}

			.navbar {
				transform: translateY(0);
			}

			.nav-top {
				padding-bottom: 9px;
			}

			.corporate-logo {
				width: 79px;
				height: 35px;
			}

			.practice-logo {
				height: 35px;
				width: 120px;
			}

			.utility-link-list {
				display: none;
			}
		}

		button {
			color: $color-blue-dark;

			&.schedule-button {
				color: $color-white;
			}
		}

		.navbar {
			background-color: rgba(255, 255, 255, 0.9);
			box-shadow: none;
			height: auto;
		}

		.nav-top {
			@include make-container();
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding-top: 9px;
			width: 100%;
		}

		.corporate-logo {
			width: 127px;
			height: auto;
		}

		.practice-logo {
			width: 188px;
			height: auto;
		}

		.nav-open {
			display: none;
		}

		.utility-nav-container {
			display: flex;
			justify-content: space-between;
		}
		.utility-link-location {
			margin-left: 10px;
		}

		.family-member-link {
			display: block;
			margin: 0 auto;
			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
		}

		.utility-nav {
			align-items: flex-end;
			align-self: flex-start;
			display: flex;
			flex-direction: column;
		}

		.utility-link-list {
			display: flex;
			margin-bottom: 26px;

			&:first-child {
				margin-left: 30px;
			}

			.text-link {
				color: $color-black;
				margin-right: 30px;
			}
		}

		.nav-bottom {
			align-items: center;
			background-color: $color-white;
			display: flex;
		}

		.nav-list-container {
			@include make-container();
			padding-left: 40px !important;
			padding-right: 40px !important;
			width: 100vw;
		}

		.nav-list {
			display: flex;
			justify-content: space-between;
		}

		.nav-list-item {
			flex-grow: 1;

			&:last-child {
				position: relative;

				.sub-nav-menu {
					right: 0;
				}
			}

			&.active {
				background-color: $color-background-hover-nav;

				.icon-chevron-up {
					display: inline-block;
				}
				.icon-chevron-down {
					display: none;
				}

				.sub-nav-menu,
				.sub-nav-menu-small {
					display: block;
				}
			}
		}

		.nav-link {
			@include text(lambda, 14);
			align-items: center;
			color: $color-letter-charcoal;
			cursor: pointer;
			display: inline-block;
			font-family: $font-family-bold;
			padding: 14px 15px;
			text-align: center;
			text-transform: uppercase;
			white-space: nowrap;
			width: 100%;

			&:hover {
				background-color: $color-background-hover-nav;
			}

			.icon {
				height: 7.41px;
				margin-left: 5px;
				width: 12px;

				use {
					fill: $color-qualderm-green;
				}
			}

			.icon-chevron-up {
				display: none;
			}
		}

		.sub-nav-menu {
			background-color: $color-background-hover-nav;
			display: none;
			left: 0;
			padding: 30px 0 60px;
			position: absolute;
			width: 100vw;

			&.fit-to-content {
				left: auto;
				min-width: 275px;
				padding: 30px 24px 24px;
				width: auto;
			}

			.link-list-title {
				@include text(eta);
				margin-bottom: 18px;
			}

			.link-list {
				margin-bottom: 0;
			}

			.link-list-item {
				padding-bottom: 0;
			}

			.text-link {
				@include text(15);
				color: $color-black;
				display: inline-block;
				font-family: $font-family-secondary;
				padding: 7px 0 8px;
			}

			.view-all-link {
				color: $color-qualderm-blue;
				padding: 20px 0 0;

				.icon {
					margin-bottom: -1px;
					height: 15px;
					margin-left: 5px;
					width: 7px;
				}
			}
		}
	}
}
