.search-landing {
	padding: 0 15px;
	min-height: 300px;
	margin-bottom: 2rem;

	&.search-landing-expanded {
		.expanded-filters {
			display: block;
		}

		.filter-expand-toggle {
			.icon {
				transform: rotate(90deg) scale(-1, -1);
			}
		}
	}

	.landing-container {
		background-color: $color-neutral-lightest-2;
		border-radius: 15px;
		padding: 20px;

		.control-panel {
			margin: 0;
			padding: 0;
		}

		.filter-title-bar {
			justify-content: flex-end;
		}

		.filter-heading {
			display: none;
		}
	}

	.page-title {
		margin-bottom: 2rem;
	}

	.search-landing-title {
		@include text(alpha);
		color: $color-blue-dark;
		font-weight: $font-weight-light;
		margin-bottom: 0.75rem;
	}

	.search-landing-desc {
		@include text(eta);
		font-family: $font-family-regular;
		margin-bottom: 2rem;
	}

	.view-all-btn {
		@include text(iota);
		background-color: $color-blue-dark;
		box-shadow: 0px 5px 5px $color-neutral;
		color: $color-white;
		font-weight: $font-weight-medium;
		margin-bottom: 1.25rem;

		.provider-count {
			font-weight: $font-weight-regular;
			margin-left: auto;
			margin-right: 0.5rem;
		}

		.icon-arrow-up {
			fill: none;
			margin-left: 0;
			transform: rotate(90deg);
			stroke: $color-white;
		}

		&:hover,
		&:focus {
			background-color: $color-neutral-darker;
			color: $color-white;

			.icon-arrow-up {
				stroke: $color-white;
			}
		}
	}

	.expanded-filters {
		display: none;
	}

	.filter-expand-toggle {
		margin-bottom: 2rem;
		width: auto;

		&:focus {
			background-color: rgba($color-white, 0.5);
		}

		.icon {
			height: 0.75rem;
			margin-right: 0.5rem;
			stroke: $color-neutral-dark;
			transform: rotate(90deg);
			transition: all 0.2s ease-in-out;
			width: 0.75rem;
		}
	}

	@include breakpoint(md) {
		@include make-row();
		padding: 0;

		.landing-container {
			@include make-col-ready();
			@include make-col(7);
			margin: auto;
			padding: 20px;
		}
	}

	@include breakpoint(lg) {
		.landing-container {
			@include make-col(6);
			padding: 20px;

			.filter-title-bar {
				.close-btn {
					display: none;
				}
			}
		}
	}
}
