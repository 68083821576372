.upcoming-event-item {
	display: none;
	flex-direction: column;
	margin-bottom: 2rem;
	padding-bottom: 2rem;
	border-bottom: 1px solid $color-neon-silver;

	&.active {
		display: flex;
	}

	&.last-visible {
		border-bottom: none;
	}

	.upcoming-event-image {
		width: 100%;
		margin-bottom: 0.938rem;
		object-fit: cover;
		border-radius: 6px;
	}

	.date-label {
		@include text(lambda, 14);
		font-family: $font-family-bold;
		text-transform: uppercase;
	}

	.custom-event-title {
		margin-top: 0.938rem;
	}

	.custom-secondary-button {
		margin-left: 1rem;

		&:only-child {
			margin-left: 0;
		}
	}

	@include breakpoint(md) {
		flex-direction: row;

		.upcoming-event-image {
			width: 22rem;
			margin-bottom: 0;
		}

		.right-info {
			margin-left: 2rem;
		}
	}
}
