.featured-providers {
	.location {
		border-top: none;
		padding: 0;
		min-height: 0;

		.location-not-available {
			@include text(lambda);
		}

		.distance {
			@include text(lambda);
			padding: 0;
		}

		.location-address {
			color: $color-light-black;

			.location-name {
				@include text(kappa);
				font-weight: $font-weight-regular;
			}

			span {
				@include text(lambda);
			}
		}

		&:hover,
		&:focus {
			background-color: transparent;

			.location-name {
				text-decoration: underline;
			}
		}
	}

	.scheduling-content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.scheduling-grid {
		display: flex;
		width: 100%;
		padding: 0 5px;
		margin-bottom: 1rem;
	}

	.scheduling-day {
		background-color: $color-neutral-dark;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		flex: 33.333%;
		max-width: 33.333%;
		margin: 0 5px;
		overflow: hidden;
	}

	.day-title,
	.day-link {
		@include text(lambda);
		align-items: center;
		color: $color-white;
		display: flex;
		font-weight: $font-weight-regular;
		height: 31px;
		justify-content: center;
		margin: 0;
	}

	.day-link {
		background-color: $color-neutral-darker;

		&:hover,
		&:focus {
			background-color: $color-neutral-light;
		}
	}

	@container (min-width: 768px) {
		.expanded-scheduling {
			width: 100%;
			.featured-providers-result {
				display: flex;
				flex: 100%;
				flex-direction: row;
				max-width: 100%;
				margin-right: 0 !important;

				&:hover,
				&:focus {
					.result-name {
						text-decoration: underline;
					}
				}

				.result-details {
					&:hover,
					&:focus {
						background-color: transparent;

						.result-name {
							text-decoration: underline;
						}
					}
				}

				.profile-image-outer {
					flex: 0 1 180px;
					max-width: 180px;
				}

				.profile-image-container {
					height: 100%;
					padding-bottom: 0;
				}

				.result-inner {
					display: flex;
					flex-direction: column;
					flex: 0 1 calc(100% - 180px);
					max-width: calc(100% - 180px);
					padding: 1rem;
				}

				.result-name {
					@include text(eta);
					margin-top: 0;
				}

				.result-top {
					align-items: center;
					display: flex;
				}

				.result-bottom {
					display: flex;
				}

				.result-details,
				.location-scheduling {
					flex: 0 1 35%;
					max-width: 35%;
					margin: 0;
				}

				.location-scheduling {
					padding-bottom: 0;
				}

				.scheduling-help-text,
				.scheduling-content {
					flex: 1;
					padding: 0 1.5rem;
				}
			}
		}
	}

	@container (min-width: 875px) {
		&.multi {
			.inner-container {
				display: flex;
				width: 100%;
				padding: 1.25rem 1.25rem 0;

				.need-more-help {
					flex: 0 0 25%;
					max-width: 25%;
					padding: 0 1.25rem 1rem;
				}
			}
		}

		.expanded-scheduling {
			.featured-providers-result {
				.profile-image-outer {
					flex: 0 1 205px;
					max-width: 205px;
				}

				.result-inner {
					flex: 0 1 calc(100% - 205px);
					max-width: calc(100% - 205px);
				}

				.scheduling-content {
					flex-direction: row;
					padding: 0 1rem;
				}

				.scheduling-grid {
					flex: 1;
					width: auto;
					margin-bottom: 0;
				}

				.view-more-container {
					flex: 0 1 130px;
					max-width: 130px;

					.view-more-link {
						text-align: center;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
