.hero {
	margin: 0 auto;
	overflow: hidden;
	padding: 0;
	width: 100%;

	.hero-img {
		display: block;
		min-height: 350px;
		width: 100%;

		img,
		source,
		canvas {
			object-fit: cover;
			width: 100%;
			height: 100%;
			max-height: 490px;
			min-height: 350px;
		}
	}

	@include breakpoint(lg) {
		.hero-img {
			img,
			source,
			canvas {
				max-height: 560px;
			}
		}
	}
}
