.single-finder {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: auto;
	margin-top: -35px;
	gap: 20px;
	transform: translateY(-50%);
	width: 100%;

	.finder-component {
		background-color: $color-white;
		border-radius: 6px;
		height: auto;
		box-shadow: 1px 3px 3px rgba(85, 85, 85, 0.35);
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 540px;
	}

	.find-component {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 20px;
		margin: auto;
		width: 100%;
		max-width: 362px;

		span {
			text-align: center;
			font-size: 0.875rem;
			margin-bottom: 0.5rem;
		}
	}

	.icon {
		margin: auto;
		margin-bottom: 0.5rem;
	}

	.icon-find-provider {
		width: 2.125rem;
		height: 2.125rem;
	}

	.icon-find-location {
		width: 1.438rem;
		height: 1.875rem;
	}

	.icon-quick-links {
		width: 2.375rem;
		height: 1.688rem;
	}

	@include breakpoint(md) {
		height: auto;
		display: flex;
		margin: auto;
		gap: 20px;
		margin-top: -35px;
		flex-direction: row;
	}
}
