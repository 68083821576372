.checkmark-text {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	margin-bottom: 13px;

	span {
		@include text(kappa, 20);
		font-family: $font-family-secondary;
	}

	@include breakpoint(md) {
		span {
			@include text(lambda, 18);
		}
	}
}
