.contact-us {
	padding: 15px 0px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.contact-us-inner {
		display: flex;
		flex-direction: column;
		max-width: 353px;
		width: 100%;
	}

	@include breakpoint(sm) {
		padding: 40px 0px;
	}
}
