.scroll-box {
	align-items: center;
	background-color: $color-neutral-lightest-2;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	padding: 2rem;
	width: 100%;

	div {
		@include text(iota);
	}
}
