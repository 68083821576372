.input-text {
	display: flex;
	flex-direction: column;
	gap: 10px;

	&.success {
		input {
			border-color: $color-green-lighter;
		}
	}
	&.error {
		input {
			border-color: $color-critical;
		}
	}
	label {
		display: block;
		margin-bottom: 5px;
	}
	.input-container {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 24.5px;
	}
	input {
		@include text(15, 22);
		font-family: $font-family-secondary;
		width: calc(100% - 20px);
		padding: 8px 11.5px;
		border: 1px solid $color-warm-gray;
		border-radius: 6px;
		transition: border-color 0.3s;

		&:focus {
			border-color: $color-green-lighter;
		}
	}
	.label-input-text {
		@include text(14);
		color: $color-black;
		font-family: $font-family-regular;
		text-transform: uppercase;
	}
	.input-error-message {
		@include text(12, 14);
		color: $color-warning;
		font-family: $font-family-tertiary;
	}
	.span-explanation {
		@include text(10);
		display: flex;
		color: $color-qualderm-gray;
		font-family: $font-family-tertiary;
		margin-top: 8px;
	}
	.email-input input {
		width: 100%;
	}
	.icon-container {
		position: absolute;
		top: 50%;
		right: 30px;
		transform: translateY(-50%);
		width: 20px;
		height: 20px;
	}
	.status-icon {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
	}
	.error-message {
		color: $color-critical;
		margin-top: 5px;
	}
	.submit-container {
		&.hide-on-mobile {
			display: none;
		}
		&.hide-on-desktop {
			display: block;
		}
	}

	@include breakpoint(sm) {
		.submit-container {
			min-width: 100px;
		}
	}

	@include breakpoint(md) {
		gap: 2px;

		.submit-container {
			min-width: 120px;
			&.hide-on-mobile {
				display: block;
			}
			&.hide-on-desktop {
				display: none;
			}
		}
	}

	@include breakpoint(lg) {
		.submit-container {
			min-width: 164px;
		}
	}
}
