.map-container {
	object-fit: cover;

	a {
		display: block;
		line-height: 0;
	}

	@include breakpoint(md) {
		img {
			display: unset;
			max-width: unset;
			height: 20.625rem;
			width: 20.625rem;
			object-fit: cover;
		}
	}
}
