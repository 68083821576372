@mixin make-container() {
	margin-right: auto;
	margin-left: auto;
	max-width: $max-container-width;
	padding-right: $sm-grid-outer-padding;
	padding-left: $sm-grid-outer-padding;
	width: 100%;

	@include breakpoint(md) {
		padding-right: $md-grid-outer-padding;
		padding-left: $md-grid-outer-padding;
	}

	@include breakpoint(lg) {
		padding-right: $lg-grid-outer-padding;
		padding-left: $lg-grid-outer-padding;
	}

	@include breakpoint(xl) {
		padding-right: $xl-grid-outer-padding;
		padding-left: $xl-grid-outer-padding;
	}
}

@mixin make-container-compact() {
	margin-right: auto;
	margin-left: auto;
	max-width: $max-container-width-compact;
	padding-right: $sm-grid-outer-padding;
	padding-left: $sm-grid-outer-padding;
	width: 100%;
}

@mixin make-container-narrow() {
	margin-right: auto;
	margin-left: auto;
	max-width: $max-container-width-narrow;
	padding-right: $sm-grid-outer-padding;
	padding-left: $sm-grid-outer-padding;
	width: 100%;
}

@mixin make-container-bg() {
	margin-right: auto;
	margin-left: auto;
	max-width: calc(#{$max-container-width} - #{$sm-grid-outer-padding} * 2);
	padding-right: $sm-grid-outer-padding;
	padding-left: $sm-grid-outer-padding;
	width: 100%;

	@include breakpoint(md) {
		max-width: calc(
			#{$max-container-width} - #{$md-grid-outer-padding} * 2
		);
		padding-right: $md-grid-outer-padding;
		padding-left: $md-grid-outer-padding;
	}

	@include breakpoint(lg) {
		max-width: calc(
			#{$max-container-width} - #{$lg-grid-outer-padding} * 2
		);
		padding-right: $lg-grid-outer-padding;
		padding-left: $lg-grid-outer-padding;
	}

	@include breakpoint(xl) {
		max-width: calc(
			#{$max-container-width} - #{$xl-grid-outer-padding} * 2
		);
		padding-right: $xl-grid-outer-padding;
		padding-left: $xl-grid-outer-padding;
	}
}

@mixin make-row() {
	display: flex;
	flex-wrap: wrap;
	margin-right: -$gutter;
	margin-left: -$gutter;
}

@mixin make-col-ready() {
	flex: 1 1 0;
	margin-bottom: 0;
	padding: 0 $gutter;
}

@mixin make-col($size) {
	flex: 0 0 percentage(calc($size / $grid-columns));
	max-width: percentage(calc($size / $grid-columns));
	margin-bottom: 0;
	padding: 0 $gutter;
}
