.video-and-text {
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: center;

	.text-container {
		flex: 1 1 100%;
		min-width: 200px;
		margin: 0;

		.headline-medium {
			margin-top: 0;
		}

		.body-copy {
			margin-bottom: 0;
		}
	}

	.video-container {
		flex: 1 1 100%;
		position: relative;
		width: 100%;
		height: 0px;
		padding-top: 56.25%;

		iframe,
		lite-youtube {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	@include breakpoint(md) {
		flex-direction: row;

		.video-container {
			padding-top: 27%;
		}

		.text-container {
		}

		.text-container,
		.video-container {
			flex: 0 1 50%;
		}
	}
}
