.location-profile {
	.location-img-outer {
		display: block;
		height: 0;
		overflow: hidden;
		padding-bottom: 50%;
		position: relative;
		width: 100%;
	}

	.profile-image-bg {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			height: 100%;
			object-fit: cover;
			object-position: center center;
			width: 100%;
		}
	}

	.location-img {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img,
		source,
		canvas {
			height: 100%;
			object-fit: cover;
			object-position: center 46%;
			width: 100%;
		}
	}

	.location-about {
		.location-img-outer {
			display: none;
		}
	}

	.specialty-name,
	.compliance-info,
	.subtitle {
		font-weight: $font-weight-medium;
		margin-bottom: 0.5rem;
	}

	.locations-actions {
		display: none;
	}

	.location-section {
		background-color: $color-green-lightest;
		color: $color-black;
		min-height: 90px;
		span:nth-child(2) {
			font-size: 1rem;
			padding: 0.25em 0;
		}
		&:hover {
			background-color: $color-blue-lightest;
		}
	}

	.location-result {
		height: 100%;
		margin-bottom: 1rem;
	}

	.profile-content [data-status] {
		background-color: $color-white;
		width: 100%;
	}

	.share-location {
		background-color: $color-green-lightest;
		min-height: 90px;
		&:hover {
			background-color: $color-blue-lightest;
		}
	}

	.location-section {
		background-color: $color-green-lightest;
		color: $color-black;
		min-height: 90px;
	}

	.inner-wrap {
		padding: 35px 25px;
	}

	.location-result {
		@include text(lambda);
		background-color: $color-white;
		color: $color-neutral-darker;
		margin-bottom: 6.25rem;
		padding: 0;

		.location-details {
			padding: 1.25rem 1rem 1.25rem 1.25rem;
			position: relative;
		}

		.location-name {
			@include text(iota);
			color: $color-neutral-darker;
			display: block;
			font-weight: 500;
			padding-bottom: 0.5rem;
		}

		.location-longname {
			@include text(iota, 22);
			font-weight: 500;
			padding-bottom: 0.5rem;
		}

		.location-type {
			@include text(mu, 16);
			color: $color-green-dark;
			display: block;
			font-weight: $font-weight-medium;
			letter-spacing: 1px;
			padding-bottom: 0.75em;
			text-transform: uppercase;
		}

		.healthcare-location-longname {
			font-weight: 400;
			padding-bottom: 0.5em;
		}

		.address-locality {
			display: flex;
			padding-bottom: 0.75em;

			span {
				padding-left: 0.25em;
			}
		}

		.new-patients {
			@include text(kappa, 20);
			color: $color-green-dark;
			padding-bottom: 1rem;
		}

		.location-scheduling {
			margin-top: auto;
		}

		.location {
			@include text(kappa, 26);
			background-color: $color-neutral-lightest;
			min-height: 130px;
			padding: 1.25rem;
			position: relative;
		}

		.hours {
			@include text(kappa, 20);
			color: $color-green-dark;
		}

		span {
			display: block;
		}

		caption {
			@include text(mu);
			text-align: left;
			text-transform: uppercase;
		}
		table {
			border-spacing: 0;
			padding-bottom: 1rem;
		}

		td {
			@include text(lambda);
			padding-left: 0;
		}

		td:nth-child(2) {
			padding-left: 1rem;
		}
	}

	.location-desktop {
		display: none;
	}

	.service-columns {
		columns: 1;
		li {
			color: $color-green-dark;
			display: block;
		}
	}

	@include breakpoint(md) {
		.location-desktop {
			display: block;
			position: sticky;
			top: 64px;
		}

		.location-introduction {
			display: none;
		}

		.location-about {
			.location-img-outer {
				display: block;
			}
		}

		.overview-link,
		.location-result {
			flex-basis: 240px;
			margin-right: 15px;
			min-width: 240px;
		}

		.service-columns {
			columns: 2;
		}
	}
}
