.toast {
	width: 100%;
	background-color: rgba(29, 29, 29, 0.9);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	position: fixed;
	top: 24px;
	right: 0;
	opacity: 0;
	padding: 1rem 2.5rem 1rem 1rem;
	transition: opacity 0.3s ease;

	&.show {
		opacity: 1;
	}

	* {
		color: $color-white;
	}

	.toast-close-btn {
		position: absolute;
		right: 1rem;
		top: 1rem;
		cursor: pointer;
	}

	.toast-content {
		display: flex;
		flex-direction: row;
		gap: 16px;

		p {
			margin: 0;
			flex: 1;

			a {
				text-decoration: underline;
			}
		}
	}

	@include breakpoint(450px) {
		top: 50px;
		width: 440px;
		border-radius: 6px 0 0 6px;
		padding: 30px 60px 30px 40px;

		&.show {
			animation: slideInRight 0.5s forwards;
		}

		.toast-content {
			gap: 12px;
		}
	}

	@keyframes slideInRight {
		0% {
			transform: translateX(100%);
			opacity: 0;
		}
		100% {
			transform: translateX(0);
			opacity: 1;
		}
	}
}
