.load-more {
	display: flex;
	justify-content: center;

	.button {
		&.hidden {
			display: none;
		}
	}

	@include breakpoint(md) {
		.button {
			height: 50px;
			width: 300px;
		}
	}
}
