.conditions-list {
	background-color: $color-white;
	left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	position: relative;
	right: 50%;
	width: 100vw;
	padding: 30px 0;

	+ .schedule-appointment-cta {
		margin-top: -60px;
	}

	&:last-child {
		margin-bottom: -32px;
	}

	.conditions-list-container {
		@include make-container();
	}

	.horizontal-columns-wrap:last-of-type {
		border-bottom: solid 1px $color-neon-silver;
	}

	.line-bottom-title {
		border-bottom: solid 1px $color-neon-silver;
		padding-bottom: 15px;
	}

	.condition-column {
		margin-bottom: 50px;
	}

	.condition-item {
		@include text(kappa);
		font-family: $font-family-secondary;
		margin-top: 15px;
		width: 100%;
	}

	@include breakpoint(md) {
		&:last-child {
			margin-bottom: -14px;
		}

		.horizontal-columns-wrap {
			@include make-row();

			&:last-of-type {
				padding-bottom: 36px;
			}

			.condition-column {
				@include make-col-ready();
				@include make-col(3);

				&.full {
					max-width: 100%;
					flex: 1;
				}

				.condition-list {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					column-gap: 24px;
					row-gap: 8px;

					&.two-col .condition-item {
						width: calc(50% - 12px);
					}

					&.three-col .condition-item {
						width: calc(33.3333% - 16px);
					}

					&.four-col .condition-item {
						width: 25%;
					}
				}
			}
		}

		.condition-item {
			@include text(lambda);
			margin-top: 0;
		}

		.insurance-list-wrap {
			@include make-row();
			align-items: flex-end;

			.condition-list {
				@include make-col(9);
			}

			.dont-see-your-insurance-block {
				@include make-col(3);
			}
		}
	}

	@include breakpoint(lg) {
		.condition-item {
			@include text(kappa);
		}
	}
}
