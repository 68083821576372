.profile-navigation {
	background-color: $color-white;
	display: flex;
	flex-direction: column;
	overflow-x: auto;
	position: sticky;
	top: 0px;
	z-index: 1;

	// hide duplicate intro-btn when < 3 .profile-section elements
	&.minimal {
		.intro-btn {
			&:last-child {
				visibility: hidden;
			}
		}
	}

	.back-link-container {
		padding-bottom: 0;
		padding-left: 0.75rem;
	}

	.intro-btn {
		svg {
			pointer-events: none;
		}
	}

	@include breakpoint(md) {
		flex-direction: row;
		margin-bottom: 0.5rem;
		padding: 0 30px;

		.back-link-container {
			min-width: 285px;
			padding-bottom: 0;
			padding-top: 0;
			padding-left: 0;
		}

		.button-bar {
			.intro-btn {
				display: none;
			}
		}

		.overview-link,
		.search-result {
			flex-basis: 240px;
			margin-right: 15px;
			min-width: 240px;
		}
	}

	@include breakpoint(lg) {
		padding: 0 70px;

		.back-link-container {
			min-width: 360px;
			gap: 0.25rem;
			justify-content: flex-start;
			padding: 0.75rem;
		}
	}

	@include breakpoint(xl) {
		margin-left: auto;
		margin-right: auto;
		max-width: 1440px;

		.back-link-container {
			gap: 0.15rem;
			padding: 0.75rem;
		}
	}
}
