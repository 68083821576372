.action-btn {
	@include text(theta, 23);
	background-color: $color-neutral-dark;
	color: $color-white;
	cursor: pointer;
	display: flex;
	flex: 0 1 50%;
	flex-direction: column;
	font-weight: 400;
	min-height: 130px;
	padding: 1.25rem;
	position: relative;
	text-align: left;
	transition: background-color 0.1s ease-in-out;

	&:only-child {
		flex: 1;
	}

	&:hover,
	&:focus {
		background-color: $color-black;
	}

	&.action-btn-secondary {
		background-color: $color-neutral-darker;
	}

	.action-btn-subtitle {
		@include text(kappa, iota);
		display: block;
		margin-top: 0.25rem;

		span {
			display: block;
		}
	}

	.icon {
		fill: $color-white;
		position: absolute;
		bottom: 20px;
		right: 20px;
		height: 22px;
		width: 22px;
	}
}
