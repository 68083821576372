.featured-products {
	margin-bottom: 2rem;

	.headline-medium {
		margin-bottom: 0.5rem;
	}
	.card-products-container {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}

	@include breakpoint(md) {
		margin-bottom: 4rem;

		.card-products-container {
			flex-direction: row;
			justify-content: space-evenly;
			gap: 1rem;

			.product-card {
				flex-basis: 50%;
				max-width: 50%;
			}
		}
	}
}
