.rich-text {
	@include text(15, 22);
	color: $color-black !important;
	font-family: $font-family-secondary !important;
	font-size: 0.9375rem !important;
	font-weight: $font-weight-regular;
	line-height: 1.46666 !important;
	max-width: 100%;
	margin: 0 auto;

	&:after {
		clear: left;
		content: '';
		display: block;
	}

	a {
		color: $color-qualderm-blue;
		cursor: pointer;
		font-family: $font-family-secondary;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	img {
		float: left;
		margin-right: 2rem;
		margin-bottom: 0.75rem;
		max-width: 352px;
		border-radius: 6px;
	}

	p,
	span {
		color: $color-black !important;
		font-family: $font-family-secondary !important;
		font-size: 0.9375rem !important;
		font-weight: $font-weight-regular;
		line-height: 1.46666 !important;
	}

	ul {
		list-style-type: none;
		margin-block-start: 1rem;
		margin-block-end: 1rem;
		padding-inline-start: 1.5rem;
		margin-bottom: 1.625rem;

		li {
			color: $color-black !important;
			font-family: $font-family-secondary !important;
			font-size: 0.875rem !important;
			font-weight: $font-weight-regular;
			line-height: 1.2857rem !important;
			padding-bottom: 0.5rem;
			position: relative;

			&:before {
				content: '•';
				font-size: 1rem;
				line-height: 1.25;
				position: absolute;
				left: -1rem;
				vertical-align: middle;
			}

			* {
				color: $color-black !important;
				font-family: $font-family-secondary !important;
				font-size: 0.875rem !important;
				font-weight: $font-weight-regular;
				line-height: 1.2857rem !important;
			}
		}
	}
}
