.related-story {
	.eyebrow-related-story {
		@include text(14);
		color: $color-charcoal;
		font-family: $font-family-regular;
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	.related-link {
		@include text(15);
		color: $color-qualderm-blue;
		font-family: $font-family-regular;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.related-news-title {
		color: $color-qualderm-blue;
		margin-bottom: 10px;
	}

	.related-description {
		@include text(15, 22); //size heigth
		color: $color-black;
		font-family: $font-family-secondary;
	}
}
