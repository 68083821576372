.filter-action-input {
	@include text(kappa, 20);
	display: flex;

	.input-icon-container,
	input[type='text'] {
		flex: 1;
	}

	.filter-accept-btn,
	.filter-remove-btn {
		align-items: center;
		background-color: $color-neutral-dark;
		border-radius: 25px;
		color: $color-white;
		display: flex;
		flex: 0 0 40px;
		height: 40px;
		justify-content: center;
		margin-bottom: 0.75rem;
		padding: 0;
		transition: all 0.1s ease-in-out;
		width: 40px;

		.icon {
			stroke: $color-white;
		}

		&:hover {
			background-color: $color-green-lighter;
			color: $color-black;
			cursor: pointer;

			.icon {
				stroke: $color-black;
			}
		}
	}

	.filter-accept-btn {
		background-color: rgba($color-white, 0.5);

		.icon {
			stroke: $color-black;
		}
	}
}
