.global-footer {
	background-color: $color-charcoal;

	.container-fluid & {
		margin-left: -12px;
		width: 100vw;
	}

	a {
		color: $color-white;
		font-family: $font-family-regular;
	}

	.footer-inner-separator {
		border: 0.1px solid $color-gray-dark-3;
	}

	.footer-container {
		@include make-container();
		display: flex;
		flex-direction: column;
		padding-bottom: 20px;
		padding-top: 20px;
	}

	.footer-logo-container {
		display: flex;
		flex-direction: column;
		margin-bottom: 1.5rem;
		margin-top: 30px;
		align-items: center;

		a {
			margin-bottom: 11.2px;
		}
	}

	.family-member-link {
		margin-bottom: 0;
		text-align: center;
		width: 100%;
	}

	.practice-logo {
		width: 160px;
		height: 65px;
	}

	.partner-logo {
		width: 103px;
		height: 45px;
	}

	.footer-section-title {
		@include text(lambda);
		color: $color-white;
		font-family: $font-family-bold;
		margin-bottom: 0.5rem;
	}

	.footer-link-list {
		text-align: center;

		li {
			@include text(kappa);

			a {
				display: inline-block;
				padding: 7px 0;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.second-row-container {
		display: flex;
	}

	.copyright-container {
		@include text(11);
		display: flex;
		justify-content: center;
		color: $color-gray-light;
		font-family: $font-family-secondary;

		a {
			color: $color-gray-light;
			font-family: $font-family-secondary;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	@include breakpoint(xs) {
		.footer-container {
			padding-top: 0;
		}

		.footer-col {
			.footer-logo-subtitle-container {
				padding: 0;
				display: flex;
				justify-content: center;
			}

			.footer-logo-subtitle {
				font-size: 12px;
				text-align: center;
			}
		}

		.copyright-container {
			margin-top: 30px;
			text-align: center;
		}
	}

	@include breakpoint(md) {
		.footer-container {
			padding-bottom: 0;
			padding-top: 40px;
		}

		.second-row {
			padding-top: 25px;
		}

		.footer-inner {
			display: flex;
			flex-wrap: wrap;
			gap: 1rem;
			justify-content: space-between;
		}

		.footer-logo-container {
			padding-top: 5px;
			margin: 0 0 0.5rem;

			.footer-logo-subtitle-container {
				display: flex;
				justify-content: center;
				margin-top: 10px;
			}
		}

		.partner-logo-container {
			padding-top: 0;
			min-width: 222px;
		}

		.footer-link-list {
			columns: 3;
			margin-bottom: 1.5rem;
			text-align: left;
		}

		.footer-col {
			&.social {
				margin-bottom: 2rem;
			}

			.logo-subtitle {
				@include text(eta);
				color: $color-white;
				margin-top: -5px;
				margin-left: 6px;
				font-size: 12px;
			}

			.footer-logo-subtitle {
				@include text(mu);
				text-align: center;
			}

			.copyright-container {
				margin-top: 0;
			}
		}

		.social-link {
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}
		}

		.footer-col-second-row {
			flex: 0 1 25%;
			min-width: 25%;
		}

		.footer-section-title {
			margin-top: 2rem;
		}

		.copyright-container {
			justify-content: flex-end;
		}
	}
}
