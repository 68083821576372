.video-modal {
	top: 1rem;
	position: absolute;
	right: 1rem;

	.modal-open-btn {
		align-items: center;
		background-color: $color-white;
		border-radius: 2rem;
		cursor: pointer;
		display: flex;
		height: 1.75rem;
		justify-content: center;
		width: 1.75rem;

		&:hover {
			background-color: $color-blue-lightest;
		}

		.icon {
			height: 1.5rem;
			width: 1.5rem;
		}
	}
}
