.search-result {
	@include text(lambda);
	background-color: $color-neutral-lightest-2;
	border-radius: 20px;
	color: $color-neutral-darker;
	margin-bottom: 2rem;
	overflow: hidden;
	padding: 0;
	position: relative;

	&.location-result {
		.location {
			background-color: $color-green-lightest;
		}

		.location-name {
			@include text(theta);
			color: $color-black;
			font-weight: $font-weight-regular;
		}

		.icon-arrow-up {
			transform: rotate(90deg);
		}

		.schedule-or-call {
			flex-direction: column;
		}

		.schedule-btn {
			background-color: $color-green-lighter;
		}

		.call-link {
			background-color: $color-green-light;
		}
	}

	&.map-result {
		bottom: 0;
		left: 5%;
		margin-bottom: 14px;
		position: absolute;
		width: 90%;
		z-index: 999;

		&.expanded {
			.location-scheduling {
				display: block;
			}

			.result-expand-btn {
				.icon-chevron-right {
					transform: rotate(90deg);
				}
			}
		}

		.map-result-pagination {
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding: 1rem 1.25rem;

			p {
				font-family: $font-family-regular;
				margin-bottom: 0;
			}

			button[disabled] {
				&:hover {
					cursor: default;
				}

				.icon {
					stroke: $color-neutral-light;
				}
			}

			button {
				align-items: center;
				display: flex;

				&:hover {
					cursor: pointer;
				}
			}

			.icon {
				stroke: $color-neutral-darker;
				stroke-width: 1;
			}

			.left {
				transform: rotate(180deg);
			}
		}

		.result-top {
			display: block;
		}

		.result-expand-btn {
			align-items: center;
			display: flex;
			justify-content: center;
			padding: 1rem;
			width: 100%;

			.icon-chevron-right {
				height: 0.75rem;
				stroke: $color-neutral-dark;
				transform: rotate(-90deg);
				width: 0.75rem;
			}

			&:hover {
				cursor: pointer;
			}
		}

		.map-result-close-btn {
			padding: 1rem;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;

			.icon-close {
				stroke: $color-neutral-dark;
			}

			&:hover {
				cursor: pointer;
			}
		}

		.location-scheduling {
			display: none;
		}

		.schedule-or-call {
			flex-direction: column;
		}

		.location,
		.action-btn {
			min-height: 90px;
		}
	}

	p {
		@include text(lambda);
		color: $color-neutral-darker;
	}

	.btn {
		@include text(lambda);
		border: 1px solid $color-neutral-darker;
		border-radius: 2px;
		margin: 0;
		padding: 0.625rem 0.25rem 0.5rem;
	}

	a.result-details {
		position: relative;
		transition: background-color 0.1s ease-in-out;
		display: block;

		.icon-star {
			transition: fill 0.1s ease-in-out;
		}

		&:hover,
		&:focus {
			background-color: $color-neutral-light;

			.result-name,
			.specialties,
			.new-patient-text,
			.preferred,
			.average-rating,
			.location-address,
			.location-hours,
			.open {
				color: $color-black;
			}

			.icon-star {
				fill: $color-neutral;
			}

			.star-fill {
				.icon-star {
					fill: $color-neutral-darker;
				}
			}
		}
	}

	.profile-image-container {
		display: block;
		height: 0;
		overflow: hidden;
		padding-bottom: 80%;
		position: relative;
		width: 100%;
	}

	.profile-image-bg {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			height: 100%;
			object-fit: cover;
			object-position: center center;
			width: 100%;
		}
	}

	.profile-image {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			height: 100%;
			object-fit: cover;
			object-position: center 25%;
			width: 100%;
		}
	}

	.result-details {
		color: $color-neutral-darker;
		padding: 1.25rem;
		position: relative;
	}

	.result-name {
		@include text(iota);
		color: $color-neutral-darker;
		display: block;
		font-weight: 500;
		margin-bottom: 0.5rem;
	}

	.medical-group {
		@include text(kappa, 23);
		font-weight: 400;

		&.preferred {
			color: $color-blue-dark;
		}
	}

	.specialties {
		margin-bottom: 0.5rem;
	}

	.specialty {
		@include text(kappa, 23);
	}

	.new-patient-text {
		@include text(kappa, 23);

		&.accepting {
			color: $color-blue-dark;
		}
	}

	.ratings {
		margin: 1rem 0;
	}

	.distance-link {
		margin-right: 0.375rem;
	}

	.location-name {
		@include text(iota, 26);
	}

	.location-hours {
		@include text(kappa);
		color: $color-neutral-darker;
		margin-top: 1.25rem;

		.open-closed {
			display: inline-block;
			margin-right: 0.5rem;

			&.open {
				color: $color-green;
			}

			&.closed {
				color: $color-red;
			}
		}
	}

	.location-scheduling {
		margin-top: auto;
	}

	.location {
		@include text(kappa, 26);
		border-top: 2px solid $color-neutral-darker;
		color: $color-neutral-darker;
		display: block;
		min-height: 130px;
		padding: 1.25rem;
		position: relative;
		transition: background-color 0.1s ease-in-out;

		&:hover,
		&:focus {
			background-color: $color-neutral-light;

			.icon-location {
				fill: $color-blue-dark;
			}
		}

		&.not-available {
			&:hover {
				background-color: $color-neutral-lightest;
			}
		}

		.location-not-available {
			@include text(iota);
			align-items: flex-start;
			display: flex;
			height: 100%;
			justify-content: flex-start;
			width: 100%;
		}

		.icon-location {
			fill: $color-blue;
		}
	}

	.location-address {
		span {
			display: block;
		}
	}

	.directions-link {
		color: $color-blue-dark;
	}

	.schedule-or-call {
		display: flex;
		flex-direction: column;

		.action-btn {
			color: $color-white;
			.action-btn-subtitle {
				@include text(lambda);
			}
		}

		.schedule-btn {
			flex-basis: 63%;

			.action-btn-subtitle {
				padding-right: 1.5rem;
			}
		}

		.call-link {
			flex-basis: 37%;
		}

		.icon {
			fill: $color-white;
		}
	}

	.schedule-or-call-introduction {
		display: flex;
		flex-direction: column;
	}

	.schedule-btn {
		background-color: $color-neutral-dark;
		transition: background-color 0.1s ease-in-out;

		&:hover,
		&:focus {
			background-color: $color-black;
		}
	}

	.call-link {
		background-color: $color-neutral-darker;
		transition: background-color 0.1s ease-in-out;

		&:hover,
		&:focus {
			background-color: $color-black;
		}
	}

	.section-icon {
		bottom: 20px;
		height: 30px;
		position: absolute;
		right: 20px;
		width: 30px;
	}

	@include breakpoint(md) {
		margin-bottom: 6.25rem;

		&.location-result {
			.schedule-or-call {
				flex-direction: row;
			}
		}

		&.map-result {
			.schedule-or-call {
				flex-direction: column;
			}
		}

		&.grid-result {
			.schedule-or-call {
				.schedule-btn {
					flex-basis: 64%;
				}

				.call-link {
					flex-basis: 36%;
				}
			}
		}

		&.list-result {
			display: flex;
			flex-direction: column;
			margin-bottom: 2rem;
			padding-bottom: 0;

			.result-top {
				display: flex;
				position: relative;
			}

			.profile-image-outer {
				flex: 0 0 50%;
			}

			.profile-image-container {
				padding-bottom: 61%;
			}

			.result-details {
				flex: 1 0 50%;
				padding: 1.25rem 1.5rem;
			}

			.location-scheduling {
				display: flex;
			}

			.location,
			.schedule-or-call {
				flex: 0 0 50%;
				min-height: 140px;
			}

			.action-btn {
				min-height: 90px;
			}

			.location {
				border-top: none;

				&:only-child {
					flex: 0 0 100%;
				}
			}

			.schedule-or-call {
				.schedule-btn {
					flex-basis: 61%;
					padding: 0.75rem 20px;
				}
				.call-link {
					flex-basis: 39%;
					padding: 0.75rem 20px;
				}
			}
		}

		&.map-result {
			bottom: 0;
			left: auto;
			position: absolute;
			right: 0;
			width: 335px;

			.result-expand-btn {
				display: none;
			}

			.map-result-pagination {
				padding: 1rem 4rem 1rem 1.25rem;
			}

			.location-scheduling {
				display: block;
			}
		}

		.appointment-types {
			.schedule-btns {
				justify-content: space-around;
				margin-bottom: 0;
				margin-left: auto;
				margin-right: auto;
				max-width: 400px;

				.btn {
					max-width: 200px;
					min-width: 0;
				}
			}
		}
	}
}
