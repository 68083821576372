.profile-scheduling {
	&.active {
		.schedule-appointment {
			display: block;
		}
	}

	.schedule-btn {
		width: 100%;
	}

	.schedule-appointment {
		display: none;
	}

	.location-schedule {
		&.selected {
			display: none;
		}
	}

	.patient-content {
		background-color: $color-white;

		&.expanded {
			.schedule-widget {
				min-height: 60vh;

				&.active {
					&:after {
						display: none;
					}
				}
			}
		}

		.inner-content {
			padding: 1.25em;
		}

		.expand-collapse-btn {
			background-color: $color-white;
			display: none;
			&.active {
				display: flex;
			}
			.section-icon {
				position: static;
			}
		}

		.schedule-widget {
			background-color: $color-white;
			display: none;
			min-height: 50vh;
			padding-bottom: 1em;
			position: relative;
			width: 100%;

			&.active {
				display: block;

				&:after {
					background: linear-gradient(transparent, $color-white);
					bottom: 0;
					content: '';
					display: block;
					height: 100px;
					left: 0;
					pointer-events: none;
					position: absolute;
					width: 100%;
				}
			}

			iframe {
				bottom: 0;
				height: 100%;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
			}
		}
	}

	.patient-type {
		@include text(kappa, lambda);
		align-items: center;
		display: flex;
		font-weight: $font-weight-medium;
		justify-content: center;

		a {
			color: $color-neutral-darker;
		}

		.patient {
			background-color: $color-green-lightest;
			cursor: pointer;
			height: 50px;
			margin: auto;
			padding: 0.75em 1.5em;
			width: 50%;

			&.selected {
				background-color: $color-white;
			}
		}
	}

	.schedule-existing {
		background-color: $color-white;
		display: none;
		min-height: 375px;
		padding-top: 1.5em;
		position: relative;

		&.active {
			display: block;
		}

		p {
			@include text(kappa);
			font-family: $font-family-regular;
			font-style: $font-weight-regular;
			margin-bottom: 0;
			padding: 0.5em 0;
			width: 75%;
		}

		.action-btn {
			bottom: 0;
			margin-top: auto;
			min-height: 90px;
			position: absolute;
			right: 0;
			width: 50%;
		}
	}

	@include breakpoint(md) {
		.patient-content {
			&.expanded {
				.schedule-widget {
					min-height: 50vw;
				}
			}
			.schedule-widget {
				min-height: 30vw;
			}
		}
	}
}
