form {
	//.input-text
	.form-field {
		margin-bottom: 1.25rem;
	}

	//.eyebrow
	.control-label {
		@include text(lambda, 14);
		color: $color-black;
		display: block;
		font-family: $font-family-bold;
		margin-bottom: 0.5625rem;
		text-transform: uppercase;
	}

	//.input-container
	.editing-form-control-nested-control {
		align-items: center;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 0.5rem;
		position: relative;
		width: 100%;

		select {
			cursor: pointer;
			padding-left: 1.25rem;
			padding-right: 2rem;
		}
	}

	.form-control {
		@include text(15, 22);
		font-family: $font-family-secondary;
		padding: 8px 11.5px;
		border: none;
		border-radius: 6px;
		width: 100%;

		&[type='file'] {
			padding-left: 0.125rem;
		}

		&:focus,
		&:focus-visible {
			outline-color: $color-qualderm-blue;
		}

		&[type='checkbox'],
		&[type='radio'] {
			cursor: pointer;
			height: 1rem;
			margin: 0.125rem 0 0;
			min-width: 1rem;
			width: 1rem;
		}
	}

	.ktc-uploader-text {
		@include text(15, 22);
		font-family: $font-family-secondary;
	}

	.ktc-checkbox {
		display: flex;
		gap: 0.75rem;

		label {
			@include text(lambda);
			cursor: pointer;
			font-family: $font-family-secondary;
		}
	}

	.ktc-checkbox-list {
		.ktc-checkbox {
			margin-bottom: 0.5rem;
		}
	}

	.ktc-radio-list {
		.ktc-radio {
			display: flex;
			gap: 0.75rem;
			margin-bottom: 0.5rem;

			label {
				@include text(lambda);
				cursor: pointer;
				font-family: $font-family-secondary;
			}
		}
	}

	.explanation-text {
		@include text(10);
		display: flex;
		color: $color-qualderm-gray;
		font-family: $font-family-tertiary;
		margin-top: 8px;
	}

	.field-validation-error {
		@include text(10);
		display: flex;
		color: $color-critical;
		font-family: $font-family-tertiary;
	}

	input[type='submit'] {
		@include text(16, 16);
		border-radius: 6px;
		border: none;
		background: $color-qualderm-blue;
		display: flex;
		padding: 12px 20px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		color: $color-white;
		display: flex;
		width: 100%;
		cursor: pointer;
		transition: all 0.3s;

		&:hover {
			filter: brightness(0.9);
		}

		&:active {
			filter: brightness(1.1);
		}
	}
}
