.text-link {
	@include text(lambda);
	color: $color-qualderm-blue;
	cursor: pointer;
	font-family: $font-family-secondary;

	&:hover {
		text-decoration: underline;
	}

	&.with-icon {
		&:hover {
			text-decoration: none;

			span {
				text-decoration: underline;
			}
		}
	}

	.icon {
		margin-bottom: -2px;
	}
}
