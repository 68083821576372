.location-listing-group {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	@include breakpoint(md) {
		justify-content: space-around;
		flex-wrap: wrap;
	}

	@include breakpoint(1140px) {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 26px;
	}
}
