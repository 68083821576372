/*
 *
 * TYPOGRAPHY VARIABLES AND MAPS
 *
 */

/*
  * Font Size and Line Height Maps
  * Matching keys gets used together by default
  * Used in text() mixin and calulateLineHeight() function
  */

$font-size-scale: (
	alpha: 54,
	beta: 48,
	gamma: 37,
	delta: 30,
	epsilon: 26,
	zeta: 24,
	eta: 22,
	theta: 20,
	iota: 18,
	kappa: 16,
	lambda: 14,
	mu: 12,
);

$line-height-scale: (
	alpha: 54,
	beta: 48,
	gamma: 37,
	delta: 30,
	epsilon: 32,
	zeta: 32,
	eta: 32,
	pie: 36,
	theta: 30,
	iota: 24,
	kappa: 22,
	lambda: 20,
	mu: 18,
);

@font-face {
	font-family: 'Tw Cen MT';
	src: url('/assets/fonts/Tw Cen MT.ttf');
}

@font-face {
	font-family: 'Tw Cen MT Bold';
	src: url('/assets/fonts/Tw Cen MT Bold.ttf');
}

@font-face {
	font-family: 'Tw Cen MT Italic';
	src: url('/assets/fonts/Tw Cen MT Italic.ttf');
}

@font-face {
	font-family: 'Tw Cen MT Bold Italic';
	src: url('/assets/fonts/Tw Cen MT Bold Italic.ttf');
}

$font-family-regular: 'Tw Cen MT', sans-serif;
$font-family-bold: 'Tw Cen MT Bold', sans-serif;
$font-family-italic: 'Tw Cen MT Italic', sans-serif;
$font-family-bold-italic: 'Tw Cen MT Bold Italic', sans-serif;
$font-family-secondary: 'Arial', sans-serif;
$font-family-tertiary: 'Open Sans', sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;
