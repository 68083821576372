.treatment-detail {
	.custom-h2 {
		margin-bottom: 0.938rem;
		font-weight: $font-weight-regular;
	}
	.custom-h3 {
		margin-bottom: 0.68rem;
		font-weight: $font-weight-regular;
	}
	.two-columns-block {
		margin-top: 20px;
		margin-bottom: 40px;
		display: flex;

		.column-wrap {
			width: 50%;
		}
	}

	.column {
		padding: 15px 0;
		margin-top: 15px;
		border-top: 1px solid $color-neon-silver;
		border-bottom: 1px solid $color-neon-silver;
	}

	li {
		@include text(lambda);
		font-family: $font-family-secondary;
		list-style-type: circle;
	}

	.li-item,
	.side-effect {
		display: block;
	}

	.info-block {
		margin-top: 40px;
	}

	.border-bottom-block {
		border-bottom: 1px solid $color-neon-silver;
	}

	.margin-bottom-block {
		margin-bottom: 50px;
	}
}
