.modal-call-to-schedule {
	width: 100%;
	background-color: $color-warm-gray;
	padding: 20px;
	border-radius: 6px;

	.modal-title {
		margin: 0px 0px 13px;
	}

	.locations-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;

		.location {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 11px;
			width: 100%;
			max-width: 300px;
			background: $color-white;
			padding: 17px;
			border-radius: 6px;

			.location-info {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				gap: 11px;

				img {
					width: 296px;
					height: 167px;
					object-fit: cover;
					object-position: center;
				}

				.location-microdata {
					.text-link {
						@include text(18, 20);
						display: inline-block;
						font-weight: $font-weight-bold;
						margin-bottom: 0.25rem;
					}

					.street-address {
						display: inline;
					}
				}

				.location-miles {
					@include text(16, 20);
					color: $color-qualderm-blue;
					font-family: $font-family-secondary;
					margin: 0;
				}
			}
		}
	}

	@include breakpoint(md) {
		.locations-container {
			flex-direction: row;
			align-items: stretch;
		}
	}
}
