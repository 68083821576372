.provider-result-card {
	display: flex;
	flex-direction: column;
	background-color: $color-white;
	border-radius: 6px;
	padding: 17px;
	margin-bottom: 1.875rem;
	width: 100%;

	.provider-picture-container {
		aspect-ratio: 41 / 49;
		display: block;
		margin-bottom: 17.5px;
		position: relative;
		width: 100%;
	}

	.provider-picture-bg {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.provider-picture {
		height: 100%;
		left: 0;
		object-fit: cover;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.provider-name {
		@include text(iota, 20);
		font-family: $font-family-secondary;
		color: $color-qualderm-blue;
		display: inline-block;
		font-weight: $font-weight-bold;
		margin-bottom: 5px;

		&:hover {
			text-decoration: underline;
		}
	}

	.checkmark-text {
		margin-top: 0.25rem;
	}

	.distance-link {
		&.has-location {
			.additional-location {
				margin-left: 1.125rem;
				position: relative;
	
				&::before {
					color: $color-qualderm-gray;
					content: '|';
					display: block;
					font-weight: $font-weight-regular;
					left: -0.8125rem;
					pointer-events: none;
					position: absolute;
					top: -1px;
					transform: translateX(-50%);
				}
			}
		}

		&:has(.distance-label:empty) {
			display: none;
		}

		&:has(.additional-location) {
			display: block !important;
		}
	}

	.directions-link,
	.additional-location {
		@include text(lambda, 18);
		font-family: $font-family-secondary;
	}

	.directions-link {
		color: $color-qualderm-blue;
		margin-right: 0.5rem;

		&:hover {
			text-decoration: underline;
		}
	}

	.distance-label {
		@include text(lambda, 14);
		display: inline;
		min-width: 60px;
	}

	.additional-location {
		color: $color-black;
		font-weight: $font-weight-bold;
	}

	.custom-check {
		margin-right: 5px;
	}

	.location-microdata {
		margin-top: 13px;
	}

	.custom-schedule-appointment-button {
		@include text(theta, 22);
		padding: 14px 20px;
		width: 100%;
	}

	.distance-link {
		font-family: $font-family-secondary;
		margin-bottom: 13px;
	}

	.schedule-an-appointment-call {
		padding-top: 0.375rem;

		.schedule-an-appointment-call-text {
			@include text(14, 18);
			margin: 0 0 6px;
		}
	}

	@include breakpoint(md) {
		flex-direction: row;
		gap: 24px;
		padding: 12px 21px 13px 12px;

		.provider-picture-container {
			margin-bottom: 0;
			max-width: 164px;
			max-height: 196px;
		}

		.provider-name {
			@include text(kappa, 20);
			margin-top: 0;
		}

		.checkmark-text {
			margin-top: 0;
		}

		.custom-schedule-appointment-button {
			@include text(kappa, 18);
			padding: 9px 20px;
			width: auto;
		}
	}
}
