.subscription-with-name {
	background-color: $color-qualderm-blue;
	margin: 0;
	padding: 0px 15px;

	.subscription-container {
		max-width: 726px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		padding-bottom: 46px;
		padding-top: 46px;
		gap: 26px;

		.subscription-info {
			display: flex;
			flex-direction: column;
			gap: 5px;

			h2 {
				@include text(22, 22);
				color: $color-white;
				font-weight: $font-weight-regular;
				font-weight: 400;
				margin: 0;
			}

			p {
				@include text(15, 22);
				color: $color-white;
				font-family: $font-family-secondary;
				font-style: normal;
				font-weight: 400;
				margin: 0;
			}
		}

		.subscription-form-wrapper-wrapper {
			width: 100%;
			display: flex;
			flex-direction: column-reverse;
			gap: 24px;

			&.hide {
				display: none;
			}

			.input-wrapper {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 16px;

				button {
					max-width: 200px;
				}

				.body-copy {
					@include text(15, 22);
					color: $color-white;
					font-family: $font-family-secondary;
				}
			}

			.label-input-text {
				color: $color-white;
			}

			.span-explanation {
				@include text(14, 18);
				color: $color-white;
				font-family: $font-family-secondary;
			}
		}

		.subscription-confirmation {
			display: none;

			&.show {
				display: block;
			}

			p {
				@include text(15, 22);
				color: $color-white;
				font-family: $font-family-secondary;
				font-style: normal;
				font-weight: 400;
				margin: 0;
			}
		}
	}

	@include breakpoint(md) {
		padding: 0px;

		.subscription-container {
			.subscription-form-wrapper {
				width: 100%;
				flex-direction: row;
			}
		}
	}
}
