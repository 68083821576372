.profile-insurance {
	.inner-wrap {
		max-height: 450px;
	}

	.insurance-input {
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
			no-repeat 95%;
		background-color: $color-neutral-lightest;
		border-radius: 2em;
		border: none;
		padding: 0.8em;
		text-indent: 1rem;
		width: 95%;
	}

	.search-message {
		margin: 0.75em 1.25em;
		padding: 1em 0.5em;
		width: 85%;

		&.error {
			background-color: rgba($color: $color-red, $alpha: 0.2);
		}
	}

	.insurance-list {
		padding: 0.5em 1.25em;
		columns: 2;
		> li {
			@include text(kappa, 20);
			padding-bottom: 1em;
			.highlight {
				background-color: $color-blue-lightest;
			}
		}
	}

	p {
		font-family: $font-family-regular;
		margin-left: 1.25em;
	}
}
