.selected-filters-list {
	padding: 10px 10px 0;

	.tag-list {
		margin-bottom: 0;
	}

	.mobile-clear-button-container {
		display: flex;
		justify-content: flex-end;
	}

	.icon-interaction-btn {
		margin-bottom: 1rem;
	}

	@include breakpoint(lg) {
		padding: 0;

		.mobile-clear-button-container {
			display: none;
		}
	}
}
