.eyebrow {
	@include text(lambda);
	color: $color-black;
	font-family: $font-family-bold;
	font-weight: $font-weight-regular;
	margin-bottom: 0.625rem;
	text-transform: uppercase;

	span {
		color: $color-neon-silver;
	}
}

.headline-large {
	@include text(28);
	color: $color-black;
	font-family: $font-family-regular;
	font-weight: $font-weight-regular;
	margin-bottom: 1.3125rem;
}

.headline-medium {
	@include text(zeta);
	color: $color-black;
	font-family: $font-family-regular;
	font-weight: $font-weight-regular;
	margin-bottom: 0.6875rem;

	.text-link {
		@include text(kappa);
	}
}

.headline-small {
	@include text(eta);
	color: $color-black;
	font-family: $font-family-regular;
	font-weight: $font-weight-regular;
	margin-bottom: 1rem;
}
