.expandable {
	&.expanded {
		.inner-wrap {
			max-height: none;

			&:after {
				display: none;
			}
		}

		.expand-collapse-btn {
			box-shadow: none;
		}
	}

	.inner-wrap {
		max-height: 400px;
		overflow: hidden;
		position: relative;

		&:after {
			background: linear-gradient(transparent, $color-neutral-lightest-2);
			bottom: 0;
			content: '';
			display: block;
			height: 200px;
			left: 0;
			pointer-events: none;
			position: absolute;
			width: 100%;
		}
	}

	.expand-collapse-btn {
		background-color: $color-neutral-lightest-2;
		bottom: 0;
		display: flex;
		width: 100%;
		z-index: 1;
	}
}

.expand-collapse-btn {
	align-items: center;
	cursor: pointer;
	display: none;
	justify-content: center;
	padding: 1.5em;
	width: 100%;

	.icon {
		transform: rotate(90deg);
	}

	.expanded & {
		.icon {
			transform: rotate(-90deg);
		}
	}
}
