.article-list {
	margin-bottom: 3.625rem;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	.article-listing {
		display: none;

		&:before {
			display: none;
		}

		&.last-visible {
			.separator {
				display: none;
			}
		}

		&.active {
			display: block;
		}

		&:last-of-type {
			margin-bottom: 2.5rem;
		}
	}

	.article-date {
		@include text(lambda);
		display: block;
		color: $color-light-black;
		font-weight: $font-weight-regular;
		margin-bottom: 0.25rem;
	}

	.article-image {
		border-radius: 10px;
		margin-bottom: 1.5rem;
		aspect-ratio: 16 / 9;
		overflow: hidden;

		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}

	.heading-4 {
		@include text(eta);
		color: $color-neutral-darker;
		font-weight: $font-weight-semi-bold;
		margin-bottom: 0.5rem;
	}

	p {
		@include text(iota);
		color: $color-neutral-darker;
		margin-bottom: 1rem;
	}

	.article-link {
		@include text(kappa);
		color: $color-blue-dark;
	}

	.separator {
		border: none;
		display: block;
		height: 1px;
		margin: 1.875rem 0;
		width: 100%;
		background-color: $color-neon-silver;
	}

	@include breakpoint(md) {
		.article-inner {
			align-items: flex-start;
			display: flex;
		}

		.article-image {
			flex: 0 0 240px;
			margin-bottom: 0;
			margin-right: 2rem;
		}
	}

	@include breakpoint(lg) {
		ul {
			margin-left: -10px;
			width: calc(100% + 20px);
		}

		.article-listing {
			padding: 0 10px;
		}

		.article-image {
			flex: 0 0 390px;
		}
	}
}
