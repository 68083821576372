.related-news {
	margin: 0 auto;

	.eyebrow {
		@include text(14); //size heigth
		color: $color-charcoal;
		font-family: $font-family-regular;
		text-transform: uppercase;
	}

	.news-title {
		@include text(28); //size heigth
		color: $color-black;
		font-family: $font-family-regular;
		margin-bottom: 4px;
	}

	.body-copy {
		@include text(15, 22); //size heigth
		color: $color-black;
		font-family: $font-family-secondary;
	}

	.separator {
		height: 1px;
		background: $color-neon-silver;
		border-style: none;
		margin: 20px 0;
	}

	.separator-new-list {
		height: 1px;
		background: $color-neon-silver;
		border-style: none;
		margin: 20px 0;
	}
	.news-list {
		margin-top: 30px;
	}
	.related-title {
		@include text(28, 32); //size heigth
		color: $color-charcoal;
		font-family: $font-family-regular;
		margin-bottom: 24px;
	}
}
