.breadcrumb {
	display: flex;
	margin-bottom: 2rem;

	.text-link,
	.current {
		@include text(15, 15);
		display: flex;
		font-family: $font-family-regular;
	}

	.text-link {
		margin-right: 1.625rem;
		position: relative;

		&::after {
			color: $color-qualderm-gray;
			content: '|';
			display: block;
			left: calc(100% + 0.8125rem);
			pointer-events: none;
			position: absolute;
			top: 0;
			transform: translateX(-50%);
		}
	}

	.current {
		color: $color-qualderm-gray;
	}

	@include breakpoint(lg) {
		margin-bottom: 4rem;
	}
}
