.featured-providers-aside {
	width: 258px;

	.featured-providers-wrap {
		display: flex;
		flex-direction: column;
		background-color: $color-white;
		border-radius: 6px;
		padding: 12px;
	}

	.bottom-label {
		@include text(13, 18);
		font-family: $font-family-secondary;
	}

	.featured-providers-row {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 12px;
	}

	.featured-item {
		display: flex;
		flex-direction: column;
		flex: 0 1 calc(50% - 6px);
		margin-bottom: 10px;
		max-width: calc(50% - 6px);

		a {
			aspect-ratio: 1/1;
			border-radius: 6px;
			display: block;
			margin-bottom: 0.375rem;
			overflow: hidden;
			width: 100%;
		}

		&:only-child {
			flex: 0 1 100%;
			max-width: 100%;
		}
	}

	.featured-item-image {
		height: 100%;
		object-fit: cover;
		object-position: center 10%;
		border-radius: 6px;
		width: 100%;
	}
}
