.schedule-appointment-cta {
	display: flex;
	justify-content: center;
	background-color: $color-white;
	left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	padding-top: 30px;
	padding-bottom: 50px;
	position: relative;
	right: 50%;
	text-align: center;
	width: 100vw;

	&:last-child {
		margin-bottom: -32px;
	}

	.schedule-appointment-content {
		@include make-container();
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.schedule-appointment-label {
		@include text(zeta);
		font-weight: $font-weight-regular;
	}

	@include breakpoint(md) {
		&:last-child {
			margin-bottom: -14px;
		}

		.schedule-appointment-label {
			@include text(eta);
		}
	}
}
