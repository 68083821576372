$default-icon-color: $color-black;

.icon {
	height: 1rem;
	stroke-linecap: round;
	width: 1rem;

	&.icon-chevron-right {
		fill: $color-qualderm-blue;
	}

	&.icon-location {
		fill: $color-qualderm-green;
	}

	&.icon-filter,
	&.icon-arrow-left,
	&.icon-search {
		fill: $color-qualderm-green;
		stroke-width: 0;
	}

	&.icon-menu-close {
		fill: $color-charcoal;
		stroke-width: 0;
	}

	&.icon-sort-icon,
	&.icon-list-view,
	&.icon-close {
		fill: $default-icon-color;
		stroke-width: 0;
	}

	use {
		path {
			all: inherit;
		}
	}
}
