.heading-0 {
	@include heading-0();
}

.heading-1 {
	@include heading-1();
}

.heading-2 {
	@include heading-2();
}

.heading-2-regular {
	@include heading-2-regular();
}

.heading-3 {
	@include heading-3();
}

.heading-4 {
	@include heading-4();
}

.heading-5 {
	@include heading-5();
}

.heading-6 {
	@include heading-6();
}

.subtitle {
	@include subtitle();
}

.page-title {
	@include page-title();
}

.section-title {
	@include section-title();
}

.content-title {
	@include content-title();
}

.content-subtitle {
	@include content-subtitle();
}

.hero-content-title {
	@include hero-content-title();
}
.hero-content-paragraph {
	@include hero-content-paragraph();
}
