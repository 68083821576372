@media print {
	body,
	html {
		width: 1100px;
	}

	* {
		transition: none !important;
	}

	header,
	footer,
	aside,
	nav,
	form,
	iframe {
		display: none !important;
	}

	.search-print {
		padding: 1.75em;
		h3 {
			padding-left: 0.5em;
		}
		ul {
			padding-inline-start: 0;
			list-style: none;
		}

		li {
			padding-left: 0.5em;
		}
	}

	.profile .profile-content {
		display: table;
		height: 100%;
	}

	//ios/mobile specific for provider/location introduction
	@media (max-width: 768px) {
		.provider-mobile-print,
		.location-mobile-print {
			display: block !important;
			page-break-inside: avoid;
		}
	}
	.profile-content,
	.provider-result,
	.provider-background,
	.provider-location,
	.provider-insurance,
	.provider-ratings,
	.results-list-item {
		display: block !important;
		page-break-inside: avoid;
	}

	.expandable {
		.inner-wrap {
			max-height: none;
		}
	}

	//supress blank pages in Safari mobile
	.profile .profile-content {
		height: auto;
	}

	.search-result.grid-result,
	.search-result.list-result {
		flex-direction: row;
		.result-top {
			flex: 0 1 45%;
		}
	}

	.location-about,
	.location-appointments,
	.location-providers,
	.location-services,
	.location-instructions,
	.location-parking,
	.location-custom {
		display: block !important;
		page-break-inside: avoid;
	}
	.search-print {
		page-break-after: avoid;
		display: block !important;
		height: auto;
	}

	.search-page-wrapper {
		display: block !important;
		height: 100%;
	}

	.profile-navigation,
	.section-nav,
	.profile-mobile-actions-inner,
	.profile-actions,
	.expand-collapse-btn,
	.controls-container {
		display: none !important;
	}

	.profile-navigation,
	.pagination-nav,
	.back-to-top-btn,
	.search-page-controls {
		visibility: hidden;
	}

	.reveal-content {
		max-height: none !important;
	}

	.section-controls {
		padding: 0;
	}
	.search-result {
		&.grid-result,
		&.list-result {
			display: flex;
			flex-direction: column;
			margin-bottom: 2rem;
			padding-bottom: 0;

			.result-top {
				display: flex;
			}

			.profile-image-outer {
				flex: 0 0 50%;
			}

			.profile-image-container {
				padding-bottom: 61%;
			}

			.result-details {
				flex: 0 0 50%;
				padding: 1.25rem 1.5rem;
			}

			.location-scheduling {
				display: flex;
			}

			.location,
			.schedule-or-call {
				flex: 0 0 50%;
				min-height: 140px;
			}

			.location {
				&:only-child {
					flex: 0 0 100%;
				}
			}

			.schedule-or-call {
				.schedule-btn {
					flex-basis: 61%;
				}
				.call-link {
					flex-basis: 39%;
				}
			}
		}
	}
	.search-page {
		.search-page-wrapper {
			.controls-closed {
				.grid-result {
					flex-direction: row;
					min-height: 230px;

					.result-top {
						flex: 0 1 35%;
					}

					.location-scheduling {
						flex: 0 1 65%;
						margin-top: 0;
					}

					.profile-image-outer {
						flex: 0 1 180px;
					}

					.profile-image-container {
						height: 100%;
						padding-bottom: 0;
					}

					.location {
						background-color: $color-green-lightest;
						flex: 2;

						&:hover {
							background-color: $color-blue-lightest;
						}

						&.not-available {
							background-color: $color-neutral-lightest;

							&:hover {
								background-color: $color-neutral-lightest;
							}
						}
					}

					.schedule-or-call {
						display: flex;
						flex: 3;
						flex-direction: row;

						.schedule-btn {
							background-color: $color-green-lighter;
							flex-basis: 60%;
							max-width: 60%;

							&:hover {
								background-color: $color-blue-lightest;
							}

							&:only-child {
								flex-basis: 100%;
								max-width: 100%;
							}
						}

						.call-link {
							background-color: $color-green-light;
							flex-basis: 40%;
							max-width: 40%;

							&:hover {
								background-color: $color-blue-lightest;
							}

							&:only-child {
								flex-basis: 100%;
								max-width: 100%;
							}
						}
					}
				}
			}
			.grid-result,
			.list-result {
				flex-direction: row;
				min-height: 230px;

				.result-top {
					flex: 0 1 35%;
				}

				.location-scheduling {
					flex: 0 1 65%;
					margin-top: 0;
				}

				.profile-image-outer {
					flex: 0 1 180px;
				}

				.profile-image-container {
					height: 100%;
					padding-bottom: 0;
				}

				.location {
					background-color: $color-green-lightest;
					flex: 2;

					&:hover {
						background-color: $color-blue-lightest;
					}

					&.not-available {
						background-color: $color-neutral-lightest;

						&:hover {
							background-color: $color-neutral-lightest;
						}
					}
				}

				.schedule-or-call {
					display: flex;
					flex: 3;
					flex-direction: row;

					.schedule-btn {
						background-color: $color-green-lighter;
						flex-basis: 60%;
						max-width: 60%;

						&:hover {
							background-color: $color-blue-lightest;
						}

						&:only-child {
							flex-basis: 100%;
							max-width: 100%;
						}
					}

					.call-link {
						background-color: $color-green-light;
						flex-basis: 40%;
						max-width: 40%;

						&:hover {
							background-color: $color-blue-lightest;
						}

						&:only-child {
							flex-basis: 100%;
							max-width: 100%;
						}
					}
				}
			}
		}
	}

	@page {
		size: 330mm 427mm;
		margin: 14mm;
	}
}
