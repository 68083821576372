.button-submit {
	@include text(16, 16);
	border-radius: 6px;
	border: 1px solid $color-qualderm-gray;
	background: $color-qualderm-blue;
	display: flex;
	padding: 12px 20px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	color: $color-white;
	display: flex;
	width: 100%;
	cursor: pointer;
	transition: all 0.3s;

	&:hover {
		filter: brightness(0.9);
	}

	&:active {
		filter: brightness(1.1);
	}
}
