.filter-provider-result {
	margin-bottom: 1rem;
	.filter-header {
		display: flex;
		align-items: center;
		height: 70px;
		background-color: $color-white;
		padding: 14px 24px;
		margin-bottom: 0.1rem;
		box-shadow: 0px 2px 3px -1px rgba(85, 85, 85, 0.35);
	}
	.filter-list {
		list-style-type: none;
	}

	.filter {
		width: 30px;
		height: 18px;
	}

	.filter-item-label {
		flex: 1;
	}

	.icon-close {
		width: 24px;
		height: 24px;
	}

	.icon-chevron-right {
		use {
			fill: $color-qualderm-green;
		}
	}

	.filter-item {
		display: flex;
		padding: 14px 20px;
		background-color: $color-background-hover-nav;
		margin-bottom: 2px;
	}

	.black-bold-label {
		@include text(lambda, 20);
		color: $color-black;
		font-family: $font-family-bold;
	}

	.sort-header-label {
		@include text(zeta);
		font-weight: $font-weight-regular;
		flex: 1;
		margin-left: 21px;
	}
}
