.provider-location-card {
	background-color: $color-neutral-lightest-3;
	display: flex;
	flex-direction: column;
	padding: 1.4375rem;
	border-radius: 5px;
	margin-bottom: 13px;

	.provider-location-card-description {
		@include breakpoint(xs) {
			padding-top: 1.4375rem;
		}
	}

	.text-link {
		@include text(15, 18);
	}

	.location-name {
		@include text(15, 18);
    	font-family: $font-family-secondary;
		font-weight: $font-weight-bold;
	}

	.text-link:hover {
		border-bottom: none;
	}
	.description-location {
		margin-bottom: 10px;
	}
	.description-location .city-location {
		margin-top: 5px;
		color: $color-black;
		font-family: $font-family-secondary;
		@include text(15, 22);
	}
	p.city-location {
		margin-top: 5px;
		margin-bottom: 0;
	}
	.office-hours {
		margin-bottom: 10px;
	}
	.provider-location-card-map {
		display: block;
		position: relative;

		.distance-label {
			font-family: $font-family-secondary;
			@include text(15, 22); //size, height
			top: 8.25rem;
			left: 1.25rem;
			background-color: $color-neutral-lightest-3;
			color: $color-qualderm-blue;
			padding: 0.5rem;
			position: absolute;
			border-radius: 5px;
			border: 1px solid $color-gray-neutral-ligth;
			pointer-events: none;

			&:empty {
				display: none;
			}
		}
	}

	.provider-location-card-office-hours {
		width: 100%;
		background-color: $color-white;
		display: none;

		&.show {
			display: block;
		}

		.location-card-row-info-column {
			display: flex;
			flex-direction: column;
			padding-right: 25px;
			width: 210px;

			.location-card-schedule {
				display: flex;
				justify-content: space-between;
				flex-direction: row;
				margin-bottom: 0.375rem;
				white-space: nowrap;

				&:last-child {
					margin-bottom: 0;
				}

				&.hidden {
					display: none;
				}

				.day-schedule,
				.day-schedule-time {
					@include text(14, 14);
				}

				.day-schedule {
					margin-right: 0.75rem;
					width: 31px;
					font-weight: 700;
				}

				.day-schedule-time {
					flex: 1;
				}

				span {
					@include text(15, 22);
					font-family: $font-family-secondary;
					text-align: left;
				}
			}
		}
	}

	@include breakpoint(md) {
		display: flex;
		flex-direction: row;
		gap: 24px;
		// height: 17.4375rem;
		width: 33.75rem;
		padding: 1.4375rem;
		margin-right: 13px;

		.provider-location-card-map {
			width: 15rem;
			display: block;
			position: relative;
			img {
				height: 14.625rem;
				object-fit: cover;
			}
			.distance-label {
				font-family: $font-family-secondary;
				@include text(15, 22); //size, height
				top: 11.25rem;
				left: 1.25rem;
				background-color: $color-neutral-lightest-3;
				color: $color-qualderm-blue;
				padding: 0.5rem;
				position: absolute;
				border-radius: 5px;
				border: 1px solid $color-gray-neutral-ligth;
			}
		}

		.provider-location-card-description {
			width: 14.75rem;
			@include breakpoint(sm) {
				padding-top: 0;
			}
		}
	}
}
