.page-sections {
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;

	.sections-title {
		@include text(eta);
		color: $color-light-black;
		font-weight: $font-weight-semi-bold;
		padding: 0.75rem;
	}

	.sections-inner {
		border: 1px solid $color-neutral-light;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		padding: 0.75rem;
	}

	a {
		@include text(kappa);
		color: $color-blue-links;
		text-decoration: underline;
		margin-bottom: 0.5rem;
	}

	@include breakpoint(lg) {
		margin-top: 5.5rem;
	}
}
