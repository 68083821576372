.campaign-landing {
	display: flex;
	justify-content: center;
	flex-direction: column;

	> section {
		margin-bottom: 50px;
	}

	.content-block {
		width: 45.5rem;
		height: auto;
		margin: auto;
		background-color: rgba($color-white, .9);
		border-radius: 6px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 53px 90px;
		margin-bottom: 50px;
		box-shadow: 0px 3px 8px 0px rgba(85, 85, 85, 0.35);
	}

	.custom-schedule-appointment-button {
		width: 300px;
		margin-top: 10px;
	}

	.content-block-padding-80 {
		padding: 80px 90px;
	}

	.posts-wrap {
		display: flex;
		margin: auto;
		justify-content: space-between;
		width: 45.5rem;
		margin-bottom: 50px;
	}

	.posts {
		width: 352px;
		height: auto;
		border-radius: 6px;
		background-color: $color-white;
		padding-bottom: 11px;
		box-shadow: 0px 3px 8px 0px rgba(85, 85, 85, 0.35);
	}

	.posts-image {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}

	.post-title {
		font-size: 1.375rem;
		font-family: $font-family-regular;
		font-weight: $font-weight-regular;
		margin-top: 44px;
	}

	.post-content-wrap {
		padding: 11px 24px;
	}

	.post-content {
		margin-top: 10px;
	}

	.custom-schedule-appointment-button {
		width: 100%;
	}

	.video-player {
		border-radius: 6px;
	}

	.video-with-title {
		width: 45.5rem;
		margin-left: auto;
		margin-right: auto;

		lite-youtube {
			border-radius: 6px;
		}
	}
}
