.family-member-link {
	@include text(lambda);
	align-self: flex-start;
	color: $color-qualderm-gray;
	display: block;
	flex-grow: 1;
	font-family: $font-family-regular;
	pointer-events: none;
	text-transform: uppercase;

	a {
		pointer-events: auto;
	}

	span {
		color: $color-qualderm-blue;
	}
}
