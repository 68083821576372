.speed-bump {
	align-items: center;
	background-color: $color-black;
	display: none;
	height: 100%;
	justify-content: center;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 5;

	&.active {
		display: flex;
	}

	.speed-bump-content {
		background-color: $color-white;
		padding: 10px;
		border-radius: 8px;
		text-align: left;
		width: 80%;
	}
	.headline-small {
		margin-bottom: 0.3125rem;
	}
	.speed-bump-description {
		@include text(15, 22);
		color: $color-black;
		font-family: $font-family-secondary;
		margin-top: 0.3125rem;
		margin-bottom: 0.3125rem;
	}

	.speed-bump-buttons {
		margin-top: 7px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 6.875rem;
	}

	.speed-bump-buttons button {
		padding: 10px 20px;
		cursor: pointer;
	}

	.confirm-button {
		background-color: $color-qualderm-blue;
		color: $color-white;
		border: none;
		border-radius: 7px;
	}

	.cancel-button {
		background-color: $color-white;
		color: $color-qualderm-blue;
		border: none;
		border: $color-qualderm-blue;
		border: 1px solid $color-qualderm-blue;
		border-radius: 7px;
	}

	@include breakpoint(md) {
		.speed-bump-content {
			width: 40%;
			padding: 20px;
		}

		.speed-bump-buttons {
			height: auto;
			margin-top: 15px;
			display: flex;
			justify-content: space-between;
			flex-direction: row;
		}
	}
}
