.appointment-questions {
	&.expanded {
		.header {
			.icon {
				transform: rotate(-90deg);
			}
		}
		.questions {
			display: block;
		}
	}

	.header {
		align-items: center;
		border-bottom: 0.125em solid $color-neutral-lightest;
		cursor: pointer;
		display: none;
		justify-content: space-between;
		padding: 25px;
		width: 100%;

		&.active {
			display: flex;
		}

		h3 {
			@include text(kappa, iota);
			font-family: $font-family-regular;
			font-weight: $font-weight-medium;
		}
		.icon {
			transform: rotate(90deg);
		}
	}

	h5 {
		@include text(kappa, iota);
		font-family: $font-family-regular;
		font-weight: $font-weight-regular;
		margin-block-start: auto;
	}

	p {
		@include text(theta);
		font-family: $font-family-regular;
		font-weight: $font-weight-regular;
		max-width: 400px;
		width: 80%;
	}

	.question-history {
		@include text(lambda, lambda);
		border-bottom: solid 2px $color-neutral-lightest;
		display: none;
		font-family: $font-family-regular;
		font-weight: $font-weight-regular;
		padding: 15px 25px;
		position: relative;
		&.active {
			display: flex;
		}
		:first-child {
			width: 340px;
		}

		.answers {
			@include text(iota, lambda);

			color: $color-neutral;
			flex-grow: 2;
			font-weight: $font-weight-medium;
			text-align: right;
			span {
				padding-left: 2em;
			}
			.selected {
				color: $color-green;
			}
		}

		&.last {
			&:before {
				background-color: $color-neutral-lightest;
				bottom: 0;
				content: '';
				height: 4px;
				left: 0;
				position: absolute;
				right: 0;
				top: 100%;
				width: 100%;
			}
			&:after {
				background-color: $color-green;
				bottom: 0;
				content: '';
				height: 4px;
				left: 0;
				position: absolute;
				right: 0;
				top: 100%;
				width: var(--question-history-progress);
			}
		}
	}
	.questions {
		display: none;
	}
	.question-content {
		display: none;
		&.active {
			display: block;
		}
		.start {
			display: none;
			&.active {
				display: block;
			}
		}
		.stop {
			display: none;
			&.active {
				display: block;
			}
		}
	}
	.stop-question,
	.question {
		align-items: top;
		min-height: 175px;
		padding: 1.5em;
		p:first-of-type {
			padding-top: 0.275em;
		}
		.icon-information {
			fill: $color-green;
			transform: scale(1.25);
		}
	}
	.stop-answer,
	.action-answer {
		display: flex;
		gap: 0.25em;
		button {
			cursor: pointer;
		}

		.action-btn {
			flex: 1;
			min-height: 90px;
		}
		.lrg-btn {
			flex-grow: 2.28;
		}
		.start-over {
			background-color: $color-white;
		}
	}
}
