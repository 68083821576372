.provider-profile-rating {
	display: flex;
	flex-direction: column;
	border-bottom: solid 1px $color-neon-silver;
	padding-bottom: 32px;

	.rating-score {
		font-family: $font-family-regular;
		font-size: 2.5rem;
		font-family: $font-family-bold;
	}

	.reviews-label {
		display: inline-block;
		margin-bottom: 1rem;
	}

	.rating-wrap {
		display: flex;
		flex-direction: column;
	}

	.rating-item {
		display: flex;
		margin-bottom: 5px;
	}

	.bar-container {
		width: 200px;
		height: 9px;
		margin-left: 5px;
		position: relative;
		overflow: hidden;
		border-radius: 5px;
		margin-top: 4px;
		margin-left: 12px;
	}

	.bar {
		width: 200px;
		height: 9px;
		border-radius: 5px;
		position: absolute;
		top: 0;
		left: 0;
	}

	.gray-bar {
		background-color: $color-gray;
	}

	.orange-bar {
		background-color: $color-gold;
		transition: width 0.2s ease-in;
		width: 0;
	}

	@include breakpoint(md) {
		flex-direction: row;
		gap: 50px;
	}
}
