.modal {
	background-color: rgba($color-neutral-darker, 0.5);
	display: none;
	height: 100%;
	left: 0;
	opacity: 0;
	outline: 0;
	overflow-y: scroll;
	position: fixed;
	top: 0;
	transition: opacity 0.2s ease-in-out;
	width: 100%;
	z-index: 10;

	&.visible {
		display: block;
		z-index: 999999;
	}

	&.open {
		opacity: 1;
	}

	.modal-container {
		align-items: center;
		background-color: transparent;
		display: flex;
		flex-direction: row;
		height: 100%;
		width: 100%;
		justify-content: center;
		max-height: 100vh;
	}

	.modal-inner-container {
		overflow: auto;
		border-radius: 6px;
		max-height: 100vh;
		width: 100%;
	}

	.modal-body {
		flex-grow: 1;
		overflow-y: auto;
	}

	.modal-inner {
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
		border-radius: 6px;

		.modal-content {
			position: relative;
			background: none;

			.modal-close-btn {
				align-items: center;
				background-color: none;
				border-radius: 2rem;
				cursor: pointer;
				display: flex;
				justify-content: center;
				outline: 0;
				position: absolute;
				right: 15px;
				top: 15px;
				width: 1.75rem;
				height: 1.75rem;

				&:hover,
				&:focus {
					background-color: $color-blue-lightest;
				}

				&.text-close {
					color: $color-blue;
					height: auto;
					transform: none;
					width: auto;

					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}

				.icon {
					height: 0.875rem;
					stroke: $color-black;
					width: 0.875rem;
				}
			}
		}
	}

	@include breakpoint(md) {
		&.visible {
			align-items: center;
			background-color: rgba($color-neutral-darker, 0.5);
			display: flex;
		}

		.modal-inner-container {
			background-color: $color-white;
			max-height: calc(100vh - 2rem);
			max-width: calc(100vw - 4rem);
			width: auto;
		}

		.modal-body {
			align-items: center;
			display: flex;
			flex-grow: 1;
			justify-content: center;
		}
	}
}
