.blog-post-summary {
	width: 100%;
	display: none;
	flex-direction: column;
	flex-basis: 100%;
	max-width: 355px;
	flex-grow: 1;
	background-color: $color-white;
	border-radius: 5px;
	margin-bottom: 1rem;

	&.active {
		display: flex;
	}

	&.paginated-item {
		&.active {
			display: none;

			&.current-page {
				display: flex;
			}
		}
	}

	.bottom-info-wrap {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		padding: 13px 20px;
	}

	.post-title {
		@include text(eta);
		color: $color-qualderm-blue;
		font-weight: $font-weight-regular;
		line-height: 25px;

		&:hover {
			text-decoration: underline;
		}
	}

	.post-paragraph {
		@include text(lambda);
		font-weight: $font-weight-regular;
		margin-top: 10px;
	}

	.custom-secondary-button {
		width: 100%;
	}

	@include breakpoint(sm) {
		flex-basis: calc(calc(100% / 2) - 18px);
		max-width: calc(calc(100% / 2) - 18px);
	}

	@include breakpoint(md) {
		flex-basis: calc(calc(100% / 3) - 18px);
		max-width: calc(calc(100% / 3) - 18px);
	}

	@include breakpoint(lg) {
		flex-basis: calc(calc(100% / 3) - 18px);
		max-width: calc(calc(100% / 3) - 18px);
	}

	@include breakpoint(xl) {
		.four-up & {
			flex-basis: calc(calc(100% / 4) - 18px);
			max-width: calc(calc(100% / 4) - 18px);
		}
	}
}
