.profile-actions {
	display: none;
	position: sticky;
	top: 64px;

	.clipboard-notification {
		opacity: 0;
		transition: opacity 0.4s;

		&.active {
			opacity: 1;
		}
	}

	@include breakpoint(lg) {
		border-radius: 15px;
		display: block;
		flex-basis: 195px;
		height: 100%;
		margin-bottom: 1rem;
		margin-left: 15px;
		min-width: 195px;
		overflow: hidden;

		&.is-expanded {
			.action-btn {
				background-color: $color-white;

				&:hover {
					background-color: $color-blue-lightest;
				}
			}
		}

		.action-btn {
			@include text(kappa);
			cursor: pointer;
			min-height: 90px;
			width: 100%;

			.action-btn-subtitle {
				margin-top: 0;
			}

			.icon {
				bottom: auto;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.expanded .action-btn {
			background-color: $color-green-lightest;
			cursor: pointer;

			&:hover {
				background-color: $color-blue-lightest;
			}
		}

		.open .action-btn {
			background-color: $color-white;
		}
		.expand-collapse-btn .icon {
			transform: rotate(-90deg);
		}

		.expand-collapse-btn {
			display: none;
		}

		.expand-disclosure-btn {
			~ .action-btn {
				display: none;
			}
		}

		.expanded {
			.expand-collapse-btn {
				display: flex;
				margin-bottom: 2px;
				padding: 0.5rem;

				.icon {
					align-items: center;
				}
			}
			.expand-disclosure-btn {
				display: none;

				~ .action-btn {
					display: flex;
				}
			}
		}

		.expanded {
			.reveal-content {
				max-height: none;
			}
		}

		.reveal-content {
			max-height: 0;
			overflow: hidden;
			transition: max-height 1s ease-in-out;
		}

		.expand-collapse-btn {
			background-color: $color-green-lightest;
			margin-bottom: 2px;

			&:hover {
				background-color: $color-blue-lightest;
			}
		}
	}
}
