.numeric-pagination {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 0.75rem;

	button {
		cursor: pointer;
	}

	.pagination-nav {
		margin: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 0.75rem;

		button {
			@include text(14, 18);
			font-family: $font-family-secondary;
			color: $color-letter-charcoal;

			&.active {
				font-weight: 700;
				color: $color-black;
			}
		}
	}

	.prev-page,
	.next-page,
	.pagination-icon {
		width: 1.5rem;
		height: 1.5rem;
	}
}
