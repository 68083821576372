.nav-btn {
	@include text(lambda);
	background-color: $color-white;
	border: 1px solid $color-neutral;
	border-radius: 12px;
	color: $color-neutral-dark;
	cursor: pointer;
	display: flex;
	font-family: $font-family-regular;
	padding: 0.125rem 1.5rem;

	&.active {
		background-color: $color-neutral;
		color: $color-white;
	}

	@include breakpoint(md) {
		&:hover {
			background: $color-blue-lightest;
		}
	}
}
