.provider-location {
	background: $color-neutral-lightest-2;
	border-radius: 15px;
	margin-bottom: 1rem;
	overflow: hidden;
	padding: 1rem;
	width: 100%;

	&:last-child {
		margin-bottom: 0;
	}

	.location-bottom-row .action-btn {
		min-height: 90px;
	}

	.location-name {
		@include text(iota, 26);
		font-weight: $font-weight-medium;
	}

	.location-scheduling {
		margin-top: auto;
	}

	.location-info {
		@include text(kappa, 26);
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		background-color: $color-white;
		min-height: 225px;
		padding: 1.25rem;
		position: relative;
		width: 50%;
	}

	.location-map {
		background-color: $color-white;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
		overflow: hidden;
		width: 50%;

		img {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}
	}

	.location-top-row {
		display: flex;
		flex-direction: column-reverse;
		> div {
			width: 100%;
		}
	}
	.location-bottom-row,
	.location-schedule {
		display: flex;
		flex-basis: 1;
		gap: 2px;
		margin-bottom: 2px;
	}

	.location-schedule-link {
		@include text(theta, 23);
		background-color: $color-green-lightest;
		color: $color-black;
		display: flex;
		font-weight: 400;
		min-height: 90px;
		padding: 1.25rem;
		position: relative;
		text-align: left;
		width: 50%;
		span {
			@include text(lambda, 20);
		}
	}
	.location-schedule-link {
		background-color: $color-blue-dark;
		color: $color-white;
		flex: 1;
		.section-icon {
			fill: $color-white;
		}
	}

	.primary-location-head {
		@include text(mu, 16);
		font-weight: $font-weight-medium;
		color: $color-blue-dark;
		letter-spacing: 1px;
		padding-bottom: 0.75em;
		text-transform: uppercase;
		display: block;
	}

	.section-icon {
		bottom: 20px;
		height: 20px;
		position: absolute;
		right: 20px;
		width: 20px;
	}
	.street-name,
	.distance {
		padding-top: 0.5em;
	}
	@include breakpoint(md) {
		.location-top-row {
			flex-direction: row;
			> div {
				width: 50%;
			}
		}

		.location-info {
			border-bottom-right-radius: 15px;
			border-top-left-radius: 0;
			border-top-right-radius: 15px;
		}

		.location-map {
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 0;
			border-top-left-radius: 15px;
		}
	}
}
