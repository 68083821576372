.close-btn {
	align-items: center;
	background-color: $color-neutral-dark;
	border-radius: 25px;
	cursor: pointer;
	display: flex;
	flex: 0 0 40px;
	height: 40px;
	justify-content: center;
	margin-bottom: 0.75rem;
	padding: 0 1.25rem;
	padding: 0;
	width: 40px;

	.icon.icon-close {
		stroke: $color-white;
	}
}
