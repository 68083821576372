.location-card-description-container {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;

	.address,
	.city-state-zip {
		@include text(15, 22);
		font-family: $font-family-secondary;
	}
	.city-state-zip {
		margin-bottom: 0.625rem;
	}
	.location-card-row-desc {
		display: flex;
		flex-direction: column;
		width: 70%;
	}
	.headline-large {
		margin-bottom: 0.25rem;
	}
	.location-card-row-info {
		display: flex;
		flex-direction: column;
	}
	.location-card-row-info-column {
		display: flex;
		flex-direction: column;
		padding-right: 25px;
		margin-bottom: 10px;
		width: 210px;
	}
	.location-card-contact {
		@include text(lambda);
		font-family: $font-family-secondary;
		margin-bottom: 5px;
	}
	.location-card-schedule {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		white-space: nowrap;

		&.hidden {
			display: none;
		}

		.day-schedule {
			margin-right: 0.75rem;
			width: 31px;
		}

		.day-schedule-time {
			flex: 1;
		}

		span {
			@include text(15, 22);
			font-family: $font-family-secondary;
			text-align: left;
		}
	}
	.headline-small {
		margin-bottom: 0.438rem;
	}
	.separator {
		border-style: none;
		width: 90%;
		height: 1px;
		background-color: $color-neon-silver;
		margin: 10px 0;
	}
	.day-schedule {
		@include text(15, 22);
		font-weight: bold;
		font-family: $font-family-secondary;
		color: $color-black;
	}
	@include breakpoint(md) {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: flex-end;
		height: auto;
		margin: 0 30px;

		.location-card-row-info {
			display: flex;
			flex-direction: row;
		}
	}
}
