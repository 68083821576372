.carousel {
	--picturesBySlideWithFallback: var(--picturesBySlide, 3);

	&.with-image-viewer {
		.gallery-picture {
			cursor: pointer;
		}

		.current-picture-container {
			margin-bottom: 24px;

			img {
				border-radius: 6px;
			}
		}
	}

	&.has-square-images {
		.gallery-picture {
			aspect-ratio: 1;
		}
	}

	.carousel-inner {
		position: relative;
	}

	.gallery-wrap {
		margin-bottom: 2rem;
		width: 100%;
	}

	.gallery {
		display: flex;
		flex-shrink: 0;
		width: 100%;
		overflow-x: hidden;
		gap: 16px;
		scroll-behavior: smooth;

		.gallery-item {
			flex: 0 1 calc((100% - 16px) / 2);
			min-width: calc((100% - 16px) / 2);
		}

		// Hide scrollbar for all the browsers
		&::-webkit-scrollbar {
			display: none;
		}
		-webkit-overflow-scrolling: touch;
		scrollbar-width: none; // Firefox
		& {
			scrollbar-width: none; // Firefox 64
		}
		overflow: -moz-scrollbars-none; // older Firefox versions
	}

	.gallery-picture {
		border-radius: 6px;
		width: 100%;
		height: 100%;
		object-fit: cover;
		aspect-ratio: 1.46;
	}

	.current-picture {
		width: 100%;
	}

	.controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 12px;
	}

	li {
		display: inline-block;
	}

	.carousel-dot {
		background-color: $color-neon-silver;
		width: 7px;
		height: 7px;
		border-radius: 25px;
		margin: 2px;
		cursor: pointer;

		&.active {
			background-color: $color-qualderm-green;
		}
	}

	.icon-arrow-left {
		width: 40px;
		height: 40px;
		cursor: pointer;
	}

	.icon-arrow-right {
		width: 40px;
		height: 40px;
		cursor: pointer;
	}

	.icon {
		use {
			fill: $color-qualderm-green;
		}
	}

	@include breakpoint(md) {
		.gallery {
			.gallery-item {
				flex: 0 1
					calc(
						(
								100% -
									(
										16px *
											(
												var(
														--picturesBySlideWithFallback
													) - 1
											)
									)
							) / var(--picturesBySlideWithFallback)
					);
				min-width: calc(
					(100% - (16px * (var(--picturesBySlideWithFallback) - 1))) /
						var(--picturesBySlideWithFallback)
				);
			}
		}
	}
}
