.button {
	color: $color-white;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	font-family: $font-family-regular;
	font-weight: $font-weight-regular;
	padding: 12px 20px;
	border-radius: 6px;
	background: $color-qualderm-blue;

	&:hover {
		background-color: $color-deep-blue;
	}

	&.button-secondary {
		background-color: transparent;
		border: 1px solid $color-qualderm-green;
		color: $color-black;

		&:hover {
			background-color: $color-qualderm-green;
		}
	}

	&.button-tertiary {
		background-color: transparent;
		border: 1px solid $color-qualderm-gray;
		color: $color-black;

		&:hover {
			background-color: $color-white;
		}
	}

	&.disabled {
		background-color: $color-neon-silver;
		border: 1px solid $color-neon-silver;
		color: $color-charcoal;
		cursor: default;

		&:hover {
			cursor: default;
		}
	}
}
