.location-microdata {
	@include text(kappa, 20);
	color: $color-black;
	display: block;
	font-family: $font-family-secondary;
	font-weight: $font-weight-regular;
	margin-bottom: 0.75rem;

	.street-address {
		display: block;
	}

	@include breakpoint(md) {
		@include text(lambda, 18);
	}
}
