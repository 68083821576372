.category-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 20px;
	width: 100%;

	.category-list-item {
		@include text(15, 22);
		color: $color-neutral-darker;
		font-family: $font-family-regular;
		margin-right: 1.375rem;
		position: relative;

		&:after {
			color: $color-qualderm-gray;
			content: '|';
			display: block;
			left: calc(100% + 0.6875rem);
			pointer-events: none;
			position: absolute;
			top: 0;
			transform: translateX(-50%);
		}

		&:last-child {
			margin-right: 0;

			&:after {
				display: none;
			}
		}
	}

	@include breakpoint(md) {
		justify-content: flex-start;
	}
}
