.profile {
	@include make-container();
	padding-left: 0;
	padding-right: 0;

	.action-btn {
		span {
			display: block;
		}
	}

	.location-hours {
		@include text(lambda);
		color: $color-neutral-darker;

		.open-closed {
			display: inline-block;
			margin-right: 0.5rem;

			&.open {
				color: $color-green;
			}

			&.closed {
				color: $color-red;
			}
		}
	}

	.provider-desktop {
		display: none;
	}

	.profile-content {
		display: flex;
		flex-direction: column;
		padding: 0 15px;

		.profile-section {
			background-color: $color-neutral-lightest-2;
			border-radius: 15px;
			margin-bottom: 2rem;
			overflow: hidden;
			width: 100%;
		}

		.provider-locations {
			background-color: transparent;
		}

		.search-result {
			display: block;
		}
	}

	.content a {
		@include text(kappa, 20);
		color: $color-green-dark;
		display: block;
		padding: 0.25em 0;

		&:hover {
			color: $color-neutral-darker;
		}
	}

	.search-link-wrap {
		align-items: center;
		display: flex;
		padding: 1.25rem;

		span {
			@include text(lambda);
			color: $color-neutral-darker;
			font-family: $font-family-regular;
			font-weight: $font-weight-medium;
			padding: 0 0.5em;
		}
	}

	.search-result {
		border-radius: 15px;
		height: 100%;
		padding: 15px;
		overflow: hidden;

		.profile-image-container {
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			padding-bottom: 119%;
		}

		.result-details {
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;
			background-color: $color-white;
		}

		.icon-profile {
			display: none;
		}
	}

	.condition-columns {
		border-bottom: 1px solid;
		display: flex;
		gap: 2em;
		justify-content: flex-start;
		margin-bottom: 1em;
		padding: 1em;
	}

	.symptoms-section {
		border-bottom: 1px solid;
		margin-bottom: 1em;
		padding: 1em;
	}

	.treatments-section {
		border-bottom: 1px solid;
		margin-bottom: 1em;
		padding: 1em;
	}

	.provider-background {
		.section-head {
			@include text(iota);
			font-weight: $font-weight-medium;
			margin-bottom: 0.75rem;
			text-transform: none;
		}

		.biography-text {
			font-weight: $font-weight-light;
		}
	}

	.provider-count {
		color: $color-green-dark;
	}

	.inner-wrap {
		padding: 30px;

		> :last-child {
			margin-bottom: 0;
		}

		.content {
			> :last-child {
				margin-bottom: 0;
			}
		}
	}

	.list-block-col {
		margin-bottom: 1rem;
	}

	a.subject-matter-detail {
		color: $color-green-dark;
		display: block;
		margin-bottom: 0.25em;
	}

	.result-top {
		position: relative;
	}

	.subtitle {
		@include text(kappa);
		font-weight: $font-weight-medium;
	}

	.profile-content {
		.reveal-content {
			max-height: 0;
			overflow: hidden;
			transition: max-height 1s ease-in-out;
		}
		.expanded {
			.reveal-content {
				max-height: none;
			}
		}
	}

	.subtitle-paragraph p {
		font-family: $font-family-regular;
	}

	@include breakpoint(md) {
		.provider-desktop {
			display: block;
			position: sticky;
			margin-bottom: 86px;
			top: 64px;
		}

		.profile-navigation {
			flex-direction: row;
			min-height: 64px;
			padding-top: 2rem;
		}

		.profile-content {
			flex-direction: column;
			width: 100%;

			.provider-result {
				display: none;
			}
			> div:not(.active, .search-result, .location-introduction) {
				display: block;
			}
			> div.back-to-top {
				display: flex;
			}
		}

		.search-link-wrap {
			flex-basis: 240px;
			min-width: 240px;
			padding: 0.5em 0;
		}

		.overview-link,
		.search-result {
			box-shadow: none;
			flex-basis: 240px;
			margin-right: 15px;
			min-width: 240px;
		}

		.profile-content {
			flex-direction: column;
			width: 100%;

			.provider-result,
			.search-result {
				display: none;
			}
		}

		.profile-main {
			display: flex;
		}

		.result-top {
			display: block;
		}

		.reveal-content {
			max-height: 0;
			overflow: hidden;
			transition: max-height 1s ease-in-out;
		}
	}

	@include breakpoint(lg) {
		.search-result {
			flex-basis: 315px;
			min-width: 315px;
		}
	}
}
