.location-profile-container {
	display: flex;
	flex-direction: column;
	background-color: $color-neutral-lightest-3;
	padding: 10px 0 10px 5px;
	border-radius: 5px;
	padding: 10px;

	.location-profile-image {
		display: block;
		height: 18.5rem;
		width: auto;
		margin-bottom: 10px;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	@include breakpoint(md) {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: $color-neutral-lightest-3;
		border-radius: 5px;
		padding: 10px;

		.location-profile-image {
			height: 20.625rem;
			width: 30rem;
			margin-bottom: 0;
		}
	}
}
