.nav-accordion {
	background-color: $color-background-hover-nav;
	overflow: visible;
	position: relative;

	&.filter-accordion {
		> .accordion-item {
			&[aria-expanded='true'] {
				&.closing {
					> .accordion-item-body {
						transform: translateX(100%);
					}
				}

				> .accordion-item-title {
					.accordion-item-toggle {
						.icon-chevron-down {
							transform: rotate(-90deg) translateX(50%);
						}
					}
				}

				> .accordion-item-body {
					transform: translateX(0);
					z-index: 2;
					overflow-x: hidden;
					visibility: visible;
					-ms-overflow-style: none;
					scrollbar-width: none;

					&::-webkit-scrollbar {
						display: none;
					}
				}
			}

			&.opening {
				> .accordion-item-body {
					transition: all 0.2s ease-in-out;
				}
			}

			> .accordion-item-body {
				background-color: $color-white;
				display: block;
				left: 0;
				min-height: 100vh;
				position: fixed;
				top: 0;
				transform: translateX(100%);
				transition: all 0.2s ease-in-out;
				visibility: hidden;
				width: 100%;

				> .accordion-item-body-inner {
					padding: 1rem 20px 6rem;
				}

				.filter-list & {
					min-height: calc(100vh - 80px);
					height: calc(100vh - 80px);
				}
			}

			> .accordion-item-title {
				.accordion-item-toggle {
					.icon-chevron-down {
						transform: rotate(-90deg) translateX(50%);
					}
				}
			}
		}
		// &[data-accordion-opened='true'] {
		// 	> .accordion-item {
		// 		&[aria-expanded='false'] {
		// 			display: none;
		// 		}
		// 	}
		// }
	}

	&.flush {
		.accordion-item {
			> .accordion-item-title > .accordion-item-toggle {
				.icon {
					display: none;
				}
			}

			&.opening {
				> .accordion-item-body {
					overflow: hidden;
					transition: height 0.4s ease-in-out;
				}
			}

			&[aria-expanded='true'] {
				> .accordion-item-body {
					display: block;
					height: auto;
				}

				&.closing {
					> .accordion-item-title {
						.accordion-item-toggle {
							// color: $color-gray-dark;

							&:hover,
							&:focus {
								// color: $color-blue;
							}

							.icon-chevron-down {
								transform: translateY(-50%);
							}

							.label-secondary {
								opacity: 1;
							}
						}

						.accordion-item-tags {
							opacity: 1;
							transition: opacity 0.4s ease-in-out;
						}
					}

					> .accordion-item-body {
						overflow: hidden;
						transition: height 0.4s ease-in-out;
					}
				}
			}
		}
	}
	.nav-accordion {
		background-color: $color-white;
		border-radius: 0;

		&.filter-accordion {
			.accordion-item {
				.accordion-item-title {
					.accordion-item-toggle {
						padding-left: 1rem;

						.icon-chevron-down {
							right: 1rem;
							transform: translateY(-50%);
						}
					}
				}

				.accordion-item-body {
					display: none;
					height: 0;
					min-height: 0;
					position: relative;
					transition: all 0.4s ease-in-out;
					transform: none;
					.filter-list & {
						min-height: 0;
					}
					[class^='translated-'] & {
						min-height: 0;
						top: 0;
					}
					.accordion-item-body-inner {
						padding: 0 1rem 1rem;
					}
				}
			}
		}

		&.flush {
			> .accordion-item {
				> .accordion-item-title > .accordion-item-toggle,
				> .accordion-item-body > .accordion-item-body-inner {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}

		.accordion-item {
			border-bottom: 2px solid $color-warm-gray;
		}
	}

	.accordion-item {
		border-bottom: 2px solid $color-white;
		padding: 0;

		&[aria-expanded='true'] {
			&.closing {
				> .accordion-item-body {
					transform: none;
					overflow: hidden;
				}
			}

			> .accordion-item-title {
				.accordion-item-toggle {
					.icon-chevron-down {
						transform: rotate(180deg) translateY(50%);
					}
				}
			}

			> .accordion-item-body {
				display: block;
				height: auto;
				overflow-x: unset;
			}
		}

		&.opening {
			> .accordion-item-body {
				overflow: hidden;
				transition: height 0.4s ease-in-out;
			}
		}

		> .accordion-item-title {
			.accordion-item-toggle {
				padding-left: 1rem;

				.icon-chevron-down {
					right: 1rem;
					transform: translateY(-50%);
				}
			}
		}

		.accordion-item-body {
			display: none;
			height: 0;
			min-height: 0;
			position: relative;
			transition: all 0.4s ease-in-out;
			transform: none;
			.filter-list & {
				min-height: 0;
			}
			.accordion-item-body-inner {
				padding: 0 1rem 1rem;
			}
		}

		.accordion-item-title {
			position: relative;

			.accordion-item-toggle {
				@include text(epsilon);
				background: none;
				border: none;
				color: $color-black;
				display: flex;
				justify-content: space-between;
				margin: 0;
				padding: 14px 2.5rem 1rem 20px;
				position: relative;
				text-align: left;
				width: 100%;

				&:hover {
					color: $color-qualderm-green;
					cursor: pointer;
					outline: 0;
				}

				.accordion-item-label {
					@include text(kappa, 20);
					font-family: $font-family-bold;
					white-space: nowrap;
				}

				.label-secondary {
					color: $color-gray;
					overflow: hidden;
					padding-left: 0.5rem;
					text-overflow: ellipsis;
					transition: opacity 0.4s ease-in-out;
					white-space: nowrap;
				}

				.icon-chevron-down {
					fill: $color-qualderm-green;
					position: absolute;
					right: 20px;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			.accordion-item-tags {
				background-color: $color-white;
				transition: opacity 0.2s ease-in-out;
				z-index: 1;

				.tag-list {
					padding: 0 1rem 0.5rem;

					&:last-of-type {
						padding-bottom: 1rem;
					}

					.tag:first-of-type {
						padding-top: 0;
					}
				}
			}
		}

		.accordion-item-body {
			display: none;
			height: 0;
		}
	}

	@include breakpoint(lg) {
		background-color: $color-white;
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px;

		&.filter-accordion {
			.accordion-item {
				&[aria-expanded='true'] {
					&.closing {
						> .accordion-item-body {
							transform: none;
							overflow: hidden;
						}
					}

					> .accordion-item-title {
						.accordion-item-toggle {
							.icon-chevron-down {
								transform: rotate(180deg) translateY(50%);
							}
						}
					}

					> .accordion-item-body {
						display: block;
						height: auto;
						overflow-x: unset;
					}
				}

				&.opening {
					> .accordion-item-body {
						overflow: hidden;
						transition: height 0.4s ease-in-out;
					}
				}

				.accordion-item-title {
					.accordion-item-toggle {
						padding-left: 1rem;

						.icon-chevron-down {
							right: 1rem;
							transform: translateY(-50%);
						}
					}
				}

				.accordion-item-body {
					display: none;
					height: 0;
					min-height: 0;
					position: relative;
					transition: all 0.4s ease-in-out;
					transform: none;
					.filter-list & {
						min-height: 0;
					}
					[class^='translated-'] & {
						min-height: 0;
						top: 0;
					}
					.accordion-item-body-inner {
						padding: 0 1rem 1rem;
					}
				}
			}
			&[data-accordion-opened='true'] {
				> .accordion-item {
					&[aria-expanded='false'] {
						display: block;
					}
				}
			}
		}

		&.flush {
			> .accordion-item {
				> .accordion-item-title > .accordion-item-toggle,
				> .accordion-item-body > .accordion-item-body-inner {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}

		.accordion-item {
			border-bottom: 2px solid $color-warm-gray;

			.accordion-item-title {
				.accordion-item-toggle {
					.accordion-item-label {
						@include text(kappa, 20);
						font-family: $font-family-regular;
					}
				}
			}
		}
	}
}
