.filter-dropdown {
	color: $color-black;
	width: 540px;
	position: relative;

	.dropdown-button {
		@include text(iota);
		font-family: $font-family-regular;
		text-align: left;
		background: $color-white;
		border: none;
		border-radius: 6px;
		padding: 15px;
		padding-right: 60px;
		width: 100%;
		height: 50px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.dropdown-placeholder {
		@include text(iota);
		font-family: $font-family-regular;
		display: block;
		position: absolute;
		left: 20px;
		opacity: 0.5;
		top: 50%;
		transform: translateY(-50%);
		pointer-events: none;

		&.hidden {
			display: none;
		}
	}

	img {
		position: absolute;
		right: 15px;
		top: 0;
		bottom: 0;
		margin: auto;
		pointer-events: none;
	}

	.dropdown-menu {
		display: none; // Initially hidden
		position: absolute;
		background-color: $color-white;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		top: calc(100% - 6px);
		width: 100%;
		z-index: 1;

		&.active {
			display: block;
		}

		form {
			padding: 10px 0px 5px;
		}

		.dropdown-item {
			@include text(16, 32);
			display: block;
			padding: 5px 15px;
			cursor: pointer;

			input[type='checkbox'] {
				cursor: pointer;
				margin-right: 5px;
			}
		}

		button {
			padding: 5px 10px;
			margin: 0px 10px 10px;
			cursor: pointer;
		}
	}
}
