.social-channels {
	display: flex;
	justify-content: center;
	margin-top: 35px;

	@include breakpoint(md) {
		justify-content: flex-end;
		margin: 0 0 0;
	}
}
