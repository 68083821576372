.ratings {
	display: flex;

	&.small {
		.star {
			height: 17.18182px;
			width: 18px;

			svg {
				height: 17.18182px;
				width: 18px;
			}

			.star-fill {
				height: 17.18182px;
			}
		}

		.average-rating-and-count {
			margin-top: -1px;
		}

		.average-rating,
		.rating-count {
			@include text(12, 16);
		}
	}

	.ratings-inner {
		display: flex;
	}

	.radio-wrapper & {
		.average-rating {
			margin-left: 0.375rem;
		}

		.average-rating,
		.rating-count {
			color: $color-black;
			margin-top: 1px;
		}
	}

	.disabled & {
		.average-rating,
		.rating-count {
			color: $color-black;
		}
	}

	.average-rating-and-count {
		font-family: $font-family-secondary;
		margin-top: 1px;
	}

	.average-rating {
		@include text(lambda);
		font-family: $font-family-secondary;
		margin-left: 0.4375rem;
	}

	.rating-count {
		@include text(lambda);
		color: $color-gray-dark;
		font-family: $font-family-secondary;
		margin-left: 0.125rem;
	}

	.rating-stars {
		display: flex;
		justify-content: space-between;
		width: 100px;

		.disabled & {
			.star {
				svg {
					fill: $color-white;
				}

				.star-fill {
					svg {
						fill: $color-neutral-lightest;
					}
				}
			}
		}

		@for $i from 1 through 5 {
			.star:nth-child(#{$i}) {
				.star-fill {
					$percent: calc((var(--rating) - (#{$i} - 1)) * 100%);
					max-width: 100%;
					width: $percent;
				}
			}
		}
	}
}
