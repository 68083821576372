.callout-cta {
	align-items: flex-start;
	background-color: $color-white;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 2rem;
	padding: 15px 16px;

	.callout-image {
		margin-bottom: 1rem;

		img {
			border-radius: 6px;
			height: auto;
			width: 100%;
		}
	}

	@include breakpoint(md) {
		flex-direction: row;
		justify-content: flex-start;

		.callout-image {
			margin-right: 20px;
			margin-bottom: 0;
			flex-basis: 300px;
			min-width: 300px;
			width: 300px;
		}
	}
}
