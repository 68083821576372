.three-up {
	flex-direction: column;
	margin-bottom: 90px;
	padding-top: 2rem;

	.three-up-item {
		display: flex;
		border-radius: 10px;
		flex-direction: column;
		margin-bottom: 2rem;
		overflow: hidden;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.three-up-img-outer {
		width: 100%;
	}

	.three-up-img-container {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		height: 0;
		overflow: hidden;
		padding-top: 66%;
		position: relative;
		width: 100%;
	}

	.three-up-img {
		height: 100%;
		left: 0;
		object-fit: cover;
		object-position: center;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.three-up-content {
		background-color: $color-neutral-lightest-2;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		display: flex;
		flex-direction: column;
		height: 100%;
		padding: 2rem;
		min-height: 240px;
	}

	.heading-3 {
		@include text(iota, 24);
		color: $color-neutral-darker;
		font-family: $font-family-bold;
		letter-spacing: 0;
		margin-bottom: 1rem;
		text-transform: none;
	}

	p {
		@include text(iota, 24);
		color: $color-neutral-darker;
		margin-bottom: 0;

		a {
			color: $color-neutral-darker;

			&:hover,
			&:focus {
				color: $color-black;
			}
		}
	}

	.icon-arrow-up {
		transform: rotate(90deg);
		stroke-width: 3px;
	}

	.read-more-container {
		margin-top: auto;
		text-align: right;
	}

	.read-more-link {
		@include text(eta, 30);
		margin-top: auto;
		text-decoration: none;
	}

	@include breakpoint(md) {
		display: flex;
		justify-content: space-between;

		.three-up-img-outer {
			width: 100%;
		}

		.three-up-row {
			display: flex;
			margin-left: -10px;
			width: calc(100% + 20px);
		}

		.three-up-item {
			@include make-col-ready();
			flex: 0 0 calc(100% / 3);
			max-width: calc(100% / 3);
		}

		.three-up-content {
			padding: 1.25rem;
		}
	}

	@include breakpoint(lg) {
		.three-up-content {
			padding: 1.5rem;
		}
	}

	@include breakpoint(xxl) {
		.three-up-content {
			padding: 2rem;
		}
	}
}
