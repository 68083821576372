.blog-post-summary-list {
	margin-bottom: 20px;

	.blog-post-item {
		width: 100%;
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		max-width: 355px;
		flex-grow: 1;
		background-color: $color-white;
		border-radius: 6px;
		margin-bottom: 1rem;
	}

	.blog-post-header {
		display: flex;
		justify-content: flex-end;
	}

	.header-right-title {
		@include text(lambda);
		font-family: $font-family-tertiary;
		margin-bottom: 1.25rem;
	}

	@include breakpoint(sm) {
		.gallery-wrap {
			display: flex;
			justify-content: center;
			margin: 0 auto;
			flex-direction: column;
			width: calc(100% + 18px);
		}

		.gallery {
			display: flex;
			justify-content: flex-start;
			flex-wrap: wrap;
			gap: 18px;
		}
	}
}
