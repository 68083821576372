.corporate-homepage {
	.finder-wrapper {
		transform: translateY(-50%);
		margin-bottom: -100px;
		width: 100%;
	}

	.headline-large {
		margin-bottom: 11px;

		&.center {
			text-align: center;
		}
	}

	.body-copy {
		margin-bottom: 16px;
	}

	.centered-content {
		display: flex;
		flex-direction: column;
		align-items: center;

		.inline-list {
			list-style: initial;
			display: flex;
			flex-direction: column;
			max-height: 100px;
			flex-wrap: wrap;
			gap: 14px;

			li {
				padding-right: 20px;
			}
		}

		.partner-logo {
			margin: 42px 0px 79px;
			max-width: 256px;
			width: 100%;
			object-fit: contain;
		}

		.blockquote {
			margin-bottom: 47px;
		}

		.blog-post-summary {
			margin-bottom: 0;
		}
	}

	.smaller-content {
		max-width: 726px;
		margin: 0 auto;

		.corporate-grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			margin-top: 27px;
			margin-bottom: 49px;

			.corporate-grid-item {
				display: flex;
				flex-direction: column;
				gap: 5px;
				padding: 0px 23px;
				border-left: 1px solid $color-neon-silver;

				&:first-child {
					border: none;
					padding: 0px 17px 17px 0px;
				}

				&:nth-child(2) {
					padding: 0px 0px 17px 17px;
				}

				&:nth-child(3) {
					padding: 17px 17px 0px 0px;
				}

				&:last-child {
					padding: 17px 0px 0px 17px;
				}

				&:nth-child(3) {
					border-left: none;
				}

				&:nth-child(3),
				&:nth-child(4) {
					border-top: 1px solid $color-neon-silver;
				}

				strong {
					@include text(48);
				}

				p {
					@include text(15, 22);
				}
			}
		}
	}

	@include breakpoint(md) {
		.centered-content {
			.inline-list {
				flex-direction: row;
				flex-wrap: wrap;
				gap: 30px;

				li:first-child {
					list-style-type: none;
				}

				li {
					padding: 0;
				}
			}
		}

		.smaller-content {
			max-width: 726px;
			margin: 0 auto;

			.corporate-grid {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				margin-top: 27px;
				margin-bottom: 49px;

				.corporate-grid-item {
					display: flex;
					flex-direction: column;
					gap: 5px;
					padding: 0px 23px !important;
					border-left: 1px solid $color-neon-silver;

					&:first-child {
						border: none;
						padding: 0px 23px 0px 0px;
					}

					&:last-child {
						padding: 0px 0px 0px 23px;
					}

					&:nth-child(3) {
						border-left: 1px solid $color-neon-silver;
					}

					&:nth-child(3),
					&:nth-child(4) {
						border-top: none;
					}
				}
			}
		}
	}
}
