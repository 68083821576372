.contact-us-form {
	width: 100%;

	.contact-form-wrapper {
		display: block;

		&.hide {
			display: none;
		}

		.headline-large {
			margin-bottom: 12px;
		}

		.contact-form {
			display: flex;
			flex-direction: column;
			gap: 20px;
			transition: all 0.3s;

			label {
				@include text(lambda);
				color: $color-black;
				font-family: $font-family-bold;
				text-transform: uppercase;
			}

			d .input-text .input-error-message,
			.textarea .textarea-error-message {
				color: $color-critical;
			}
		}
	}

	.confirmation {
		display: none;
		transition: all 0.3s;
		width: 100%;

		&.show {
			display: block;
		}

		.button {
			width: 100%;
		}
	}
}
