@charset "UTF-8";
/* Package Specific Styling */
lite-youtube {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  max-width: 720px;
}

/* gradient */
lite-youtube::before {
  content: attr(data-title);
  display: block;
  position: absolute;
  top: 0;
  /* Pixel-perfect port of YT's gradient PNG, using https://github.com/bluesmoon/pngtocss plus optimizations */
  background-image: linear-gradient(180deg, rgb(0 0 0/67%) 0%, rgb(0 0 0/54%) 14%, rgb(0 0 0/15%) 54%, rgb(0 0 0/5%) 72%, rgb(0 0 0/0%) 94%);
  height: 99px;
  width: 100%;
  font-family: "YouTube Noto", Roboto, Arial, Helvetica, sans-serif;
  color: hsl(0deg 0% 93.33%);
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  font-size: 18px;
  padding: 25px 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

lite-youtube:hover::before {
  color: white;
}

/* responsive iframe with a 16:9 aspect ratio
    thanks https://css-tricks.com/responsive-iframes/
*/
lite-youtube::after {
  content: "";
  display: block;
  padding-bottom: 56.25%;
}

lite-youtube > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
}

/* play button */
lite-youtube > .lty-playbtn {
  display: block;
  /* Make the button element cover the whole area for a large hover/click target… */
  width: 100%;
  height: 100%;
  /* …but visually it's still the same size */
  background: no-repeat center/68px 48px;
  /* YT's actual play button svg */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>');
  position: absolute;
  cursor: pointer;
  z-index: 1;
  filter: grayscale(100%);
  transition: filter 0.1s cubic-bezier(0, 0, 0.2, 1);
  border: 0;
}

lite-youtube:hover > .lty-playbtn,
lite-youtube .lty-playbtn:focus {
  filter: none;
}

/* Post-click styles */
lite-youtube.lyt-activated {
  cursor: unset;
}

lite-youtube.lyt-activated::before,
lite-youtube.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

.lyt-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

lite-youtube {
  max-width: none;
}

/* Global (reset, functions, variables, mixins, helpers) */
/* Dont change order */
/* Reset */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}
body.body-fixed {
  overflow-y: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  display: block;
  max-width: 100%;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Strip border, outline, and padding from unclassed buttons */
button {
  background: transparent;
  border: 0;
  padding: 0;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}
/* Variables */
/*
 *
 * BREAKPOINTS MAP
 *
 */
/*
 *
 * COLOR VARIABLES
 *
 */
/* Monochromes */
/* Greens */
/* Blues */
/* Other */
/* Box Shadow */
/*
 *
 * TYPOGRAPHY VARIABLES AND MAPS
 *
 */
/*
  * Font Size and Line Height Maps
  * Matching keys gets used together by default
  * Used in text() mixin and calulateLineHeight() function
  */
@font-face {
  font-family: "Tw Cen MT";
  src: url("/assets/fonts/Tw Cen MT.ttf");
}
@font-face {
  font-family: "Tw Cen MT Bold";
  src: url("/assets/fonts/Tw Cen MT Bold.ttf");
}
@font-face {
  font-family: "Tw Cen MT Italic";
  src: url("/assets/fonts/Tw Cen MT Italic.ttf");
}
@font-face {
  font-family: "Tw Cen MT Bold Italic";
  src: url("/assets/fonts/Tw Cen MT Bold Italic.ttf");
}
/* Functions */
/*
 *
 * TYPOGRAPHY FUNCTIONS
 *
 */
/*
 * calculateRem($font-size, $base)
 * convert absolute (px) into relative em (rem) values
 * assumes a 16px baseline; override if in a different context
 * -----
 * calculateRem(32)      ==> 0.5rem
 * calculateRem(48, 12)  ==> 4rem
 */
/*
 * calulateLineHeight($font-size, $line-height)
 * convert absolute (px) from corresponding line-heights map into unitless line-height values
 * $font-size argument must correlate to $font-size-scale in _variables.scss to avoid errant font-sizes
 * $line-height argument can be correlated to $line-height-scale (ie alpha, beta) in _variables.scss or a specific number value (ie 27, 32)
 */
/* Mixins */
/*
 * highlight($color (optional, default $color-bright-blue)))
 * adds background highlight and ltr animation - defaults to $color-bright-blue, but can accept other colors
 */
/*
 * loader($color, $height(optional, default 26px), $stroke(optional, default 2px))
 * adds generic loader as :after psuedo element
 * $color must be passed. $height and $stroke have defaults, but can be passed in as needed.
 */
/*
 * pulse-highlight($color)
 * adds pulse highlight to circular element with equal height and width
 * $color must be passed
 */
/*
 * text($font-size, $line-height(optional))
 * calculates font-size and line-height corresponding to $font-size-scale and $line-height-scale maps in _variables.scss
 * $line-height argument is optional, and can be a different value in $line-height-scale or a number value
 */
/* Helpers */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

body .bg-green-dark {
  background-color: #4e8209;
}
body .bg-green {
  background-color: #64a70b;
}
body .bg-green-light {
  background-color: #7cba25;
}
body .bg-green-lighter {
  background-color: #a1d45f;
}
body .bg-green-lightest {
  background-color: #c7e6a1;
}
body .bg-blue-dark {
  background-color: #2178bc;
}
body .bg-blue {
  background-color: #53b9d1;
}
body .bg-blue-light {
  background-color: #2bb9d9;
}
body .bg-blue-lighter {
  background-color: #74d3e8;
}
body .bg-blue-lightest {
  background-color: #eefafe;
}
body .bg-neutral-darker {
  background-color: #181922;
}
body .bg-neutral-dark {
  background-color: #525259;
}
body .bg-neutral {
  background-color: #8b8b90;
}
body .bg-neutral-light {
  background-color: #b1b1b3;
}
body .bg-neutral-lighter {
  background-color: #e3dbd1;
}
body .bg-neutral-lightest {
  background-color: #f0ebe6;
}
body .bg-neutral-lightest-2 {
  background-color: #f2f2f2;
}
body .bg-white {
  background-color: #fff;
}
body .bg-black {
  background-color: #000;
  color: #fff;
}

body .color-black {
  color: #000;
}
body .color-green {
  color: #64a70b;
}
body .color-green.jss-link > a {
  color: #64a70b;
}
body .color-blue-dark {
  color: #2178bc;
}
body .color-white {
  color: #fff;
}

@media (min-width: 768px) {
  .two-column {
    columns: 2;
  }
}

@media (min-width: 768px) {
  .three-column {
    columns: 2;
  }
}
@media (min-width: 1280px) {
  .three-column {
    columns: 3;
  }
}

@media (min-width: 768px) {
  .four-column {
    columns: 2;
  }
}
@media (min-width: 1280px) {
  .four-column {
    columns: 3;
  }
}
@media (min-width: 1440px) {
  .four-column {
    columns: 4;
  }
}

.show-on-mobile {
  display: block;
}
.show-on-mobile .flex {
  display: flex;
}
@media (min-width: 768px) {
  .show-on-mobile {
    display: none;
  }
}

.hide-on-mobile {
  display: none;
}
@media (min-width: 768px) {
  .hide-on-mobile {
    display: block;
  }
}

main {
  min-height: calc(100vh - 70px - 324px - 751px);
}
@media (min-width: 768px) {
  main {
    min-height: calc(100vh - 70px - 159px - 379px);
  }
}
@media (min-width: 944px) {
  main {
    min-height: calc(100vh - 70px - 151px - 337px);
  }
}
@media (min-width: 1280px) {
  main {
    min-height: calc(100vh - 150px - 151px - 337px);
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
}
@media (min-width: 768px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .container {
    padding-right: 70px;
    padding-left: 70px;
  }
}

.container-bg {
  margin-right: auto;
  margin-left: auto;
  max-width: calc(1440px - 20px * 2);
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
}
@media (min-width: 768px) {
  .container-bg {
    max-width: calc(1440px - 30px * 2);
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .container-bg {
    max-width: calc(1440px - 70px * 2);
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .container-bg {
    max-width: calc(1440px - 70px * 2);
    padding-right: 70px;
    padding-left: 70px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.col {
  width: 100%;
}

.col,
[class*=col-] {
  flex: 1 1 0;
  margin-bottom: 0;
  padding: 0 12px;
}
.col.position-center,
[class*=col-].position-center {
  margin: auto;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
  margin-bottom: 0;
  padding: 0 12px;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
  margin-bottom: 0;
  padding: 0 12px;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
  margin-bottom: 0;
  padding: 0 12px;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  margin-bottom: 0;
  padding: 0 12px;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
  margin-bottom: 0;
  padding: 0 12px;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 0;
  padding: 0 12px;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
  margin-bottom: 0;
  padding: 0 12px;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
  margin-bottom: 0;
  padding: 0 12px;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
  margin-bottom: 0;
  padding: 0 12px;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
  margin-bottom: 0;
  padding: 0 12px;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
  margin-bottom: 0;
  padding: 0 12px;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 0;
  padding: 0 12px;
}

@media (min-width: 768px) {
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .justify-content-md-center {
    justify-content: center;
  }
}
@media (min-width: 1280px) {
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .justify-content-lg-center {
    justify-content: center;
  }
}
@media (min-width: 1440px) {
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .justify-content-xl-center {
    justify-content: center;
  }
}
.heading-0 {
  font-size: 3rem;
  line-height: 1.125;
  color: #333;
  font-weight: 300;
  margin-bottom: 1.25rem;
}

.heading-1 {
  font-size: 2.125rem;
  line-height: 1.1176470588;
  color: #333;
  font-weight: 300;
  margin-bottom: 1.25rem;
}

.heading-2 {
  font-size: 1.375rem;
  line-height: 1.1818181818;
  font-weight: 300;
  margin-bottom: 0.3rem;
}

.heading-2-regular {
  font-size: 1.375rem;
  line-height: 1.1818181818;
  font-weight: 400;
  margin-bottom: 0.3rem;
}

.heading-3 {
  font-size: 2.3125rem;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.15rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.heading-4 {
  font-size: 2.3125rem;
  line-height: 1;
  color: #181922;
  font-weight: 600;
  margin-bottom: 1rem;
}

.heading-5 {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  color: #181922;
  font-weight: 600;
  margin-bottom: 0.875rem;
}
.heading-5:not(:first-of-type) {
  border-top: 1px solid #b1b1b3;
  margin-top: 50px;
  padding-top: 50px;
}

.heading-6 {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #181922;
  font-weight: 700;
  margin: 0;
}

.subtitle {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-weight: 700;
}

.page-title {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-weight: 700;
}

.section-title {
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.84px;
  margin-bottom: 1.25rem;
}

.content-title {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  font-weight: 700;
  color: #333;
  margin-bottom: 1rem;
}

.content-subtitle {
  font-size: 1.625rem;
  line-height: 1.2307692308;
  font-weight: 300;
  color: #333;
  margin-bottom: 1rem;
}

.hero-content-title {
  font-size: 2.125rem;
  line-height: 1.1176470588;
  color: #333;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
  margin-bottom: 1.25rem;
}

.hero-content-paragraph {
  font-size: 2.125rem;
  line-height: 1.1176470588;
  color: #181922;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 300;
  margin-bottom: 1.25rem;
}

@media (min-width: 768px) {
  ol.two-columns, ol.three-columns, ol.four-columns,
  ul.two-columns,
  ul.three-columns,
  ul.four-columns {
    columns: 2;
    column-gap: 1rem;
  }
}
@media (min-width: 1280px) {
  ol.three-columns, ol.four-columns,
  ul.three-columns,
  ul.four-columns {
    columns: 3;
    column-gap: 1rem;
  }
}
@media (min-width: 1440px) {
  ol.four-columns,
  ul.four-columns {
    columns: 4;
    column-gap: 1rem;
  }
}

.styleguide-header {
  padding-top: 2rem;
}

.styleguide-divider {
  font-size: 1rem;
  line-height: 1.375;
  align-items: center;
  display: flex;
  margin: 3rem -1rem 1rem;
  text-transform: uppercase;
}
.styleguide-divider:before, .styleguide-divider:after {
  content: "";
  flex: 1;
  height: 1px;
  margin: 0 1rem;
}
.styleguide-divider:before {
  background: linear-gradient(to right, #f6f3f1, black);
}
.styleguide-divider:after {
  background: linear-gradient(to left, #f6f3f1, black);
}

.styleguide-notes {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  text-align: center;
  font-family: "Tw Cen MT Italic", sans-serif;
}

.styleguide-list {
  margin-top: 1rem;
}
.styleguide-list li {
  padding: 0.375rem 0;
}

html {
  scroll-behavior: smooth;
}

.layout-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .layout-wrapper {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .layout-wrapper {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .layout-wrapper {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.layout-wrapper section {
  margin-bottom: 2rem;
}
.layout-wrapper .column-main,
.layout-wrapper .column-sidebar {
  padding-bottom: 3rem;
}
.layout-wrapper .column-sidebar {
  display: flex;
  flex-wrap: wrap;
}
.layout-wrapper .column-sidebar > section {
  margin-bottom: 1.5625rem;
  margin-right: 1.5625rem;
}
@media (min-width: 1280px) {
  .layout-wrapper section {
    margin-bottom: 3.125rem;
  }
  .layout-wrapper .column-sidebar {
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
  }
  .layout-wrapper .column-sidebar > section {
    margin-right: 0;
  }
}

main > section {
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  main > section {
    margin-bottom: 3.125rem;
  }
}

/* Atoms (basic HTML elements e.g. inputs, labels, buttons) */
.back-link {
  margin-bottom: 15px;
}

.background-image {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}
.background-image picture,
.background-image img {
  height: 100%;
  position: absolute;
  width: 100%;
}
.background-image img {
  object-fit: cover;
}

.button {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
  padding: 12px 20px;
  border-radius: 6px;
  background: #0c5688;
}
.button:hover {
  background-color: #002742;
}
.button.button-secondary {
  background-color: transparent;
  border: 1px solid #9bc158;
  color: #000;
}
.button.button-secondary:hover {
  background-color: #9bc158;
}
.button.button-tertiary {
  background-color: transparent;
  border: 1px solid #888b8d;
  color: #000;
}
.button.button-tertiary:hover {
  background-color: #fff;
}
.button.disabled {
  background-color: #c9cbcb;
  border: 1px solid #c9cbcb;
  color: #1d1d1d;
  cursor: default;
}
.button.disabled:hover {
  cursor: default;
}

.button-submit {
  font-size: 1rem;
  line-height: 1;
  border-radius: 6px;
  border: 1px solid #888b8d;
  background: #0c5688;
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  display: flex;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s;
}
.button-submit:hover {
  filter: brightness(0.9);
}
.button-submit:active {
  filter: brightness(1.1);
}

.checkbox-wrapper {
  align-items: flex-start;
  display: flex;
  justify-items: flex-start;
  margin-bottom: 0.75rem;
  position: relative;
}
.checkbox-wrapper.disabled {
  pointer-events: none;
}
.checkbox-wrapper.disabled input[type=checkbox]:checked + label:after {
  background-color: #f0ebe6;
}
.checkbox-wrapper.disabled input[type=checkbox] + label {
  background-color: #fff;
  border-radius: 25px;
  color: #f0ebe6;
  display: block;
  padding: 0.625rem 2.5rem 0.625rem 1.25rem;
}
.checkbox-wrapper input[type=checkbox] {
  height: 0;
  margin: 0;
  opacity: 0;
  width: 0;
}
.checkbox-wrapper input[type=checkbox]:checked + label {
  background-color: #525259;
  border-color: #525259;
  color: #fff;
}
.checkbox-wrapper input[type=checkbox]:checked + label:after {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  content: "";
  display: block;
  height: 0.9rem;
  right: 20px;
  margin: 0.25rem 0.5rem;
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translate(calc(-75% - 1px), calc(-75% - 1px));
  width: 0.45rem;
  border-bottom-width: 1px;
  border-right-width: 1px;
}
.checkbox-wrapper input[type=checkbox]:checked + label .label-number {
  visibility: hidden;
}
.checkbox-wrapper input[type=checkbox]:hover {
  cursor: pointer;
}
.checkbox-wrapper label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0 0 0 0.5rem;
  width: 100%;
  background-color: #fff;
  border-radius: 25px;
  padding: 0.625rem 1.25rem;
  transition: all 0.1s ease-in-out;
  border: 1px solid #e3dbd1;
}
.checkbox-wrapper label:hover {
  cursor: pointer;
  background-color: #b1b1b3;
  border-color: #b1b1b3;
}
.checkbox-wrapper label .label-display-name + .label-name {
  text-align: right;
}
.checkbox-wrapper label .label-name {
  flex-grow: 1;
}
.checkbox-wrapper label .label-number {
  padding-left: 1rem;
}

.checkmark-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 13px;
}
.checkmark-text span {
  font-size: 1rem;
  line-height: 1.25;
  font-family: "Arial", sans-serif;
}
@media (min-width: 768px) {
  .checkmark-text span {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
}

.close-btn {
  align-items: center;
  background-color: #525259;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  flex: 0 0 40px;
  height: 40px;
  justify-content: center;
  margin-bottom: 0.75rem;
  padding: 0 1.25rem;
  padding: 0;
  width: 40px;
}
.close-btn .icon.icon-close {
  stroke: #fff;
}

.eyebrow {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #000;
  font-family: "Tw Cen MT Bold", sans-serif;
  font-weight: 400;
  margin-bottom: 0.625rem;
  text-transform: uppercase;
}
.eyebrow span {
  color: #c9cbcb;
}

.headline-large {
  font-size: 1.75rem;
  line-height: 1;
  color: #000;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
  margin-bottom: 1.3125rem;
}

.headline-medium {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  color: #000;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
  margin-bottom: 0.6875rem;
}
.headline-medium .text-link {
  font-size: 1rem;
  line-height: 1.375;
}

.headline-small {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  color: #000;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
  margin-bottom: 1rem;
}

.icon {
  height: 1rem;
  stroke-linecap: round;
  width: 1rem;
}
.icon.icon-chevron-right {
  fill: #0c5688;
}
.icon.icon-location {
  fill: #9bc158;
}
.icon.icon-filter, .icon.icon-arrow-left, .icon.icon-search {
  fill: #9bc158;
  stroke-width: 0;
}
.icon.icon-menu-close {
  fill: #1d1d1d;
  stroke-width: 0;
}
.icon.icon-sort-icon, .icon.icon-list-view, .icon.icon-close {
  fill: #000;
  stroke-width: 0;
}
.icon use path {
  all: inherit;
}

.input-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.input-text.success input {
  border-color: #a1d45f;
}
.input-text.error input {
  border-color: #e52b24;
}
.input-text label {
  display: block;
  margin-bottom: 5px;
}
.input-text .input-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24.5px;
}
.input-text input {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Arial", sans-serif;
  width: calc(100% - 20px);
  padding: 8px 11.5px;
  border: 1px solid #f6f3f1;
  border-radius: 6px;
  transition: border-color 0.3s;
}
.input-text input:focus {
  border-color: #a1d45f;
}
.input-text .label-input-text {
  font-size: 0.875rem;
  line-height: 1;
  color: #000;
  font-family: "Tw Cen MT", sans-serif;
  text-transform: uppercase;
}
.input-text .input-error-message {
  font-size: 0.75rem;
  line-height: 1.1666666667;
  color: #ffd905;
  font-family: "Open Sans", sans-serif;
}
.input-text .span-explanation {
  font-size: 0.625rem;
  line-height: 1;
  display: flex;
  color: #888b8d;
  font-family: "Open Sans", sans-serif;
  margin-top: 8px;
}
.input-text .email-input input {
  width: 100%;
}
.input-text .icon-container {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.input-text .status-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.input-text .error-message {
  color: #e52b24;
  margin-top: 5px;
}
.input-text .submit-container.hide-on-mobile {
  display: none;
}
.input-text .submit-container.hide-on-desktop {
  display: block;
}
@media (min-width: 414px) {
  .input-text .submit-container {
    min-width: 100px;
  }
}
@media (min-width: 768px) {
  .input-text {
    gap: 2px;
  }
  .input-text .submit-container {
    min-width: 120px;
  }
  .input-text .submit-container.hide-on-mobile {
    display: block;
  }
  .input-text .submit-container.hide-on-desktop {
    display: none;
  }
}
@media (min-width: 1280px) {
  .input-text .submit-container {
    min-width: 164px;
  }
}

.intro-text {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #000;
  font-family: "Arial", sans-serif;
  font-weight: 400;
}
.rich-text .intro-text {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #000;
  font-family: "Arial", sans-serif;
  font-weight: 400;
}

.loading-overlay {
  align-items: flex-start;
  background-color: #f6f3f1;
  display: flex;
  height: 100%;
  justify-content: center;
  left: -15px;
  opacity: 0;
  padding-top: 130px;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.4s ease-in-out;
  width: calc(100% + 30px);
  z-index: 2;
}
.loading-overlay:after {
  border: 5px solid #888b8d;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.radio-wrapper {
  align-items: flex-start;
  display: flex;
  justify-items: flex-start;
  margin-bottom: 0.75rem;
  position: relative;
}
.radio-wrapper.disabled {
  pointer-events: none;
}
.radio-wrapper.disabled input[type=radio] + label {
  color: #8b8b90;
}
.radio-wrapper input[type=radio] {
  height: 0;
  margin: 0;
  opacity: 0;
  width: 0;
}
.radio-wrapper input[type=radio]:checked + label {
  background-color: #525259;
  border-color: #525259;
  color: #fff;
  cursor: default;
}
.radio-wrapper input[type=radio]:checked + label:after {
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  content: "";
  display: block;
  height: 0.9rem;
  right: 20px;
  margin: 0.25rem 0.5rem;
  position: absolute;
  top: 50%;
  transform: rotate(45deg) translate(calc(-75% - 1px), calc(-75% - 1px));
  width: 0.45rem;
  border-bottom-width: 1px;
  border-right-width: 1px;
}
.radio-wrapper input[type=radio]:checked + label .label-number {
  visibility: hidden;
}
.radio-wrapper input[type=radio]:hover {
  cursor: pointer;
}
.radio-wrapper label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0 0 0 0.5rem;
  width: 100%;
  background-color: #fff;
  border-radius: 25px;
  padding: 0.625rem 1.25rem;
  border: 1px solid #e3dbd1;
}
.radio-wrapper label:hover, .radio-wrapper label:focus, .radio-wrapper label[aria-selected=true] {
  background-color: #b1b1b3;
  border-color: #b1b1b3;
}
.radio-wrapper label .label-display-name + .label-name {
  text-align: right;
}
.radio-wrapper label .label-name {
  flex-grow: 1;
}
.radio-wrapper label .label-number {
  padding-left: 1rem;
}

.select {
  position: relative;
  display: flex;
}
.select.select-secondary select {
  background-color: transparent;
  width: 100%;
}
.select.select-secondary select:hover {
  background-color: #9bc158;
}
.select select {
  appearance: none;
  border: 1px solid #9bc158;
  border-radius: 6px;
  color: #000;
  cursor: pointer;
  height: 2.25rem;
  padding-left: 1.25rem;
  padding-right: 2rem;
}
.select .icon {
  pointer-events: none;
  position: absolute;
  right: 0.85rem;
  top: 10px;
}

.social-link {
  padding: 0 10px;
}
.social-link:last-child() {
  padding-right: 0;
}
.social-link .icon {
  height: 35px;
  width: 35px;
}
.social-link:hover .icon {
  display: none;
}
.social-link:hover .icon[class$=-hover] {
  display: block;
}
.social-link .icon[class$=-hover] {
  display: none;
}

body {
  font-family: "Tw Cen MT", sans-serif;
  overflow-x: hidden;
}

p {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Arial", sans-serif;
  margin-bottom: 1.25rem;
}

.button-headline {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: "Tw Cen MT Bold", sans-serif;
}

.button-cta {
  font-size: 1.25rem;
  line-height: 1.5;
}

.button-detail {
  font-size: 1rem;
  line-height: 1.25;
}

.filter {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}

.subject-matter-detail {
  font-size: 1rem;
  line-height: 1.375;
}

.text-plus {
  font-size: 1rem;
  line-height: 1.25;
}

.paragraph-intro {
  font-size: 1rem;
  line-height: 1.375;
}
.paragraph-intro span {
  margin-bottom: 1.5rem;
}

.text-container {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.biography-text {
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.ordered-list {
  font-size: 1rem;
  line-height: 1.25;
  font-family: "Tw Cen MT", sans-serif;
}

.content-block {
  margin-bottom: 1.25rem;
}

.text-link {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #0c5688;
  cursor: pointer;
  font-family: "Arial", sans-serif;
}
.text-link:hover {
  text-decoration: underline;
}
.text-link.with-icon:hover {
  text-decoration: none;
}
.text-link.with-icon:hover span {
  text-decoration: underline;
}
.text-link .icon {
  margin-bottom: -2px;
}

.map-container {
  object-fit: cover;
}
.map-container a {
  display: block;
  line-height: 0;
}
@media (min-width: 768px) {
  .map-container img {
    display: unset;
    max-width: unset;
    height: 20.625rem;
    width: 20.625rem;
    object-fit: cover;
  }
}

.location-card-description-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.location-card-description-container .address,
.location-card-description-container .city-state-zip {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Arial", sans-serif;
}
.location-card-description-container .city-state-zip {
  margin-bottom: 0.625rem;
}
.location-card-description-container .location-card-row-desc {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.location-card-description-container .headline-large {
  margin-bottom: 0.25rem;
}
.location-card-description-container .location-card-row-info {
  display: flex;
  flex-direction: column;
}
.location-card-description-container .location-card-row-info-column {
  display: flex;
  flex-direction: column;
  padding-right: 25px;
  margin-bottom: 10px;
  width: 210px;
}
.location-card-description-container .location-card-contact {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: "Arial", sans-serif;
  margin-bottom: 5px;
}
.location-card-description-container .location-card-schedule {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  white-space: nowrap;
}
.location-card-description-container .location-card-schedule.hidden {
  display: none;
}
.location-card-description-container .location-card-schedule .day-schedule {
  margin-right: 0.75rem;
  width: 31px;
}
.location-card-description-container .location-card-schedule .day-schedule-time {
  flex: 1;
}
.location-card-description-container .location-card-schedule span {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Arial", sans-serif;
  text-align: left;
}
.location-card-description-container .headline-small {
  margin-bottom: 0.438rem;
}
.location-card-description-container .separator {
  border-style: none;
  width: 90%;
  height: 1px;
  background-color: #c9cbcb;
  margin: 10px 0;
}
.location-card-description-container .day-schedule {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-weight: bold;
  font-family: "Arial", sans-serif;
  color: #000;
}
@media (min-width: 768px) {
  .location-card-description-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    height: auto;
    margin: 0 30px;
  }
  .location-card-description-container .location-card-row-info {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1280px) {
  .scroll-anchor {
    scroll-margin-top: 100px;
  }
}

.separator {
  border-style: none;
  width: 100%;
  height: 1px;
  background-color: #c9cbcb;
  margin: 2rem 0;
}

.star {
  display: inline-block;
  position: relative;
  height: 21px;
  width: 22px;
}
.star:last-child {
  margin-right: 0;
}
.star svg {
  fill: #c9cbcb;
  height: 21px;
  width: 22px;
  position: absolute;
  top: 0;
  left: 0;
}
.star .star-fill {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 0;
  height: 21px;
}
.star .star-fill svg {
  fill: #ff8c00;
}

.textarea {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.textarea.success textarea {
  border-color: #a1d45f;
}
.textarea.error textarea {
  border-color: #e52b24;
}
.textarea label {
  display: block;
  margin-bottom: 5px;
}
.textarea .textarea-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24.5px;
}
.textarea textarea {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Arial", sans-serif;
  padding: 8px 11.5px;
  border: 1px solid #f6f3f1;
  border-radius: 6px;
  transition: border-color 0.3s;
  width: 100%;
}
.textarea textarea:focus {
  border-color: #a1d45f;
}
.textarea .label-textarea {
  font-size: 0.875rem;
  line-height: 1;
  color: #000;
  font-family: "Tw Cen MT", sans-serif;
  text-transform: uppercase;
}
.textarea .textarea-error-message {
  color: #ffd905;
  font-family: "Open Sans", sans-serif;
}
.textarea .span-explanation {
  font-size: 0.625rem;
  line-height: 1;
  display: flex;
  color: #888b8d;
  font-family: "Open Sans", sans-serif;
  margin-top: 8px;
}
.textarea .error-message {
  color: #e52b24;
  margin-top: 5px;
}
@media (min-width: 768px) {
  .textarea {
    gap: 2px;
  }
}

/* Molecules (e.g. Hero, Primary Navigation, Search) */
.about-this-location-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 20px;
}
.about-this-location-container .features-icon {
  display: flex;
  flex-direction: row;
  height: 25px;
}
.about-this-location-container .features-icon-description {
  padding-left: 14px;
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Arial", sans-serif;
  margin-bottom: 14px;
  margin-top: -2px;
}
.about-this-location-container .about-this-location-description {
  flex-direction: column-reverse;
  display: flex;
}
@media (min-width: 768px) {
  .about-this-location-container {
    width: 100%;
    display: flex;
  }
  .about-this-location-container .about-this-location-container {
    display: flex;
    flex-direction: column;
  }
  .about-this-location-container .about-this-location-description {
    display: flex;
    flex-direction: row;
    max-width: 950px;
  }
  .about-this-location-container .about-this-location-features {
    margin-left: 50px;
  }
  .about-this-location-container .features-icon {
    display: flex;
    flex-direction: row;
    height: 34px;
  }
  .about-this-location-container .features-icon-description {
    white-space: nowrap;
  }
}

.accordion {
  margin-bottom: 3rem;
}
.accordion .accordion-item-wrapper {
  border-bottom: solid 1px #c9cbcb;
  height: auto;
}
.accordion .accordion-item {
  font-size: 1.25rem;
  line-height: 1.5;
  align-items: center;
  color: #0c5688;
  cursor: pointer;
  display: flex;
  padding: 15px 15px 15px 0;
  text-align: left;
  width: 100%;
}
.accordion .accordion-item:hover {
  color: #002742;
}
.accordion .accordion-item:hover .icon use {
  fill: #002742;
}
.accordion .accordion-item .icon {
  margin-left: auto;
}
.accordion .accordion-item .icon use {
  fill: #0c5688;
}
.accordion .accordion-item .icon-chevron-down {
  display: block;
}
.accordion .accordion-item .icon-chevron-up {
  display: none;
}
.accordion .accordion-item.active .icon-chevron-down {
  display: none;
}
.accordion .accordion-item.active .icon-chevron-up {
  display: block;
}
.accordion .icon-chevron-down {
  float: right;
}
.accordion .icon-chevron-up {
  float: right;
}
.accordion .panel {
  display: none;
}
.accordion .panel p,
.accordion .panel span {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Arial", sans-serif;
}
.accordion .panel .rich-text {
  margin-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .accordion {
    margin-bottom: 4rem;
  }
}

.action-btn {
  font-size: 1.25rem;
  line-height: 1.15;
  background-color: #525259;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex: 0 1 50%;
  flex-direction: column;
  font-weight: 400;
  min-height: 130px;
  padding: 1.25rem;
  position: relative;
  text-align: left;
  transition: background-color 0.1s ease-in-out;
}
.action-btn:only-child {
  flex: 1;
}
.action-btn:hover, .action-btn:focus {
  background-color: #000;
}
.action-btn.action-btn-secondary {
  background-color: #181922;
}
.action-btn .action-btn-subtitle {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  margin-top: 0.25rem;
}
.action-btn .action-btn-subtitle span {
  display: block;
}
.action-btn .icon {
  fill: #fff;
  position: absolute;
  bottom: 20px;
  right: 20px;
  height: 22px;
  width: 22px;
}

.active-filter-panel {
  position: relative;
}
.active-filter-panel:not(:first-child) {
  padding-top: 1.75rem;
}
.active-filter-panel:not(:first-child)::before {
  background-color: #8b8b90;
  border-radius: 50%;
  display: block;
  content: "";
  margin: auto;
  position: absolute;
  top: 11px;
  left: 50%;
  transform: translateX(-50%);
  height: 0.5rem;
  width: 0.5rem;
}
.active-filter-panel:not(:last-child) {
  padding-bottom: 2.5rem;
}
.active-filter-panel:not(:last-child)::after {
  background-color: #8b8b90;
  border-radius: 50%;
  display: block;
  content: "";
  margin: auto;
  position: absolute;
  top: calc(100% - 31px);
  left: 50%;
  transform: translateX(-50%);
  height: 0.5rem;
  width: 0.5rem;
}
.active-filter-panel .filter-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
}
.active-filter-panel .filter-panel-title {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-left: 1.25rem;
  padding-top: 0.5rem;
}
.active-filter-panel .view-all-trigger,
.active-filter-panel .view-all-back-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-weight: 400;
  color: #2178bc;
  margin-bottom: 0.75rem;
  margin-left: 20px;
}
.active-filter-panel .view-all-trigger:hover,
.active-filter-panel .view-all-back-btn:hover {
  cursor: pointer;
  text-decoration: underline;
}
.active-filter-panel .view-all-panel {
  display: none;
}
.active-filter-panel .view-all-panel.view-all-open {
  display: block;
}

.alert {
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
  max-width: unset;
  padding: 10px;
}
.alert.alert-success .icon-wrapper {
  background-color: #a1d45f;
  background-image: url(/assets/images/icons/success.svg);
}
.alert.alert-default .icon-wrapper {
  background-color: #53b9d1;
  background-image: url(/assets/images/icons/default.svg);
}
.alert.alert-info .icon-wrapper {
  background-color: #74d3e8;
  background-image: url(/assets/images/icons/info.svg);
}
.alert.alert-warning .icon-wrapper {
  background-color: #ffd905;
  background-image: url(/assets/images/icons/warning.svg);
}
.alert.alert-critical .icon-wrapper {
  background-color: #e52b24;
  background-image: url(/assets/images/icons/critical.svg);
}
.alert .content {
  align-items: flex-start;
  display: flex;
}
.alert .icon-wrapper {
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 40px;
  margin-right: 15px;
  max-width: unset;
  width: 40px;
}
.alert a {
  color: #6e9735;
}
.alert button {
  cursor: pointer;
  margin-left: 15px;
  padding: 5px 10px;
}
@media (min-width: 768px) {
  .alert {
    align-items: center;
    margin: 0 auto;
    max-width: calc(100vw - 500px);
    width: 90%;
  }
  .alert .content {
    align-items: center;
  }
}

.back-to-top {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.875rem;
  margin-right: 0.75rem;
}
.back-to-top .back-to-top-btn {
  background-color: #fff;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.back-to-top .back-to-top-btn:hover {
  background-color: #eefafe;
}
.back-to-top .icon-download-file {
  display: block;
  margin: auto;
  transform: rotate(180deg) scale(1.2);
}
@media (min-width: 768px) {
  .back-to-top {
    margin-right: 0;
  }
}

.blockquote-carousel {
  width: 100%;
  position: relative;
  background-color: #1d1d1d;
  border-radius: 10px;
  margin-bottom: 42px;
}
.blockquote-carousel .blockquote-container {
  display: flex;
  overflow: hidden;
  width: 100%;
}
.blockquote-carousel .blockquote-container .blockquote-item {
  flex: 0 0 auto;
  width: 100%;
  scroll-snap-align: start;
}
.blockquote-carousel .blockquote-container .blockquote-item.active {
  opacity: 1;
}
.blockquote-carousel .blockquote-container .blockquote-item .blockquote {
  margin: 0;
}
.blockquote-carousel .controls {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0.6;
  transition: opacity 0.3s;
}
.blockquote-carousel .controls .arrow {
  background: transparent;
  border: none;
  fill: #343a40;
}
.blockquote-carousel .controls .arrow:hover {
  cursor: pointer;
}
.blockquote-carousel .controls .blockquote-carousel-indicators {
  display: flex;
  list-style: none;
  justify-content: center;
  gap: 7px;
  width: 100px;
}
.blockquote-carousel .controls .blockquote-carousel-indicators .blockquote-carousel-dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #c9cbcb;
}
.blockquote-carousel .controls .blockquote-carousel-indicators .blockquote-carousel-dot:hover {
  cursor: pointer;
}
.blockquote-carousel .controls .blockquote-carousel-indicators li {
  display: flex;
  justify-content: center;
}
.blockquote-carousel .controls .blockquote-carousel-indicators .active {
  background: #9bc158;
}
.blockquote-carousel:hover .controls {
  opacity: 1;
}

.blockquote {
  background-color: #1d1d1d;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.blockquote img {
  margin: 24px;
  max-width: 200px;
}
.blockquote .blockquote-inner {
  font-size: 2rem;
  line-height: 1.09375;
  background-color: #1d1d1d;
  color: #9bc158;
  font-family: "Tw Cen MT Italic", sans-serif;
  padding: 24px 27px 29px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.blockquote .blockquote-inner .icon-container {
  margin-left: 9px;
  margin-bottom: 19px;
}
.blockquote .blockquote-inner .icon {
  height: 26.51515px;
  width: 35px;
}
@media (min-width: 768px) {
  .blockquote {
    flex-direction: row;
  }
  .blockquote .blockquote-inner {
    padding: 30px 94px 30px 30px;
    flex-direction: row;
  }
  .blockquote .blockquote-inner .icon-container {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 29px;
  }
}

.blog-post-hero {
  margin: 0 auto;
  overflow: hidden;
  padding: 0;
  width: 100%;
  position: relative;
  padding-bottom: 55.55%;
}
.blog-post-hero .blog-post-hero-img {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.blog-post-hero .blog-post-hero-img img,
.blog-post-hero .blog-post-hero-img source,
.blog-post-hero .blog-post-hero-img canvas {
  object-fit: cover;
  object-position: top;
  width: 100%;
  height: 100%;
  aspect-ratio: 720/400;
}
@media (min-width: 768px) {
  .blog-post-hero {
    padding-bottom: 31.25%;
  }
  .blog-post-hero .blog-post-hero-img img,
  .blog-post-hero .blog-post-hero-img source,
  .blog-post-hero .blog-post-hero-img canvas {
    aspect-ratio: 2000/625;
  }
}
@media (min-width: 1440px) {
  .blog-post-hero {
    margin: 0 auto;
    overflow: hidden;
    padding: 0;
    width: 100%;
  }
  .blog-post-hero .blog-post-hero-img {
    display: block;
    position: relative;
    max-height: 492px;
    min-height: 350px;
    width: 100%;
  }
  .blog-post-hero .blog-post-hero-img img,
  .blog-post-hero .blog-post-hero-img source,
  .blog-post-hero .blog-post-hero-img canvas {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
    min-height: 350px;
  }
}

.blog-post-summary {
  width: 100%;
  display: none;
  flex-direction: column;
  flex-basis: 100%;
  max-width: 355px;
  flex-grow: 1;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 1rem;
}
.blog-post-summary.active {
  display: flex;
}
.blog-post-summary.paginated-item.active {
  display: none;
}
.blog-post-summary.paginated-item.active.current-page {
  display: flex;
}
.blog-post-summary .bottom-info-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 13px 20px;
}
.blog-post-summary .post-title {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  color: #0c5688;
  font-weight: 400;
  line-height: 25px;
}
.blog-post-summary .post-title:hover {
  text-decoration: underline;
}
.blog-post-summary .post-paragraph {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-weight: 400;
  margin-top: 10px;
}
.blog-post-summary .custom-secondary-button {
  width: 100%;
}
@media (min-width: 414px) {
  .blog-post-summary {
    flex-basis: calc(50% - 18px);
    max-width: calc(50% - 18px);
  }
}
@media (min-width: 768px) {
  .blog-post-summary {
    flex-basis: calc(33.3333333333% - 18px);
    max-width: calc(33.3333333333% - 18px);
  }
}
@media (min-width: 1280px) {
  .blog-post-summary {
    flex-basis: calc(33.3333333333% - 18px);
    max-width: calc(33.3333333333% - 18px);
  }
}
@media (min-width: 1440px) {
  .four-up .blog-post-summary {
    flex-basis: calc(25% - 18px);
    max-width: calc(25% - 18px);
  }
}

.breadcrumb {
  display: flex;
  margin-bottom: 2rem;
}
.breadcrumb .text-link,
.breadcrumb .current {
  font-size: 0.9375rem;
  line-height: 1;
  display: flex;
  font-family: "Tw Cen MT", sans-serif;
}
.breadcrumb .text-link {
  margin-right: 1.625rem;
  position: relative;
}
.breadcrumb .text-link::after {
  color: #888b8d;
  content: "|";
  display: block;
  left: calc(100% + 0.8125rem);
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}
.breadcrumb .current {
  color: #888b8d;
}
@media (min-width: 1280px) {
  .breadcrumb {
    margin-bottom: 4rem;
  }
}

.callout-cta {
  align-items: flex-start;
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 15px 16px;
}
.callout-cta .callout-image {
  margin-bottom: 1rem;
}
.callout-cta .callout-image img {
  border-radius: 6px;
  height: auto;
  width: 100%;
}
@media (min-width: 768px) {
  .callout-cta {
    flex-direction: row;
    justify-content: flex-start;
  }
  .callout-cta .callout-image {
    margin-right: 20px;
    margin-bottom: 0;
    flex-basis: 300px;
    min-width: 300px;
    width: 300px;
  }
}

.carousel {
  --picturesBySlideWithFallback: var(--picturesBySlide, 3);
}
.carousel.with-image-viewer .gallery-picture {
  cursor: pointer;
}
.carousel.with-image-viewer .current-picture-container {
  margin-bottom: 24px;
}
.carousel.with-image-viewer .current-picture-container img {
  border-radius: 6px;
}
.carousel.has-square-images .gallery-picture {
  aspect-ratio: 1;
}
.carousel .carousel-inner {
  position: relative;
}
.carousel .gallery-wrap {
  margin-bottom: 2rem;
  width: 100%;
}
.carousel .gallery {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  overflow-x: hidden;
  gap: 16px;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
}
.carousel .gallery .gallery-item {
  flex: 0 1 calc((100% - 16px) / 2);
  min-width: calc((100% - 16px) / 2);
}
.carousel .gallery::-webkit-scrollbar {
  display: none;
}
.carousel .gallery {
  scrollbar-width: none;
}
.carousel .gallery-picture {
  border-radius: 6px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1.46;
}
.carousel .current-picture {
  width: 100%;
}
.carousel .controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}
.carousel li {
  display: inline-block;
}
.carousel .carousel-dot {
  background-color: #c9cbcb;
  width: 7px;
  height: 7px;
  border-radius: 25px;
  margin: 2px;
  cursor: pointer;
}
.carousel .carousel-dot.active {
  background-color: #9bc158;
}
.carousel .icon-arrow-left {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.carousel .icon-arrow-right {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.carousel .icon use {
  fill: #9bc158;
}
@media (min-width: 768px) {
  .carousel .gallery .gallery-item {
    flex: 0 1 calc((100% - 16px * (var(--picturesBySlideWithFallback) - 1)) / var(--picturesBySlideWithFallback));
    min-width: calc((100% - 16px * (var(--picturesBySlideWithFallback) - 1)) / var(--picturesBySlideWithFallback));
  }
}

.category-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}
.category-list .category-list-item {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  color: #181922;
  font-family: "Tw Cen MT", sans-serif;
  margin-right: 1.375rem;
  position: relative;
}
.category-list .category-list-item:after {
  color: #888b8d;
  content: "|";
  display: block;
  left: calc(100% + 0.6875rem);
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}
.category-list .category-list-item:last-child {
  margin-right: 0;
}
.category-list .category-list-item:last-child:after {
  display: none;
}
@media (min-width: 768px) {
  .category-list {
    justify-content: flex-start;
  }
}

.conditions-list {
  background-color: #fff;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  right: 50%;
  width: 100vw;
  padding: 30px 0;
}
.conditions-list + .schedule-appointment-cta {
  margin-top: -60px;
}
.conditions-list:last-child {
  margin-bottom: -32px;
}
.conditions-list .conditions-list-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
}
@media (min-width: 768px) {
  .conditions-list .conditions-list-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .conditions-list .conditions-list-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .conditions-list .conditions-list-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.conditions-list .horizontal-columns-wrap:last-of-type {
  border-bottom: solid 1px #c9cbcb;
}
.conditions-list .line-bottom-title {
  border-bottom: solid 1px #c9cbcb;
  padding-bottom: 15px;
}
.conditions-list .condition-column {
  margin-bottom: 50px;
}
.conditions-list .condition-item {
  font-size: 1rem;
  line-height: 1.375;
  font-family: "Arial", sans-serif;
  margin-top: 15px;
  width: 100%;
}
@media (min-width: 768px) {
  .conditions-list:last-child {
    margin-bottom: -14px;
  }
  .conditions-list .horizontal-columns-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
  }
  .conditions-list .horizontal-columns-wrap:last-of-type {
    padding-bottom: 36px;
  }
  .conditions-list .horizontal-columns-wrap .condition-column {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .conditions-list .horizontal-columns-wrap .condition-column.full {
    max-width: 100%;
    flex: 1;
  }
  .conditions-list .horizontal-columns-wrap .condition-column .condition-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 8px;
  }
  .conditions-list .horizontal-columns-wrap .condition-column .condition-list.two-col .condition-item {
    width: calc(50% - 12px);
  }
  .conditions-list .horizontal-columns-wrap .condition-column .condition-list.three-col .condition-item {
    width: calc(33.3333% - 16px);
  }
  .conditions-list .horizontal-columns-wrap .condition-column .condition-list.four-col .condition-item {
    width: 25%;
  }
  .conditions-list .condition-item {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    margin-top: 0;
  }
  .conditions-list .insurance-list-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
    align-items: flex-end;
  }
  .conditions-list .insurance-list-wrap .condition-list {
    flex: 0 0 75%;
    max-width: 75%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .conditions-list .insurance-list-wrap .dont-see-your-insurance-block {
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 12px;
  }
}
@media (min-width: 1280px) {
  .conditions-list .condition-item {
    font-size: 1rem;
    line-height: 1.375;
  }
}

.control-toggles {
  justify-content: flex-start;
  display: flex;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
  position: relative;
}
.control-toggles .control-toggle-btns {
  display: flex;
  width: 100%;
}
.control-toggles .filter-count {
  display: none;
}
.control-toggles .control-toggle {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  align-items: center;
  background-color: #fff;
  color: #888b8d;
  display: flex;
  font-weight: 700;
  height: 50px;
  justify-content: center;
  text-transform: uppercase;
  transition: background-color 0.1s ease-in-out;
  width: 50px;
}
.control-toggles .control-toggle .icon {
  height: 20px;
  transition: fill 0.1s ease-in-out, stroke 0.1s ease-in-out;
  width: 20px;
  stroke: #888b8d;
  fill: #888b8d;
}
.control-toggles .control-toggle .icon-columns {
  transform: rotate(90deg);
}
.mobile-controls-open .control-toggles .control-toggle.active {
  background-color: #525259;
}
.mobile-controls-open .control-toggles .control-toggle.active .icon {
  display: none;
  stroke: #fff;
  fill: #fff;
}
.control-toggles .control-toggle:hover {
  color: #9bc158;
  cursor: pointer;
}
.control-toggles .control-toggle:hover .icon {
  fill: #9bc158;
  stroke: #9bc158;
}
.control-toggles .view-toggles-container {
  display: flex;
  margin-left: auto;
}
.control-toggles .view-toggle[disabled] {
  background-color: #f6f3f1;
  cursor: default;
  pointer-events: none;
}
.control-toggles .view-toggle[disabled] .icon {
  fill: #000;
  stroke: #000;
}
@media (min-width: 768px) {
  .control-toggles {
    margin-right: 15px;
  }
}
@media (min-width: 1280px) {
  .control-toggles {
    margin-bottom: 0;
    margin-right: 0;
  }
  .control-toggles .filter-count {
    font-size: 1rem;
    line-height: 1.375;
    align-items: center;
    color: #0c5688;
    display: flex;
    font-family: "Tw Cen MT Bold", sans-serif;
    height: 50px;
    left: 0;
    margin-right: 0.5rem;
    padding: 0 8px 0 0;
    position: absolute;
    transform: translateX(-100%);
  }
  .control-toggles .control-toggle-btns {
    padding-right: 0;
    flex-direction: column;
    width: 100%;
  }
  .control-toggles .control-toggle {
    margin-right: 0;
    margin-bottom: 2px;
    width: 100%;
  }
  .control-toggles .control-toggle:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .control-toggles .control-toggle:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .control-toggles .control-toggle .icon {
    stroke: #888b8d;
    fill: #888b8d;
  }
  .control-toggles .control-toggle.active .icon {
    stroke: #000;
    fill: #000;
  }
  .control-toggles .view-toggle {
    display: none;
  }
}

.contact-us-form {
  width: 100%;
}
.contact-us-form .contact-form-wrapper {
  display: block;
}
.contact-us-form .contact-form-wrapper.hide {
  display: none;
}
.contact-us-form .contact-form-wrapper .headline-large {
  margin-bottom: 12px;
}
.contact-us-form .contact-form-wrapper .contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all 0.3s;
}
.contact-us-form .contact-form-wrapper .contact-form label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #000;
  font-family: "Tw Cen MT Bold", sans-serif;
  text-transform: uppercase;
}
.contact-us-form .contact-form-wrapper .contact-form d .input-text .input-error-message,
.contact-us-form .contact-form-wrapper .contact-form .textarea .textarea-error-message {
  color: #e52b24;
}
.contact-us-form .confirmation {
  display: none;
  transition: all 0.3s;
  width: 100%;
}
.contact-us-form .confirmation.show {
  display: block;
}
.contact-us-form .confirmation .button {
  width: 100%;
}

.expandable.expanded .inner-wrap {
  max-height: none;
}
.expandable.expanded .inner-wrap:after {
  display: none;
}
.expandable.expanded .expand-collapse-btn {
  box-shadow: none;
}
.expandable .inner-wrap {
  max-height: 400px;
  overflow: hidden;
  position: relative;
}
.expandable .inner-wrap:after {
  background: linear-gradient(transparent, #f2f2f2);
  bottom: 0;
  content: "";
  display: block;
  height: 200px;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.expandable .expand-collapse-btn {
  background-color: #f2f2f2;
  bottom: 0;
  display: flex;
  width: 100%;
  z-index: 1;
}

.expand-collapse-btn {
  align-items: center;
  cursor: pointer;
  display: none;
  justify-content: center;
  padding: 1.5em;
  width: 100%;
}
.expand-collapse-btn .icon {
  transform: rotate(90deg);
}
.expanded .expand-collapse-btn .icon {
  transform: rotate(-90deg);
}

.family-member-link {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  align-self: flex-start;
  color: #888b8d;
  display: block;
  flex-grow: 1;
  font-family: "Tw Cen MT", sans-serif;
  pointer-events: none;
  text-transform: uppercase;
}
.family-member-link a {
  pointer-events: auto;
}
.family-member-link span {
  color: #0c5688;
}

.featured-locations-aside {
  width: 260px;
}
.featured-locations-aside .featured-locations-wrap {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  padding: 0.75rem;
}
.featured-locations-aside .location-microdata {
  font-size: 0.8125rem;
  line-height: 1.3846153846;
  color: #000;
  font-family: "Arial", sans-serif;
}
.featured-locations-aside .location-microdata .street-address {
  display: inline;
}
.featured-locations-aside .featured-locations-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}
.featured-locations-aside .featured-item {
  display: flex;
  flex-direction: column;
  flex: 0 1 calc(50% - 6px);
  margin-bottom: 10px;
  max-width: calc(50% - 6px);
}
.featured-locations-aside .featured-item a {
  aspect-ratio: 1/1;
  border-radius: 6px;
  display: block;
  margin-bottom: 0.375rem;
  overflow: hidden;
  width: 100%;
}
.featured-locations-aside .featured-item:only-child {
  flex: 0 1 100%;
  max-width: 100%;
}
.featured-locations-aside .featured-item-image {
  height: 100%;
  object-fit: cover;
  object-position: center 10%;
  border-radius: 6px;
  width: 100%;
}

.featured-providers-aside {
  width: 258px;
}
.featured-providers-aside .featured-providers-wrap {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  padding: 12px;
}
.featured-providers-aside .bottom-label {
  font-size: 0.8125rem;
  line-height: 1.3846153846;
  font-family: "Arial", sans-serif;
}
.featured-providers-aside .featured-providers-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}
.featured-providers-aside .featured-item {
  display: flex;
  flex-direction: column;
  flex: 0 1 calc(50% - 6px);
  margin-bottom: 10px;
  max-width: calc(50% - 6px);
}
.featured-providers-aside .featured-item a {
  aspect-ratio: 1/1;
  border-radius: 6px;
  display: block;
  margin-bottom: 0.375rem;
  overflow: hidden;
  width: 100%;
}
.featured-providers-aside .featured-item:only-child {
  flex: 0 1 100%;
  max-width: 100%;
}
.featured-providers-aside .featured-item-image {
  height: 100%;
  object-fit: cover;
  object-position: center 10%;
  border-radius: 6px;
  width: 100%;
}

.filter-action-input {
  font-size: 1rem;
  line-height: 1.25;
  display: flex;
}
.filter-action-input .input-icon-container,
.filter-action-input input[type=text] {
  flex: 1;
}
.filter-action-input .filter-accept-btn,
.filter-action-input .filter-remove-btn {
  align-items: center;
  background-color: #525259;
  border-radius: 25px;
  color: #fff;
  display: flex;
  flex: 0 0 40px;
  height: 40px;
  justify-content: center;
  margin-bottom: 0.75rem;
  padding: 0;
  transition: all 0.1s ease-in-out;
  width: 40px;
}
.filter-action-input .filter-accept-btn .icon,
.filter-action-input .filter-remove-btn .icon {
  stroke: #fff;
}
.filter-action-input .filter-accept-btn:hover,
.filter-action-input .filter-remove-btn:hover {
  background-color: #a1d45f;
  color: #000;
  cursor: pointer;
}
.filter-action-input .filter-accept-btn:hover .icon,
.filter-action-input .filter-remove-btn:hover .icon {
  stroke: #000;
}
.filter-action-input .filter-accept-btn {
  background-color: rgba(255, 255, 255, 0.5);
}
.filter-action-input .filter-accept-btn .icon {
  stroke: #000;
}

.filter-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: #fff;
  border-radius: 8px;
  color: #000;
  display: flex;
  margin: 0;
  margin-bottom: 0.75rem;
  padding: 0 0 0 0.5rem;
  padding: 15px 1.25rem;
  position: relative;
  transition: all 0.1s ease-in-out;
  width: 100%;
}
.filter-btn.disabled {
  color: #8b8b90;
}
.filter-btn.filter-btn-small {
  border-radius: 1.5625rem;
  height: 1.5625rem;
  line-height: 1;
  padding: 0.375rem 0.5rem;
  white-space: nowrap;
  width: auto;
}
.filter-btn.filter-btn-small .filter-label {
  color: #525259;
}
.filter-btn.filter-btn-small .icon {
  height: 0.625rem;
  margin: 0 0.25rem 0 0.5rem;
  stroke: #525259;
  width: 0.625rem;
}
.filter-btn.filter-btn-small .icon.icon-check {
  height: 0.75rem;
  width: 0.75rem;
}
.filter-btn.filter-btn-highlight {
  background-color: #2178bc;
}
.filter-btn.filter-btn-highlight .filter-label {
  color: #fff;
}
.filter-btn.filter-btn-highlight .icon {
  stroke: #fff;
}
.filter-btn .filter-label {
  color: #8b8b90;
  margin-left: 0.25rem;
}
.filter-btn .icon {
  align-self: center;
  fill: #181922;
  height: 1.2rem;
  margin-left: auto;
  stroke: #181922;
  width: 1.2rem;
}
.filter-btn .icon.icon-search {
  height: 1rem;
  width: 1rem;
}
.filter-btn .icon.rotate-left {
  transform: rotate(-90deg);
}
.filter-btn:hover:not(.disabled), .filter-btn:focus:not(.disabled) {
  background-color: #b1b1b3;
  cursor: pointer;
}
.filter-btn:hover .filter-label, .filter-btn:focus .filter-label {
  color: #000;
}
.filter-btn:hover .icon, .filter-btn:focus .icon {
  fill: #000;
  stroke: #000;
}

.filter-dropdown {
  color: #000;
  width: 540px;
  position: relative;
}
.filter-dropdown .dropdown-button {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: "Tw Cen MT", sans-serif;
  text-align: left;
  background: #fff;
  border: none;
  border-radius: 6px;
  padding: 15px;
  padding-right: 60px;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  cursor: pointer;
}
.filter-dropdown .dropdown-placeholder {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-family: "Tw Cen MT", sans-serif;
  display: block;
  position: absolute;
  left: 20px;
  opacity: 0.5;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.filter-dropdown .dropdown-placeholder.hidden {
  display: none;
}
.filter-dropdown img {
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
}
.filter-dropdown .dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  top: calc(100% - 6px);
  width: 100%;
  z-index: 1;
}
.filter-dropdown .dropdown-menu.active {
  display: block;
}
.filter-dropdown .dropdown-menu form {
  padding: 10px 0px 5px;
}
.filter-dropdown .dropdown-menu .dropdown-item {
  font-size: 1rem;
  line-height: 2;
  display: block;
  padding: 5px 15px;
  cursor: pointer;
}
.filter-dropdown .dropdown-menu .dropdown-item input[type=checkbox] {
  cursor: pointer;
  margin-right: 5px;
}
.filter-dropdown .dropdown-menu button {
  padding: 5px 10px;
  margin: 0px 10px 10px;
  cursor: pointer;
}

.filter-provider-result {
  margin-bottom: 1rem;
}
.filter-provider-result .filter-header {
  display: flex;
  align-items: center;
  height: 70px;
  background-color: #fff;
  padding: 14px 24px;
  margin-bottom: 0.1rem;
  box-shadow: 0px 2px 3px -1px rgba(85, 85, 85, 0.35);
}
.filter-provider-result .filter-list {
  list-style-type: none;
}
.filter-provider-result .filter {
  width: 30px;
  height: 18px;
}
.filter-provider-result .filter-item-label {
  flex: 1;
}
.filter-provider-result .icon-close {
  width: 24px;
  height: 24px;
}
.filter-provider-result .icon-chevron-right use {
  fill: #9bc158;
}
.filter-provider-result .filter-item {
  display: flex;
  padding: 14px 20px;
  background-color: #eeeeee;
  margin-bottom: 2px;
}
.filter-provider-result .black-bold-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #000;
  font-family: "Tw Cen MT Bold", sans-serif;
}
.filter-provider-result .sort-header-label {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  font-weight: 400;
  flex: 1;
  margin-left: 21px;
}

.geolocate-user {
  display: flex;
  justify-content: flex-end;
  padding: 14px 30px;
  position: relative;
}
.geolocate-user.geolocating .loader {
  display: inline-block;
}
.geolocate-user.geolocating .zip-input {
  display: none;
}
.geolocate-user.geolocating .icon-location {
  fill: #888b8d;
}
.geolocate-user.entering .zip-input {
  border-bottom: 2px solid #0c5688;
}
.geolocate-user.entering .icon-location {
  fill: #888b8d;
}
.geolocate-user.valid .zip-input {
  border-bottom: none;
}
.geolocate-user.invalid .zip-input {
  border-bottom: 2px solid #0c5688;
}
.geolocate-user .input-with-icon {
  display: flex;
}
.geolocate-user .input-with-icon .icon {
  margin-right: 5px;
}
.geolocate-user .zip-input {
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: none;
  border-radius: 0;
  line-height: 1;
  min-height: 0;
  padding: 0;
  width: 45px;
}
.geolocate-user .zip-input::placeholder {
  font-size: 0.875rem;
  line-height: 1.1428571429;
}
.geolocate-user .zip-input:focus, .geolocate-user .zip-input:focus-visible {
  outline: none;
}
.geolocate-user .loader {
  display: none;
  font-family: "Tw Cen MT", sans-serif;
  width: 45px;
}
.geolocate-user .loader:after {
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: "";
}
@keyframes ellipsis {
  to {
    width: 80px;
  }
}
.geolocate-user .error-message,
.geolocate-user .fallback-message {
  font-size: 0.875rem;
  line-height: 1;
  color: #ab405b;
  display: inline-block;
  font-family: "Tw Cen MT", sans-serif;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: calc(100% + 4px);
  transition: opacity 0.2s;
  width: 196px;
}
.geolocate-user .error-message.active,
.geolocate-user .fallback-message.active {
  opacity: 1;
}
@keyframes dotty {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}
@media (min-width: 1280px) {
  .geolocate-user {
    padding: 0;
  }
}

.hero {
  margin: 0 auto;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
.hero .hero-img {
  display: block;
  min-height: 350px;
  width: 100%;
}
.hero .hero-img img,
.hero .hero-img source,
.hero .hero-img canvas {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 490px;
  min-height: 350px;
}
@media (min-width: 1280px) {
  .hero .hero-img img,
  .hero .hero-img source,
  .hero .hero-img canvas {
    max-height: 560px;
  }
}

.input-submit {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.input-submit.success input {
  border-color: #a1d45f;
}
.input-submit.error input {
  border-color: #e52b24;
}
.input-submit label {
  display: block;
  margin-bottom: 5px;
}
.input-submit .input-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24.5px;
}
.input-submit input {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Arial", sans-serif;
  width: calc(100% - 20px);
  padding: 8px 11.5px;
  border: 1px solid #f6f3f1;
  border-radius: 6px;
  transition: border-color 0.3s;
}
.input-submit input:focus {
  border-color: #a1d45f;
}
.input-submit .label-input-text {
  font-size: 0.875rem;
  line-height: 1;
  color: #000;
  font-family: "Tw Cen MT", sans-serif;
  text-transform: uppercase;
}
.input-submit .input-error-message {
  color: #ab405b;
  font-family: "Open Sans", sans-serif;
}
.input-submit .span-explanation {
  font-size: 0.625rem;
  line-height: 1;
  display: flex;
  color: #888b8d;
  font-family: "Open Sans", sans-serif;
  margin-top: 8px;
}
.input-submit .email-input input {
  width: 100%;
}
.input-submit .icon-container {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.input-submit .status-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.input-submit .error-message {
  color: #e52b24;
  margin-top: 5px;
}
.input-submit .submit-container.hide-on-mobile {
  display: none;
}
.input-submit .submit-container.hide-on-desktop {
  display: block;
}
@media (min-width: 414px) {
  .input-submit .submit-container {
    min-width: 100px;
  }
}
@media (min-width: 768px) {
  .input-submit {
    gap: 2px;
  }
  .input-submit .submit-container {
    min-width: 120px;
  }
  .input-submit .submit-container.hide-on-mobile {
    display: block;
  }
  .input-submit .submit-container.hide-on-desktop {
    display: none;
  }
}
@media (min-width: 1280px) {
  .input-submit .submit-container {
    min-width: 164px;
  }
}

.link-list {
  margin-bottom: 1.5em;
}
.link-list > li {
  padding-bottom: 0.5rem;
}

a {
  text-decoration: none;
}
a .list-block {
  font-size: 1.25rem;
  line-height: 1.5;
  align-items: center;
  background-color: #f2f2f2;
  color: #525259;
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.75rem;
}
a .list-block.active {
  background-color: #fff;
  color: #181922;
}
a .list-block.active {
  background-color: #fff;
}
a .list-block-icon {
  margin-right: -10px;
  width: 40px;
}

.load-more {
  display: flex;
  justify-content: center;
}
.load-more .button.hidden {
  display: none;
}
@media (min-width: 768px) {
  .load-more .button {
    height: 50px;
    width: 300px;
  }
}

.location-profile-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px 0 10px 5px;
  border-radius: 5px;
  padding: 10px;
}
.location-profile-container .location-profile-image {
  display: block;
  height: 18.5rem;
  width: auto;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (min-width: 768px) {
  .location-profile-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
  }
  .location-profile-container .location-profile-image {
    height: 20.625rem;
    width: 30rem;
    margin-bottom: 0;
  }
}

.location-listing-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 17px 17px 15px;
  margin-bottom: 1.875rem;
  position: relative;
}
.location-listing-card .left-block .location-picture {
  aspect-ratio: 16/9;
  width: 100%;
  margin-bottom: 0.75rem;
  position: relative;
}
.location-listing-card .left-block .location-picture img {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.location-listing-card .right-block .main-info .location-name {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #0c5688;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0.25rem;
}
.location-listing-card .right-block .main-info .location-name:hover {
  text-decoration: underline;
}
.location-listing-card .right-block .ratings-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.location-listing-card .right-block .ratings-wrapper .ratings {
  margin-bottom: 0.75rem;
}
.location-listing-card .right-block .ratings-wrapper .ratings .rating-stars {
  height: fit-content;
}
.location-listing-card .right-block .custom-schedule-appointment-button {
  font-size: 1.25rem;
  line-height: 1.1;
  padding: 14px 20px;
  width: 100%;
}
.location-listing-card .right-block .schedule-an-appointment-call {
  padding-top: 0.375rem;
}
.location-listing-card .right-block .schedule-an-appointment-call .schedule-an-appointment-call-text {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  margin: 0 0 6px;
}
.location-listing-card .distance-link {
  display: none;
  margin-bottom: 13px;
}
.location-listing-card .distance-link .directions-link {
  font-size: 1rem;
  line-height: 1.25;
}
.location-listing-card .distance-link.mobile {
  display: block;
}
@media (min-width: 768px) {
  .location-listing-card {
    flex-direction: row;
    gap: 24px;
    padding: 12px 21px 21px 12px;
  }
  .location-listing-card .left-block .location-picture {
    width: 164px;
    margin-bottom: 10px;
  }
  .location-listing-card .right-block .main-info .location-name {
    font-size: 1rem;
    line-height: 1.25;
    margin-bottom: 5px;
  }
  .location-listing-card .right-block .ratings-wrapper .ratings {
    margin-bottom: 10px;
  }
  .location-listing-card .right-block .custom-schedule-appointment-button {
    font-size: 1rem;
    line-height: 1.125;
    padding: 9px 20px;
    width: auto;
  }
  .location-listing-card .distance-link {
    display: block;
  }
  .location-listing-card .distance-link.mobile {
    display: none;
  }
}

.location-microdata {
  font-size: 1rem;
  line-height: 1.25;
  color: #000;
  display: block;
  font-family: "Arial", sans-serif;
  font-weight: 400;
  margin-bottom: 0.75rem;
}
.location-microdata .street-address {
  display: block;
}
@media (min-width: 768px) {
  .location-microdata {
    font-size: 0.875rem;
    line-height: 1.2857142857;
  }
}

.medium-provider-card {
  display: flex;
  width: 28rem;
  height: 14rem;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  padding: 12px;
}
.medium-provider-card .provider-profile-info {
  padding-top: 4rem;
  margin-left: 12px;
}

.nav-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: #fff;
  border: 1px solid #8b8b90;
  border-radius: 12px;
  color: #525259;
  cursor: pointer;
  display: flex;
  font-family: "Tw Cen MT", sans-serif;
  padding: 0.125rem 1.5rem;
}
.nav-btn.active {
  background-color: #8b8b90;
  color: #fff;
}
@media (min-width: 768px) {
  .nav-btn:hover {
    background: #eefafe;
  }
}

.numeric-pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}
.numeric-pagination button {
  cursor: pointer;
}
.numeric-pagination .pagination-nav {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}
.numeric-pagination .pagination-nav button {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  font-family: "Arial", sans-serif;
  color: #1d1d1d;
}
.numeric-pagination .pagination-nav button.active {
  font-weight: 700;
  color: #000;
}
.numeric-pagination .prev-page,
.numeric-pagination .next-page,
.numeric-pagination .pagination-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.numeric-pagination-results-count {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.page-sections {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.page-sections .sections-title {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  color: #333;
  font-weight: 600;
  padding: 0.75rem;
}
.page-sections .sections-inner {
  border: 1px solid #b1b1b3;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
}
.page-sections a {
  font-size: 1rem;
  line-height: 1.375;
  color: #046791;
  text-decoration: underline;
  margin-bottom: 0.5rem;
}
@media (min-width: 1280px) {
  .page-sections {
    margin-top: 5.5rem;
  }
}

.pagination-nav {
  display: flex;
  justify-content: center;
  margin: 0 0 3.25rem;
}
.pagination-nav .pagination {
  align-items: center;
  border-radius: 25px;
  display: flex;
  padding: 0 5px;
  width: auto;
}
.pagination-nav .page-item {
  align-items: center;
  display: flex;
  margin-right: 2px;
  padding: 0;
}
.pagination-nav .page-item.active {
  pointer-events: none;
}
.pagination-nav .page-item.active .page-link {
  background-color: #9bc158;
  color: #fff;
}
.pagination-nav .page-item.disabled {
  pointer-events: none;
  color: #dddddd;
}
.pagination-nav .page-item.disabled .page-link {
  background-color: transparent;
}
.pagination-nav .page-item.disabled svg {
  fill: #b1b1b3;
}
.pagination-nav .page-item:last-of-type {
  margin-right: 0;
}
.pagination-nav .page-link {
  font-size: 1rem;
  line-height: 1.375;
  border: none;
  border-radius: 50%;
  font-weight: 600;
  height: 40px;
  padding: 0.625rem;
  line-height: 1;
  width: 40px;
}
.pagination-nav .page-link:hover, .pagination-nav .page-link:focus {
  background-color: #b1b1b3;
  color: #181922;
  cursor: pointer;
}
.pagination-nav .page-link:hover svg, .pagination-nav .page-link:focus svg {
  fill: #181922;
}
.pagination-nav .page-link .icon-chevron-right {
  margin-left: 2px;
}
.pagination-nav .page-link .left {
  transform: rotate(180deg);
  margin: 4px 2px 0 0;
}

.pagination {
  display: inline-flex;
  justify-content: center;
  margin-bottom: 2rem;
  width: 100%;
}
.pagination ul,
.pagination li {
  align-items: center;
  display: inline-flex;
  list-style: none;
  padding: 5px;
}
.pagination ul,
.pagination li:first-child,
.pagination li:last-child {
  padding: 0px;
  text-decoration: unset;
}
.pagination ul:hover a::after,
.pagination li:first-child:hover a::after,
.pagination li:last-child:hover a::after {
  opacity: 0;
}
.pagination li:hover a::after {
  opacity: 1;
}
.pagination li.active a::after {
  opacity: 1;
}
.pagination a {
  color: #000;
  margin: 0px 4px 0px 4px;
  padding: 0px 3px;
  position: relative;
}
.pagination a:focus::after {
  opacity: 1;
}
.pagination a::after {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: black;
  opacity: 0;
}
.pagination .pagination-btn {
  align-items: center;
  background-color: #f2efeb;
  border-radius: 50%;
  display: inline-flex;
  height: 40px;
  justify-content: center;
  position: relative;
  width: 40px;
}
.pagination .previous svg {
  position: absolute;
  transform: rotate(-90deg);
}
.pagination .next svg {
  position: absolute;
  transform: rotate(90deg);
}
@media (min-width: 414px) {
  .pagination a {
    margin: 0px 10px 0px 10px;
  }
}

.product-card {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 16px;
  max-width: 500px;
  background-color: #ffffff;
}
.product-card .card-image {
  height: 257px;
  display: block;
  margin: 0 auto;
  padding-top: 0.313rem;
}
.product-card .card-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
}
.product-card .card-content .card-title {
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 10px;
}
.product-card .card-content .card-description {
  margin-bottom: 8px;
  overflow: hidden;
  margin-bottom: 20px;
}
.product-card .card-content .card-cta-container {
  display: flex;
  flex-grow: 1;
}
.product-card .card-content .button,
.product-card .card-content .card-cta {
  align-self: flex-end;
  display: block;
  width: 100%;
  text-align: center;
}
.product-card .card-content .button {
  font-size: 1em;
  font-family: "Tw Cen MT", sans-serif;
}
.product-card .card-content .card-cta {
  font-size: 0.75rem;
  line-height: 1.5;
  color: #000;
  font-family: "Tw Cen MT Bold", sans-serif;
  margin-bottom: 0;
  padding: 8px;
  text-transform: uppercase;
}

.provider-location-card {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 1.4375rem;
  border-radius: 5px;
  margin-bottom: 13px;
}
@media (min-width: 360px) {
  .provider-location-card .provider-location-card-description {
    padding-top: 1.4375rem;
  }
}
.provider-location-card .text-link {
  font-size: 0.9375rem;
  line-height: 1.2;
}
.provider-location-card .location-name {
  font-size: 0.9375rem;
  line-height: 1.2;
  font-family: "Arial", sans-serif;
  font-weight: 700;
}
.provider-location-card .text-link:hover {
  border-bottom: none;
}
.provider-location-card .description-location {
  margin-bottom: 10px;
}
.provider-location-card .description-location .city-location {
  margin-top: 5px;
  color: #000;
  font-family: "Arial", sans-serif;
  font-size: 0.9375rem;
  line-height: 1.4666666667;
}
.provider-location-card p.city-location {
  margin-top: 5px;
  margin-bottom: 0;
}
.provider-location-card .office-hours {
  margin-bottom: 10px;
}
.provider-location-card .provider-location-card-map {
  display: block;
  position: relative;
}
.provider-location-card .provider-location-card-map .distance-label {
  font-family: "Arial", sans-serif;
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  top: 8.25rem;
  left: 1.25rem;
  background-color: #ffffff;
  color: #0c5688;
  padding: 0.5rem;
  position: absolute;
  border-radius: 5px;
  border: 1px solid #707070;
  pointer-events: none;
}
.provider-location-card .provider-location-card-map .distance-label:empty {
  display: none;
}
.provider-location-card .provider-location-card-office-hours {
  width: 100%;
  background-color: #fff;
  display: none;
}
.provider-location-card .provider-location-card-office-hours.show {
  display: block;
}
.provider-location-card .provider-location-card-office-hours .location-card-row-info-column {
  display: flex;
  flex-direction: column;
  padding-right: 25px;
  width: 210px;
}
.provider-location-card .provider-location-card-office-hours .location-card-row-info-column .location-card-schedule {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 0.375rem;
  white-space: nowrap;
}
.provider-location-card .provider-location-card-office-hours .location-card-row-info-column .location-card-schedule:last-child {
  margin-bottom: 0;
}
.provider-location-card .provider-location-card-office-hours .location-card-row-info-column .location-card-schedule.hidden {
  display: none;
}
.provider-location-card .provider-location-card-office-hours .location-card-row-info-column .location-card-schedule .day-schedule,
.provider-location-card .provider-location-card-office-hours .location-card-row-info-column .location-card-schedule .day-schedule-time {
  font-size: 0.875rem;
  line-height: 1;
}
.provider-location-card .provider-location-card-office-hours .location-card-row-info-column .location-card-schedule .day-schedule {
  margin-right: 0.75rem;
  width: 31px;
  font-weight: 700;
}
.provider-location-card .provider-location-card-office-hours .location-card-row-info-column .location-card-schedule .day-schedule-time {
  flex: 1;
}
.provider-location-card .provider-location-card-office-hours .location-card-row-info-column .location-card-schedule span {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Arial", sans-serif;
  text-align: left;
}
@media (min-width: 768px) {
  .provider-location-card {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 33.75rem;
    padding: 1.4375rem;
    margin-right: 13px;
  }
  .provider-location-card .provider-location-card-map {
    width: 15rem;
    display: block;
    position: relative;
  }
  .provider-location-card .provider-location-card-map img {
    height: 14.625rem;
    object-fit: cover;
  }
  .provider-location-card .provider-location-card-map .distance-label {
    font-family: "Arial", sans-serif;
    font-size: 0.9375rem;
    line-height: 1.4666666667;
    top: 11.25rem;
    left: 1.25rem;
    background-color: #ffffff;
    color: #0c5688;
    padding: 0.5rem;
    position: absolute;
    border-radius: 5px;
    border: 1px solid #707070;
  }
  .provider-location-card .provider-location-card-description {
    width: 14.75rem;
  }
}
@media (min-width: 768px) and (min-width: 414px) {
  .provider-location-card .provider-location-card-description {
    padding-top: 0;
  }
}

.provider-result-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  padding: 17px;
  margin-bottom: 1.875rem;
  width: 100%;
}
.provider-result-card .provider-picture-container {
  aspect-ratio: 41/49;
  display: block;
  margin-bottom: 17.5px;
  position: relative;
  width: 100%;
}
.provider-result-card .provider-picture-bg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.provider-result-card .provider-picture {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
.provider-result-card .provider-name {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  font-family: "Arial", sans-serif;
  color: #0c5688;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 5px;
}
.provider-result-card .provider-name:hover {
  text-decoration: underline;
}
.provider-result-card .checkmark-text {
  margin-top: 0.25rem;
}
.provider-result-card .distance-link.has-location .additional-location {
  margin-left: 1.125rem;
  position: relative;
}
.provider-result-card .distance-link.has-location .additional-location::before {
  color: #888b8d;
  content: "|";
  display: block;
  font-weight: 400;
  left: -0.8125rem;
  pointer-events: none;
  position: absolute;
  top: -1px;
  transform: translateX(-50%);
}
.provider-result-card .distance-link:has(.distance-label:empty) {
  display: none;
}
.provider-result-card .distance-link:has(.additional-location) {
  display: block !important;
}
.provider-result-card .directions-link,
.provider-result-card .additional-location {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  font-family: "Arial", sans-serif;
}
.provider-result-card .directions-link {
  color: #0c5688;
  margin-right: 0.5rem;
}
.provider-result-card .directions-link:hover {
  text-decoration: underline;
}
.provider-result-card .distance-label {
  font-size: 0.875rem;
  line-height: 1;
  display: inline;
  min-width: 60px;
}
.provider-result-card .additional-location {
  color: #000;
  font-weight: 700;
}
.provider-result-card .custom-check {
  margin-right: 5px;
}
.provider-result-card .location-microdata {
  margin-top: 13px;
}
.provider-result-card .custom-schedule-appointment-button {
  font-size: 1.25rem;
  line-height: 1.1;
  padding: 14px 20px;
  width: 100%;
}
.provider-result-card .distance-link {
  font-family: "Arial", sans-serif;
  margin-bottom: 13px;
}
.provider-result-card .schedule-an-appointment-call {
  padding-top: 0.375rem;
}
.provider-result-card .schedule-an-appointment-call .schedule-an-appointment-call-text {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  margin: 0 0 6px;
}
@media (min-width: 768px) {
  .provider-result-card {
    flex-direction: row;
    gap: 24px;
    padding: 12px 21px 13px 12px;
  }
  .provider-result-card .provider-picture-container {
    margin-bottom: 0;
    max-width: 164px;
    max-height: 196px;
  }
  .provider-result-card .provider-name {
    font-size: 1rem;
    line-height: 1.25;
    margin-top: 0;
  }
  .provider-result-card .checkmark-text {
    margin-top: 0;
  }
  .provider-result-card .custom-schedule-appointment-button {
    font-size: 1rem;
    line-height: 1.125;
    padding: 9px 20px;
    width: auto;
  }
}

.ratings {
  display: flex;
}
.ratings.small .star {
  height: 17.18182px;
  width: 18px;
}
.ratings.small .star svg {
  height: 17.18182px;
  width: 18px;
}
.ratings.small .star .star-fill {
  height: 17.18182px;
}
.ratings.small .average-rating-and-count {
  margin-top: -1px;
}
.ratings.small .average-rating,
.ratings.small .rating-count {
  font-size: 0.75rem;
  line-height: 1.3333333333;
}
.ratings .ratings-inner {
  display: flex;
}
.radio-wrapper .ratings .average-rating {
  margin-left: 0.375rem;
}
.radio-wrapper .ratings .average-rating,
.radio-wrapper .ratings .rating-count {
  color: #000;
  margin-top: 1px;
}
.disabled .ratings .average-rating,
.disabled .ratings .rating-count {
  color: #000;
}
.ratings .average-rating-and-count {
  font-family: "Arial", sans-serif;
  margin-top: 1px;
}
.ratings .average-rating {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: "Arial", sans-serif;
  margin-left: 0.4375rem;
}
.ratings .rating-count {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #989898;
  font-family: "Arial", sans-serif;
  margin-left: 0.125rem;
}
.ratings .rating-stars {
  display: flex;
  justify-content: space-between;
  width: 100px;
}
.disabled .ratings .rating-stars .star svg {
  fill: #fff;
}
.disabled .ratings .rating-stars .star .star-fill svg {
  fill: #f0ebe6;
}
.ratings .rating-stars .star:nth-child(1) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (1 - 1)) * 100%);
}
.ratings .rating-stars .star:nth-child(2) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (2 - 1)) * 100%);
}
.ratings .rating-stars .star:nth-child(3) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (3 - 1)) * 100%);
}
.ratings .rating-stars .star:nth-child(4) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (4 - 1)) * 100%);
}
.ratings .rating-stars .star:nth-child(5) .star-fill {
  max-width: 100%;
  width: calc((var(--rating) - (5 - 1)) * 100%);
}

.related-pages-aside ul {
  list-style-type: none;
  padding: 0;
}
.related-pages-aside ul li {
  padding-bottom: 0.375rem;
}

.related-story .eyebrow-related-story {
  font-size: 0.875rem;
  line-height: 1;
  color: #1d1d1d;
  font-family: "Tw Cen MT", sans-serif;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.related-story .related-link {
  font-size: 0.9375rem;
  line-height: 1;
  color: #0c5688;
  font-family: "Tw Cen MT", sans-serif;
  text-decoration: none;
}
.related-story .related-link:hover {
  text-decoration: underline;
}
.related-story .related-news-title {
  color: #0c5688;
  margin-bottom: 10px;
}
.related-story .related-description {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  color: #000;
  font-family: "Arial", sans-serif;
}

.related-topics .related-title {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  color: #333;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-left: 0.75rem;
}
.related-topics .related-link {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  align-items: center;
  background-color: #181922;
  border-radius: 4px;
  color: #fff;
  display: flex;
  font-weight: 400;
  height: 50px;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 0.75rem;
  width: 100%;
}

.schedule-appointment-cta {
  display: flex;
  justify-content: center;
  background-color: #fff;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding-top: 30px;
  padding-bottom: 50px;
  position: relative;
  right: 50%;
  text-align: center;
  width: 100vw;
}
.schedule-appointment-cta:last-child {
  margin-bottom: -32px;
}
.schedule-appointment-cta .schedule-appointment-content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .schedule-appointment-cta .schedule-appointment-content {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .schedule-appointment-cta .schedule-appointment-content {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .schedule-appointment-cta .schedule-appointment-content {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.schedule-appointment-cta .schedule-appointment-label {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  font-weight: 400;
}
@media (min-width: 768px) {
  .schedule-appointment-cta:last-child {
    margin-bottom: -14px;
  }
  .schedule-appointment-cta .schedule-appointment-label {
    font-size: 1.375rem;
    line-height: 1.4545454545;
  }
}

.scroll-box {
  align-items: center;
  background-color: #f2f2f2;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  width: 100%;
}
.scroll-box div {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}

.previous-section-button,
.next-section-button {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: "Tw Cen MT", sans-serif;
  background: #fff;
  border: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  padding: 0.5rem 0.5rem;
  display: inline-block;
  cursor: pointer;
}

.previous-section-button .previous-section-icon {
  transform: scale(-1.5, 1.5) translate(0, 1px);
}

.next-section-button .next-section-icon {
  transform: scale(1.5) translate(0, 1px);
}

.section-previous,
.section-next {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}

.section-nav {
  display: flex;
  background-color: #f0ebe6;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 1.5rem 1.75rem 3.5rem;
}

.single-finder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: -35px;
  gap: 20px;
  transform: translateY(-50%);
  width: 100%;
}
.single-finder .finder-component {
  background-color: #fff;
  border-radius: 6px;
  height: auto;
  box-shadow: 1px 3px 3px rgba(85, 85, 85, 0.35);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 540px;
}
.single-finder .find-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  margin: auto;
  width: 100%;
  max-width: 362px;
}
.single-finder .find-component span {
  text-align: center;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}
.single-finder .icon {
  margin: auto;
  margin-bottom: 0.5rem;
}
.single-finder .icon-find-provider {
  width: 2.125rem;
  height: 2.125rem;
}
.single-finder .icon-find-location {
  width: 1.438rem;
  height: 1.875rem;
}
.single-finder .icon-quick-links {
  width: 2.375rem;
  height: 1.688rem;
}
@media (min-width: 768px) {
  .single-finder {
    height: auto;
    display: flex;
    margin: auto;
    gap: 20px;
    margin-top: -35px;
    flex-direction: row;
  }
}

.social-channels {
  display: flex;
  justify-content: center;
  margin-top: 35px;
}
@media (min-width: 768px) {
  .social-channels {
    justify-content: flex-end;
    margin: 0 0 0;
  }
}

.sort-provider-result {
  margin-bottom: 1rem;
}
.sort-provider-result .sort-header {
  display: flex;
  align-items: center;
  height: 70px;
  background-color: #fff;
  padding: 14px 24px;
  margin-bottom: 0.1rem;
  box-shadow: 0px 2px 3px -1px rgba(85, 85, 85, 0.35);
}
.sort-provider-result .sort-list {
  list-style-type: none;
}
.sort-provider-result .sort-icon {
  width: 22px;
  height: 22px;
}
.sort-provider-result .icon-close {
  width: 24px;
  height: 24px;
}
.sort-provider-result .sort-item {
  padding: 14px 20px;
  background-color: #eeeeee;
  margin-bottom: 2px;
}
.sort-provider-result .black-bold-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #000;
  font-family: "Tw Cen MT Bold", sans-serif;
}
.sort-provider-result .sort-header-label {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  font-weight: 400;
  flex: 1;
  margin-left: 21px;
}

.speed-bump {
  align-items: center;
  background-color: #000;
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}
.speed-bump.active {
  display: flex;
}
.speed-bump .speed-bump-content {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  text-align: left;
  width: 80%;
}
.speed-bump .headline-small {
  margin-bottom: 0.3125rem;
}
.speed-bump .speed-bump-description {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  color: #000;
  font-family: "Arial", sans-serif;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}
.speed-bump .speed-bump-buttons {
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 6.875rem;
}
.speed-bump .speed-bump-buttons button {
  padding: 10px 20px;
  cursor: pointer;
}
.speed-bump .confirm-button {
  background-color: #0c5688;
  color: #fff;
  border: none;
  border-radius: 7px;
}
.speed-bump .cancel-button {
  background-color: #fff;
  color: #0c5688;
  border: none;
  border: #0c5688;
  border: 1px solid #0c5688;
  border-radius: 7px;
}
@media (min-width: 768px) {
  .speed-bump .speed-bump-content {
    width: 40%;
    padding: 20px;
  }
  .speed-bump .speed-bump-buttons {
    height: auto;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}

.story-listing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
}
.story-listing .content-story-listing {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.story-listing .image-story-listing {
  width: 330px;
  height: 188px;
  border-radius: 6px;
  margin-bottom: 20px;
}
.story-listing .eyebrow-story-listing {
  font-size: 0.875rem;
  line-height: 1;
  display: block;
  font-family: "Tw Cen MT Bold", sans-serif;
  text-transform: uppercase;
  color: #1d1d1d;
  margin-bottom: 0.625rem;
}
.story-listing .eyebrow-story-listing span {
  color: #888b8d;
}
.story-listing .title-story-listing {
  font-size: 1.5rem;
  line-height: 1;
  display: block;
  font-family: "Tw Cen MT", sans-serif;
  color: #0c5688;
  margin-bottom: 0.625rem;
}
.story-listing .title-story-listing:hover {
  text-decoration: underline;
}
.story-listing .description-story-listing {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Arial", sans-serif;
  color: #000;
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .story-listing {
    display: flex;
    flex-direction: row;
  }
  .story-listing .content-story-listing {
    flex-direction: row;
  }
  .story-listing .image-story-listing {
    width: 352px;
    height: 198px;
    margin-bottom: 0;
    margin-right: 20px;
    border-radius: 6px;
  }
}

.tag-container {
  display: flex;
}

.tag {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  align-items: center;
  background-color: #0c5688;
  border-radius: 14px;
  display: flex;
  justify-content: flex-start;
  padding: 0.25rem;
  margin-bottom: 0.25rem;
  max-width: 100%;
}
.tag .tag-description {
  align-items: center;
  color: #fff;
  display: flex;
  flex-basis: calc(100% - 16px);
  max-width: calc(100% - 16px);
  justify-content: flex-start;
  margin-left: 0.25rem;
  margin-right: -1rem;
  transition: all 0.1s ease-in-out;
}
.tag .close-btn {
  background-color: #fff;
  height: 16px;
  flex: 0 0 2px;
  margin: 0;
  min-width: 16px;
}
.tag .close-btn .icon {
  height: 8px;
  stroke: #0c5688;
  stroke-width: 3px;
  width: 8px;
}
.tag .filter-category {
  font-size: 0.875rem;
  line-height: 1.1428571429;
  margin-right: 0.25rem;
  transition: all 0.1s ease-in-out;
}
.tag .filter-value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 2rem;
}
.tag-list .button {
  font-size: 1rem;
  line-height: 1.125;
  background-color: #9bc158;
  color: #000;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
}
.tag-list .button:hover {
  background-color: #c9cbcb;
}

.title-and-description.title-only {
  margin-bottom: 0;
}

.tooltip {
  display: block;
  position: relative;
}
.tooltip .color-neutral-lightest {
  background-color: #f0ebe6;
  color: #181922;
}
.tooltip .content {
  border-radius: 5px;
  position: absolute;
  visibility: hidden;
  width: 280px;
  z-index: 1;
}
.tooltip .content .data-content {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 17px 20px 13px 20px;
}
.tooltip .content .data-content .text {
  font-size: 1rem;
  line-height: 1.25;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
}
.tooltip .content .data-content .text .link-text {
  color: #4e8209;
  padding: 20px 0;
}
.tooltip .content .data-content .text p {
  font-size: 1rem;
  line-height: 1.25;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
}
.tooltip .content .data-content .x-icon {
  cursor: pointer;
  padding: 8px;
}
.tooltip .content .data-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 7px 20px;
}
.tooltip .content .data-wrapper > div {
  font-size: 1rem;
  line-height: 1.25;
  background-color: #181922;
  color: #fff;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
}
.tooltip:hover .content {
  background-color: #181922;
  color: #fff;
  visibility: visible;
}
.tooltip:hover .content:after {
  border-style: solid;
  border-width: 13px;
  content: " ";
  margin-left: -13px;
  position: absolute;
}
.tooltip:hover .content .icon-white {
  stroke: #fff;
}
.tooltip:hover .content .icon-black {
  stroke: #000;
}
.tooltip:hover .content.arrow-top-right::after {
  border-color: transparent transparent #181922 transparent;
  content: " ";
  left: 85%;
  top: -26px;
}
.tooltip:hover .content.arrow-top-left::after {
  border-color: transparent transparent #181922 transparent;
  content: " ";
  left: 15%;
  top: -26px;
}
.tooltip:hover .content.arrow-bottom-right::after {
  border-color: #181922 transparent transparent transparent;
  bottom: -26px;
  content: " ";
  left: 85%;
}
.tooltip:hover .content.arrow-bottom-left::after {
  border-color: #181922 transparent transparent transparent;
  bottom: -26px;
  content: " ";
  left: 15%;
}
.tooltip:hover .content .data-wrapper {
  display: flex;
  justify-content: space-between;
}

.two-up-rich-text .section {
  border-bottom: 1px solid #c9cbcb;
}
.two-up-rich-text .section .headline-small,
.two-up-rich-text .section .headline-medium {
  border-bottom: 1px solid #c9cbcb;
  padding-bottom: 0.375rem;
}
@media (min-width: 414px) {
  .two-up-rich-text .rich-text ul {
    columns: 2;
  }
}
@media (min-width: 768px) {
  .two-up-rich-text {
    display: flex;
    gap: 1.5rem;
  }
  .two-up-rich-text .section {
    flex-basis: 50%;
    min-width: 50%;
  }
}

.video-and-text {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
.video-and-text .text-container {
  flex: 1 1 100%;
  min-width: 200px;
  margin: 0;
}
.video-and-text .text-container .headline-medium {
  margin-top: 0;
}
.video-and-text .text-container .body-copy {
  margin-bottom: 0;
}
.video-and-text .video-container {
  flex: 1 1 100%;
  position: relative;
  width: 100%;
  height: 0px;
  padding-top: 56.25%;
}
.video-and-text .video-container iframe,
.video-and-text .video-container lite-youtube {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .video-and-text {
    flex-direction: row;
  }
  .video-and-text .video-container {
    padding-top: 27%;
  }
  .video-and-text .text-container,
  .video-and-text .video-container {
    flex: 0 1 50%;
  }
}

.video-card {
  background-color: #fff;
  border-radius: 10px;
}
@media (min-width: 360px) {
  .video-card {
    width: 100%;
    margin-bottom: 1.6rem;
  }
}

.video-content-wrapper {
  max-height: 360px;
  overflow: hidden;
  padding: 0;
  padding-top: 70px;
  position: relative;
}
.video-content-wrapper.video-active iframe {
  z-index: 0;
}
.video-content-wrapper img {
  cursor: pointer;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.video-content-wrapper iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  padding-top: 70px;
  width: 100%;
  z-index: -1;
}
@media (min-width: 414px) {
  .video-content-wrapper iframe {
    padding-top: 0px;
  }
}
@media (min-width: 414px) {
  .video-content-wrapper {
    padding-top: 0px;
  }
}

/* Organisms (e.g. Header, Footer, Article, SitecoreComponent) */
.alert-banner {
  width: 100%;
  background-color: #0c5688;
}
.alert-banner * {
  color: #fff;
}
.alert-banner .alert-banner-inner {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  padding: 1rem;
  position: relative;
}
@media (min-width: 768px) {
  .alert-banner .alert-banner-inner {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .alert-banner .alert-banner-inner {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .alert-banner .alert-banner-inner {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.alert-banner .alert-banner-inner .alert-close-btn {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.alert-banner .alert-banner-inner .alert-content {
  display: flex;
  flex-direction: column;
  gap: 1.4375rem;
}
.alert-banner .alert-banner-inner .alert-content p {
  flex: 1;
}
.alert-banner .alert-banner-inner .alert-content p .alert-actions {
  padding-left: 1rem;
}
.alert-banner .alert-banner-inner .alert-content p .alert-actions a {
  text-decoration: underline;
}
.alert-banner .alert-banner-inner .alert-content p .alert-actions .action-separator {
  padding: 0 0.5rem;
}
.alert-banner .icon {
  margin-top: 2px;
}
@media (min-width: 768px) {
  .alert-banner .alert-banner-inner {
    padding: 1rem 2.5rem 1rem 1rem;
  }
  .alert-banner .alert-banner-inner .alert-content {
    flex-direction: row;
  }
}

.appointment-questions.expanded .header .icon {
  transform: rotate(-90deg);
}
.appointment-questions.expanded .questions {
  display: block;
}
.appointment-questions .header {
  align-items: center;
  border-bottom: 0.125em solid #f0ebe6;
  cursor: pointer;
  display: none;
  justify-content: space-between;
  padding: 25px;
  width: 100%;
}
.appointment-questions .header.active {
  display: flex;
}
.appointment-questions .header h3 {
  font-size: 1rem;
  line-height: 1.5;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 500;
}
.appointment-questions .header .icon {
  transform: rotate(90deg);
}
.appointment-questions h5 {
  font-size: 1rem;
  line-height: 1.5;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
  margin-block-start: auto;
}
.appointment-questions p {
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
  max-width: 400px;
  width: 80%;
}
.appointment-questions .question-history {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  border-bottom: solid 2px #f0ebe6;
  display: none;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
  padding: 15px 25px;
  position: relative;
}
.appointment-questions .question-history.active {
  display: flex;
}
.appointment-questions .question-history :first-child {
  width: 340px;
}
.appointment-questions .question-history .answers {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  color: #8b8b90;
  flex-grow: 2;
  font-weight: 500;
  text-align: right;
}
.appointment-questions .question-history .answers span {
  padding-left: 2em;
}
.appointment-questions .question-history .answers .selected {
  color: #64a70b;
}
.appointment-questions .question-history.last:before {
  background-color: #f0ebe6;
  bottom: 0;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
  width: 100%;
}
.appointment-questions .question-history.last:after {
  background-color: #64a70b;
  bottom: 0;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
  width: var(--question-history-progress);
}
.appointment-questions .questions {
  display: none;
}
.appointment-questions .question-content {
  display: none;
}
.appointment-questions .question-content.active {
  display: block;
}
.appointment-questions .question-content .start {
  display: none;
}
.appointment-questions .question-content .start.active {
  display: block;
}
.appointment-questions .question-content .stop {
  display: none;
}
.appointment-questions .question-content .stop.active {
  display: block;
}
.appointment-questions .stop-question,
.appointment-questions .question {
  align-items: top;
  min-height: 175px;
  padding: 1.5em;
}
.appointment-questions .stop-question p:first-of-type,
.appointment-questions .question p:first-of-type {
  padding-top: 0.275em;
}
.appointment-questions .stop-question .icon-information,
.appointment-questions .question .icon-information {
  fill: #64a70b;
  transform: scale(1.25);
}
.appointment-questions .stop-answer,
.appointment-questions .action-answer {
  display: flex;
  gap: 0.25em;
}
.appointment-questions .stop-answer button,
.appointment-questions .action-answer button {
  cursor: pointer;
}
.appointment-questions .stop-answer .action-btn,
.appointment-questions .action-answer .action-btn {
  flex: 1;
  min-height: 90px;
}
.appointment-questions .stop-answer .lrg-btn,
.appointment-questions .action-answer .lrg-btn {
  flex-grow: 2.28;
}
.appointment-questions .stop-answer .start-over,
.appointment-questions .action-answer .start-over {
  background-color: #fff;
}

.article-list {
  margin-bottom: 3.625rem;
}
.article-list ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.article-list .article-listing {
  display: none;
}
.article-list .article-listing:before {
  display: none;
}
.article-list .article-listing.last-visible .separator {
  display: none;
}
.article-list .article-listing.active {
  display: block;
}
.article-list .article-listing:last-of-type {
  margin-bottom: 2.5rem;
}
.article-list .article-date {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  display: block;
  color: #333;
  font-weight: 400;
  margin-bottom: 0.25rem;
}
.article-list .article-image {
  border-radius: 10px;
  margin-bottom: 1.5rem;
  aspect-ratio: 16/9;
  overflow: hidden;
}
.article-list .article-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
.article-list .heading-4 {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  color: #181922;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.article-list p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #181922;
  margin-bottom: 1rem;
}
.article-list .article-link {
  font-size: 1rem;
  line-height: 1.375;
  color: #2178bc;
}
.article-list .separator {
  border: none;
  display: block;
  height: 1px;
  margin: 1.875rem 0;
  width: 100%;
  background-color: #c9cbcb;
}
@media (min-width: 768px) {
  .article-list .article-inner {
    align-items: flex-start;
    display: flex;
  }
  .article-list .article-image {
    flex: 0 0 240px;
    margin-bottom: 0;
    margin-right: 2rem;
  }
}
@media (min-width: 1280px) {
  .article-list ul {
    margin-left: -10px;
    width: calc(100% + 20px);
  }
  .article-list .article-listing {
    padding: 0 10px;
  }
  .article-list .article-image {
    flex: 0 0 390px;
  }
}

.blog-post-summary-list {
  margin-bottom: 20px;
}
.blog-post-summary-list .blog-post-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  max-width: 355px;
  flex-grow: 1;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 1rem;
}
.blog-post-summary-list .blog-post-header {
  display: flex;
  justify-content: flex-end;
}
.blog-post-summary-list .header-right-title {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 1.25rem;
}
@media (min-width: 414px) {
  .blog-post-summary-list .gallery-wrap {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    flex-direction: column;
    width: calc(100% + 18px);
  }
  .blog-post-summary-list .gallery {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 18px;
  }
}

.blog .blog-header {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 35px;
}

.button-bar {
  align-items: center;
  display: flex;
  gap: 1em;
  justify-content: flex-start;
  justify-items: flex-start;
  overflow-x: auto;
  padding: 0.75em;
  position: relative;
}
.button-bar .nav-btn {
  margin-right: 5px;
  white-space: nowrap;
}
.button-bar .nav-btn:last-child {
  margin-right: 0;
}
@media (min-width: 768px) {
  .button-bar {
    padding: 0.75em 0;
  }
}

.campaign-landing {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.campaign-landing > section {
  margin-bottom: 50px;
}
.campaign-landing .content-block {
  width: 45.5rem;
  height: auto;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 53px 90px;
  margin-bottom: 50px;
  box-shadow: 0px 3px 8px 0px rgba(85, 85, 85, 0.35);
}
.campaign-landing .custom-schedule-appointment-button {
  width: 300px;
  margin-top: 10px;
}
.campaign-landing .content-block-padding-80 {
  padding: 80px 90px;
}
.campaign-landing .posts-wrap {
  display: flex;
  margin: auto;
  justify-content: space-between;
  width: 45.5rem;
  margin-bottom: 50px;
}
.campaign-landing .posts {
  width: 352px;
  height: auto;
  border-radius: 6px;
  background-color: #fff;
  padding-bottom: 11px;
  box-shadow: 0px 3px 8px 0px rgba(85, 85, 85, 0.35);
}
.campaign-landing .posts-image {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.campaign-landing .post-title {
  font-size: 1.375rem;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
  margin-top: 44px;
}
.campaign-landing .post-content-wrap {
  padding: 11px 24px;
}
.campaign-landing .post-content {
  margin-top: 10px;
}
.campaign-landing .custom-schedule-appointment-button {
  width: 100%;
}
.campaign-landing .video-player {
  border-radius: 6px;
}
.campaign-landing .video-with-title {
  width: 45.5rem;
  margin-left: auto;
  margin-right: auto;
}
.campaign-landing .video-with-title lite-youtube {
  border-radius: 6px;
}

.campaign .custom-campaign-title {
  margin-top: 1.5rem;
  margin-bottom: 1.2rem;
}

.contact-us {
  padding: 15px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact-us .contact-us-inner {
  display: flex;
  flex-direction: column;
  max-width: 353px;
  width: 100%;
}
@media (min-width: 414px) {
  .contact-us {
    padding: 40px 0px;
  }
}

.error-content {
  padding: 0px 0px 0px;
}
.error-content .wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.error-content .left-content {
  display: flex;
  flex-direction: column;
}
.error-content .left-content .error-404-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: "Tw Cen MT Bold", sans-serif;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.error-content .left-content .error-404-message {
  font-size: 1.875rem;
  line-height: 1;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
}
.error-content .left-content .links-list-desktop {
  margin-top: 20px;
}
.error-content .right-content .image-wrapper {
  position: relative;
  width: 100%;
  padding-top: 55%;
  overflow: hidden;
}
.error-content .right-content .image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.error-content .links-list-title {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 400;
}
.error-content .links-list li {
  margin-top: 10px;
}
.error-content .report-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}
@media (min-width: 360px) {
  .error-content .wrapper {
    flex-direction: column;
  }
  .error-content .wrapper .left-content .links-list-desktop {
    display: none;
  }
  .error-content .links-list-mobile {
    display: block;
    margin-top: 20px;
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .error-content {
    padding: 60px 0px 20px;
  }
  .error-content .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .error-content .wrapper .left-content {
    width: 100%;
  }
  .error-content .wrapper .left-content .links-list-desktop {
    display: block;
  }
  .error-content .links-list-mobile {
    display: none;
  }
  .error-content .report-link {
    margin-top: 130px;
  }
}

.event-listing .card-description {
  padding: 14px 20px 20px;
}
.event-listing .event-image {
  border-radius: 6px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1.46;
}
.event-listing .event-date-and-cost {
  border-bottom: 1px solid #c9cbcb;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}
.event-listing .start-date {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #000;
  font-family: "Arial", sans-serif;
  font-weight: 700;
  margin-bottom: 0.375rem;
}
.event-listing .start-time {
  font-size: 0.9375rem;
  line-height: 1;
  font-family: "Arial", sans-serif;
}
.event-listing .eyebrow {
  margin-bottom: 0.25rem;
}
.event-listing .text-link {
  font-size: 1rem;
  line-height: 1.375;
}
.event-listing .event-location,
.event-listing .event-contact {
  font-size: 1rem;
  line-height: 1.375;
  color: #000;
  font-family: "Arial", sans-serif;
  margin-bottom: 1.5rem;
}
.event-listing .event-location a,
.event-listing .event-location span[itemprop=streetAddress],
.event-listing .event-contact a,
.event-listing .event-contact span[itemprop=streetAddress] {
  display: block;
}
.event-listing .button {
  width: 100%;
}
.event-listing .title-main {
  display: none;
}
.event-listing .mobile-title {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .event-listing {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
  }
  .event-listing .event-details,
  .event-listing .event-description {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .event-listing .mobile-title {
    display: none;
  }
  .event-listing .event-date-and-cost {
    display: flex;
    justify-content: space-between;
  }
  .event-listing .event-cost {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .event-listing .title-main {
    font-size: 1.75rem;
    line-height: 1.1428571429;
    display: block;
    font-family: "Tw Cen MT", sans-serif;
    font-weight: 400;
    margin-bottom: 0.8125rem;
  }
}
@media (min-width: 1280px) {
  .event-listing .event-details {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .event-listing .event-description {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
}

.featured-blog-posts .blog-post-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  max-width: 355px;
  flex-grow: 1;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 1rem;
}
.featured-blog-posts .blog-post-header {
  display: flex;
  justify-content: space-between;
}
.featured-blog-posts .header-right-title {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 1.25rem;
}
@media (min-width: 414px) {
  .featured-blog-posts .gallery-wrap {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    flex-direction: column;
    width: calc(100% + 18px);
  }
  .featured-blog-posts .gallery {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 18px;
  }
}

.featured-products {
  margin-bottom: 2rem;
}
.featured-products .headline-medium {
  margin-bottom: 0.5rem;
}
.featured-products .card-products-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 768px) {
  .featured-products {
    margin-bottom: 4rem;
  }
  .featured-products .card-products-container {
    flex-direction: row;
    justify-content: space-evenly;
    gap: 1rem;
  }
  .featured-products .card-products-container .product-card {
    flex-basis: 50%;
    max-width: 50%;
  }
}

.featured-providers .location {
  border-top: none;
  padding: 0;
  min-height: 0;
}
.featured-providers .location .location-not-available {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.featured-providers .location .distance {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  padding: 0;
}
.featured-providers .location .location-address {
  color: #333;
}
.featured-providers .location .location-address .location-name {
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 400;
}
.featured-providers .location .location-address span {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.featured-providers .location:hover, .featured-providers .location:focus {
  background-color: transparent;
}
.featured-providers .location:hover .location-name, .featured-providers .location:focus .location-name {
  text-decoration: underline;
}
.featured-providers .scheduling-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.featured-providers .scheduling-grid {
  display: flex;
  width: 100%;
  padding: 0 5px;
  margin-bottom: 1rem;
}
.featured-providers .scheduling-day {
  background-color: #525259;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex: 33.333%;
  max-width: 33.333%;
  margin: 0 5px;
  overflow: hidden;
}
.featured-providers .day-title,
.featured-providers .day-link {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  align-items: center;
  color: #fff;
  display: flex;
  font-weight: 400;
  height: 31px;
  justify-content: center;
  margin: 0;
}
.featured-providers .day-link {
  background-color: #181922;
}
.featured-providers .day-link:hover, .featured-providers .day-link:focus {
  background-color: #b1b1b3;
}
@container (min-width: 768px) {
  .featured-providers .expanded-scheduling {
    width: 100%;
  }
  .featured-providers .expanded-scheduling .featured-providers-result {
    display: flex;
    flex: 100%;
    flex-direction: row;
    max-width: 100%;
    margin-right: 0 !important;
  }
  .featured-providers .expanded-scheduling .featured-providers-result:hover .result-name, .featured-providers .expanded-scheduling .featured-providers-result:focus .result-name {
    text-decoration: underline;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .result-details:hover, .featured-providers .expanded-scheduling .featured-providers-result .result-details:focus {
    background-color: transparent;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .result-details:hover .result-name, .featured-providers .expanded-scheduling .featured-providers-result .result-details:focus .result-name {
    text-decoration: underline;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .profile-image-outer {
    flex: 0 1 180px;
    max-width: 180px;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .profile-image-container {
    height: 100%;
    padding-bottom: 0;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .result-inner {
    display: flex;
    flex-direction: column;
    flex: 0 1 calc(100% - 180px);
    max-width: calc(100% - 180px);
    padding: 1rem;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .result-name {
    font-size: 1.375rem;
    line-height: 1.4545454545;
    margin-top: 0;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .result-top {
    align-items: center;
    display: flex;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .result-bottom {
    display: flex;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .result-details,
  .featured-providers .expanded-scheduling .featured-providers-result .location-scheduling {
    flex: 0 1 35%;
    max-width: 35%;
    margin: 0;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .location-scheduling {
    padding-bottom: 0;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .scheduling-help-text,
  .featured-providers .expanded-scheduling .featured-providers-result .scheduling-content {
    flex: 1;
    padding: 0 1.5rem;
  }
}
@container (min-width: 875px) {
  .featured-providers.multi .inner-container {
    display: flex;
    width: 100%;
    padding: 1.25rem 1.25rem 0;
  }
  .featured-providers.multi .inner-container .need-more-help {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 1.25rem 1rem;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .profile-image-outer {
    flex: 0 1 205px;
    max-width: 205px;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .result-inner {
    flex: 0 1 calc(100% - 205px);
    max-width: calc(100% - 205px);
  }
  .featured-providers .expanded-scheduling .featured-providers-result .scheduling-content {
    flex-direction: row;
    padding: 0 1rem;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .scheduling-grid {
    flex: 1;
    width: auto;
    margin-bottom: 0;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .view-more-container {
    flex: 0 1 130px;
    max-width: 130px;
  }
  .featured-providers .expanded-scheduling .featured-providers-result .view-more-container .view-more-link {
    text-align: center;
    margin-bottom: 0;
  }
}

.filter-list.view-all-open > .accordion-container {
  display: none;
}
.filter-list .filter-title {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-weight: 500;
  color: #989898;
  margin-bottom: 1rem;
}
.filter-list .control-panel-header {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  align-items: center;
  background-color: #fff;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
  color: #989898;
  display: flex;
  height: 70px;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .filter-list .control-panel-header {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .filter-list .control-panel-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .filter-list .control-panel-header {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.filter-list .control-panel-header .mobile-close-btn {
  font-size: 1.875rem;
  line-height: 1;
  font-weight: 700;
  background: none;
  border: none;
  color: #000;
  line-height: 0;
  margin-left: -1rem;
  padding: 1rem;
  transform: translateX(1rem);
}
.filter-list .control-panel-header .mobile-close-btn .icon {
  height: 16px;
  stroke: #000;
  width: 16px;
}
.filter-list .control-panel-header .mobile-close-btn:hover, .filter-list .control-panel-header .mobile-close-btn:focus {
  outline: 0;
}
.filter-list .control-panel-header .mobile-close-btn:hover .icon, .filter-list .control-panel-header .mobile-close-btn:focus .icon {
  fill: #989898;
}
.filter-list .control-panel-header .icon-arrow-right {
  transform: rotate(180deg);
}
.filter-list .control-panel-header .control-heading {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  align-items: center;
  font-weight: 500;
  color: #000;
  display: flex;
  margin: 0;
}
.filter-list .control-panel-header .control-heading .icon {
  height: 30px;
  margin-right: 17px;
  fill: #000;
  width: 30px;
}
.filter-list .control-panel-header .control-heading .icon-sort-icon {
  height: 22px;
}
.filter-list .control-panel-header .filter-link {
  color: #53b9d1;
}
.filter-list .input-icon-container {
  margin-top: 1rem;
  padding: 0;
  position: relative;
}
.filter-list .input-icon-container input {
  font-size: 1rem;
  line-height: 1.375;
  background-color: #fff;
  border: none;
  height: 60px;
  margin-bottom: 0;
  padding: 1.125rem 2.75rem 1.125rem 20px;
  text-align: left;
  width: 100%;
}
.filter-list .input-icon-container input::placeholder {
  color: #999;
  text-align: left;
}
.filter-list .input-icon-container button,
.filter-list .input-icon-container > .icon {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.filter-list .input-icon-container > .icon {
  pointer-events: none;
}
.filter-list .nav-accordion .accordion-item:last-of-type {
  border-bottom: none;
}
.filter-list .nav-accordion .accordion-item[aria-expanded=true] .accordion-item-title .accordion-item-toggle .label-secondary.fade-out {
  opacity: 0;
}
.filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle {
  color: #989898;
}
.filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:hover, .filter-list .nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle:focus {
  color: #989898;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle > h2 {
  margin: 0;
  color: inherit;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .label-secondary {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-weight: 600;
  color: #666666;
  display: block;
  margin-left: auto;
  margin-right: 0.5rem;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;
}
.filter-list .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .label-secondary .rating-stars {
  margin-right: 0.375rem;
}
.filter-list .nav-accordion .accordion-item .accordion-item-body {
  color: #464646;
}
.filter-list .nav-accordion .accordion-item .accordion-item-body .input-icon-container {
  margin-left: 0;
  width: 100%;
}
.filter-list .nav-accordion .accordion-item .accordion-item-body .input-icon-container input {
  border: 1px solid #888b8d;
  border-radius: 6px;
}
.filter-list .checkbox-filter {
  align-items: center;
  display: flex;
  padding: 1rem;
}
.filter-list .checkbox-filter .checkbox-wrapper {
  margin: 0 1rem 0 0;
}
.filter-list .checkbox-filter .checkbox-wrapper input[type=checkbox] + label {
  color: #fff;
}
.filter-list .checkbox-filter img {
  display: none;
}
.filter-list .checkbox-filter.isMHMG-container {
  display: none;
}
.filter-list .distance-radio {
  display: flex;
}
.filter-list .distance-radio .distance-radio-wrapper {
  flex: 1;
  height: 40px;
  position: relative;
}
.filter-list .distance-radio .distance-radio-wrapper input[type=radio] {
  height: 0;
  width: 0;
}
.filter-list .distance-radio .distance-radio-wrapper input[type=radio]:checked + label {
  background-color: #989898;
  color: #fff;
}
.filter-list .distance-radio .distance-radio-wrapper input[type=radio]:focus + label, .filter-list .distance-radio .distance-radio-wrapper input[type=radio]:hover + label {
  box-shadow: inset 0 0 1px 1px #cccccc;
}
.filter-list .distance-radio .distance-radio-wrapper label {
  font-size: 0.75rem;
  line-height: 1.5;
  font-weight: 700;
  align-items: center;
  background-color: #cccccc;
  color: #989898;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
}
.filter-list .distance-radio .distance-radio-wrapper label:hover {
  cursor: pointer;
}
.filter-list .mobile-filter-show-btn {
  align-items: center;
  background-color: #989898;
  bottom: 0;
  display: flex;
  height: 80px;
  padding: 0 15px;
  position: fixed;
  width: 100%;
}
.filter-list .mobile-filter-show-btn .filter-show-btn {
  background-color: #53b9d1;
  border-color: #53b9d1;
  width: 100%;
}
.filter-list .mobile-filter-show-btn .filter-show-btn:hover {
  background-color: #53b9d1;
  border-color: #53b9d1;
}
.filter-list .mobile-filter-show-btn .filter-show-btn:focus {
  background-color: #53b9d1;
  border-color: #53b9d1;
  box-shadow: 0 0 0 0.2rem rgba(83, 185, 209, 0.5);
}
.filter-list .mobile-filter-show-btn.loading .filter-show-btn {
  color: transparent;
  position: relative;
}
.filter-list .mobile-filter-show-btn.loading .filter-show-btn:after {
  border: 2px solid #fff;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  content: " ";
  display: block;
  pointer-events: none;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  animation: spin 1.4s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.filter-list .mobile-filter-show-btn.loading .filter-show-btn:after {
  left: 50%;
  margin-left: -13px;
  margin-top: -13px;
  position: absolute;
  top: 50%;
}
.filter-list .filter-header {
  display: none;
}
.filter-list .filter-description {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.filter-list .filter-link,
.filter-list .show-more-trigger {
  font-size: 0.875rem;
  line-height: 1;
  font-weight: 600;
  background: none;
  border: 0;
  color: #000;
  margin: 0;
  outline: 0;
  padding: 0;
}
.filter-list .filter-link:hover, .filter-list .filter-link:focus,
.filter-list .show-more-trigger:hover,
.filter-list .show-more-trigger:focus {
  text-decoration: underline;
}
.filter-list .filter-link:disabled,
.filter-list .show-more-trigger:disabled {
  color: #707070;
  pointer-events: none;
}
.filter-list .geolocation-link {
  color: #53b9d1;
  display: inline-flex;
  margin-bottom: 2rem;
  margin-top: -0.5rem;
}
.filter-list .geolocation-link .icon-geolocate {
  margin-right: 0.25rem;
  stroke: #53b9d1;
}
.filter-list .geolocation-link.geolocating {
  text-decoration: none;
}
.filter-list .geolocation-link.geolocating:after {
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "…"; /* ascii code for the ellipsis character */
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
  width: 0px;
}
@keyframes ellipsis {
  to {
    width: 20px;
  }
}
@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
.filter-list .range-container {
  margin: 0 1rem;
  padding-top: 1.5rem;
}
.filter-list .view-all-trigger {
  font-size: 0.875rem;
  line-height: 1;
  align-items: center;
  color: #53b9d1;
  display: flex;
  height: 19px;
  margin-top: 2rem;
  overflow: hidden;
}
.filter-list .view-all-trigger .icon {
  height: 0.75rem;
  margin-left: 5px;
  stroke: #53b9d1;
  transition: margin 0.1s ease-in-out;
  width: 1.25rem;
}
.filter-list .view-all-trigger:hover .icon, .filter-list .view-all-trigger:focus .icon {
  margin-left: 10px;
}
.filter-list .view-all-panel {
  background-color: #fff;
  display: block;
  position: relative;
  transform: translateX(100%);
  transition: all 0.2s ease-in-out;
  width: 100%;
  visibility: hidden;
}
.filter-list .view-all-panel.view-all-open {
  transform: translateX(0);
  visibility: visible;
}
.filter-list .view-all-panel + .filter-title {
  margin-top: 2rem;
}
.filter-list .view-all-panel .view-all-back-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-weight: 600;
  align-items: center;
  color: #53b9d1;
  display: flex;
  margin-left: 1.25rem;
  margin-top: 2rem;
}
.filter-list .view-all-panel .view-all-back-btn .icon {
  stroke: #53b9d1;
}
.filter-list .view-all-panel .view-all-close-btn {
  display: none;
}
.filter-list .view-all-panel .view-all-body {
  margin-bottom: 80px;
}
.filter-list .view-all-panel .view-all-title-container,
.filter-list .view-all-panel .tag-list {
  padding: 0 18px;
}
.filter-list .view-all-panel .range-container {
  margin: 0 2rem;
  padding: 1.5rem 0 0;
}
.filter-list .view-all-panel .accordion-label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-weight: 500;
  color: #000;
  margin: 2rem 1rem 0;
}
.filter-list .view-all-panel .nav-accordion {
  margin-top: 1rem;
}
.filter-list .view-all-panel .object-section {
  display: flex;
  padding: 0.5rem 1rem 0.5rem 0;
}
.filter-list .view-all-panel .object-section .object-section-label {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  margin-left: 1.25rem;
  margin-right: 2rem;
  min-width: 16px;
}
.filter-list .view-all-panel .object-section .object-section-content {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 0.5rem;
  width: 100%;
}
.filter-list .view-all-panel .object-section:last-of-type .object-section-content {
  border-bottom: 0;
}
.filter-list .view-all-panel .view-all-no-accordion {
  border-top: none;
  margin-top: 1rem;
}
.filter-list .filter-availability {
  padding: 0.25rem 0;
}
.filter-list .filter-availability hr {
  border-color: #989898;
  margin: 1.5rem 0;
}
.filter-list .filter-availability .secondary-filter-title {
  font-size: 1.625rem;
  line-height: 1.2307692308;
  font-weight: 600;
  color: #fff;
}
.filter-list .filter-availability p {
  font-size: 1.5rem;
  line-height: 1.3333333333;
}
.filter-list .filter-availability .radio-btns {
  padding-top: 0.25rem;
}
.filter-list .filter-availability .radio-btns input[type=radio]:checked + label {
  background-color: #53b9d1;
  border-color: #53b9d1;
}
.filter-list .filter-availability .radio-btns input[type=radio]:focus + label {
  background-color: #53b9d1;
  border-color: #53b9d1;
  box-shadow: 0 0 0 0.2rem rgba(83, 185, 209, 0.5);
}
.filter-list .filter-availability .radio-btns input[type=radio] + label {
  background-color: #53b9d1;
  border-color: #53b9d1;
}
.filter-list .filter-availability .radio-btns input[type=radio]:disabled + label {
  background-color: #cccccc;
  border-color: #cccccc;
  pointer-events: none;
}
.filter-list .filter-availability .radio-btns input[type=radio]:disabled:checked + label {
  background-color: #cccccc;
  border-color: #cccccc;
}
.filter-list .filter-gender {
  padding: 0.25rem 0;
}
.filter-list .filter-gender .radio-wrapper:last-of-type {
  margin-bottom: 0;
}
.filter-list .clear-btn-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 0.75rem 20px 0;
}
.filter-list .react-autosuggest__container {
  position: relative;
}
.filter-list .react-autosuggest__container--open .react-autosuggest__suggestions-container {
  border-bottom: 1px solid #cccccc;
}
.filter-list .react-autosuggest__suggestions-container {
  background-color: #fff;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 2;
}
.filter-list .react-autosuggest__suggestion--highlighted .typeahead-suggestion {
  background-color: #cccccc;
}
.filter-list .mobile-filter-actions {
  align-items: center;
  background-color: #fff;
  bottom: 0;
  display: flex;
  height: 80px;
  position: fixed;
  width: 100%;
  z-index: 1;
}
.filter-list .mobile-filter-actions .buttons-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .filter-list .mobile-filter-actions .buttons-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .filter-list .mobile-filter-actions .buttons-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .filter-list .mobile-filter-actions .buttons-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.filter-list .mobile-clear-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-weight: 500;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 2px;
  color: #000;
  display: flex;
  height: 37px;
  justify-content: center;
  width: calc(50% - 0.5rem);
}
.filter-list .mobile-apply-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-weight: 500;
  align-items: center;
  background-color: #989898;
  border-radius: 2px;
  color: #fff;
  display: flex;
  height: 37px;
  justify-content: center;
  width: calc(50% - 0.5rem);
}
@media (min-width: 768px) {
  .filter-list .input-icon-container input {
    font-size: 0.875rem;
    line-height: 1.4285714286;
    padding: 1.25rem 2.75rem 1.25rem 1.25rem;
  }
}
@media (min-width: 1280px) {
  .filter-list {
    align-self: flex-start;
    background-color: #cccccc;
    display: block;
    height: auto;
    left: auto;
    opacity: 1;
    overflow: visible;
    pointer-events: auto;
    position: relative;
    top: auto;
    visibility: visible;
    width: auto;
    z-index: 7;
  }
  .filter-list.view-all-open {
    overflow: visible;
  }
  .filter-list .input-icon-container {
    margin: 0;
    padding: 0;
  }
  .filter-list .input-icon-container .react-autosuggest__container {
    border-bottom: 2px solid #f6f3f1;
    margin-bottom: 0;
  }
  .filter-list .input-icon-container input {
    height: auto;
    min-height: 0;
    padding: 14px 2.75rem 1rem 1rem;
    border-radius: 6px 6px 0 0;
  }
  .filter-list .input-icon-container button,
  .filter-list .input-icon-container > .icon {
    right: 1rem;
  }
  .filter-list .accordion-container {
    margin-bottom: 0;
    padding: 0;
  }
  .filter-list .checkbox-filter .checkbox-wrapper input[type=checkbox] + label {
    color: #989898;
  }
  .filter-list .checkbox-filter img {
    display: block;
    height: auto;
    width: 80px;
  }
  .filter-list .filter-title.mobile-only {
    display: none;
  }
  .filter-list .mobile-filter-show-btn {
    display: none;
  }
  .filter-list .control-panel-header {
    display: none;
  }
  .filter-list .filter-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
  .filter-list .filter-header .filter-heading {
    font-size: 1rem;
    line-height: 1.375;
    font-weight: 500;
    color: #989898;
    margin: 0;
    padding-left: 1rem;
  }
  .filter-list .filter-header .clear-btn-container {
    margin-right: 2px;
  }
  .filter-list .clear-btn-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 0.25rem 0 1rem;
  }
  .filter-list .filter-link,
  .filter-list .show-more-trigger {
    color: #53b9d1;
  }
  .filter-list .range-container {
    margin: 0;
  }
  .filter-list .view-all-panel {
    color: #464646;
    display: none;
    height: auto;
    min-height: 0;
    transform: none;
  }
  .filter-list .view-all-panel.view-all-open {
    display: block;
    overflow: visible;
    position: relative;
  }
  .filter-list .view-all-panel .control-panel-header {
    display: none;
  }
  .filter-list .view-all-panel .view-all-title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: 0;
  }
  .filter-list .view-all-panel .view-all-title-container .filter-title {
    margin-bottom: 0;
  }
  .filter-list .view-all-panel .view-all-title-container .view-all-close-btn {
    display: block;
  }
  .filter-list .view-all-panel .view-all-title-container .view-all-close-btn .icon-menu-close {
    height: 1rem;
    stroke: #989898;
    width: 1rem;
  }
  .filter-list .view-all-panel .view-all-title-container .view-all-close-btn:hover .icon-menu-close, .filter-list .view-all-panel .view-all-title-container .view-all-close-btn:focus .icon-menu-close {
    stroke: #000;
  }
  .filter-list .view-all-panel .view-all-body {
    margin-bottom: 0;
    padding: 0;
  }
  .filter-list .view-all-panel .rbt {
    margin: 0;
  }
  .filter-list .view-all-panel .range-container {
    margin: 0;
    padding: 1.5rem 0 0;
  }
  .filter-list .view-all-panel .tag-list {
    padding: 0 0.1rem;
  }
  .filter-list .view-all-panel .accordion-label {
    margin: 2rem 0 0;
  }
  .filter-list .view-all-panel .nav-accordion .object-section {
    padding-right: 1.5rem;
  }
  .filter-list .view-all-panel .view-all-no-accordion {
    border-color: #cccccc;
    padding: 0;
  }
  .filter-list .view-all-panel .view-all-back-btn {
    margin-left: 0;
  }
  .filter-list .view-all-panel .view-all-back-btn:hover, .filter-list .view-all-panel .view-all-back-btn:focus {
    text-decoration: underline;
  }
  .filter-list .view-all-panel .view-all-back-btn:hover .icon, .filter-list .view-all-panel .view-all-back-btn:focus .icon {
    margin-left: -0.3125rem;
    margin-right: 10px;
    transition: margin 0.1s ease-in-out;
  }
  .filter-list .filter-availability hr {
    border-color: rgba(0, 0, 0, 0.1);
  }
  .filter-list .filter-availability .secondary-filter-title {
    color: #989898;
  }
  .filter-list .filter-availability .radio-btns input[type=radio]:checked + label {
    background-color: #989898;
    border-color: #989898;
  }
  .filter-list .filter-availability .radio-btns input[type=radio]:focus + label {
    background-color: #989898;
    border-color: #989898;
    box-shadow: 0 0 0 0.2rem rgba(152, 152, 152, 0.35);
  }
  .filter-list .filter-availability .radio-btns input[type=radio] + label {
    background-color: #989898;
    border-color: #989898;
  }
  .filter-list .filter-gender {
    padding: 0.25rem 0;
  }
  .filter-list .filter-gender .radio-wrapper:last-of-type {
    margin-bottom: 0;
  }
  .filter-list .mobile-filter-actions {
    display: none;
  }
}

.global-footer {
  background-color: #1d1d1d;
}
.container-fluid .global-footer {
  margin-left: -12px;
  width: 100vw;
}
.global-footer a {
  color: #fff;
  font-family: "Tw Cen MT", sans-serif;
}
.global-footer .footer-inner-separator {
  border: 0.1px solid #464646;
}
.global-footer .footer-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-top: 20px;
}
@media (min-width: 768px) {
  .global-footer .footer-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .global-footer .footer-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .global-footer .footer-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.global-footer .footer-logo-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin-top: 30px;
  align-items: center;
}
.global-footer .footer-logo-container a {
  margin-bottom: 11.2px;
}
.global-footer .family-member-link {
  margin-bottom: 0;
  text-align: center;
  width: 100%;
}
.global-footer .practice-logo {
  width: 160px;
  height: 65px;
}
.global-footer .partner-logo {
  width: 103px;
  height: 45px;
}
.global-footer .footer-section-title {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #fff;
  font-family: "Tw Cen MT Bold", sans-serif;
  margin-bottom: 0.5rem;
}
.global-footer .footer-link-list {
  text-align: center;
}
.global-footer .footer-link-list li {
  font-size: 1rem;
  line-height: 1.375;
}
.global-footer .footer-link-list li a {
  display: inline-block;
  padding: 7px 0;
}
.global-footer .footer-link-list li a:hover {
  text-decoration: underline;
}
.global-footer .second-row-container {
  display: flex;
}
.global-footer .copyright-container {
  font-size: 0.6875rem;
  line-height: 1;
  display: flex;
  justify-content: center;
  color: #cccccc;
  font-family: "Arial", sans-serif;
}
.global-footer .copyright-container a {
  color: #cccccc;
  font-family: "Arial", sans-serif;
}
.global-footer .copyright-container a:hover {
  text-decoration: underline;
}
@media (min-width: 360px) {
  .global-footer .footer-container {
    padding-top: 0;
  }
  .global-footer .footer-col .footer-logo-subtitle-container {
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .global-footer .footer-col .footer-logo-subtitle {
    font-size: 12px;
    text-align: center;
  }
  .global-footer .copyright-container {
    margin-top: 30px;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .global-footer .footer-container {
    padding-bottom: 0;
    padding-top: 40px;
  }
  .global-footer .second-row {
    padding-top: 25px;
  }
  .global-footer .footer-inner {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
  }
  .global-footer .footer-logo-container {
    padding-top: 5px;
    margin: 0 0 0.5rem;
  }
  .global-footer .footer-logo-container .footer-logo-subtitle-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .global-footer .partner-logo-container {
    padding-top: 0;
    min-width: 222px;
  }
  .global-footer .footer-link-list {
    columns: 3;
    margin-bottom: 1.5rem;
    text-align: left;
  }
  .global-footer .footer-col.social {
    margin-bottom: 2rem;
  }
  .global-footer .footer-col .logo-subtitle {
    font-size: 1.375rem;
    line-height: 1.4545454545;
    color: #fff;
    margin-top: -5px;
    margin-left: 6px;
    font-size: 12px;
  }
  .global-footer .footer-col .footer-logo-subtitle {
    font-size: 0.75rem;
    line-height: 1.5;
    text-align: center;
  }
  .global-footer .footer-col .copyright-container {
    margin-top: 0;
  }
  .global-footer .social-link:first-child {
    padding-left: 0;
  }
  .global-footer .social-link:last-child {
    padding-right: 0;
  }
  .global-footer .footer-col-second-row {
    flex: 0 1 25%;
    min-width: 25%;
  }
  .global-footer .footer-section-title {
    margin-top: 2rem;
  }
  .global-footer .copyright-container {
    justify-content: flex-end;
  }
}

.global-navigation {
  height: 70px;
  overflow: visible;
  position: relative;
  transition: margin-bottom 0.3s ease-in-out;
  z-index: 7;
}
.global-navigation.no-transition {
  transition: none;
}
.global-navigation.scroll-up .navbar {
  animation: mobile-nav-slide-down 0.3s ease-in-out;
  transform: translateY(0);
  top: 0;
  position: fixed;
}
@keyframes mobile-nav-slide-down {
  from {
    transform: translateY(-70px);
  }
  to {
    transform: translateY(0);
  }
}
.global-navigation.scroll-up.scroll-down .navbar {
  transform: translateY(-70px);
  pointer-events: none;
  position: fixed;
}
.global-navigation.navbar-fixed.no-transition .navbar {
  transition: none;
}
.global-navigation.navbar-fixed .navbar {
  transform: translateY(-70px);
  position: fixed;
  top: 0;
  transition: none;
}
.global-navigation.navbar-fixed.scroll-up .navbar {
  transform: translateY(0);
  transition: transform 0.3s ease-in-out, margin-top 0.3s ease-in-out;
}
.global-navigation.navbar-fixed.scroll-up.scroll-down .navbar {
  transform: translateY(-70px);
  pointer-events: none;
}
.global-navigation.mobile-nav-open {
  margin-bottom: calc(100vh + 2rem);
}
.global-navigation.mobile-nav-open .navbar {
  margin-top: 100vh !important;
}
.global-navigation.mobile-nav-open .mobile-nav {
  display: block;
}
.global-navigation .nav-inner {
  width: 100%;
}
.global-navigation .navbar {
  background-color: #fff;
  box-shadow: 0px 3px 8px 0px rgba(85, 85, 85, 0.35);
  display: flex;
  height: 70px;
  padding: 0;
  transition: margin-top 0.3s ease-in-out;
  z-index: 9;
  width: 100%;
}
.global-navigation .nav-top {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
}
.global-navigation .nav-top .text-link {
  font-family: "Tw Cen MT", sans-serif;
}
.global-navigation .corporate-logo {
  width: 106px;
  height: auto;
}
.global-navigation .practice-logo {
  width: 140px;
  height: auto;
}
.global-navigation .family-member-link {
  display: none;
}
.global-navigation .nav-bottom {
  display: none;
}
.global-navigation .utility-nav {
  display: none;
}
.global-navigation .hovered {
  background-color: #eeeeee;
}
.global-navigation .hovered:hover {
  background-color: #eeeeee;
}
.global-navigation .mobile-nav {
  display: none;
  width: 100vw;
  height: 100svh;
  background-color: #eeeeee;
  margin-top: -100vh;
  overflow: hidden;
  position: absolute;
  top: 0;
}
.global-navigation .mobile-nav:before {
  background-color: #eeeeee;
  content: " ";
  height: 100svh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.global-navigation .mobile-nav.visible {
  display: block;
}
.global-navigation .mobile-nav .nav-top {
  background-color: #fff;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
  padding-right: 20px;
}
.global-navigation .mobile-nav .input-icon-container {
  align-items: center;
  position: relative;
  background-color: #eeeeee;
  border-bottom: 1px solid #fff;
  display: flex;
  height: 50px;
  justify-content: flex-end;
  padding: 15px;
  width: 100%;
}
.global-navigation .mobile-nav .nav-link {
  font-size: 1.25rem;
  line-height: 1.1;
  border-bottom: 1px solid #fff;
  color: #000;
  display: flex;
  font-family: "Tw Cen MT Bold", sans-serif;
  padding: 14px 30px;
  width: 100%;
}
.global-navigation .mobile-nav .nav-link .icon {
  margin-left: auto;
}
.global-navigation .mobile-nav .nav-link.has-sub-nav svg {
  fill: #9bc158;
}
.global-navigation .mobile-nav .nav-link.view-all-link {
  color: #0c5688;
}
.global-navigation .mobile-nav-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}
.global-navigation .mobile-nav-container .nav-logo {
  font-size: 3.375rem;
  line-height: 1;
  margin-bottom: 4rem;
}
.global-navigation .mobile-nav-container .nav-logo .logo-subtitle {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  margin-left: 10px;
  margin-top: -5px;
}
.global-navigation .mobile-nav-list-title {
  font-size: 1.25rem;
  line-height: 1.1;
  background-color: #fff;
  border-bottom: 1px solid #fff;
  color: #000;
  display: block;
  font-family: "Tw Cen MT Bold", sans-serif;
  padding: 14px 20px;
}
.global-navigation .mobile-sub-nav {
  display: none;
  width: 100%;
  height: 100vh;
  background-color: #eeeeee;
  flex-direction: column;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.global-navigation .mobile-sub-nav .nav-link {
  font-size: 1rem;
  line-height: 1.125;
  font-weight: 400;
  padding: 14px 20px;
}
.global-navigation .sub-nav-header {
  align-items: center;
  background-color: #fff;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  min-height: 70px;
  justify-content: space-between;
  padding: 20px;
  z-index: 1;
}
.global-navigation .sub-nav-header .icon {
  align-items: center;
  display: flex;
  height: 25px;
  justify-content: center;
  width: 25px;
}
.global-navigation .sub-nav-header .icon.icon-chevron-right {
  transform: rotate(180deg);
  fill: #000;
}
.global-navigation .mobile-utility-nav {
  margin-top: auto;
}
.global-navigation .mobile-utility-nav .utility-link-list {
  display: flex;
}
.global-navigation .mobile-utility-nav .utility-link-list li {
  flex-grow: 1;
  margin-right: 2px;
}
.global-navigation .mobile-utility-nav .utility-link-list li:last-of-type {
  margin-right: 0;
}
.global-navigation .mobile-utility-nav .utility-link-list li .utility-link {
  font-size: 1rem;
  line-height: 1;
  align-items: center;
  background-color: #fff;
  color: #000;
  display: flex;
  height: 50px;
  justify-content: center;
  padding: 8px;
  width: 100%;
}
.global-navigation .mobile-utility-nav .mobile-schedule-button {
  font-size: 1.125rem;
  line-height: 1.2222222222;
  align-items: center;
  background-color: #0c5688;
  border-radius: 0;
  color: #fff;
  display: flex;
  font-weight: 400;
  height: 50px;
  justify-content: center;
  width: 100%;
}
.global-navigation .nav-close .icon {
  align-items: center;
  display: flex;
  height: 25px;
  justify-content: center;
  width: 25px;
}
.global-navigation .sub-nav-title {
  font-size: 1.25rem;
  line-height: 1.1;
  color: #000;
  font-family: "Tw Cen MT Bold", sans-serif;
}
.global-navigation .nav-list-item.active.closing .mobile-sub-nav {
  transform: translateX(100%);
  transition: transform 0.2s ease-in-out;
}
.global-navigation .nav-list-item.active .mobile-sub-nav {
  animation: slideIn 0.2s ease-in-out;
  display: flex;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
.global-navigation .nav-open,
.global-navigation .nav-close {
  cursor: pointer;
}
.global-navigation .nav-open .icon {
  height: 30px;
  fill: #fff;
  width: 30px;
}
@media (min-width: 1280px) {
  .global-navigation {
    height: 150px;
  }
  .global-navigation + .hero,
  .global-navigation + .blog-post-hero {
    margin-top: -150px;
  }
  .global-navigation.navbar-fixed {
    height: 100px;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .global-navigation.navbar-fixed + .hero,
  .global-navigation.navbar-fixed + .blog-post-hero {
    margin-top: 0;
  }
  .global-navigation.navbar-fixed + [class^=layout-] {
    margin-top: 150px;
  }
  .global-navigation.navbar-fixed .navbar {
    transform: translateY(0);
  }
  .global-navigation.navbar-fixed .nav-top {
    padding-bottom: 9px;
  }
  .global-navigation.navbar-fixed .corporate-logo {
    width: 79px;
    height: 35px;
  }
  .global-navigation.navbar-fixed .practice-logo {
    height: 35px;
    width: 120px;
  }
  .global-navigation.navbar-fixed .utility-link-list {
    display: none;
  }
  .global-navigation button {
    color: #2178bc;
  }
  .global-navigation button.schedule-button {
    color: #fff;
  }
  .global-navigation .navbar {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: none;
    height: auto;
  }
  .global-navigation .nav-top {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 9px;
    width: 100%;
  }
}
@media (min-width: 1280px) and (min-width: 768px) {
  .global-navigation .nav-top {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) and (min-width: 1280px) {
  .global-navigation .nav-top {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) and (min-width: 1440px) {
  .global-navigation .nav-top {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) {
  .global-navigation .corporate-logo {
    width: 127px;
    height: auto;
  }
  .global-navigation .practice-logo {
    width: 188px;
    height: auto;
  }
  .global-navigation .nav-open {
    display: none;
  }
  .global-navigation .utility-nav-container {
    display: flex;
    justify-content: space-between;
  }
  .global-navigation .utility-link-location {
    margin-left: 10px;
  }
  .global-navigation .family-member-link {
    display: block;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }
  .global-navigation .utility-nav {
    align-items: flex-end;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
  }
  .global-navigation .utility-link-list {
    display: flex;
    margin-bottom: 26px;
  }
  .global-navigation .utility-link-list:first-child {
    margin-left: 30px;
  }
  .global-navigation .utility-link-list .text-link {
    color: #000;
    margin-right: 30px;
  }
  .global-navigation .nav-bottom {
    align-items: center;
    background-color: #fff;
    display: flex;
  }
  .global-navigation .nav-list-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1440px;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    padding-left: 40px !important;
    padding-right: 40px !important;
    width: 100vw;
  }
}
@media (min-width: 1280px) and (min-width: 768px) {
  .global-navigation .nav-list-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) and (min-width: 1280px) {
  .global-navigation .nav-list-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) and (min-width: 1440px) {
  .global-navigation .nav-list-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1280px) {
  .global-navigation .nav-list {
    display: flex;
    justify-content: space-between;
  }
  .global-navigation .nav-list-item {
    flex-grow: 1;
  }
  .global-navigation .nav-list-item:last-child {
    position: relative;
  }
  .global-navigation .nav-list-item:last-child .sub-nav-menu {
    right: 0;
  }
  .global-navigation .nav-list-item.active {
    background-color: #eeeeee;
  }
  .global-navigation .nav-list-item.active .icon-chevron-up {
    display: inline-block;
  }
  .global-navigation .nav-list-item.active .icon-chevron-down {
    display: none;
  }
  .global-navigation .nav-list-item.active .sub-nav-menu,
  .global-navigation .nav-list-item.active .sub-nav-menu-small {
    display: block;
  }
  .global-navigation .nav-link {
    font-size: 0.875rem;
    line-height: 1;
    align-items: center;
    color: #1d1d1d;
    cursor: pointer;
    display: inline-block;
    font-family: "Tw Cen MT Bold", sans-serif;
    padding: 14px 15px;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
  }
  .global-navigation .nav-link:hover {
    background-color: #eeeeee;
  }
  .global-navigation .nav-link .icon {
    height: 7.41px;
    margin-left: 5px;
    width: 12px;
  }
  .global-navigation .nav-link .icon use {
    fill: #9bc158;
  }
  .global-navigation .nav-link .icon-chevron-up {
    display: none;
  }
  .global-navigation .sub-nav-menu {
    background-color: #eeeeee;
    display: none;
    left: 0;
    padding: 30px 0 60px;
    position: absolute;
    width: 100vw;
  }
  .global-navigation .sub-nav-menu.fit-to-content {
    left: auto;
    min-width: 275px;
    padding: 30px 24px 24px;
    width: auto;
  }
  .global-navigation .sub-nav-menu .link-list-title {
    font-size: 1.375rem;
    line-height: 1.4545454545;
    margin-bottom: 18px;
  }
  .global-navigation .sub-nav-menu .link-list {
    margin-bottom: 0;
  }
  .global-navigation .sub-nav-menu .link-list-item {
    padding-bottom: 0;
  }
  .global-navigation .sub-nav-menu .text-link {
    font-size: 0.9375rem;
    line-height: 1;
    color: #000;
    display: inline-block;
    font-family: "Arial", sans-serif;
    padding: 7px 0 8px;
  }
  .global-navigation .sub-nav-menu .view-all-link {
    color: #0c5688;
    padding: 20px 0 0;
  }
  .global-navigation .sub-nav-menu .view-all-link .icon {
    margin-bottom: -1px;
    height: 15px;
    margin-left: 5px;
    width: 7px;
  }
}

.home-finder {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 0 15px;
  margin: auto;
  margin-top: -60px;
  margin-bottom: 3.125rem;
  gap: 20px;
}
.home-finder .finder-component {
  background-color: #fff;
  border-radius: 6px;
  height: auto;
  box-shadow: 1px 3px 3px rgba(85, 85, 85, 0.35);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
}
.home-finder .left-block {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
}
.home-finder .find-component {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 350px;
  padding: 14px 20px 20px;
}
.home-finder .find-component span {
  text-align: center;
  font-size: 0.875rem;
  margin-bottom: 0.4375rem;
}
.home-finder .find-component .schedules {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;
}
.home-finder .find-component .schedules .schedule-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.home-finder .find-component .schedules .schedule-row .schedule-separator {
  text-align: center;
  color: #888b8d;
}
.home-finder .find-component .schedules .schedule-row .schedule-item {
  font-size: 1rem;
  line-height: 1;
}
.home-finder .find-component .schedules .schedule-row .schedule-item .day-schedule {
  text-transform: uppercase;
}
.home-finder .icon-container {
  display: flex;
  margin-bottom: 0.5rem;
  min-height: 2.125rem;
  justify-content: center;
  align-items: flex-end;
}
.home-finder .icon-find-provider {
  width: 2.125rem;
  height: 2.125rem;
}
.home-finder .icon-find-location,
.home-finder .icon-clock {
  width: 1.431rem;
  height: 1.875rem;
}
.home-finder .icon-quick-links {
  width: 2.391rem;
  height: 1.6875rem;
}
.home-finder .horizontal-separaton {
  border-bottom: 1px solid #c9cbcb;
  width: 90%;
  margin: auto;
}
.home-finder .button {
  line-height: 1;
  padding: 9px 20px;
}
.home-finder .find-component-full-width {
  width: 100%;
}
@media (min-width: 414px) {
  .home-finder {
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
  }
}
@media (min-width: 768px) {
  .home-finder {
    margin-left: -40px;
    margin-right: -40px;
    margin-top: -70px;
    display: flex;
    gap: 20px;
    flex-direction: row;
    width: calc(100% + 80px);
  }
  .home-finder .finder-component {
    max-width: none;
  }
  .home-finder .left-block {
    flex-basis: 66.6666666667%;
    margin-bottom: 0;
  }
  .home-finder .right-block {
    flex-basis: 33.3333333333%;
  }
  .home-finder .find-component-full-width {
    width: 100%;
  }
  .home-finder .left-block {
    flex-direction: row;
  }
  .home-finder .find .find-component {
    width: auto;
    flex-grow: 1;
  }
  .home-finder .horizontal-separaton {
    border-bottom: none;
    border-right: 1px solid #c9cbcb;
    width: 1px;
    flex-basis: 1px;
    height: 95px;
    margin: auto;
  }
}
@media (min-width: 1280px) {
  .home-finder {
    margin-left: -70px;
    margin-right: -70px;
    margin-top: -90px;
    width: 1100px;
  }
}
@media (min-width: 1440px) {
  .home-finder {
    margin-left: -70px;
  }
}

.horizontal-videos-container .videos-wrap {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 360px) {
  .horizontal-videos-container .videos-wrap {
    width: 100%;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .horizontal-videos-container .videos-wrap {
    gap: 24px;
    flex-direction: row;
  }
}

.image-viewer .gallery-item {
  min-width: 200px;
}

form .form-field {
  margin-bottom: 1.25rem;
}
form .control-label {
  font-size: 0.875rem;
  line-height: 1;
  color: #000;
  display: block;
  font-family: "Tw Cen MT Bold", sans-serif;
  margin-bottom: 0.5625rem;
  text-transform: uppercase;
}
form .editing-form-control-nested-control {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  position: relative;
  width: 100%;
}
form .editing-form-control-nested-control select {
  cursor: pointer;
  padding-left: 1.25rem;
  padding-right: 2rem;
}
form .form-control {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Arial", sans-serif;
  padding: 8px 11.5px;
  border: none;
  border-radius: 6px;
  width: 100%;
}
form .form-control[type=file] {
  padding-left: 0.125rem;
}
form .form-control:focus, form .form-control:focus-visible {
  outline-color: #0c5688;
}
form .form-control[type=checkbox], form .form-control[type=radio] {
  cursor: pointer;
  height: 1rem;
  margin: 0.125rem 0 0;
  min-width: 1rem;
  width: 1rem;
}
form .ktc-uploader-text {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Arial", sans-serif;
}
form .ktc-checkbox {
  display: flex;
  gap: 0.75rem;
}
form .ktc-checkbox label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  cursor: pointer;
  font-family: "Arial", sans-serif;
}
form .ktc-checkbox-list .ktc-checkbox {
  margin-bottom: 0.5rem;
}
form .ktc-radio-list .ktc-radio {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.5rem;
}
form .ktc-radio-list .ktc-radio label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  cursor: pointer;
  font-family: "Arial", sans-serif;
}
form .explanation-text {
  font-size: 0.625rem;
  line-height: 1;
  display: flex;
  color: #888b8d;
  font-family: "Open Sans", sans-serif;
  margin-top: 8px;
}
form .field-validation-error {
  font-size: 0.625rem;
  line-height: 1;
  display: flex;
  color: #e52b24;
  font-family: "Open Sans", sans-serif;
}
form input[type=submit] {
  font-size: 1rem;
  line-height: 1;
  border-radius: 6px;
  border: none;
  background: #0c5688;
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  display: flex;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s;
}
form input[type=submit]:hover {
  filter: brightness(0.9);
}
form input[type=submit]:active {
  filter: brightness(1.1);
}

.location-listing-group {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
@media (min-width: 768px) {
  .location-listing-group {
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
@media (min-width: 1140px) {
  .location-listing-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 26px;
  }
}

.location-profile-providers {
  width: 100%;
}
.location-profile-providers .cards-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: space-between;
}
.location-profile-providers .provider-profile-card {
  display: flex;
  width: 22rem;
  height: 6.75rem;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  padding: 12px;
}
.location-profile-providers .provider-profile-info {
  margin-left: 12px;
}
.location-profile-providers .provider-profile-name {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  color: #0c5688;
  font-family: "Arial", sans-serif;
  font-weight: 700;
}
.location-profile-providers .provider-profile-name:hover {
  text-decoration: underline;
}
.location-profile-providers .accepting-new-patients-label {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  color: #000;
  font-weight: 400;
}
.location-profile-providers .accepting-new-patients-wrap {
  margin-top: -5px;
}
.location-profile-providers .ratings {
  margin-top: 5px;
}
.location-profile-providers .view-all {
  display: block;
  width: 100%;
  text-align: center;
}
@media (min-width: 360px) {
  .location-profile-providers .provider-profile-card {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .location-profile-providers .cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1280px) {
  .location-profile-providers .cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.location-profile .location-img-outer {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 50%;
  position: relative;
  width: 100%;
}
.location-profile .profile-image-bg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.location-profile .profile-image-bg img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
  width: 100%;
}
.location-profile .location-img {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.location-profile .location-img img,
.location-profile .location-img source,
.location-profile .location-img canvas {
  height: 100%;
  object-fit: cover;
  object-position: center 46%;
  width: 100%;
}
.location-profile .location-about .location-img-outer {
  display: none;
}
.location-profile .specialty-name,
.location-profile .compliance-info,
.location-profile .subtitle {
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.location-profile .locations-actions {
  display: none;
}
.location-profile .location-section {
  background-color: #c7e6a1;
  color: #000;
  min-height: 90px;
}
.location-profile .location-section span:nth-child(2) {
  font-size: 1rem;
  padding: 0.25em 0;
}
.location-profile .location-section:hover {
  background-color: #eefafe;
}
.location-profile .location-result {
  height: 100%;
  margin-bottom: 1rem;
}
.location-profile .profile-content [data-status] {
  background-color: #fff;
  width: 100%;
}
.location-profile .share-location {
  background-color: #c7e6a1;
  min-height: 90px;
}
.location-profile .share-location:hover {
  background-color: #eefafe;
}
.location-profile .location-section {
  background-color: #c7e6a1;
  color: #000;
  min-height: 90px;
}
.location-profile .inner-wrap {
  padding: 35px 25px;
}
.location-profile .location-result {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: #fff;
  color: #181922;
  margin-bottom: 6.25rem;
  padding: 0;
}
.location-profile .location-result .location-details {
  padding: 1.25rem 1rem 1.25rem 1.25rem;
  position: relative;
}
.location-profile .location-result .location-name {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #181922;
  display: block;
  font-weight: 500;
  padding-bottom: 0.5rem;
}
.location-profile .location-result .location-longname {
  font-size: 1.125rem;
  line-height: 1.2222222222;
  font-weight: 500;
  padding-bottom: 0.5rem;
}
.location-profile .location-result .location-type {
  font-size: 0.75rem;
  line-height: 1.3333333333;
  color: #4e8209;
  display: block;
  font-weight: 500;
  letter-spacing: 1px;
  padding-bottom: 0.75em;
  text-transform: uppercase;
}
.location-profile .location-result .healthcare-location-longname {
  font-weight: 400;
  padding-bottom: 0.5em;
}
.location-profile .location-result .address-locality {
  display: flex;
  padding-bottom: 0.75em;
}
.location-profile .location-result .address-locality span {
  padding-left: 0.25em;
}
.location-profile .location-result .new-patients {
  font-size: 1rem;
  line-height: 1.25;
  color: #4e8209;
  padding-bottom: 1rem;
}
.location-profile .location-result .location-scheduling {
  margin-top: auto;
}
.location-profile .location-result .location {
  font-size: 1rem;
  line-height: 1.625;
  background-color: #f0ebe6;
  min-height: 130px;
  padding: 1.25rem;
  position: relative;
}
.location-profile .location-result .hours {
  font-size: 1rem;
  line-height: 1.25;
  color: #4e8209;
}
.location-profile .location-result span {
  display: block;
}
.location-profile .location-result caption {
  font-size: 0.75rem;
  line-height: 1.5;
  text-align: left;
  text-transform: uppercase;
}
.location-profile .location-result table {
  border-spacing: 0;
  padding-bottom: 1rem;
}
.location-profile .location-result td {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  padding-left: 0;
}
.location-profile .location-result td:nth-child(2) {
  padding-left: 1rem;
}
.location-profile .location-desktop {
  display: none;
}
.location-profile .service-columns {
  columns: 1;
}
.location-profile .service-columns li {
  color: #4e8209;
  display: block;
}
@media (min-width: 768px) {
  .location-profile .location-desktop {
    display: block;
    position: sticky;
    top: 64px;
  }
  .location-profile .location-introduction {
    display: none;
  }
  .location-profile .location-about .location-img-outer {
    display: block;
  }
  .location-profile .overview-link,
  .location-profile .location-result {
    flex-basis: 240px;
    margin-right: 15px;
    min-width: 240px;
  }
  .location-profile .service-columns {
    columns: 2;
  }
}

.map-image {
  display: flex;
  flex-direction: row;
}
.map-image .map {
  height: 300px;
  overflow: hidden;
  width: 50%;
}
.map-image .image {
  height: 300px;
  width: 50%;
}
.map-image .image img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media (max-width: 768px) {
  .map-image {
    flex-direction: column;
  }
  .map-image .image,
  .map-image .map {
    width: 100%;
  }
}

.meet-the-providers {
  width: 100%;
}
.meet-the-providers .cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5625rem;
  justify-content: space-between;
}
.meet-the-providers .medium-provider-card {
  flex-grow: 1;
}
.meet-the-providers .provider-profile-name {
  font-size: 1rem;
  line-height: 1.375;
  color: #0c5688;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}
.meet-the-providers .provider-profile-name:hover {
  text-decoration: underline;
}
.meet-the-providers .provider-profile-info {
  margin-left: 12px;
}

.modal {
  background-color: rgba(24, 25, 34, 0.5);
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  outline: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  z-index: 10;
}
.modal.visible {
  display: block;
  z-index: 999999;
}
.modal.open {
  opacity: 1;
}
.modal .modal-container {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
  max-height: 100vh;
}
.modal .modal-inner-container {
  overflow: auto;
  border-radius: 6px;
  max-height: 100vh;
  width: 100%;
}
.modal .modal-body {
  flex-grow: 1;
  overflow-y: auto;
}
.modal .modal-inner {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  border-radius: 6px;
}
.modal .modal-inner .modal-content {
  position: relative;
  background: none;
}
.modal .modal-inner .modal-content .modal-close-btn {
  align-items: center;
  background-color: none;
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 1.75rem;
  height: 1.75rem;
}
.modal .modal-inner .modal-content .modal-close-btn:hover, .modal .modal-inner .modal-content .modal-close-btn:focus {
  background-color: #eefafe;
}
.modal .modal-inner .modal-content .modal-close-btn.text-close {
  color: #53b9d1;
  height: auto;
  transform: none;
  width: auto;
}
.modal .modal-inner .modal-content .modal-close-btn.text-close:hover, .modal .modal-inner .modal-content .modal-close-btn.text-close:focus {
  text-decoration: underline;
}
.modal .modal-inner .modal-content .modal-close-btn .icon {
  height: 0.875rem;
  stroke: #000;
  width: 0.875rem;
}
@media (min-width: 768px) {
  .modal.visible {
    align-items: center;
    background-color: rgba(24, 25, 34, 0.5);
    display: flex;
  }
  .modal .modal-inner-container {
    background-color: #fff;
    max-height: calc(100vh - 2rem);
    max-width: calc(100vw - 4rem);
    width: auto;
  }
  .modal .modal-body {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
}

.modal-call-to-schedule {
  width: 100%;
  background-color: #f6f3f1;
  padding: 20px;
  border-radius: 6px;
}
.modal-call-to-schedule .modal-title {
  margin: 0px 0px 13px;
}
.modal-call-to-schedule .locations-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.modal-call-to-schedule .locations-container .location {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 11px;
  width: 100%;
  max-width: 300px;
  background: #fff;
  padding: 17px;
  border-radius: 6px;
}
.modal-call-to-schedule .locations-container .location .location-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 11px;
}
.modal-call-to-schedule .locations-container .location .location-info img {
  width: 296px;
  height: 167px;
  object-fit: cover;
  object-position: center;
}
.modal-call-to-schedule .locations-container .location .location-info .location-microdata .text-link {
  font-size: 1.125rem;
  line-height: 1.1111111111;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 0.25rem;
}
.modal-call-to-schedule .locations-container .location .location-info .location-microdata .street-address {
  display: inline;
}
.modal-call-to-schedule .locations-container .location .location-info .location-miles {
  font-size: 1rem;
  line-height: 1.25;
  color: #0c5688;
  font-family: "Arial", sans-serif;
  margin: 0;
}
@media (min-width: 768px) {
  .modal-call-to-schedule .locations-container {
    flex-direction: row;
    align-items: stretch;
  }
}

.nav-accordion {
  background-color: #eeeeee;
  overflow: visible;
  position: relative;
}
.nav-accordion.filter-accordion > .accordion-item[aria-expanded=true].closing > .accordion-item-body {
  transform: translateX(100%);
}
.nav-accordion.filter-accordion > .accordion-item[aria-expanded=true] > .accordion-item-title .accordion-item-toggle .icon-chevron-down {
  transform: rotate(-90deg) translateX(50%);
}
.nav-accordion.filter-accordion > .accordion-item[aria-expanded=true] > .accordion-item-body {
  transform: translateX(0);
  z-index: 2;
  overflow-x: hidden;
  visibility: visible;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.nav-accordion.filter-accordion > .accordion-item[aria-expanded=true] > .accordion-item-body::-webkit-scrollbar {
  display: none;
}
.nav-accordion.filter-accordion > .accordion-item.opening > .accordion-item-body {
  transition: all 0.2s ease-in-out;
}
.nav-accordion.filter-accordion > .accordion-item > .accordion-item-body {
  background-color: #fff;
  display: block;
  left: 0;
  min-height: 100vh;
  position: fixed;
  top: 0;
  transform: translateX(100%);
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  width: 100%;
}
.nav-accordion.filter-accordion > .accordion-item > .accordion-item-body > .accordion-item-body-inner {
  padding: 1rem 20px 6rem;
}
.filter-list .nav-accordion.filter-accordion > .accordion-item > .accordion-item-body {
  min-height: calc(100vh - 80px);
  height: calc(100vh - 80px);
}
.nav-accordion.filter-accordion > .accordion-item > .accordion-item-title .accordion-item-toggle .icon-chevron-down {
  transform: rotate(-90deg) translateX(50%);
}
.nav-accordion.flush .accordion-item > .accordion-item-title > .accordion-item-toggle .icon {
  display: none;
}
.nav-accordion.flush .accordion-item.opening > .accordion-item-body {
  overflow: hidden;
  transition: height 0.4s ease-in-out;
}
.nav-accordion.flush .accordion-item[aria-expanded=true] > .accordion-item-body {
  display: block;
  height: auto;
}
.nav-accordion.flush .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle .icon-chevron-down {
  transform: translateY(-50%);
}
.nav-accordion.flush .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-toggle .label-secondary {
  opacity: 1;
}
.nav-accordion.flush .accordion-item[aria-expanded=true].closing > .accordion-item-title .accordion-item-tags {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}
.nav-accordion.flush .accordion-item[aria-expanded=true].closing > .accordion-item-body {
  overflow: hidden;
  transition: height 0.4s ease-in-out;
}
.nav-accordion .nav-accordion {
  background-color: #fff;
  border-radius: 0;
}
.nav-accordion .nav-accordion.filter-accordion .accordion-item .accordion-item-title .accordion-item-toggle {
  padding-left: 1rem;
}
.nav-accordion .nav-accordion.filter-accordion .accordion-item .accordion-item-title .accordion-item-toggle .icon-chevron-down {
  right: 1rem;
  transform: translateY(-50%);
}
.nav-accordion .nav-accordion.filter-accordion .accordion-item .accordion-item-body {
  display: none;
  height: 0;
  min-height: 0;
  position: relative;
  transition: all 0.4s ease-in-out;
  transform: none;
}
.filter-list .nav-accordion .nav-accordion.filter-accordion .accordion-item .accordion-item-body {
  min-height: 0;
}
[class^=translated-] .nav-accordion .nav-accordion.filter-accordion .accordion-item .accordion-item-body {
  min-height: 0;
  top: 0;
}
.nav-accordion .nav-accordion.filter-accordion .accordion-item .accordion-item-body .accordion-item-body-inner {
  padding: 0 1rem 1rem;
}
.nav-accordion .nav-accordion.flush > .accordion-item > .accordion-item-title > .accordion-item-toggle,
.nav-accordion .nav-accordion.flush > .accordion-item > .accordion-item-body > .accordion-item-body-inner {
  padding-left: 0;
  padding-right: 0;
}
.nav-accordion .nav-accordion .accordion-item {
  border-bottom: 2px solid #f6f3f1;
}
.nav-accordion .accordion-item {
  border-bottom: 2px solid #fff;
  padding: 0;
}
.nav-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-body {
  transform: none;
  overflow: hidden;
}
.nav-accordion .accordion-item[aria-expanded=true] > .accordion-item-title .accordion-item-toggle .icon-chevron-down {
  transform: rotate(180deg) translateY(50%);
}
.nav-accordion .accordion-item[aria-expanded=true] > .accordion-item-body {
  display: block;
  height: auto;
  overflow-x: unset;
}
.nav-accordion .accordion-item.opening > .accordion-item-body {
  overflow: hidden;
  transition: height 0.4s ease-in-out;
}
.nav-accordion .accordion-item > .accordion-item-title .accordion-item-toggle {
  padding-left: 1rem;
}
.nav-accordion .accordion-item > .accordion-item-title .accordion-item-toggle .icon-chevron-down {
  right: 1rem;
  transform: translateY(-50%);
}
.nav-accordion .accordion-item .accordion-item-body {
  display: none;
  height: 0;
  min-height: 0;
  position: relative;
  transition: all 0.4s ease-in-out;
  transform: none;
}
.filter-list .nav-accordion .accordion-item .accordion-item-body {
  min-height: 0;
}
.nav-accordion .accordion-item .accordion-item-body .accordion-item-body-inner {
  padding: 0 1rem 1rem;
}
.nav-accordion .accordion-item .accordion-item-title {
  position: relative;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle {
  font-size: 1.625rem;
  line-height: 1.2307692308;
  background: none;
  border: none;
  color: #000;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 14px 2.5rem 1rem 20px;
  position: relative;
  text-align: left;
  width: 100%;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle:hover {
  color: #9bc158;
  cursor: pointer;
  outline: 0;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .accordion-item-label {
  font-size: 1rem;
  line-height: 1.25;
  font-family: "Tw Cen MT Bold", sans-serif;
  white-space: nowrap;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .label-secondary {
  color: #cdcdcd;
  overflow: hidden;
  padding-left: 0.5rem;
  text-overflow: ellipsis;
  transition: opacity 0.4s ease-in-out;
  white-space: nowrap;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .icon-chevron-down {
  fill: #9bc158;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-tags {
  background-color: #fff;
  transition: opacity 0.2s ease-in-out;
  z-index: 1;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-tags .tag-list {
  padding: 0 1rem 0.5rem;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-tags .tag-list:last-of-type {
  padding-bottom: 1rem;
}
.nav-accordion .accordion-item .accordion-item-title .accordion-item-tags .tag-list .tag:first-of-type {
  padding-top: 0;
}
.nav-accordion .accordion-item .accordion-item-body {
  display: none;
  height: 0;
}
@media (min-width: 1280px) {
  .nav-accordion {
    background-color: #fff;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .nav-accordion.filter-accordion .accordion-item[aria-expanded=true].closing > .accordion-item-body {
    transform: none;
    overflow: hidden;
  }
  .nav-accordion.filter-accordion .accordion-item[aria-expanded=true] > .accordion-item-title .accordion-item-toggle .icon-chevron-down {
    transform: rotate(180deg) translateY(50%);
  }
  .nav-accordion.filter-accordion .accordion-item[aria-expanded=true] > .accordion-item-body {
    display: block;
    height: auto;
    overflow-x: unset;
  }
  .nav-accordion.filter-accordion .accordion-item.opening > .accordion-item-body {
    overflow: hidden;
    transition: height 0.4s ease-in-out;
  }
  .nav-accordion.filter-accordion .accordion-item .accordion-item-title .accordion-item-toggle {
    padding-left: 1rem;
  }
  .nav-accordion.filter-accordion .accordion-item .accordion-item-title .accordion-item-toggle .icon-chevron-down {
    right: 1rem;
    transform: translateY(-50%);
  }
  .nav-accordion.filter-accordion .accordion-item .accordion-item-body {
    display: none;
    height: 0;
    min-height: 0;
    position: relative;
    transition: all 0.4s ease-in-out;
    transform: none;
  }
  .filter-list .nav-accordion.filter-accordion .accordion-item .accordion-item-body {
    min-height: 0;
  }
  [class^=translated-] .nav-accordion.filter-accordion .accordion-item .accordion-item-body {
    min-height: 0;
    top: 0;
  }
  .nav-accordion.filter-accordion .accordion-item .accordion-item-body .accordion-item-body-inner {
    padding: 0 1rem 1rem;
  }
  .nav-accordion.filter-accordion[data-accordion-opened=true] > .accordion-item[aria-expanded=false] {
    display: block;
  }
  .nav-accordion.flush > .accordion-item > .accordion-item-title > .accordion-item-toggle,
  .nav-accordion.flush > .accordion-item > .accordion-item-body > .accordion-item-body-inner {
    padding-left: 0;
    padding-right: 0;
  }
  .nav-accordion .accordion-item {
    border-bottom: 2px solid #f6f3f1;
  }
  .nav-accordion .accordion-item .accordion-item-title .accordion-item-toggle .accordion-item-label {
    font-size: 1rem;
    line-height: 1.25;
    font-family: "Tw Cen MT", sans-serif;
  }
}

.profile-actions {
  display: none;
  position: sticky;
  top: 64px;
}
.profile-actions .clipboard-notification {
  opacity: 0;
  transition: opacity 0.4s;
}
.profile-actions .clipboard-notification.active {
  opacity: 1;
}
@media (min-width: 1280px) {
  .profile-actions {
    border-radius: 15px;
    display: block;
    flex-basis: 195px;
    height: 100%;
    margin-bottom: 1rem;
    margin-left: 15px;
    min-width: 195px;
    overflow: hidden;
  }
  .profile-actions.is-expanded .action-btn {
    background-color: #fff;
  }
  .profile-actions.is-expanded .action-btn:hover {
    background-color: #eefafe;
  }
  .profile-actions .action-btn {
    font-size: 1rem;
    line-height: 1.375;
    cursor: pointer;
    min-height: 90px;
    width: 100%;
  }
  .profile-actions .action-btn .action-btn-subtitle {
    margin-top: 0;
  }
  .profile-actions .action-btn .icon {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  .profile-actions .expanded .action-btn {
    background-color: #c7e6a1;
    cursor: pointer;
  }
  .profile-actions .expanded .action-btn:hover {
    background-color: #eefafe;
  }
  .profile-actions .open .action-btn {
    background-color: #fff;
  }
  .profile-actions .expand-collapse-btn .icon {
    transform: rotate(-90deg);
  }
  .profile-actions .expand-collapse-btn {
    display: none;
  }
  .profile-actions .expand-disclosure-btn ~ .action-btn {
    display: none;
  }
  .profile-actions .expanded .expand-collapse-btn {
    display: flex;
    margin-bottom: 2px;
    padding: 0.5rem;
  }
  .profile-actions .expanded .expand-collapse-btn .icon {
    align-items: center;
  }
  .profile-actions .expanded .expand-disclosure-btn {
    display: none;
  }
  .profile-actions .expanded .expand-disclosure-btn ~ .action-btn {
    display: flex;
  }
  .profile-actions .expanded .reveal-content {
    max-height: none;
  }
  .profile-actions .reveal-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease-in-out;
  }
  .profile-actions .expand-collapse-btn {
    background-color: #c7e6a1;
    margin-bottom: 2px;
  }
  .profile-actions .expand-collapse-btn:hover {
    background-color: #eefafe;
  }
}

.profile-insurance .inner-wrap {
  max-height: 450px;
}
.profile-insurance .insurance-input {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 95%;
  background-color: #f0ebe6;
  border-radius: 2em;
  border: none;
  padding: 0.8em;
  text-indent: 1rem;
  width: 95%;
}
.profile-insurance .search-message {
  margin: 0.75em 1.25em;
  padding: 1em 0.5em;
  width: 85%;
}
.profile-insurance .search-message.error {
  background-color: rgba(171, 64, 91, 0.2);
}
.profile-insurance .insurance-list {
  padding: 0.5em 1.25em;
  columns: 2;
}
.profile-insurance .insurance-list > li {
  font-size: 1rem;
  line-height: 1.25;
  padding-bottom: 1em;
}
.profile-insurance .insurance-list > li .highlight {
  background-color: #eefafe;
}
.profile-insurance p {
  font-family: "Tw Cen MT", sans-serif;
  margin-left: 1.25em;
}

.profile-mobile-actions {
  height: 70px;
  width: 100%;
}
.profile-mobile-actions .clipboard-notification {
  opacity: 0;
  transition: opacity 0.4s;
}
.profile-mobile-actions .clipboard-notification.active {
  opacity: 1;
}
.profile-mobile-actions .profile-mobile-actions-inner {
  box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column-reverse;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.profile-mobile-actions .profile-mobile-actions-inner.relative {
  position: relative;
}
.profile-mobile-actions .toggles-container {
  display: flex;
}
.profile-mobile-actions .section-toggle {
  flex: 1;
  height: 70px;
}
.profile-mobile-actions .section-toggle.expanded .section-toggle-btn {
  background-color: #c7e6a1;
}
.profile-mobile-actions .section-toggle.expanded .section-content {
  background-color: #a1d45f;
  bottom: 100%;
  box-shadow: 0px -10px 10px 0px rgba(0, 0, 0, 0.4);
  display: block;
  left: 0;
  position: absolute;
  width: 100%;
}
.profile-mobile-actions .section-toggle-btn {
  background-color: #a1d45f;
  width: 100%;
  height: 70px;
}
.profile-mobile-actions .section-toggle-btn .icon {
  pointer-events: none;
}
.profile-mobile-actions .section-toggle-btn:hover {
  cursor: pointer;
}
.profile-mobile-actions .section-content {
  display: none;
}
.profile-mobile-actions .action-btn {
  background-color: #c7e6a1;
  justify-content: center;
  min-height: 90px;
  width: 100%;
}
.profile-mobile-actions .action-btn:not(:last-child) {
  margin-bottom: 2px;
}
.profile-mobile-actions .action-btn .icon {
  top: 50%;
  transform: translateY(-50%);
}
.profile-mobile-actions .action-btn .icon-share {
  transform: rotate(-90deg);
}
@media (min-width: 1280px) {
  .profile-mobile-actions {
    display: none;
  }
}

.profile-navigation {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  position: sticky;
  top: 0px;
  z-index: 1;
}
.profile-navigation.minimal .intro-btn:last-child {
  visibility: hidden;
}
.profile-navigation .back-link-container {
  padding-bottom: 0;
  padding-left: 0.75rem;
}
.profile-navigation .intro-btn svg {
  pointer-events: none;
}
@media (min-width: 768px) {
  .profile-navigation {
    flex-direction: row;
    margin-bottom: 0.5rem;
    padding: 0 30px;
  }
  .profile-navigation .back-link-container {
    min-width: 285px;
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 0;
  }
  .profile-navigation .button-bar .intro-btn {
    display: none;
  }
  .profile-navigation .overview-link,
  .profile-navigation .search-result {
    flex-basis: 240px;
    margin-right: 15px;
    min-width: 240px;
  }
}
@media (min-width: 1280px) {
  .profile-navigation {
    padding: 0 70px;
  }
  .profile-navigation .back-link-container {
    min-width: 360px;
    gap: 0.25rem;
    justify-content: flex-start;
    padding: 0.75rem;
  }
}
@media (min-width: 1440px) {
  .profile-navigation {
    margin-left: auto;
    margin-right: auto;
    max-width: 1440px;
  }
  .profile-navigation .back-link-container {
    gap: 0.15rem;
    padding: 0.75rem;
  }
}

.provider-ratings .average-rating {
  font-family: "Tw Cen MT Bold", sans-serif;
  margin: 0;
  padding-right: 0.625rem;
}
.provider-ratings .section-title {
  padding-top: 1.5rem;
}
.provider-ratings .rating-description {
  margin-bottom: 1rem;
}

.rating-main {
  font-size: 3.375rem;
  line-height: 1;
  font-family: "Tw Cen MT Bold", sans-serif;
  padding-right: 0.25em;
}

.review-number {
  font-size: 1rem;
  line-height: 1.375;
  position: absolute;
  top: 35px;
}

.rating-review {
  font-size: 1rem;
  line-height: 1.25;
  border-top: 1px solid;
  display: flex;
  padding: 0.625em 0;
}
.rating-review .rating-date {
  margin-left: 0.5rem;
}

.rating-stars {
  height: 30px;
}

.ratings-wrapper {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 1em;
}

.profile-scheduling.active .schedule-appointment {
  display: block;
}
.profile-scheduling .schedule-btn {
  width: 100%;
}
.profile-scheduling .schedule-appointment {
  display: none;
}
.profile-scheduling .location-schedule.selected {
  display: none;
}
.profile-scheduling .patient-content {
  background-color: #fff;
}
.profile-scheduling .patient-content.expanded .schedule-widget {
  min-height: 60vh;
}
.profile-scheduling .patient-content.expanded .schedule-widget.active:after {
  display: none;
}
.profile-scheduling .patient-content .inner-content {
  padding: 1.25em;
}
.profile-scheduling .patient-content .expand-collapse-btn {
  background-color: #fff;
  display: none;
}
.profile-scheduling .patient-content .expand-collapse-btn.active {
  display: flex;
}
.profile-scheduling .patient-content .expand-collapse-btn .section-icon {
  position: static;
}
.profile-scheduling .patient-content .schedule-widget {
  background-color: #fff;
  display: none;
  min-height: 50vh;
  padding-bottom: 1em;
  position: relative;
  width: 100%;
}
.profile-scheduling .patient-content .schedule-widget.active {
  display: block;
}
.profile-scheduling .patient-content .schedule-widget.active:after {
  background: linear-gradient(transparent, #fff);
  bottom: 0;
  content: "";
  display: block;
  height: 100px;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: 100%;
}
.profile-scheduling .patient-content .schedule-widget iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.profile-scheduling .patient-type {
  font-size: 1rem;
  line-height: 1.25;
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: center;
}
.profile-scheduling .patient-type a {
  color: #181922;
}
.profile-scheduling .patient-type .patient {
  background-color: #c7e6a1;
  cursor: pointer;
  height: 50px;
  margin: auto;
  padding: 0.75em 1.5em;
  width: 50%;
}
.profile-scheduling .patient-type .patient.selected {
  background-color: #fff;
}
.profile-scheduling .schedule-existing {
  background-color: #fff;
  display: none;
  min-height: 375px;
  padding-top: 1.5em;
  position: relative;
}
.profile-scheduling .schedule-existing.active {
  display: block;
}
.profile-scheduling .schedule-existing p {
  font-size: 1rem;
  line-height: 1.375;
  font-family: "Tw Cen MT", sans-serif;
  font-style: 400;
  margin-bottom: 0;
  padding: 0.5em 0;
  width: 75%;
}
.profile-scheduling .schedule-existing .action-btn {
  bottom: 0;
  margin-top: auto;
  min-height: 90px;
  position: absolute;
  right: 0;
  width: 50%;
}
@media (min-width: 768px) {
  .profile-scheduling .patient-content.expanded .schedule-widget {
    min-height: 50vw;
  }
  .profile-scheduling .patient-content .schedule-widget {
    min-height: 30vw;
  }
}

.profile {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 768px) {
  .profile {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .profile {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .profile {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.profile .action-btn span {
  display: block;
}
.profile .location-hours {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #181922;
}
.profile .location-hours .open-closed {
  display: inline-block;
  margin-right: 0.5rem;
}
.profile .location-hours .open-closed.open {
  color: #64a70b;
}
.profile .location-hours .open-closed.closed {
  color: #ab405b;
}
.profile .provider-desktop {
  display: none;
}
.profile .profile-content {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}
.profile .profile-content .profile-section {
  background-color: #f2f2f2;
  border-radius: 15px;
  margin-bottom: 2rem;
  overflow: hidden;
  width: 100%;
}
.profile .profile-content .provider-locations {
  background-color: transparent;
}
.profile .profile-content .search-result {
  display: block;
}
.profile .content a {
  font-size: 1rem;
  line-height: 1.25;
  color: #4e8209;
  display: block;
  padding: 0.25em 0;
}
.profile .content a:hover {
  color: #181922;
}
.profile .search-link-wrap {
  align-items: center;
  display: flex;
  padding: 1.25rem;
}
.profile .search-link-wrap span {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #181922;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 500;
  padding: 0 0.5em;
}
.profile .search-result {
  border-radius: 15px;
  height: 100%;
  padding: 15px;
  overflow: hidden;
}
.profile .search-result .profile-image-container {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-bottom: 119%;
}
.profile .search-result .result-details {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #fff;
}
.profile .search-result .icon-profile {
  display: none;
}
.profile .condition-columns {
  border-bottom: 1px solid;
  display: flex;
  gap: 2em;
  justify-content: flex-start;
  margin-bottom: 1em;
  padding: 1em;
}
.profile .symptoms-section {
  border-bottom: 1px solid;
  margin-bottom: 1em;
  padding: 1em;
}
.profile .treatments-section {
  border-bottom: 1px solid;
  margin-bottom: 1em;
  padding: 1em;
}
.profile .provider-background .section-head {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  font-weight: 500;
  margin-bottom: 0.75rem;
  text-transform: none;
}
.profile .provider-background .biography-text {
  font-weight: 300;
}
.profile .provider-count {
  color: #4e8209;
}
.profile .inner-wrap {
  padding: 30px;
}
.profile .inner-wrap > :last-child {
  margin-bottom: 0;
}
.profile .inner-wrap .content > :last-child {
  margin-bottom: 0;
}
.profile .list-block-col {
  margin-bottom: 1rem;
}
.profile a.subject-matter-detail {
  color: #4e8209;
  display: block;
  margin-bottom: 0.25em;
}
.profile .result-top {
  position: relative;
}
.profile .subtitle {
  font-size: 1rem;
  line-height: 1.375;
  font-weight: 500;
}
.profile .profile-content .reveal-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
}
.profile .profile-content .expanded .reveal-content {
  max-height: none;
}
.profile .subtitle-paragraph p {
  font-family: "Tw Cen MT", sans-serif;
}
@media (min-width: 768px) {
  .profile .provider-desktop {
    display: block;
    position: sticky;
    margin-bottom: 86px;
    top: 64px;
  }
  .profile .profile-navigation {
    flex-direction: row;
    min-height: 64px;
    padding-top: 2rem;
  }
  .profile .profile-content {
    flex-direction: column;
    width: 100%;
  }
  .profile .profile-content .provider-result {
    display: none;
  }
  .profile .profile-content > div:not(.active, .search-result, .location-introduction) {
    display: block;
  }
  .profile .profile-content > div.back-to-top {
    display: flex;
  }
  .profile .search-link-wrap {
    flex-basis: 240px;
    min-width: 240px;
    padding: 0.5em 0;
  }
  .profile .overview-link,
  .profile .search-result {
    box-shadow: none;
    flex-basis: 240px;
    margin-right: 15px;
    min-width: 240px;
  }
  .profile .profile-content {
    flex-direction: column;
    width: 100%;
  }
  .profile .profile-content .provider-result,
  .profile .profile-content .search-result {
    display: none;
  }
  .profile .profile-main {
    display: flex;
  }
  .profile .result-top {
    display: block;
  }
  .profile .reveal-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease-in-out;
  }
}
@media (min-width: 1280px) {
  .profile .search-result {
    flex-basis: 315px;
    min-width: 315px;
  }
}

.provider-listing-group .eyebrow {
  margin-bottom: 10px;
}
.provider-listing-group .result-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.provider-listing-group .directions-link {
  margin-right: 0.25rem;
}
@media (min-width: 768px) {
  .provider-listing-group .result-list {
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
@media (min-width: 1140px) {
  .provider-listing-group .result-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 26px;
  }
}

.provider-location {
  background: #f2f2f2;
  border-radius: 15px;
  margin-bottom: 1rem;
  overflow: hidden;
  padding: 1rem;
  width: 100%;
}
.provider-location:last-child {
  margin-bottom: 0;
}
.provider-location .location-bottom-row .action-btn {
  min-height: 90px;
}
.provider-location .location-name {
  font-size: 1.125rem;
  line-height: 1.4444444444;
  font-weight: 500;
}
.provider-location .location-scheduling {
  margin-top: auto;
}
.provider-location .location-info {
  font-size: 1rem;
  line-height: 1.625;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #fff;
  min-height: 225px;
  padding: 1.25rem;
  position: relative;
  width: 50%;
}
.provider-location .location-map {
  background-color: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
  width: 50%;
}
.provider-location .location-map img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.provider-location .location-top-row {
  display: flex;
  flex-direction: column-reverse;
}
.provider-location .location-top-row > div {
  width: 100%;
}
.provider-location .location-bottom-row,
.provider-location .location-schedule {
  display: flex;
  flex-basis: 1;
  gap: 2px;
  margin-bottom: 2px;
}
.provider-location .location-schedule-link {
  font-size: 1.25rem;
  line-height: 1.15;
  background-color: #c7e6a1;
  color: #000;
  display: flex;
  font-weight: 400;
  min-height: 90px;
  padding: 1.25rem;
  position: relative;
  text-align: left;
  width: 50%;
}
.provider-location .location-schedule-link span {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.provider-location .location-schedule-link {
  background-color: #2178bc;
  color: #fff;
  flex: 1;
}
.provider-location .location-schedule-link .section-icon {
  fill: #fff;
}
.provider-location .primary-location-head {
  font-size: 0.75rem;
  line-height: 1.3333333333;
  font-weight: 500;
  color: #2178bc;
  letter-spacing: 1px;
  padding-bottom: 0.75em;
  text-transform: uppercase;
  display: block;
}
.provider-location .section-icon {
  bottom: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  width: 20px;
}
.provider-location .street-name,
.provider-location .distance {
  padding-top: 0.5em;
}
@media (min-width: 768px) {
  .provider-location .location-top-row {
    flex-direction: row;
  }
  .provider-location .location-top-row > div {
    width: 50%;
  }
  .provider-location .location-info {
    border-bottom-right-radius: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 15px;
  }
  .provider-location .location-map {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 15px;
  }
}

.provider-locations .provider-location-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1280px) {
  .provider-locations .provider-location-inner {
    align-items: flex-start;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    width: 100%;
  }
  .provider-locations .provider-location-inner .provider-location-card {
    width: 100%;
  }
}

.provider-profile-background .background-block {
  margin-bottom: 30px;
}
.provider-profile-background .headline-small {
  margin-bottom: 0.375rem;
}
.provider-profile-background .background-list {
  list-style: none;
}
.provider-profile-background .background-item {
  font-size: 1rem;
  line-height: 1.375;
  font-family: "Arial", sans-serif;
  margin-bottom: 0.25rem;
}
@media (min-width: 768px) {
  .provider-profile-background .background-columns {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
  }
  .provider-profile-background .background-column {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .provider-profile-background .background-item {
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
}
@media (min-width: 1280px) {
  .provider-profile-background .background-item {
    font-size: 1rem;
    line-height: 1.375;
  }
}

.provider-profile-rating {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px #c9cbcb;
  padding-bottom: 32px;
}
.provider-profile-rating .rating-score {
  font-family: "Tw Cen MT", sans-serif;
  font-size: 2.5rem;
  font-family: "Tw Cen MT Bold", sans-serif;
}
.provider-profile-rating .reviews-label {
  display: inline-block;
  margin-bottom: 1rem;
}
.provider-profile-rating .rating-wrap {
  display: flex;
  flex-direction: column;
}
.provider-profile-rating .rating-item {
  display: flex;
  margin-bottom: 5px;
}
.provider-profile-rating .bar-container {
  width: 200px;
  height: 9px;
  margin-left: 5px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 4px;
  margin-left: 12px;
}
.provider-profile-rating .bar {
  width: 200px;
  height: 9px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
}
.provider-profile-rating .gray-bar {
  background-color: #cdcdcd;
}
.provider-profile-rating .orange-bar {
  background-color: #ff8c00;
  transition: width 0.2s ease-in;
  width: 0;
}
@media (min-width: 768px) {
  .provider-profile-rating {
    flex-direction: row;
    gap: 50px;
  }
}

.provider-profile-summary {
  background-color: #fff;
  padding: 24px;
}
.provider-profile-summary.bio-expanded .view-more,
.provider-profile-summary.bio-expanded .short-bio {
  display: none;
}
.provider-profile-summary.bio-expanded .view-less,
.provider-profile-summary.bio-expanded .full-bio {
  display: block;
}
.provider-profile-summary .view-less,
.provider-profile-summary .full-bio {
  display: none;
}
.provider-profile-summary .provider-headshot {
  margin-bottom: 1rem;
}
.provider-profile-summary .provider-bio {
  margin-bottom: 2rem;
}
.provider-profile-summary .provider-bio .headline-large {
  margin-bottom: 0.85rem;
}
.provider-profile-summary .provider-bio .ratings {
  margin-bottom: 0.875rem;
}
.provider-profile-summary .provider-bio p {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-weight: 400;
}
.provider-profile-summary .feature-list-item {
  margin-bottom: 10px;
}
.provider-profile-summary .feature-list-item .icon {
  margin-bottom: -2px;
}
.provider-profile-summary .feature-list-item span {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-weight: 400;
  font-family: "Arial", sans-serif;
  margin-left: 5px;
}
@media (min-width: 768px) {
  .provider-profile-summary .inner-content {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
  }
  .provider-profile-summary .provider-headshot,
  .provider-profile-summary .provider-bio-and-features {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .provider-profile-summary .provider-headshot {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .provider-profile-summary .provider-bio-and-features {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
}
@media (min-width: 1280px) {
  .provider-profile-summary .provider-bio-and-features-inner {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
    justify-content: space-between;
  }
  .provider-profile-summary .provider-bio,
  .provider-profile-summary .provider-features {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .provider-profile-summary .provider-bio {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .provider-profile-summary .feature-list {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
    margin-top: 70px;
  }
}

.related-news {
  margin: 0 auto;
}
.related-news .eyebrow {
  font-size: 0.875rem;
  line-height: 1;
  color: #1d1d1d;
  font-family: "Tw Cen MT", sans-serif;
  text-transform: uppercase;
}
.related-news .news-title {
  font-size: 1.75rem;
  line-height: 1;
  color: #000;
  font-family: "Tw Cen MT", sans-serif;
  margin-bottom: 4px;
}
.related-news .body-copy {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  color: #000;
  font-family: "Arial", sans-serif;
}
.related-news .separator {
  height: 1px;
  background: #c9cbcb;
  border-style: none;
  margin: 20px 0;
}
.related-news .separator-new-list {
  height: 1px;
  background: #c9cbcb;
  border-style: none;
  margin: 20px 0;
}
.related-news .news-list {
  margin-top: 30px;
}
.related-news .related-title {
  font-size: 1.75rem;
  line-height: 1.1428571429;
  color: #1d1d1d;
  font-family: "Tw Cen MT", sans-serif;
  margin-bottom: 24px;
}

.rich-text {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  color: #000 !important;
  font-family: "Arial", sans-serif !important;
  font-size: 0.9375rem !important;
  font-weight: 400;
  line-height: 1.46666 !important;
  max-width: 100%;
  margin: 0 auto;
}
.rich-text:after {
  clear: left;
  content: "";
  display: block;
}
.rich-text a {
  color: #0c5688;
  cursor: pointer;
  font-family: "Arial", sans-serif;
  text-decoration: none;
}
.rich-text a:hover {
  text-decoration: underline;
}
.rich-text img {
  float: left;
  margin-right: 2rem;
  margin-bottom: 0.75rem;
  max-width: 352px;
  border-radius: 6px;
}
.rich-text p,
.rich-text span {
  color: #000 !important;
  font-family: "Arial", sans-serif !important;
  font-size: 0.9375rem !important;
  font-weight: 400;
  line-height: 1.46666 !important;
}
.rich-text ul {
  list-style-type: none;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  padding-inline-start: 1.5rem;
  margin-bottom: 1.625rem;
}
.rich-text ul li {
  color: #000 !important;
  font-family: "Arial", sans-serif !important;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.2857rem !important;
  padding-bottom: 0.5rem;
  position: relative;
}
.rich-text ul li:before {
  content: "•";
  font-size: 1rem;
  line-height: 1.25;
  position: absolute;
  left: -1rem;
  vertical-align: middle;
}
.rich-text ul li * {
  color: #000 !important;
  font-family: "Arial", sans-serif !important;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.2857rem !important;
}

.schedule-appointment-form {
  display: flex;
  background-color: #fff;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
}
.modal .schedule-appointment-form .iframe-container {
  max-height: calc(100vh - 2rem - 117px);
}
.modal .schedule-appointment-form iframe {
  height: calc(100vh - 2rem - 117px);
}
.schedule-appointment-form .iframe-container {
  margin: auto;
  max-height: calc(100vh - 2rem - 117px - 100px);
  max-width: 800px;
  width: 100%;
}
.schedule-appointment-form iframe {
  height: calc(100vh - 2rem - 117px - 100px);
  min-width: 100%;
}
.schedule-appointment-form iframe .appointment-information-heading {
  font-size: 88px;
}
.schedule-appointment-form .help-cta {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  align-items: center;
}
.schedule-appointment-form .help-cta p {
  margin-bottom: 0;
}
.schedule-appointment-form .schedule-an-appointment-call {
  border-top: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  padding: 1rem 1rem 1.5rem;
  width: 100%;
}
.schedule-appointment-form .schedule-an-appointment-call .location-phone-item {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: "Arial", sans-serif;
}
.schedule-appointment-form .headline-small {
  margin-bottom: 0.2rem;
}
@media (min-width: 414px) {
  .schedule-appointment-form {
    margin: 0;
  }
}
@media (min-width: 768px) {
  .modal .schedule-appointment-form {
    min-width: 800px;
    max-width: 800px;
  }
}
@media (min-width: 1280px) {
  .schedule-appointment-form .iframe-container {
    max-height: calc(100vh - 2rem - 117px - 130px);
  }
  .schedule-appointment-form iframe {
    height: calc(100vh - 2rem - 117px - 130px);
  }
}

.search-landing {
  padding: 0 15px;
  min-height: 300px;
  margin-bottom: 2rem;
}
.search-landing.search-landing-expanded .expanded-filters {
  display: block;
}
.search-landing.search-landing-expanded .filter-expand-toggle .icon {
  transform: rotate(90deg) scale(-1, -1);
}
.search-landing .landing-container {
  background-color: #f2f2f2;
  border-radius: 15px;
  padding: 20px;
}
.search-landing .landing-container .control-panel {
  margin: 0;
  padding: 0;
}
.search-landing .landing-container .filter-title-bar {
  justify-content: flex-end;
}
.search-landing .landing-container .filter-heading {
  display: none;
}
.search-landing .page-title {
  margin-bottom: 2rem;
}
.search-landing .search-landing-title {
  font-size: 3.375rem;
  line-height: 1;
  color: #2178bc;
  font-weight: 300;
  margin-bottom: 0.75rem;
}
.search-landing .search-landing-desc {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  font-family: "Tw Cen MT", sans-serif;
  margin-bottom: 2rem;
}
.search-landing .view-all-btn {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #2178bc;
  box-shadow: 0px 5px 5px #8b8b90;
  color: #fff;
  font-weight: 500;
  margin-bottom: 1.25rem;
}
.search-landing .view-all-btn .provider-count {
  font-weight: 400;
  margin-left: auto;
  margin-right: 0.5rem;
}
.search-landing .view-all-btn .icon-arrow-up {
  fill: none;
  margin-left: 0;
  transform: rotate(90deg);
  stroke: #fff;
}
.search-landing .view-all-btn:hover, .search-landing .view-all-btn:focus {
  background-color: #181922;
  color: #fff;
}
.search-landing .view-all-btn:hover .icon-arrow-up, .search-landing .view-all-btn:focus .icon-arrow-up {
  stroke: #fff;
}
.search-landing .expanded-filters {
  display: none;
}
.search-landing .filter-expand-toggle {
  margin-bottom: 2rem;
  width: auto;
}
.search-landing .filter-expand-toggle:focus {
  background-color: rgba(255, 255, 255, 0.5);
}
.search-landing .filter-expand-toggle .icon {
  height: 0.75rem;
  margin-right: 0.5rem;
  stroke: #525259;
  transform: rotate(90deg);
  transition: all 0.2s ease-in-out;
  width: 0.75rem;
}
@media (min-width: 768px) {
  .search-landing {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
    padding: 0;
  }
  .search-landing .landing-container {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
    margin: auto;
    padding: 20px;
  }
}
@media (min-width: 1280px) {
  .search-landing .landing-container {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 12px;
    padding: 20px;
  }
  .search-landing .landing-container .filter-title-bar .close-btn {
    display: none;
  }
}

.search-result {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: #f2f2f2;
  border-radius: 20px;
  color: #181922;
  margin-bottom: 2rem;
  overflow: hidden;
  padding: 0;
  position: relative;
}
.search-result.location-result .location {
  background-color: #c7e6a1;
}
.search-result.location-result .location-name {
  font-size: 1.25rem;
  line-height: 1.5;
  color: #000;
  font-weight: 400;
}
.search-result.location-result .icon-arrow-up {
  transform: rotate(90deg);
}
.search-result.location-result .schedule-or-call {
  flex-direction: column;
}
.search-result.location-result .schedule-btn {
  background-color: #a1d45f;
}
.search-result.location-result .call-link {
  background-color: #7cba25;
}
.search-result.map-result {
  bottom: 0;
  left: 5%;
  margin-bottom: 14px;
  position: absolute;
  width: 90%;
  z-index: 999;
}
.search-result.map-result.expanded .location-scheduling {
  display: block;
}
.search-result.map-result.expanded .result-expand-btn .icon-chevron-right {
  transform: rotate(90deg);
}
.search-result.map-result .map-result-pagination {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.25rem;
}
.search-result.map-result .map-result-pagination p {
  font-family: "Tw Cen MT", sans-serif;
  margin-bottom: 0;
}
.search-result.map-result .map-result-pagination button[disabled]:hover {
  cursor: default;
}
.search-result.map-result .map-result-pagination button[disabled] .icon {
  stroke: #b1b1b3;
}
.search-result.map-result .map-result-pagination button {
  align-items: center;
  display: flex;
}
.search-result.map-result .map-result-pagination button:hover {
  cursor: pointer;
}
.search-result.map-result .map-result-pagination .icon {
  stroke: #181922;
  stroke-width: 1;
}
.search-result.map-result .map-result-pagination .left {
  transform: rotate(180deg);
}
.search-result.map-result .result-top {
  display: block;
}
.search-result.map-result .result-expand-btn {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
}
.search-result.map-result .result-expand-btn .icon-chevron-right {
  height: 0.75rem;
  stroke: #525259;
  transform: rotate(-90deg);
  width: 0.75rem;
}
.search-result.map-result .result-expand-btn:hover {
  cursor: pointer;
}
.search-result.map-result .map-result-close-btn {
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.search-result.map-result .map-result-close-btn .icon-close {
  stroke: #525259;
}
.search-result.map-result .map-result-close-btn:hover {
  cursor: pointer;
}
.search-result.map-result .location-scheduling {
  display: none;
}
.search-result.map-result .schedule-or-call {
  flex-direction: column;
}
.search-result.map-result .location,
.search-result.map-result .action-btn {
  min-height: 90px;
}
.search-result p {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #181922;
}
.search-result .btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  border: 1px solid #181922;
  border-radius: 2px;
  margin: 0;
  padding: 0.625rem 0.25rem 0.5rem;
}
.search-result a.result-details {
  position: relative;
  transition: background-color 0.1s ease-in-out;
  display: block;
}
.search-result a.result-details .icon-star {
  transition: fill 0.1s ease-in-out;
}
.search-result a.result-details:hover, .search-result a.result-details:focus {
  background-color: #b1b1b3;
}
.search-result a.result-details:hover .result-name,
.search-result a.result-details:hover .specialties,
.search-result a.result-details:hover .new-patient-text,
.search-result a.result-details:hover .preferred,
.search-result a.result-details:hover .average-rating,
.search-result a.result-details:hover .location-address,
.search-result a.result-details:hover .location-hours,
.search-result a.result-details:hover .open, .search-result a.result-details:focus .result-name,
.search-result a.result-details:focus .specialties,
.search-result a.result-details:focus .new-patient-text,
.search-result a.result-details:focus .preferred,
.search-result a.result-details:focus .average-rating,
.search-result a.result-details:focus .location-address,
.search-result a.result-details:focus .location-hours,
.search-result a.result-details:focus .open {
  color: #000;
}
.search-result a.result-details:hover .icon-star, .search-result a.result-details:focus .icon-star {
  fill: #8b8b90;
}
.search-result a.result-details:hover .star-fill .icon-star, .search-result a.result-details:focus .star-fill .icon-star {
  fill: #181922;
}
.search-result .profile-image-container {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 80%;
  position: relative;
  width: 100%;
}
.search-result .profile-image-bg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.search-result .profile-image-bg img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
  width: 100%;
}
.search-result .profile-image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.search-result .profile-image img {
  height: 100%;
  object-fit: cover;
  object-position: center 25%;
  width: 100%;
}
.search-result .result-details {
  color: #181922;
  padding: 1.25rem;
  position: relative;
}
.search-result .result-name {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #181922;
  display: block;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.search-result .medical-group {
  font-size: 1rem;
  line-height: 1.4375;
  font-weight: 400;
}
.search-result .medical-group.preferred {
  color: #2178bc;
}
.search-result .specialties {
  margin-bottom: 0.5rem;
}
.search-result .specialty {
  font-size: 1rem;
  line-height: 1.4375;
}
.search-result .new-patient-text {
  font-size: 1rem;
  line-height: 1.4375;
}
.search-result .new-patient-text.accepting {
  color: #2178bc;
}
.search-result .ratings {
  margin: 1rem 0;
}
.search-result .distance-link {
  margin-right: 0.375rem;
}
.search-result .location-name {
  font-size: 1.125rem;
  line-height: 1.4444444444;
}
.search-result .location-hours {
  font-size: 1rem;
  line-height: 1.375;
  color: #181922;
  margin-top: 1.25rem;
}
.search-result .location-hours .open-closed {
  display: inline-block;
  margin-right: 0.5rem;
}
.search-result .location-hours .open-closed.open {
  color: #64a70b;
}
.search-result .location-hours .open-closed.closed {
  color: #ab405b;
}
.search-result .location-scheduling {
  margin-top: auto;
}
.search-result .location {
  font-size: 1rem;
  line-height: 1.625;
  border-top: 2px solid #181922;
  color: #181922;
  display: block;
  min-height: 130px;
  padding: 1.25rem;
  position: relative;
  transition: background-color 0.1s ease-in-out;
}
.search-result .location:hover, .search-result .location:focus {
  background-color: #b1b1b3;
}
.search-result .location:hover .icon-location, .search-result .location:focus .icon-location {
  fill: #2178bc;
}
.search-result .location.not-available:hover {
  background-color: #f0ebe6;
}
.search-result .location .location-not-available {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
}
.search-result .location .icon-location {
  fill: #53b9d1;
}
.search-result .location-address span {
  display: block;
}
.search-result .directions-link {
  color: #2178bc;
}
.search-result .schedule-or-call {
  display: flex;
  flex-direction: column;
}
.search-result .schedule-or-call .action-btn {
  color: #fff;
}
.search-result .schedule-or-call .action-btn .action-btn-subtitle {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
.search-result .schedule-or-call .schedule-btn {
  flex-basis: 63%;
}
.search-result .schedule-or-call .schedule-btn .action-btn-subtitle {
  padding-right: 1.5rem;
}
.search-result .schedule-or-call .call-link {
  flex-basis: 37%;
}
.search-result .schedule-or-call .icon {
  fill: #fff;
}
.search-result .schedule-or-call-introduction {
  display: flex;
  flex-direction: column;
}
.search-result .schedule-btn {
  background-color: #525259;
  transition: background-color 0.1s ease-in-out;
}
.search-result .schedule-btn:hover, .search-result .schedule-btn:focus {
  background-color: #000;
}
.search-result .call-link {
  background-color: #181922;
  transition: background-color 0.1s ease-in-out;
}
.search-result .call-link:hover, .search-result .call-link:focus {
  background-color: #000;
}
.search-result .section-icon {
  bottom: 20px;
  height: 30px;
  position: absolute;
  right: 20px;
  width: 30px;
}
@media (min-width: 768px) {
  .search-result {
    margin-bottom: 6.25rem;
  }
  .search-result.location-result .schedule-or-call {
    flex-direction: row;
  }
  .search-result.map-result .schedule-or-call {
    flex-direction: column;
  }
  .search-result.grid-result .schedule-or-call .schedule-btn {
    flex-basis: 64%;
  }
  .search-result.grid-result .schedule-or-call .call-link {
    flex-basis: 36%;
  }
  .search-result.list-result {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding-bottom: 0;
  }
  .search-result.list-result .result-top {
    display: flex;
    position: relative;
  }
  .search-result.list-result .profile-image-outer {
    flex: 0 0 50%;
  }
  .search-result.list-result .profile-image-container {
    padding-bottom: 61%;
  }
  .search-result.list-result .result-details {
    flex: 1 0 50%;
    padding: 1.25rem 1.5rem;
  }
  .search-result.list-result .location-scheduling {
    display: flex;
  }
  .search-result.list-result .location,
  .search-result.list-result .schedule-or-call {
    flex: 0 0 50%;
    min-height: 140px;
  }
  .search-result.list-result .action-btn {
    min-height: 90px;
  }
  .search-result.list-result .location {
    border-top: none;
  }
  .search-result.list-result .location:only-child {
    flex: 0 0 100%;
  }
  .search-result.list-result .schedule-or-call .schedule-btn {
    flex-basis: 61%;
    padding: 0.75rem 20px;
  }
  .search-result.list-result .schedule-or-call .call-link {
    flex-basis: 39%;
    padding: 0.75rem 20px;
  }
  .search-result.map-result {
    bottom: 0;
    left: auto;
    position: absolute;
    right: 0;
    width: 335px;
  }
  .search-result.map-result .result-expand-btn {
    display: none;
  }
  .search-result.map-result .map-result-pagination {
    padding: 1rem 4rem 1rem 1.25rem;
  }
  .search-result.map-result .location-scheduling {
    display: block;
  }
  .search-result .appointment-types .schedule-btns {
    justify-content: space-around;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }
  .search-result .appointment-types .schedule-btns .btn {
    max-width: 200px;
    min-width: 0;
  }
}

.selected-filters-list {
  padding: 10px 10px 0;
}
.selected-filters-list .tag-list {
  margin-bottom: 0;
}
.selected-filters-list .mobile-clear-button-container {
  display: flex;
  justify-content: flex-end;
}
.selected-filters-list .icon-interaction-btn {
  margin-bottom: 1rem;
}
@media (min-width: 1280px) {
  .selected-filters-list {
    padding: 0;
  }
  .selected-filters-list .mobile-clear-button-container {
    display: none;
  }
}

.subscription-with-name {
  background-color: #0c5688;
  margin: 0;
  padding: 0px 15px;
}
.subscription-with-name .subscription-container {
  max-width: 726px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 46px;
  padding-top: 46px;
  gap: 26px;
}
.subscription-with-name .subscription-container .subscription-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.subscription-with-name .subscription-container .subscription-info h2 {
  font-size: 1.375rem;
  line-height: 1;
  color: #fff;
  font-weight: 400;
  font-weight: 400;
  margin: 0;
}
.subscription-with-name .subscription-container .subscription-info p {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  color: #fff;
  font-family: "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}
.subscription-with-name .subscription-container .subscription-form-wrapper-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 24px;
}
.subscription-with-name .subscription-container .subscription-form-wrapper-wrapper.hide {
  display: none;
}
.subscription-with-name .subscription-container .subscription-form-wrapper-wrapper .input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.subscription-with-name .subscription-container .subscription-form-wrapper-wrapper .input-wrapper button {
  max-width: 200px;
}
.subscription-with-name .subscription-container .subscription-form-wrapper-wrapper .input-wrapper .body-copy {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  color: #fff;
  font-family: "Arial", sans-serif;
}
.subscription-with-name .subscription-container .subscription-form-wrapper-wrapper .label-input-text {
  color: #fff;
}
.subscription-with-name .subscription-container .subscription-form-wrapper-wrapper .span-explanation {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  color: #fff;
  font-family: "Arial", sans-serif;
}
.subscription-with-name .subscription-container .subscription-confirmation {
  display: none;
}
.subscription-with-name .subscription-container .subscription-confirmation.show {
  display: block;
}
.subscription-with-name .subscription-container .subscription-confirmation p {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  color: #fff;
  font-family: "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}
@media (min-width: 768px) {
  .subscription-with-name {
    padding: 0px;
  }
  .subscription-with-name .subscription-container .subscription-form-wrapper {
    width: 100%;
    flex-direction: row;
  }
}

div[id^=form-Subscribe] {
  display: none;
}

.subscription-form {
  background: #0c5688;
}
.subscription-form .subscription-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 30px;
  gap: 30px;
}
@media (min-width: 768px) {
  .subscription-form .subscription-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .subscription-form .subscription-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .subscription-form .subscription-container {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.subscription-form .subscription-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}
.subscription-form .subscription-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.subscription-form .subscription-info .headline-large {
  color: #fff;
  font-weight: 400;
  margin: 0;
}
.subscription-form .subscription-info p {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  color: #fff;
  font-family: "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}
.subscription-form div[id^=form-Subscribe] {
  display: block;
}
.subscription-form div[id^=form-Subscribe] input[type=submit] {
  font-size: 1rem;
  line-height: 1;
  border-radius: 6px;
  border: 1px solid #888b8d;
  background: #0c5688;
  display: inline-flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  display: flex;
  height: auto;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s;
  min-width: 162px;
}
.subscription-form div[id^=form-Subscribe] input[type=submit]:hover {
  filter: brightness(0.9);
}
.subscription-form div[id^=form-Subscribe] input[type=submit]:active {
  filter: brightness(1.1);
}
.subscription-form div[id^=form-Subscribe] .ktc-default-section {
  margin-bottom: 16px;
}
.subscription-form div[id^=form-Subscribe] .form-field {
  margin-bottom: 0;
}
.subscription-form div[id^=form-Subscribe] .form-field:has([type=email]) .control-label {
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  font-family: "Tw Cen MT", sans-serif;
  text-transform: uppercase;
}
.subscription-form div[id^=form-Subscribe] .form-field:has([id$=Title_Value]), .subscription-form div[id^=form-Subscribe] .form-field:has([id$=Description_Value]), .subscription-form div[id^=form-Subscribe] .form-field:has([id$=SiteOrigin_Value]), .subscription-form div[id^=form-Subscribe] .form-field:has([id$=WebEmailConsent_Value]) {
  display: none;
}
.subscription-form div[id^=form-Subscribe] .explanation-text {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  color: #fff;
  font-family: "Arial", sans-serif;
  margin: 0;
}
.subscription-form .subscription-confirmation {
  display: none;
  padding: 0px 12px;
}
.subscription-form .subscription-confirmation.show {
  display: block;
}
.subscription-form .subscription-confirmation p {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  color: #fff;
  font-family: "Arial", sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}
.subscription-form .formwidget-submit-text {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  color: #fff;
  font-family: "Arial", sans-serif;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  text-align: center;
}
@media (min-width: 768px) {
  .subscription-form .subscription-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
    align-items: center;
    flex-direction: row;
    gap: 0;
  }
  .subscription-form .subscription-info {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .subscription-form div[id^=form-Subscribe] {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .subscription-form div[id^=form-Subscribe] form {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
  .subscription-form div[id^=form-Subscribe] .ktc-default-section {
    flex: 1;
    margin-bottom: 0;
    max-width: 330px;
  }
  .subscription-form div[id^=form-Subscribe] input[type=submit] {
    align-self: flex-start;
    flex: 0 0 30%;
    margin-top: 21px;
    max-width: 164px;
    min-width: 0;
  }
  .subscription-form .formwidget-submit-text {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 12px;
    text-align: right;
  }
}
@media (min-width: 1280px) {
  .subscription-form div[id^=form-Subscribe] {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .subscription-form .formwidget-submit-text {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
}

.three-column-text {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.three-up {
  flex-direction: column;
  margin-bottom: 90px;
  padding-top: 2rem;
}
.three-up .three-up-item {
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  margin-bottom: 2rem;
  overflow: hidden;
}
.three-up .three-up-item:last-of-type {
  margin-bottom: 0;
}
.three-up .three-up-img-outer {
  width: 100%;
}
.three-up .three-up-img-container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 0;
  overflow: hidden;
  padding-top: 66%;
  position: relative;
  width: 100%;
}
.three-up .three-up-img {
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}
.three-up .three-up-content {
  background-color: #f2f2f2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
  min-height: 240px;
}
.three-up .heading-3 {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #181922;
  font-family: "Tw Cen MT Bold", sans-serif;
  letter-spacing: 0;
  margin-bottom: 1rem;
  text-transform: none;
}
.three-up p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #181922;
  margin-bottom: 0;
}
.three-up p a {
  color: #181922;
}
.three-up p a:hover, .three-up p a:focus {
  color: #000;
}
.three-up .icon-arrow-up {
  transform: rotate(90deg);
  stroke-width: 3px;
}
.three-up .read-more-container {
  margin-top: auto;
  text-align: right;
}
.three-up .read-more-link {
  font-size: 1.375rem;
  line-height: 1.3636363636;
  margin-top: auto;
  text-decoration: none;
}
@media (min-width: 768px) {
  .three-up {
    display: flex;
    justify-content: space-between;
  }
  .three-up .three-up-img-outer {
    width: 100%;
  }
  .three-up .three-up-row {
    display: flex;
    margin-left: -10px;
    width: calc(100% + 20px);
  }
  .three-up .three-up-item {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .three-up .three-up-content {
    padding: 1.25rem;
  }
}
@media (min-width: 1280px) {
  .three-up .three-up-content {
    padding: 1.5rem;
  }
}
@media (min-width: 1680px) {
  .three-up .three-up-content {
    padding: 2rem;
  }
}

.toast {
  width: 100%;
  background-color: rgba(29, 29, 29, 0.9);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 24px;
  right: 0;
  opacity: 0;
  padding: 1rem 2.5rem 1rem 1rem;
  transition: opacity 0.3s ease;
}
.toast.show {
  opacity: 1;
}
.toast * {
  color: #fff;
}
.toast .toast-close-btn {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}
.toast .toast-content {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.toast .toast-content p {
  margin: 0;
  flex: 1;
}
.toast .toast-content p a {
  text-decoration: underline;
}
@media (min-width: 450px) {
  .toast {
    top: 50px;
    width: 440px;
    border-radius: 6px 0 0 6px;
    padding: 30px 60px 30px 40px;
  }
  .toast.show {
    animation: slideInRight 0.5s forwards;
  }
  .toast .toast-content {
    gap: 12px;
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.treatment-detail .custom-h2 {
  margin-bottom: 0.938rem;
  font-weight: 400;
}
.treatment-detail .custom-h3 {
  margin-bottom: 0.68rem;
  font-weight: 400;
}
.treatment-detail .two-columns-block {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
}
.treatment-detail .two-columns-block .column-wrap {
  width: 50%;
}
.treatment-detail .column {
  padding: 15px 0;
  margin-top: 15px;
  border-top: 1px solid #c9cbcb;
  border-bottom: 1px solid #c9cbcb;
}
.treatment-detail li {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: "Arial", sans-serif;
  list-style-type: circle;
}
.treatment-detail .li-item,
.treatment-detail .side-effect {
  display: block;
}
.treatment-detail .info-block {
  margin-top: 40px;
}
.treatment-detail .border-bottom-block {
  border-bottom: 1px solid #c9cbcb;
}
.treatment-detail .margin-bottom-block {
  margin-bottom: 50px;
}

div[id^=form-Unsubscribe] {
  display: none;
}

.unsubscribe-form {
  background: #fff;
  border-radius: 10px;
  padding: 3rem;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: -255px;
  max-width: 726px;
  z-index: 6;
}
.unsubscribe-form.submitted .form-description,
.unsubscribe-form.submitted .form-note {
  display: none;
}
.unsubscribe-form .usubscribe-info {
  margin-bottom: 1.25rem;
}
.unsubscribe-form .usubscribe-info p {
  margin-bottom: 0;
}
.unsubscribe-form .form-description:has(+ .formwidget-submit-text) {
  display: none;
}
.unsubscribe-form div[id^=form-Unsubscribe] {
  display: block;
  margin-top: -20px;
}
.unsubscribe-form div[id^=form-Unsubscribe] input[type=submit] {
  font-size: 1rem;
  line-height: 1;
  border-radius: 6px;
  border: 1px solid #888b8d;
  background: #0c5688;
  display: inline-flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  display: flex;
  height: auto;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s;
  min-width: 162px;
}
.unsubscribe-form div[id^=form-Unsubscribe] input[type=submit]:hover {
  filter: brightness(0.9);
}
.unsubscribe-form div[id^=form-Unsubscribe] input[type=submit]:active {
  filter: brightness(1.1);
}
.unsubscribe-form div[id^=form-Unsubscribe] input[type=submit].disabled {
  background: #888b8d;
  cursor: default;
}
.unsubscribe-form div[id^=form-Unsubscribe] input[type=submit].disabled:hover {
  filter: none;
}
.unsubscribe-form div[id^=form-Unsubscribe] .ktc-default-section {
  margin-bottom: 16px;
}
.unsubscribe-form div[id^=form-Unsubscribe] .form-field:has([type=email]) .control-label {
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  font-family: "Tw Cen MT", sans-serif;
  text-transform: uppercase;
}
.unsubscribe-form div[id^=form-Unsubscribe] .form-field:has([id$=Title_Value]), .unsubscribe-form div[id^=form-Unsubscribe] .form-field:has([id$=Description_Value]), .unsubscribe-form div[id^=form-Unsubscribe] .form-field:has([id$=SiteOrigin_Value]), .unsubscribe-form div[id^=form-Unsubscribe] .form-field:has([id$=WebEmailConsent_Value]) {
  display: none;
}
.unsubscribe-form div[id^=form-Unsubscribe] .explanation-text {
  font-size: 0.875rem;
  line-height: 1.2857142857;
  color: #fff;
  font-family: "Arial", sans-serif;
  margin: 0;
}
.unsubscribe-form .form-note {
  font-weight: 700;
  margin-top: 2rem;
}
.unsubscribe-form .unsubscribe-container {
  max-width: 352px;
  margin-left: auto;
  margin-right: auto;
}
.unsubscribe-form .form-field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.unsubscribe-form .form-field .editing-form-control-nested-control {
  gap: 0;
  order: 0;
  width: auto;
}
.unsubscribe-form .form-field .control-label {
  font-size: 0.9375rem;
  line-height: 1;
  font-family: "Arial", sans-serif;
  order: 1;
  margin-bottom: 0;
  text-transform: none;
  cursor: pointer;
}
.unsubscribe-form .formwidget-submit-text {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
  font-family: "Arial", sans-serif;
}
.unsubscribe-form .formwidget-submit-text ~ .form-note {
  display: none;
}

.upcoming-event-item {
  display: none;
  flex-direction: column;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #c9cbcb;
}
.upcoming-event-item.active {
  display: flex;
}
.upcoming-event-item.last-visible {
  border-bottom: none;
}
.upcoming-event-item .upcoming-event-image {
  width: 100%;
  margin-bottom: 0.938rem;
  object-fit: cover;
  border-radius: 6px;
}
.upcoming-event-item .date-label {
  font-size: 0.875rem;
  line-height: 1;
  font-family: "Tw Cen MT Bold", sans-serif;
  text-transform: uppercase;
}
.upcoming-event-item .custom-event-title {
  margin-top: 0.938rem;
}
.upcoming-event-item .custom-secondary-button {
  margin-left: 1rem;
}
.upcoming-event-item .custom-secondary-button:only-child {
  margin-left: 0;
}
@media (min-width: 768px) {
  .upcoming-event-item {
    flex-direction: row;
  }
  .upcoming-event-item .upcoming-event-image {
    width: 22rem;
    margin-bottom: 0;
  }
  .upcoming-event-item .right-info {
    margin-left: 2rem;
  }
}

.video-modal {
  top: 1rem;
  position: absolute;
  right: 1rem;
}
.video-modal .modal-open-btn {
  align-items: center;
  background-color: #fff;
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  height: 1.75rem;
  justify-content: center;
  width: 1.75rem;
}
.video-modal .modal-open-btn:hover {
  background-color: #eefafe;
}
.video-modal .modal-open-btn .icon {
  height: 1.5rem;
  width: 1.5rem;
}

/* Pages (e.g. Search Page, Article Page) */
@media (min-width: 768px) {
  .content-page {
    background-color: #f0ebe6;
  }
}

.corporate-homepage .finder-wrapper {
  transform: translateY(-50%);
  margin-bottom: -100px;
  width: 100%;
}
.corporate-homepage .headline-large {
  margin-bottom: 11px;
}
.corporate-homepage .headline-large.center {
  text-align: center;
}
.corporate-homepage .body-copy {
  margin-bottom: 16px;
}
.corporate-homepage .centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.corporate-homepage .centered-content .inline-list {
  list-style: initial;
  display: flex;
  flex-direction: column;
  max-height: 100px;
  flex-wrap: wrap;
  gap: 14px;
}
.corporate-homepage .centered-content .inline-list li {
  padding-right: 20px;
}
.corporate-homepage .centered-content .partner-logo {
  margin: 42px 0px 79px;
  max-width: 256px;
  width: 100%;
  object-fit: contain;
}
.corporate-homepage .centered-content .blockquote {
  margin-bottom: 47px;
}
.corporate-homepage .centered-content .blog-post-summary {
  margin-bottom: 0;
}
.corporate-homepage .smaller-content {
  max-width: 726px;
  margin: 0 auto;
}
.corporate-homepage .smaller-content .corporate-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 27px;
  margin-bottom: 49px;
}
.corporate-homepage .smaller-content .corporate-grid .corporate-grid-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0px 23px;
  border-left: 1px solid #c9cbcb;
}
.corporate-homepage .smaller-content .corporate-grid .corporate-grid-item:first-child {
  border: none;
  padding: 0px 17px 17px 0px;
}
.corporate-homepage .smaller-content .corporate-grid .corporate-grid-item:nth-child(2) {
  padding: 0px 0px 17px 17px;
}
.corporate-homepage .smaller-content .corporate-grid .corporate-grid-item:nth-child(3) {
  padding: 17px 17px 0px 0px;
}
.corporate-homepage .smaller-content .corporate-grid .corporate-grid-item:last-child {
  padding: 17px 0px 0px 17px;
}
.corporate-homepage .smaller-content .corporate-grid .corporate-grid-item:nth-child(3) {
  border-left: none;
}
.corporate-homepage .smaller-content .corporate-grid .corporate-grid-item:nth-child(3), .corporate-homepage .smaller-content .corporate-grid .corporate-grid-item:nth-child(4) {
  border-top: 1px solid #c9cbcb;
}
.corporate-homepage .smaller-content .corporate-grid .corporate-grid-item strong {
  font-size: 3rem;
  line-height: 1;
}
.corporate-homepage .smaller-content .corporate-grid .corporate-grid-item p {
  font-size: 0.9375rem;
  line-height: 1.4666666667;
}
@media (min-width: 768px) {
  .corporate-homepage .centered-content .inline-list {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }
  .corporate-homepage .centered-content .inline-list li:first-child {
    list-style-type: none;
  }
  .corporate-homepage .centered-content .inline-list li {
    padding: 0;
  }
  .corporate-homepage .smaller-content {
    max-width: 726px;
    margin: 0 auto;
  }
  .corporate-homepage .smaller-content .corporate-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 27px;
    margin-bottom: 49px;
  }
  .corporate-homepage .smaller-content .corporate-grid .corporate-grid-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0px 23px !important;
    border-left: 1px solid #c9cbcb;
  }
  .corporate-homepage .smaller-content .corporate-grid .corporate-grid-item:first-child {
    border: none;
    padding: 0px 23px 0px 0px;
  }
  .corporate-homepage .smaller-content .corporate-grid .corporate-grid-item:last-child {
    padding: 0px 0px 0px 23px;
  }
  .corporate-homepage .smaller-content .corporate-grid .corporate-grid-item:nth-child(3) {
    border-left: 1px solid #c9cbcb;
  }
  .corporate-homepage .smaller-content .corporate-grid .corporate-grid-item:nth-child(3), .corporate-homepage .smaller-content .corporate-grid .corporate-grid-item:nth-child(4) {
    border-top: none;
  }
}

.error-page-container {
  background-color: #f2efeb;
}
.error-page-container .error-page-inner {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  padding: 10px 33px;
}
@media (min-width: 768px) {
  .error-page-container .error-page-inner {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .error-page-container .error-page-inner {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .error-page-container .error-page-inner {
    padding-right: 70px;
    padding-left: 70px;
  }
}
.error-page-container .error-status {
  color: #525259;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.5714285714;
}
.error-page-container .error-desc {
  margin: 10% 0;
}
.error-page-container .error-wrapper {
  margin: 0 auto;
  max-width: 500px;
}
.error-page-container h1 {
  color: #5a8027;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
  font-size: 1.5rem;
  line-height: 1.25;
}
.error-page-container h4 {
  color: #000;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 300;
  font-size: 1.375rem;
  line-height: 1.1818181818;
}
.error-page-container h4:first-of-type {
  margin-bottom: 15px;
}
.error-page-container .refresh-btn,
.error-page-container .homepage-link {
  font-size: 1rem;
  line-height: 1.25;
  color: #5a8027;
  cursor: pointer;
  font-family: "Tw Cen MT", sans-serif;
  font-weight: 300;
}
.error-page-container p:not(:last-of-type) {
  margin-bottom: 8px;
}
.error-page-container .error-search {
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  flex-basis: 40%;
  margin-top: 20px;
  padding: 10px 15px 10px 20px;
}
.error-page-container .error-search.loading .loading-overlay {
  opacity: 1;
}
.error-page-container .error-search.loading svg {
  opacity: 0;
}
.error-page-container .error-search input {
  background: transparent;
  border: none;
  border-radius: unset;
  flex-grow: 1;
  font-size: unset;
  padding: 0 10px;
}
.error-page-container .error-search input:focus {
  border: 0;
  box-shadow: none;
  outline: 0;
}
.error-page-container .error-search .search-icon-container {
  display: flex;
  height: 1rem;
  position: relative;
  width: 1rem;
}
.error-page-container .error-search .search-icon-container .loading-overlay {
  height: 1rem;
  width: 1rem;
}
.error-page-container .error-search svg {
  cursor: pointer;
  fill: #64a70b;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  stroke: #64a70b;
}
@media (min-width: 768px) {
  .error-page-container h1 {
    font-size: 2.125rem;
    line-height: 1.1176470588;
  }
}

.locations-page {
  background-color: #f2f2f2;
  padding-top: 90px;
}

.news-story .headline {
  margin-bottom: 61px;
}
.news-story .main-news {
  margin-bottom: 5.5rem;
}

.provider-profile {
  /*Patient Reviews*/
}
.provider-profile > section {
  margin-bottom: 2rem;
}
.provider-profile .line-bottom-title {
  border-bottom: solid 1px #c9cbcb;
  padding-bottom: 15px;
}
.provider-profile .dr-info {
  display: flex;
  flex-direction: column;
  padding: 10px 26px;
}
.provider-profile .dr-name {
  margin-bottom: 0px;
}
.provider-profile .dr-subtitle {
  font-size: 1rem;
  line-height: 1.375;
  font-family: "Arial", sans-serif;
}
.provider-profile .patient-reviews {
  margin-top: 30px;
}
.provider-profile .review-title {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: "Tw Cen MT Bold", sans-serif;
  text-transform: uppercase;
}
.provider-profile .review-text {
  font-size: 1rem;
  font-family: "Arial", sans-serif;
  margin-bottom: 0;
}
.provider-profile .comment-review {
  padding: 20px 0;
  border-bottom: solid 1px #c9cbcb;
}
.provider-profile .comment-review:last-of-type {
  border-bottom: none;
}
.provider-profile .last-comment-review {
  margin-top: 20px;
}
.provider-profile .custom-block-title {
  margin-bottom: 20px;
}
.provider-profile .dont-see-your-insurance-block {
  margin-top: 50px;
}
.provider-profile .dont-see-your-insurance-block p:last-of-type {
  margin-bottom: 0;
}
.provider-profile .custom-p {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-family: "Arial", sans-serif;
}
.provider-profile .custom-schedule-appointment-button {
  width: 190px;
  margin: auto;
}
@media (min-width: 768px) {
  .provider-profile > section {
    margin-bottom: 3.125rem;
  }
  .provider-profile .custom-block-title {
    margin-bottom: 30px;
  }
}

.search-page {
  min-height: 100vh;
  margin-top: -32px;
}
.container .search-page {
  margin-left: -20px;
  width: 100vw;
}
.search-page .title-and-description {
  margin-bottom: 20px;
}
.search-page .title-and-description .headline-large {
  margin-bottom: 9px;
}
.search-page .title-and-description .intro-text {
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 0;
}
.search-page .search-page-controls {
  display: block;
}
.search-page .search-page-wrapper {
  display: block;
}
.search-page .search-page-wrapper.map-view .map-view-collapse-container {
  display: none;
}
.search-page .search-page-wrapper.map-view .search-page-results {
  position: relative;
}
.search-page .search-page-wrapper.map-view .results-list-outer {
  position: relative;
}
.search-page .search-page-wrapper.map-view .results-list-container {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: -20px;
  height: 100%;
  width: 100vw;
}
.search-page .search-page-wrapper.map-view .no-results-message {
  margin: 0;
  background-color: #fff;
  box-shadow: 0 -5px 23px 4px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  padding: 30px 20px;
  border-radius: 10px;
}
.search-page .search-landing {
  display: none;
}
.search-page .selected-filters-list .tag-list {
  padding-bottom: 0.5rem;
}
.search-page .selected-filters-list + .input-icon-container {
  border-top: 2px solid #cccccc;
}
.search-page .icon-interaction-btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  align-items: center;
  color: #000;
  display: flex;
  font-family: "Tw Cen MT Bold", sans-serif;
  text-transform: uppercase;
}
.search-page .icon-interaction-btn[disabled] {
  color: #b1b1b3;
}
.search-page .icon-interaction-btn[disabled] .icon {
  fill: #b1b1b3;
}
.search-page .icon-interaction-btn .icon-close-alt {
  fill: #9bc158;
  height: 12px;
  margin-right: 5px;
  width: 12px;
}
.search-page .icon-interaction-btn .icon-arrow-left {
  fill: #9bc158;
  height: 12px;
  margin-right: 5px;
  width: 22px;
}
.search-page .icon-interaction-btn .icon-arrow-right {
  fill: #9bc158;
  height: 12px;
  margin-left: 5px;
  width: 22px;
}
.search-page .open-filters-btn {
  display: none;
}
.search-page .heading-1 {
  font-size: 2.3125rem;
  line-height: 1.0810810811;
  color: #181922;
  font-weight: 500;
}
.search-page .input-icon-container {
  margin: 0;
  position: relative;
  z-index: 1;
}
.search-page .input-icon-container .icon-search {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.search-page .active-filter-toggle {
  align-items: center;
  background-color: #525259;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  min-width: 40px;
  transition: background-color 0.1s ease-in-out;
  width: 40px;
}
.search-page .active-filter-toggle span {
  color: #fff;
}
.search-page .active-filter-toggle .icon {
  height: 20px;
  stroke: #fff;
  transition: stroke 0.1s ease-in-out;
  width: 20px;
}
.search-page .active-filter-toggle .icon:hover {
  stroke: #000;
}
.search-page .active-filter-toggle:hover {
  background-color: #eefafe;
  cursor: pointer;
}
.search-page .active-filter-toggle:hover span {
  color: #000;
}
.search-loaded .search-page {
  min-height: 0;
}
.search-page .results-list li {
  margin: 0;
}
.search-page .results-list li:before {
  display: none;
}
.search-page .map-container {
  height: 70vh;
  min-height: 520px;
  position: relative;
  width: 100vw;
  margin-left: -20px;
  margin-bottom: -64px;
}
.search-page .map-container .location-listing-card {
  box-shadow: 0 -5px 23px 4px rgba(0, 0, 0, 0.15);
  position: absolute;
  bottom: 15px;
  width: calc(100% - 30px);
  left: 15px;
  margin-bottom: 0;
  max-width: none;
  overflow: hidden;
}
.search-page .map-container .location-listing-card.no-results-card {
  z-index: 9;
  position: absolute;
  top: 0;
}
.search-page .map-container .location-listing-card.multi-location-card .map-result-close-btn {
  top: 15px;
}
.search-page .map-container .location-listing-card.multi-location-card .location-picture {
  width: 100%;
}
.search-page .map-container .location-listing-card .location-picture {
  width: calc(100% - 28px);
}
.search-page .map-container .location-listing-card .map-result-close-btn {
  position: absolute;
  right: 15px;
  top: 17px;
}
.search-page .map-container .location-listing-card .map-result-close-btn .icon {
  fill: #9bc158;
}
.search-page .map-container .location-listing-card .map-result-close-btn:hover {
  cursor: pointer;
}
.search-page .map-container .location-listing-card .map-result-pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 2px solid #f6f3f1;
  width: calc(100% + 24px);
  margin-left: -12px;
  margin-top: -11px;
  margin-bottom: 1rem;
}
.search-page .map-container .location-listing-card .map-result-pagination button {
  padding: 0 20px;
}
.search-page .map-container .location-listing-card .map-result-pagination button[disabled] .icon {
  fill: #b1b1b3;
}
.search-page .map-container .location-listing-card .map-result-pagination .icon {
  fill: #0c5688;
}
.search-page .map-container .location-listing-card .map-result-pagination .icon.left {
  transform: rotate(180deg) translateY(-4px);
}
.search-page .map-container .location-listing-card .map-result-pagination p {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #333;
  font-family: "Tw Cen MT Bold", sans-serif;
  margin: 0;
}
.search-page .map-container .marker-label {
  bottom: 2px;
  position: relative;
}
.search-page .map-container .marker-label.active-marker {
  bottom: 3px;
}
.search-page .icon-filter {
  fill: #000;
}
.search-page .mobile-search-page-controls {
  background-color: #fff;
  border-top: 1px solid #eeeeee;
  box-shadow: 0px 11px 8px -8px rgba(85, 85, 85, 0.35);
  width: 100vw;
  margin-left: -20px;
  position: sticky;
  top: 0;
  z-index: 7;
  transition: top 0.3s ease-in-out;
}
.search-page .mobile-search-page-controls .filter-count {
  font-size: 0.75rem;
  line-height: 1.5;
  color: #fff;
  background-color: #9bc158;
  align-items: center;
  display: flex;
  position: absolute;
  justify-content: center;
  top: 8px;
  left: 45px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  pointer-events: none;
}
.search-page .mobile-search-page-controls .control-toggle.reset {
  display: none;
}
.scroll-up:not(.navbar-fixed) + .layout-full-width .search-page .mobile-search-page-controls {
  top: 69px;
}
.navbar-fixed.scroll-up + .layout-full-width .search-page .mobile-search-page-controls {
  top: 69px;
}
.navbar-fixed.scroll-up.scroll-down + .layout-full-width .search-page .mobile-search-page-controls {
  top: 0;
}
.mobile-nav-open .search-page .mobile-search-page-controls {
  top: 100vh !important;
}
.search-page .search-page-controls .control-toggles {
  display: none;
}
.search-page .controls-container {
  background-color: #fff;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: auto;
  pointer-events: none;
  position: fixed;
  top: -20vh;
  transition: visibility 0s ease-in-out 400ms, opacity 0.2s ease-in-out, top 0.2s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 9;
}
.mobile-controls-open .search-page .controls-container {
  display: block;
  opacity: 1;
  pointer-events: auto;
  top: 0;
  transition-delay: 0s;
  visibility: visible;
}
.search-page .controls-container .mobile-controls-results-btn {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #0c5688;
  bottom: 0;
  box-shadow: 0px 5px 5px #8b8b90;
  color: #fff;
  font-weight: 500;
  left: 1em;
  position: fixed;
  width: calc(100% - 2em);
  z-index: 2;
}
.search-page .controls-container .mobile-controls-results-btn .search-type-count {
  font-weight: 400;
  margin-left: auto;
  margin-right: 0.5rem;
}
.search-page .controls-container .mobile-controls-results-btn .search-type-match {
  text-transform: capitalize;
}
.search-page .controls-container .mobile-controls-results-btn .icon-arrow-up {
  fill: none;
  margin-left: 0;
  stroke: #fff;
  transform: rotate(90deg);
}
.search-page .controls-container .mobile-controls-results-btn:hover, .search-page .controls-container .mobile-controls-results-btn:focus {
  background-color: #8b8b90;
  color: #fff;
}
.search-page .controls-container .mobile-controls-results-btn:hover .icon-arrow-up, .search-page .controls-container .mobile-controls-results-btn:focus .icon-arrow-up {
  stroke: #fff;
}
.search-page .clear-all-btn {
  color: #53b9d1;
  cursor: pointer;
  text-decoration: underline;
}
.search-page .clear-all-btn:hover {
  color: #2178bc;
  text-decoration: none;
}
.search-page .controls-top {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.search-page .view-mode-controls-container {
  align-items: center;
  display: none;
  justify-content: space-between;
}
.search-page .view-btn {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 0.625rem;
}
.search-page .view-btn:last-of-type {
  margin-right: 0;
}
.search-page .view-btn:hover, .search-page .view-btn:focus {
  cursor: pointer;
}
.search-page .view-btn:hover .icon, .search-page .view-btn:focus .icon {
  fill: #181922;
}
.search-page .view-btn[disabled] {
  cursor: default;
}
.search-page .view-btn[disabled] .icon {
  fill: #181922;
}
.search-page .view-btn .icon {
  fill: #8b8b90;
  height: 17px;
  width: 17px;
}
.search-page .results-controls {
  margin-bottom: 10px;
}
.search-page .results-controls .controls-bottom {
  display: flex;
  justify-content: space-between;
}
.search-page .results-controls .controls-bottom .mobile-filters-toggle,
.search-page .results-controls .controls-bottom .mobile-sort-toggle {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: transparent;
  border: none;
  color: #fff;
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding: none;
}
.search-page .results-count {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  align-items: center;
  color: #000;
  display: flex;
  font-family: "Tw Cen MT Bold", sans-serif;
  text-transform: uppercase;
}
.search-page .controls-container .control-panel {
  display: none;
}
.search-page .controls-container .control-panel.active {
  display: block;
}
.search-page .clipboard-notification {
  margin-left: 1rem;
  opacity: 0;
  transition: opacity 0.4s;
}
.search-page .clipboard-notification.active {
  opacity: 1;
}
.search-page .search-page-results .no-results-message {
  margin: 4rem auto;
  max-width: 650px;
}
.search-page .search-page-results .no-results-message .heading-1 {
  font-size: 2.125rem;
  line-height: 1.1176470588;
  margin-bottom: 1rem;
}
.search-page .search-page-results .no-results-message .heading-2 {
  font-size: 1.875rem;
  line-height: 1;
}
.search-page .search-page-results .no-results-message li {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.search-page .results-list-container {
  position: relative;
  min-height: 320px;
}
.search-page .results-list-container.loading {
  pointer-events: none;
}
.search-page .results-list-container.loading .loading-overlay {
  opacity: 1;
}
.search-page .contracted-link-container {
  display: none;
}
.search-page .contracted-link {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #53b9d1;
  display: none;
  text-decoration: none;
}
.search-page .contracted-link:hover, .search-page .contracted-link:focus {
  text-decoration: underline;
}
.search-page .filter-title-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.search-page .filter-title-bar .filter-btn {
  margin-bottom: 0;
}
.search-page .filter-heading {
  font-size: 1.125rem;
  line-height: 1.3888888889;
  display: none;
  font-weight: 500;
}
.search-page .filter-title {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-weight: 400;
  margin: 0 0 1rem;
  padding-left: 20px;
}
.search-page .tag-list {
  margin-top: 0;
  padding: 0 0 1rem;
  position: relative;
}
.search-page .sort-panel .control-toggle-btns {
  padding: 1rem 30px 6rem;
}
.search-page .react-autosuggest__container {
  position: relative;
  z-index: 1;
}
.search-page .react-autosuggest__input {
  border-radius: 0;
}
.search-page .react-autosuggest__input--open + .react-autosuggest__suggestions-container, .search-page .react-autosuggest__input:focus + .react-autosuggest__suggestions-container {
  display: block;
}
.search-page .location-search-input:focus + .react-autosuggest__suggestions-container {
  display: block;
}
.search-page .distance-link .location {
  color: #0c5688;
}
.search-page .react-autosuggest__suggestions-container {
  background-color: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px -5px 23px 4px rgba(0, 0, 0, 0.15);
  display: none;
  margin-top: -20px;
  padding: 30px 10px 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: -1;
}
.search-page .react-autosuggest__suggestions-container [aria-selected=true] .typeahead-suggestion {
  background-color: #b1b1b3;
}
.search-page .react-autosuggest__suggestions-container .filter-title {
  color: #525259;
}
.search-page .typeahead-suggestion {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  margin: 0 0 0.75rem;
  padding: 0 0 0 0.5rem;
  padding: 0.625rem 1.25rem;
  width: 100%;
}
.search-page .typeahead-suggestion:hover, .search-page .typeahead-suggestion:focus, .search-page .typeahead-suggestion[aria-selected=true], .search-page .typeahead-suggestion.selected {
  background-color: #b1b1b3;
  cursor: pointer;
}
.search-page .typeahead-suggestion mark {
  background-color: #a1d45f;
}
.search-page .typeahead-suggestion .suggestion-name {
  flex-grow: 1;
}
.search-page .typeahead-suggestion .suggestion-number {
  padding-left: 1rem;
}
.search-page .filter-btn-container {
  display: flex;
}
.search-page .filter-btn-container:last-of-type .filter-btn {
  margin-bottom: 0;
}
.search-page .filter-btn-container .react-autosuggest__container {
  flex: 1;
}
.search-page .filter-btn-container .geolocate-btn {
  flex: 0;
  height: 60px;
  margin-left: 0.75rem;
  flex-basis: 90px;
  max-width: 90px;
}
.search-page .geolocate-btn {
  align-items: center;
  color: #2178bc;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}
.search-page .geolocate-btn:hover, .search-page .geolocate-btn:focus {
  color: #000;
}
.search-page .geolocate-btn.geolocating {
  justify-content: flex-start;
  text-decoration: none;
}
.search-page .geolocate-btn.geolocating:after {
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "…"; /* ascii code for the ellipsis character */
  display: inline-block;
  overflow: hidden;
  position: absolute;
  bottom: 11px;
  left: calc(100% - 20px);
  vertical-align: bottom;
  width: 0px;
}
@keyframes ellipsis {
  to {
    width: 20px;
  }
}
.search-page .location-typeahead .react-autosuggest__container {
  margin-bottom: 0.5rem;
}
.search-page .distance-filter-wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
.search-page .distance-radio {
  display: flex;
  flex: 0 0 160px;
}
.search-page .distance-radio .distance-radio-wrapper {
  flex: 1;
  height: 40px;
  width: 40px;
  position: relative;
  margin-right: 0.375rem;
}
.search-page .distance-radio .distance-radio-wrapper input[type=radio] {
  height: 0;
  width: 0;
}
.search-page .distance-radio .distance-radio-wrapper input[type=radio]:checked + label {
  background-color: #525259;
  color: #fff;
}
.search-page .distance-radio .distance-radio-wrapper input[type=radio]:focus + label, .search-page .distance-radio .distance-radio-wrapper input[type=radio]:hover + label {
  background-color: #b1b1b3;
}
.search-page .distance-radio .distance-radio-wrapper input[type=radio]:focus:checked + label, .search-page .distance-radio .distance-radio-wrapper input[type=radio]:hover:checked + label {
  background-color: #525259;
  color: #fff;
}
.search-page .distance-radio .distance-radio-wrapper label {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 25px;
  color: #000;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 40px;
}
.search-page .distance-radio .distance-radio-wrapper label:hover {
  cursor: pointer;
}
.search-page .distance-label {
  font-size: 0.75rem;
  line-height: 1.5;
}
.search-page .filter-btn-wrapper {
  display: flex;
}
.search-page .filter-btn-wrapper .filter-btn {
  background-color: rgba(255, 255, 255, 0.5);
}
.search-page .filter-btn-wrapper .filter-btn span {
  color: #4e8209;
  margin-left: auto;
}
.search-page .filter-btn-wrapper .filter-btn:hover {
  background-color: #eefafe;
  cursor: pointer;
}
.search-page .filter-btn-wrapper .filter-btn:hover span {
  color: #000;
  cursor: pointer;
}
.search-page .preferred-provider-info {
  display: none;
  padding: 0 1.25rem 1.5rem;
}
.search-page .preferred-provider-info.active {
  display: block;
}
.search-page .search-print {
  display: none;
}
.search-page .provider-result-card .provider-picture {
  aspect-ratio: 41/49;
  object-fit: cover;
  object-position: top;
}
@media (min-width: 768px) {
  .search-page {
    margin-top: -50px;
  }
  .search-page .mobile-search-page-controls {
    margin-left: -30px;
  }
  .search-page .map-container {
    position: relative;
    width: 100%;
    margin-left: 0;
    margin-bottom: -64px;
  }
  .search-page .map-container .location-listing-card {
    position: absolute;
    top: 15px;
    bottom: auto;
    left: 15px;
    width: 70%;
    margin-bottom: 0;
    padding: 31px 42px 31px;
    padding-top: 40px;
  }
  .search-page .map-container .location-listing-card.multi-location-card {
    padding-top: 50px;
  }
  .search-page .map-container .location-listing-card.multi-location-card .location-picture {
    width: 164px;
  }
  .search-page .map-container .location-listing-card .location-picture {
    width: 164px;
  }
  .search-page .map-container .location-listing-card .map-result-close-btn {
    top: 12px;
    right: 12px;
  }
  .search-page .map-container .location-listing-card .map-result-pagination {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    padding: 10px 50px 10px 42px;
    margin: 0;
  }
  .search-page .map-container .location-listing-card .text-link {
    font-size: 0.875rem;
    line-height: 1.4285714286;
  }
  .search-page .search-page-controls {
    display: block;
  }
  .search-page .search-page-wrapper.map-view .results-list-container {
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .search-page .results-controls {
    margin-left: 15px;
    margin-bottom: 1rem;
  }
  .search-page .results-list {
    padding: 0 30px;
  }
}
@media screen and (min-width: 768px) {
  .search-page .results-list.view-mode-grid {
    display: flex;
    flex-wrap: wrap;
  }
  .search-page .results-list.view-mode-grid .results-list-item {
    flex: calc(50% - 15px);
    margin-bottom: 3.25rem;
    max-width: calc(50% - 15px);
  }
  .search-page .results-list.view-mode-grid .results-list-item:nth-of-type(2n + 1) {
    margin-right: 15px;
  }
  .search-page .results-list.view-mode-grid .results-list-item:nth-of-type(2n + 2) {
    margin-left: 15px;
  }
  .search-page .results-list.view-mode-grid .search-result {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
  }
  .search-page .results-list.view-mode-grid .search-result .result-top {
    height: 100%;
  }
  .search-page .results-list.view-mode-grid .search-result .result-details {
    display: flex;
    flex-direction: column;
  }
  .search-page .results-list.view-mode-grid .search-result .detail-bottom {
    border: none;
    margin-top: auto;
    padding-top: 0;
  }
  .search-page .results-list.view-mode-grid .search-result .ratings {
    height: 21px;
  }
}
@media (min-width: 768px) {
  .search-page .map-container {
    height: 0;
    padding-bottom: 71.62%;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .container .search-page {
    margin-left: -30px;
  }
}
@media (min-width: 1280px) {
  .search-page {
    padding-top: 70px;
  }
  .container .search-page {
    margin-left: -70px;
  }
  .search-page .title-and-description {
    border-bottom: 1px solid #c9cbcb;
    margin-bottom: 15px;
    padding-bottom: 22px;
  }
  .search-page .results-count {
    margin-left: 0;
    margin-top: 0;
  }
  .search-page .results-count .count {
    display: inline-block;
    margin-left: 0.5rem;
  }
  .search-page .input-icon-container {
    z-index: 3;
  }
  .search-page .results-list {
    padding: 0;
  }
  .search-page .selected-filters-list .tag-list {
    padding-bottom: 1rem;
  }
  .search-page .selected-filters-list + .input-icon-container {
    border-top: none;
  }
  .search-page .mobile-search-page-controls {
    display: none;
  }
  .search-page .search-page-controls {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
    position: relative;
  }
  .search-page .search-page-controls .control-toggles {
    display: flex;
  }
  .search-page .mobile-close-container,
  .search-page .mobile-filter-actions,
  .search-page .mobile-controls-results-btn {
    display: none;
  }
  .search-page .results-controls {
    align-items: center;
    background-color: transparent;
    color: #000;
    display: none;
    justify-content: space-between;
    margin-left: 0;
    padding: 0;
    position: relative;
    top: 0;
  }
  .search-page .view-mode-controls-container {
    display: flex;
    margin-right: 30px;
    margin-top: 1.5rem;
  }
  .search-page .filter-heading {
    display: block;
  }
  .search-page .filter-btn-container .geolocate-btn {
    height: 52px;
  }
  .search-page .filter-btn-container .geolocate-btn.geolocating:after {
    bottom: 6px;
  }
  .search-page .sort-panel .control-toggle-btns {
    padding: 0;
  }
  .search-page .search-page-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
    align-items: stretch;
  }
  .search-page .search-page-wrapper.map-view .search-page-controls {
    display: none;
  }
  .search-page .search-page-wrapper.map-view .map-view-collapse-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .search-page .search-page-wrapper.map-view .search-page-results {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .search-page .search-page-wrapper.map-view.controls-closed .search-page-results {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .search-page .search-page-wrapper.map-view.controls-closed .open-filters-btn {
    display: block;
  }
  .search-page .search-page-wrapper.map-view.controls-closed .location-listing-card {
    width: 50%;
  }
  .search-page .search-page-wrapper.controls-closed .controls-container {
    display: none;
  }
  .search-page .search-page-wrapper.controls-closed .search-page-results {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .search-page .search-page-wrapper.controls-closed .results-list.view-mode-grid .results-list-item {
    flex: calc(33.333% - 20px);
    max-width: calc(33.333% - 20px);
  }
  .search-page .search-page-wrapper.controls-closed .results-list.view-mode-grid .results-list-item:nth-of-type(3n + 1) {
    margin-left: 0;
    margin-right: 15px;
  }
  .search-page .search-page-wrapper.controls-closed .results-list.view-mode-grid .results-list-item:nth-of-type(3n + 2) {
    margin-left: 15px;
    margin-right: 15px;
  }
  .search-page .search-page-wrapper.controls-closed .results-list.view-mode-grid .results-list-item:nth-of-type(3n + 3) {
    margin-left: 15px;
    margin-right: 0;
  }
  .search-page .search-page-wrapper.controls-closed .list-result {
    flex-direction: row;
    min-height: 230px;
  }
  .search-page .search-page-wrapper.controls-closed .list-result .result-top {
    flex: 0 1 35%;
  }
  .search-page .search-page-wrapper.controls-closed .list-result .location-scheduling {
    flex: 0 1 65%;
    margin-top: 0;
  }
  .search-page .search-page-wrapper.controls-closed .list-result .profile-image-outer {
    flex: 0 1 180px;
  }
  .search-page .search-page-wrapper.controls-closed .list-result .profile-image-container {
    height: 100%;
    padding-bottom: 0;
  }
  .search-page .search-page-wrapper.controls-closed .list-result .location {
    flex: 2;
  }
  .search-page .search-page-wrapper.controls-closed .list-result .location.not-available {
    background-color: #f0ebe6;
  }
  .search-page .search-page-wrapper.controls-closed .list-result .location.not-available:hover {
    background-color: #f0ebe6;
  }
  .search-page .search-page-wrapper.controls-closed .list-result .schedule-or-call {
    display: flex;
    flex: 3;
    flex-direction: row;
  }
  .search-page .search-page-wrapper.controls-closed .list-result .schedule-or-call .schedule-btn {
    flex-basis: 60%;
    max-width: 60%;
  }
  .search-page .search-page-wrapper.controls-closed .list-result .schedule-or-call .schedule-btn:only-child {
    flex-basis: 100%;
    max-width: 100%;
  }
  .search-page .search-page-wrapper.controls-closed .list-result .schedule-or-call .call-link {
    flex-basis: 40%;
    max-width: 40%;
  }
  .search-page .search-page-wrapper.controls-closed .list-result .schedule-or-call .call-link:only-child {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .search-page .search-page-wrapper.controls-closed .grid-result .schedule-btn,
  .search-page .search-page-wrapper.controls-closed .grid-result .call-link {
    flex-basis: auto;
    flex-grow: 1;
  }
}
@media (min-width: 1280px) {
  .search-page .search-page-wrapper.controls-closed .location-result .location-scheduling.no-scheduling .location {
    flex: 0 1 50%;
  }
  .search-page .search-page-wrapper.controls-closed .location-result .location-scheduling .location {
    flex: 0 1 33.333%;
  }
  .search-page .search-page-wrapper.controls-closed .location-result .location-scheduling .location:only-child {
    flex-basis: 100%;
  }
  .search-page .search-page-wrapper.controls-closed .location-result .location-scheduling .schedule-or-call {
    flex: 1;
    flex-direction: row;
  }
  .search-page .search-page-wrapper.controls-closed .map-container {
    padding-bottom: 60%;
  }
  .search-page .search-page-wrapper.controls-closed .map-container .map-result .schedule-or-call {
    flex-direction: column;
  }
  .search-page .controls-container {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
    background-color: transparent;
    height: auto;
    left: 0;
    opacity: 1;
    overflow: visible;
    pointer-events: auto;
    position: relative;
    top: 0;
    transition: none;
    visibility: visible;
    z-index: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .search-page .controls-container::-webkit-scrollbar {
    display: none;
  }
  .search-page .controls-container .control-toggles {
    display: none;
  }
  .search-page .controls-top {
    align-items: center;
  }
  .search-page .view-mode-controls-container {
    margin: 0;
  }
  .search-page .control-panel {
    background-color: #f2f2f2;
    margin-bottom: 20px;
  }
  .search-page .sort-panel .radio-wrapper label {
    border: none;
  }
  .search-page .mobile-filters-toggle,
  .search-page .mobile-sort-toggle {
    display: none;
  }
  .search-page .search-page-results {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 12px;
    padding-left: 13px;
  }
  .search-page .search-page-results .results-controls {
    display: flex;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .search-page .map-container {
    padding-bottom: 80%;
  }
}

.topic-index {
  padding: 2rem 0 3rem;
}
.topic-index .index-link-list {
  margin-bottom: 2rem;
}
.topic-index .index-link-list li {
  font-family: "Arial", sans-serif;
}
.topic-index .index-link-list li:first-child .text-link {
  padding-top: 0;
}
.topic-index .text-link {
  display: inline-block;
  padding: 12px 0;
}
.topic-index .section-title {
  margin-bottom: 45px;
}
@media (min-width: 768px) {
  .topic-index {
    padding: 41px 0 78px;
  }
  .topic-index .container {
    flex-direction: row;
  }
  .topic-index .index-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
    flex-grow: 1;
  }
  .topic-index .index-col {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .topic-index .index-link-list {
    margin-bottom: 3rem;
  }
}
@media (min-width: 1280px) {
  .topic-index .index-col {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .topic-index .index-link-list.three-column {
    columns: 2;
  }
}
@media (min-width: 1440px) {
  .topic-index .index-col {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .topic-index .index-link-list.three-column {
    columns: 3;
  }
}

/* Templates (e.g. Home, Full Width, Two Column) */
.layout-campaign main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .layout-campaign main {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}

.layout-home {
  background-color: #f6f3f1;
}

.layout-full-width {
  background-color: #f6f3f1;
  position: relative;
}
.layout-full-width main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .layout-full-width main {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (min-width: 1280px) {
  .layout-full-width main {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 1440px) {
  .layout-full-width main {
    padding-right: 70px;
    padding-left: 70px;
  }
}
@media (min-width: 768px) {
  .layout-full-width main {
    padding-bottom: 14px;
    padding-top: 50px;
  }
}

.layout-full-width-compact {
  background-color: #f6f3f1;
  position: relative;
}
.layout-full-width-compact main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .layout-full-width-compact main {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}

.layout-narrow {
  background-color: #f6f3f1;
}
.layout-narrow main {
  margin-right: auto;
  margin-left: auto;
  max-width: 393px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 2rem;
}
@media (min-width: 768px) {
  .layout-narrow main {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}

.layout-two-column {
  background-color: #f6f3f1;
}
@media (min-width: 1280px) {
  .layout-two-column .layout-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;
    justify-content: space-between;
  }
  .layout-two-column .column-main {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
    margin-bottom: 0;
    padding: 0 12px;
  }
  .layout-two-column .column-sidebar {
    flex: 1 1 0;
    margin-bottom: 0;
    padding: 0 12px;
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: 0;
    padding: 0 12px;
  }
}

/* Print (e.g. Provider Print, Location Print) */
@media print {
  body,
  html {
    width: 1100px;
  }
  * {
    transition: none !important;
  }
  header,
  footer,
  aside,
  nav,
  form,
  iframe {
    display: none !important;
  }
  .search-print {
    padding: 1.75em;
  }
  .search-print h3 {
    padding-left: 0.5em;
  }
  .search-print ul {
    padding-inline-start: 0;
    list-style: none;
  }
  .search-print li {
    padding-left: 0.5em;
  }
  .profile .profile-content {
    display: table;
    height: 100%;
  }
}
@media print and (max-width: 768px) {
  .provider-mobile-print,
  .location-mobile-print {
    display: block !important;
    page-break-inside: avoid;
  }
}
@media print {
  .profile-content,
  .provider-result,
  .provider-background,
  .provider-location,
  .provider-insurance,
  .provider-ratings,
  .results-list-item {
    display: block !important;
    page-break-inside: avoid;
  }
  .expandable .inner-wrap {
    max-height: none;
  }
  .profile .profile-content {
    height: auto;
  }
  .search-result.grid-result,
  .search-result.list-result {
    flex-direction: row;
  }
  .search-result.grid-result .result-top,
  .search-result.list-result .result-top {
    flex: 0 1 45%;
  }
  .location-about,
  .location-appointments,
  .location-providers,
  .location-services,
  .location-instructions,
  .location-parking,
  .location-custom {
    display: block !important;
    page-break-inside: avoid;
  }
  .search-print {
    page-break-after: avoid;
    display: block !important;
    height: auto;
  }
  .search-page-wrapper {
    display: block !important;
    height: 100%;
  }
  .profile-navigation,
  .section-nav,
  .profile-mobile-actions-inner,
  .profile-actions,
  .expand-collapse-btn,
  .controls-container {
    display: none !important;
  }
  .profile-navigation,
  .pagination-nav,
  .back-to-top-btn,
  .search-page-controls {
    visibility: hidden;
  }
  .reveal-content {
    max-height: none !important;
  }
  .section-controls {
    padding: 0;
  }
  .search-result.grid-result, .search-result.list-result {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding-bottom: 0;
  }
  .search-result.grid-result .result-top, .search-result.list-result .result-top {
    display: flex;
  }
  .search-result.grid-result .profile-image-outer, .search-result.list-result .profile-image-outer {
    flex: 0 0 50%;
  }
  .search-result.grid-result .profile-image-container, .search-result.list-result .profile-image-container {
    padding-bottom: 61%;
  }
  .search-result.grid-result .result-details, .search-result.list-result .result-details {
    flex: 0 0 50%;
    padding: 1.25rem 1.5rem;
  }
  .search-result.grid-result .location-scheduling, .search-result.list-result .location-scheduling {
    display: flex;
  }
  .search-result.grid-result .location,
  .search-result.grid-result .schedule-or-call, .search-result.list-result .location,
  .search-result.list-result .schedule-or-call {
    flex: 0 0 50%;
    min-height: 140px;
  }
  .search-result.grid-result .location:only-child, .search-result.list-result .location:only-child {
    flex: 0 0 100%;
  }
  .search-result.grid-result .schedule-or-call .schedule-btn, .search-result.list-result .schedule-or-call .schedule-btn {
    flex-basis: 61%;
  }
  .search-result.grid-result .schedule-or-call .call-link, .search-result.list-result .schedule-or-call .call-link {
    flex-basis: 39%;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result {
    flex-direction: row;
    min-height: 230px;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .result-top {
    flex: 0 1 35%;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .location-scheduling {
    flex: 0 1 65%;
    margin-top: 0;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .profile-image-outer {
    flex: 0 1 180px;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .profile-image-container {
    height: 100%;
    padding-bottom: 0;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .location {
    background-color: #c7e6a1;
    flex: 2;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .location:hover {
    background-color: #eefafe;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .location.not-available {
    background-color: #f0ebe6;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .location.not-available:hover {
    background-color: #f0ebe6;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .schedule-or-call {
    display: flex;
    flex: 3;
    flex-direction: row;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .schedule-or-call .schedule-btn {
    background-color: #a1d45f;
    flex-basis: 60%;
    max-width: 60%;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .schedule-or-call .schedule-btn:hover {
    background-color: #eefafe;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .schedule-or-call .schedule-btn:only-child {
    flex-basis: 100%;
    max-width: 100%;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .schedule-or-call .call-link {
    background-color: #7cba25;
    flex-basis: 40%;
    max-width: 40%;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .schedule-or-call .call-link:hover {
    background-color: #eefafe;
  }
  .search-page .search-page-wrapper .controls-closed .grid-result .schedule-or-call .call-link:only-child {
    flex-basis: 100%;
    max-width: 100%;
  }
  .search-page .search-page-wrapper .grid-result,
  .search-page .search-page-wrapper .list-result {
    flex-direction: row;
    min-height: 230px;
  }
  .search-page .search-page-wrapper .grid-result .result-top,
  .search-page .search-page-wrapper .list-result .result-top {
    flex: 0 1 35%;
  }
  .search-page .search-page-wrapper .grid-result .location-scheduling,
  .search-page .search-page-wrapper .list-result .location-scheduling {
    flex: 0 1 65%;
    margin-top: 0;
  }
  .search-page .search-page-wrapper .grid-result .profile-image-outer,
  .search-page .search-page-wrapper .list-result .profile-image-outer {
    flex: 0 1 180px;
  }
  .search-page .search-page-wrapper .grid-result .profile-image-container,
  .search-page .search-page-wrapper .list-result .profile-image-container {
    height: 100%;
    padding-bottom: 0;
  }
  .search-page .search-page-wrapper .grid-result .location,
  .search-page .search-page-wrapper .list-result .location {
    background-color: #c7e6a1;
    flex: 2;
  }
  .search-page .search-page-wrapper .grid-result .location:hover,
  .search-page .search-page-wrapper .list-result .location:hover {
    background-color: #eefafe;
  }
  .search-page .search-page-wrapper .grid-result .location.not-available,
  .search-page .search-page-wrapper .list-result .location.not-available {
    background-color: #f0ebe6;
  }
  .search-page .search-page-wrapper .grid-result .location.not-available:hover,
  .search-page .search-page-wrapper .list-result .location.not-available:hover {
    background-color: #f0ebe6;
  }
  .search-page .search-page-wrapper .grid-result .schedule-or-call,
  .search-page .search-page-wrapper .list-result .schedule-or-call {
    display: flex;
    flex: 3;
    flex-direction: row;
  }
  .search-page .search-page-wrapper .grid-result .schedule-or-call .schedule-btn,
  .search-page .search-page-wrapper .list-result .schedule-or-call .schedule-btn {
    background-color: #a1d45f;
    flex-basis: 60%;
    max-width: 60%;
  }
  .search-page .search-page-wrapper .grid-result .schedule-or-call .schedule-btn:hover,
  .search-page .search-page-wrapper .list-result .schedule-or-call .schedule-btn:hover {
    background-color: #eefafe;
  }
  .search-page .search-page-wrapper .grid-result .schedule-or-call .schedule-btn:only-child,
  .search-page .search-page-wrapper .list-result .schedule-or-call .schedule-btn:only-child {
    flex-basis: 100%;
    max-width: 100%;
  }
  .search-page .search-page-wrapper .grid-result .schedule-or-call .call-link,
  .search-page .search-page-wrapper .list-result .schedule-or-call .call-link {
    background-color: #7cba25;
    flex-basis: 40%;
    max-width: 40%;
  }
  .search-page .search-page-wrapper .grid-result .schedule-or-call .call-link:hover,
  .search-page .search-page-wrapper .list-result .schedule-or-call .call-link:hover {
    background-color: #eefafe;
  }
  .search-page .search-page-wrapper .grid-result .schedule-or-call .call-link:only-child,
  .search-page .search-page-wrapper .list-result .schedule-or-call .call-link:only-child {
    flex-basis: 100%;
    max-width: 100%;
  }
  @page {
    size: 330mm 427mm;
    margin: 14mm;
  }
}